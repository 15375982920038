import Vue from "vue";
import {
  Carousel,
  Switch,
  Checkbox,
  Select,
  Input,
  Card,
  Dropdown,
  Menu,
  Modal,
  Radio,
  Table,
  Button,
  Popover,
  ConfigProvider,
  DatePicker,
  TimePicker,
  Progress,
  Tooltip,
  InputNumber,
  Tag,
  Avatar,
  Icon,
  Drawer,
  Steps,
  Result,
  AutoComplete,
  Popconfirm,
  Badge,
  Alert,
  Divider,
  Upload,
  Pagination,
  Empty
} from "ant-design-vue";

Vue.use(Carousel);
Vue.use(Switch);
Vue.use(Checkbox);
Vue.use(Select);
Vue.use(Input);
Vue.use(Card);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(Radio);
Vue.use(Table);
Vue.use(Button);
Vue.use(Popover);
Vue.use(ConfigProvider);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Progress);
Vue.use(Tooltip);
Vue.use(InputNumber);
Vue.use(Tag);
Vue.use(Avatar);
Vue.use(Icon);
Vue.use(Drawer);
Vue.use(Steps);
Vue.use(Result);
Vue.use(AutoComplete);
Vue.use(Popconfirm);
Vue.use(Badge);
Vue.use(Alert);
Vue.use(Divider);
Vue.use(Upload);
Vue.use(Pagination);
Vue.use(Empty);
