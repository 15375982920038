<script>
import { reactive, onMounted, onBeforeUnmount } from "@vue/composition-api";
import { API } from "src/views/documents/api";

export default {
    setup(props, ctx) {
        const controls = reactive({
            loading: true,
            visible: false,
            isPreview: true,
            sign_url: null
        })
        const getPreviewSignURL = async (template_id) => {
            controls.loading = true;
            controls.sign_url = null;
            try {
                const { data } = await API.getPreviewSignURL(template_id);
                controls.sign_url = data.sign_url;
            } catch (error) {
                console.log("getPreviewSignURL: ", error)
            } finally {
                controls.loading = false;
            }
        }
        const toggleModal = (onOff, sign_url) => {
            controls.visible = onOff;
            controls.isPreview = false;
            controls.loading = true;
            setTimeout(() => {
                controls.loading = false;
            }, 2000);
            // Prevent loading preview signing URL in localhost
            if (onOff && process.env.VUE_APP_ENV !== "staging") {
                controls.sign_url = sign_url;
            }
        }
        const togglePreview = (onOff, template_id) => {
            controls.visible = onOff;
            controls.isPreview = true;
            // Prevent loading preview signing URL in localhost
            if (onOff && process.env.VUE_APP_ENV !== "staging") {
                getPreviewSignURL(template_id);
            }
        }
        onMounted(() => {
            ctx.root.$bus.$on("toggle-document-sign-embed-modal", toggleModal);
            ctx.root.$bus.$on("toggle-document-sign-embed-preview", togglePreview);
        })
        onBeforeUnmount(() => {
            ctx.root.$bus.$off("toggle-document-sign-embed-modal", toggleModal);
            ctx.root.$bus.$off("toggle-document-sign-embed-preview", togglePreview);
        })
        return {
            controls,
            getPreviewSignURL
        }
    }
}
</script>

<template>
    <a-modal :visible="controls.visible" :body-style="{ height: '90vh', overflow: 'auto' }" width="95vw"
        :mask-closable="false" class="document-sign-embed-modal" :footer="null" @cancel="() => {
            controls.visible = false; 
            $bus.$emit('document-sign-refresh');
        }">
        <template #title>
            <div class="flex items-center space-x-2">
                <span class="text-base font-medium">Signing Document <span v-if="controls.isPreview"
                        class="text-blue-500">(Preview)</span></span>
                <a-icon type="reload" :spin="controls.loading" @click="getPreviewSignURL()" />
            </div>
        </template>
        <div class="flex items-center justify-center h-full relative">
            <a-button type="link" loading size="large" />
            <iframe v-if="!controls.loading" title="Parqay Dashboard Embedded Signing"
                class="absolute inset-0 z-10 h-full w-full" :src="controls.sign_url" />
            <div v-if="controls.isPreview" class="absolute inset-0 z-20 bg-blue-500 px-6 py-3" :style="{ height: '120px' }">
                <div class="flex flex-col items-center justify-center h-full border-2 border-white rounded-xl">
                    <span class="text-base text-white font-semibold pb-1 border-b-2">This is not legally binding</span>
                    <p class="text-sm text-white pt-1">This is a mock signature request and has no legal value.</p>
                </div>
            </div>
        </div>
    </a-modal>
</template>
<style lang="scss">
.document-sign-embed-modal {
    .ant-modal-body {
        padding: 0;
    }

    .ant-btn-loading {
        .anticon {
            font-size: 35px;
        }
    }

    .ant-modal-close-x {
        .anticon {
            font-size: 25px;
        }
    }
}
</style>