<script>
export default {
    name: "SmartcarCard",
    props: {
        title: {
            type: String,
            default: "You have the following access to this vehicle"
        }
    },
    setup(props, ctx) {

    }
}
</script>
<template>
  <div>
    <div class="flex justify-between items-center">
      <svg-icon
        class="w-32 h-12 flex-grow-0 flex-shrink-0 mr-1"
        icon-class="smartcar"
      />
      <span class="ml-1">
        {{ title }}
      </span>
    </div>
    <div class="flex flex-col justify-center items-center pt-0 pb-2">
      <slot name="message" />
    </div>
    <div class="rounded-lg shadow bg-white">
      <div class="border-b px-4 py-4 flex justify-between items-center">
        <slot name="info" />
      </div>
      <div class="flex justify-between px-4 py-2">
        <slot name="button" />
      </div>
    </div>
  </div>
</template>