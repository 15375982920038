import request from "src/services/api/request";

export const API = {
  list(filter = {}) {
    return request.get("reservations", {
      filter: {
        role: "host",
        ...filter
      }
    });
  },
  details(id, filter = {}) {
    return request.get(`reservations/${id}`, {
      filter
    });
  },
  sendEmailReminder(id) {
    return request.post(`reservations/${id}/remind`);
  },
  create(form, on_behalf_of = null) {
    console.log("API >> create resevation: ", form);
    console.log("on_behalf_of >> ", on_behalf_of);
    return request.post("reservations/new", { form, on_behalf_of });
  },
  getReservationPriceAutomatic(
    interval_mode,
    intervals,
    location,
    pricing_rules,
    addOnItems = {}
  ) {
    const query_meta = {
      type: "reservation",
      interval_mode,
      intervals,
      add_on_items: { ...addOnItems }
    };
    return request.post("pricing/listing/calc", {
      location,
      query_meta,
      pricing_rules
    });
  },
  getReservationPriceManual(total_price, location, addOnItems = {}) {
    const query_meta = {
      type: "manual",
      add_on_items: { ...addOnItems }
    };
    return request.post("pricing/listing/calc", {
      location,
      total_price,
      query_meta
    });
  },
  updateReservationVehicle(id, vehicle) {
    return request.put(`reservations/${id}/vehicle`, { vehicle });
  },
  deleteReservationVehicle(id, vehicle_id) {
    return request.delete(`reservations/${id}/vehicle/${vehicle_id}`);
  },
  approveReservation(id) {
    return request.post(`reservations/${id}/actions/approve`);
  },
  denyReservation(id, deny_message) {
    return request.post(`reservations/${id}/actions/deny`, {
      deny_message
    });
  },
  releaseReservationBookedIntervals({ listing_id, reservation_id, reason }) {
    return request.delete(
      `listings/${listing_id}/spaces/booked-intervals/release/${reservation_id}`, {
      reason
    }
    );
  }
};
