import request from "src/services/api/request";
import { searchAround } from "src/services/util/geohash";
import { formatMomentForBackendUp } from "src/plugins/prototype";

export const API = {
  search(l, listing_type) {
    const geohashes = searchAround(l);
    return request.post(`listings/search/${listing_type}`, { l: geohashes });
  },
  searchListingsForReservations({ listing_type, keyword }) {
    const payload = {
      filter: {
        keyword
      }
    }
    if (listing_type) {
      payload.filter.type = listing_type
    }
    return request.get("listings/reservations/search", payload);
  },
  searchAvailabilities(
    interval_mode,
    start_time,
    end_time,
    booked_intervals,
    availability_meta
  ) {
    return request.post(`timekit/search/${interval_mode}`, {
      start_time: formatMomentForBackendUp(start_time),
      end_time: formatMomentForBackendUp(end_time),
      booked_intervals,
      availability_meta
    });
  },
  searchSpacesAvailabilities(
    interval_mode,
    start_time,
    end_time,
    location,
    spaces,
    availability_meta,
    presale_schedule
  ) {
    return request.post(`timekit/search/bulk/${interval_mode}`, {
      start_time: formatMomentForBackendUp(start_time),
      end_time: formatMomentForBackendUp(end_time),
      location,
      spaces,
      availability_meta,
      presale_schedule
    });
  },
  suggestAvailabilities(
    interval_mode,
    start_time,
    end_time,
    booked_intervals,
    availability_meta
  ) {
    return request.post(`timekit/suggest/${interval_mode}`, {
      start_time: formatMomentForBackendUp(start_time),
      end_time: formatMomentForBackendUp(end_time),
      booked_intervals,
      availability_meta
    });
  },
  suggestSpacesAvailabilities(
    interval_mode,
    start_time,
    end_time,
    location,
    spaces,
    availability_meta,
    prefetch
  ) {
    return request.post(`timekit/suggest/bulk/${interval_mode}`, {
      start_time: formatMomentForBackendUp(start_time),
      end_time: formatMomentForBackendUp(end_time),
      location,
      spaces,
      availability_meta,
      prefetch
    });
  },
  getSearchPrice(
    interval_mode,
    space_id,
    start_time,
    end_time,
    location,
    pricing_rules
  ) {
    const query_meta = {
      type: "search",
      interval_mode,
      space_id,
      interval: {
        start: formatMomentForBackendUp(start_time),
        end: formatMomentForBackendUp(end_time)
      }
    };
    return request.post("pricing/listing/calc", {
      location,
      query_meta,
      pricing_rules
    });
  },
  getSuggestPrice(interval_mode, intervals, location, pricing_rules) {
    const query_meta = {
      type: "suggest",
      interval_mode,
      intervals
    };
    return request.post("pricing/listing/calc", {
      location,
      query_meta,
      pricing_rules
    });
  },
  list(payload = {}) {
    return request.get("listings", payload);
  },
  listTenantsListings(payload = {}) {
    return request.get("listings/tenants", payload);
  },
  getStatus(type) {
    return request.get(`listings/status/${type}`);
  },
  details(id) {
    return request.get(`listings/${id}`);
  },
  delete(id) {
    return request.delete(`listings/${id}`);
  },
  updateTitle(id, title) {
    return request.put(`listings/${id}/title`, { title });
  },
  updateActiveState(id, is_active) {
    return request.put(`listings/${id}/active-state`, { is_active });
  },
  updateAutoApproveState(id, is_auto_approve) {
    return request.put(`listings/${id}/auto-approve-state`, {
      is_auto_approve
    });
  },
  fillBookedIntervals(listing_id, space_id, intervals) {
    return request.post(
      `listings/${listing_id}/spaces/${space_id}/booked-intervals`,
      [...intervals]
    );
  },
  edit(id) {
    return request.get(
      `listings/webhook/get_listing_worksheet_url?listing_id=${id}`
    );
  },
  updateSpaces(id, payload) {
    return request.put(`listings/${id}/spaces`, { ...payload });
  },
  getAllActivities(listing_id) {
    return request.get("activities", { listing_id });
  },
  getEventInsights(id) {
    return request.get(`listings/${id}/event-insights`);
  },
  getEventInsight(id, insightName) {
    return request.get(`listings/${id}/event-insights/${insightName}`);
  },
  addImageUrlToListing(id, imageUrl) {
    return request.post(`listings/${id}/add-image`, { image_url: imageUrl });
  },
  updateAddress(id, payload) {
    return request.put(`listings/${id}/location`, payload);
  },
  updatePrimaryContact(id, payload) {
    return request.put(`listings/${id}/primary-contact`, payload);
  },
  getAvailabilityHolidays(year) {
    return request.get(`listings/availability/holidays/${year}`);
  },
  updateAvailability(id, payload) {
    return request.put(`listings/${id}/availability`, payload);
  },
  updatePresaleSchedule(id, payload) {
    return request.put(`listings/${id}/presale-schedule`, payload);
  },
  updatePricingRule(id, payload) {
    return request.put(`listings/${id}/pricing-rule`, payload);
  },
  updateEventPricingRule(id, event_id, payload) {
    return request.put(`listings/${id}/pricing-rule/events/${event_id}`, payload);
  },
  updateTierPricingRule(id, rule_id, payload) {
    return request.put(`listings/${id}/pricing-rule/${rule_id}/tiers`, payload);
  },
  updateSettings(id, payload) {
    return request.put(`listings/${id}/settings`, payload);
  },
  startAutoposter(id) {
    return request.post(`listings/autoposter/${id}`);
  }
};
