<script>
import { ref, onMounted } from "@vue/composition-api";
import ConfirmationNumberTag from "src/views/reservations/components/ConfirmationNumberTag";
import TypeTag from "src/views/reservations/components/TypeTag";
import StatusTag from "src/views/reservations/components/StatusTag";

export default {
  name: "ReservationSearchItem",
  components: {
    TypeTag,
    StatusTag,
    ConfirmationNumberTag
  },
  props: {
    // Determine if current item is recent search item
    recentMode: {
      type: Boolean,
      default: false
    },
    // Determine if current item is starred search item
    starredMode: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    const navToResult = it => {
      console.log("navToResult: ", it);
      ctx.root.$store.commit("addRecentSearch", it);
      console.log("addRecentSearch", ctx.root.$store.state.recentSearches);
      ctx.emit("dismiss");
      ctx.root.$router.push("/dashboard/reservations/" + it.objectID);
    };
    const toggleStar = it => {
      // toggleRecentSearchStar
      ctx.root.$store.commit("toggleStarSearch", it);
      console.log(JSON.stringify(ctx.root.$store.state.starredSearches));
    };
    const starred = m => {
      var starred = ctx.root.$store.getters.checkIsStarred(m.objectID);
      console.log("starred >> " + starred);
      if (props.starredMode) {
        // In starred mode, the star icon does not need to be highlighted
        return false;
      }
      return starred;
    };
    const removeFromRecentSearch = () => {
      console.log("removeFromRecentSearch >> " + props.index);
      ctx.root.$store.commit("removeRecentSearch", props.index);
      console.log(JSON.stringify(ctx.root.$store.state.recentSearches));
    };
    return {
      navToResult,
      toggleStar,
      starred,
      removeFromRecentSearch
    };
  }
};
</script>
<template>
  <div
    class="cursor-pointer rounded-lg flex justify-between bg-white hover:bg-gray-100 border shadow-sm items-center text-sm"
  >
    <div class="flex items-center" @click="navToResult(model)">
      <div class="inline-flex">
        <svg-icon
          :icon-class="starredMode ? 'star' : 'calendar'"
          class="h-16 w-6 mx-4"
        />
      </div>
      <div class="flex flex-col py-2">
        <div class="flex items-center space-x-2">
          <span class="text-base font-medium">
            Reservation
          </span>
          <confirmation-number-tag :confirmation-id="model.confirmation_id" />
          <div class="flex items-center">
            <type-tag type="parking" />
            <status-tag status="complete" />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-y-2 gap-x-4 py-2">
          <span class="my-auto text-parqay-primary font-medium">{{
            model.listing.name
          }}</span>
          <span class="my-auto">{{ model.host_income }}</span>
          <span class="my-auto">{{ model.name }}</span>
          <span class="my-auto">
            created at:
            {{ $formatDate(model.date, "MM/DD/YYYY h:mm a") }}
          </span>
          <span class="my-auto col-span-2 font-medium mt-2">Booking Intervals</span>
          <div class="flex space-x-2 col-span-2 flex-wrap">
            <div
              v-for="(duration, i) in model.durations"
              :key="`reservation-item-duration-${i}`"
              class="flex space-x-2 px-4 py-2"
            >
              <div>
                <span>From: </span>
                <span
                  class="my-auto text-center text-xs font-medium border rounded bg-white text-parqay-primary px-2 py-1 whitespace-nowrap"
                  >{{ $formatDate(duration.from) }}</span
                >
              </div>
              <div>
                <span>To: </span>
                <span
                  class="my-auto text-center text-xs font-medium border rounded bg-white text-parqay-primary px-2 py-1 whitespace-nowrap"
                  >{{ $formatDate(duration.to) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center space-x-4">
      <div @click="toggleStar(model)">
        <svg-icon
          :icon-class="starredMode ? 'delete' : 'star'"
          class="h-16 w-6 mx-2 hover:text-yellow-300 cursor-pointer"
          :class="{ 'text-yellow-300': starred(model) }"
        />
      </div>
      <div v-if="recentMode" @click="removeFromRecentSearch">
        <svg-icon
          icon-class="delete"
          class="h-16 w-6 mr-4 hover:text-yellow-300 cursor-pointer"
        />
      </div>
    </div>
  </div>
</template>
