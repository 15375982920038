<script>
import sample from 'lodash/sample';

export default {
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "Images of Property"
    },
    images: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    return { sample }
  }
};
</script>
<template>
  <a-drawer
    :title="title"
    placement="bottom"
    height="100vh"
    :closable="true"
    :visible="visible"
    @close="() => $emit('close')"
  >
    <masonry
      :cols="{ default: 4, 1000: 3, 700: 2, 400: 1 }"
      :gutter="{ default: '30px', 700: '20px' }"
    >
      <template v-if="images.length === 0">
        <div v-for="(item, index) in 10" :key="index" class="item">
          <h4>Item: {{ index + 1 }}</h4>
          <img
            v-dummy="'400,500x400,500'"
            data-color="#eee"
            data-text="Random Sized Image"
            style="max-width: 100%"
          />
          <!-- <p v-dummy="3,30"></p> -->
        </div>
      </template>
      <template v-else>
        <div v-for="(image, index) in images" :key="index" class="item">
          <h4 class="font-semibold text-lg py-2">{{ image.name }}</h4>
          <div class="flex items-center justify-center">
            <viewer :images="[image]">
              <img
                :src="image.url"
                class="object-cover rounded-lg overflow-hidden hover:opacity-50 cursor-pointer"
                :style="{
                  height: sample([300, 400, 500]) + 'px',
                  width: sample([300, 400, 500]) + 'px'
                }"
              />
            </viewer>
          </div>
        </div>
      </template>
    </masonry>
  </a-drawer>
</template>
