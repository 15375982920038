<script>
import { reactive } from "@vue/composition-api";
export default {
  props: {
    type: String
  },
  setup(props, ctx) {
    const controls = reactive({
      parking: {
        color: "geekblue",
        name: "Parking"
      },
      stay: {
        color: "geekblue",
        name: "Stay"
      },
      coworking: {
        color: "geekblue",
        name: "Coworking"
      },
      "car-rental": {
        color: "geekblue",
        name: "Car Rental"
      }
    });
    return {
      controls
    };
  }
};
</script>
<template>
  <a-tooltip v-if="controls[type].tooltip">
    <template #title>
      {{ controls[type].tooltip }}
    </template>
    <a-tag :color="controls[type].color" class="flex items-center space-x-1">
      <span>{{ controls[type].name }}</span>
      <a-icon v-if="controls[type].tooltip" type="info-circle" />
    </a-tag>
  </a-tooltip>
  <a-tag v-else :color="controls[type].color">
    <span>{{ controls[type].name }}</span>
  </a-tag>
</template>
