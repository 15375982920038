<script>
import { reactive, ref } from "@vue/composition-api";
import PQDropzone from "src/views/components/PQDropzone";
import ReservationSearchDropdown from "src/views/components/ReservationSearchDropdown";
import { API } from "src/views/maintenance/api";
import { shortUID, convertFileToDataURL } from "src/plugins/prototype";
export default {
  name: "MaintenanceRequestModal",
  components: {
    ReservationSearchDropdown,
    "pq-dropzone": PQDropzone,
  },
  props: {
    visible: Boolean,
  },
  setup(props, ctx) {
    const controls = reactive({
      loading: false,
    });

    const form = reactive({
      title: null,
      reservation_id: null,
      reservation_title: null,
      tenant: null,
      tenant_name: null,
      host: null,
      host_name: null,
      enable_due_date: false,
      due_date: new Date(),
      notes: null,
      image: null,
    });

    const addMaintenanceImage = async (f) => {
      form.image = await convertFileToDataURL(f);
    };

    const submit = async () => {
      let resp;
      controls.loading = true;
      try {
        resp = await API.create({
          reservation_id: form.reservation_id,
          title: form.title,
          notes: form.notes,
        });
        ctx.root.$openNotification(
          "Maintenance request created successfully",
          "",
          5,
          "info"
        );
        ctx.emit("close");
      } catch (error) {
        console.log("createMaintenance error: ", error);
      }
      controls.loading = false;
    };

    return {
      controls,
      form,
      addMaintenanceImage,
      submit,
    };
  },
};
</script>
<template>
  <a-modal
    :visible="visible"
    :closable="true"
    width="900px"
    :footer="null"
    dialog-class=""
    :mask-style="{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }"
    @ok="() => $emit('close')"
    @cancel="() => $emit('close')"
  >
    <div class="flex flex-col">
      <span class="font-bold text-center text-lg pb-3 border-b">
        Maintenance Request Form
      </span>
      <div class="flex">
        <div class="w-1/2 flex flex-col items-center space-y-4 px-4 py-3 border-r">
          <div class="flex items-center space-x-2">
            <span class="">Image</span>
            <a-tag color="orange">Optional</a-tag>
          </div>
          <viewer
            v-if="form.image"
            :images="[form.image]"
            class="flex items-center justify-center rounded-lg overflow-hidden relative"
          >
            <img
              :src="form.image"
              class="w-full object-cover"
              style="height:300px;"
            />
            <div class="absolute top-2 right-2">
              <a-button
                type="danger"
                shape="circle"
                icon="close"
                @click="() => (form.image = null)"
              />
            </div>
          </viewer>
          <pq-dropzone
            v-else
            intro="Drop image you think that may help the maintence"
            height="300px"
            @file-added="addMaintenanceImage"
          />
        </div>
        <div class="w-1/2 flex flex-col">
          <div class="flex justify-between items-center px-4 pt-6 pb-3">
            <span class="w-1/3">Task Name</span>
            <a-input size="large" v-model="form.title" />
          </div>
          <div
            v-if="form.tenant_name"
            class="flex justify-between items-center px-4 py-3"
          >
            <span class="w-1/3">Tenant Name</span>
            <a-tag color="geekblue">{{ form.tenant_name }}</a-tag>
          </div>
          <div class="flex justify-between items-center px-4 py-3">
            <span class="w-1/3">Choose Reservation</span>
            <reservation-search-dropdown
              v-if="!form.reservation_title"
              role="tenant"
              :auto-select-first-on-blur="false"
              :show-select-all="false"
              @selected="
                (selected) => {
                  form.reservation_id = selected.id;
                  form.reservation_title = selected.title || selected.listing.name;
                  form.tenant = selected.customer;
                  form.host = selected.user;
                  form.tenant_name = selected.tenant_name;
                  form.host_name = selected.host_name;
                }
              "
            />
            <a-tag
              v-else
              color="geekblue"
              closable
              @close="
                () => {
                  form.reservation_id = null;
                  form.reservation_title = null;
                  form.tenant = null;
                  form.host = null;
                  form.tenant_name = null;
                  form.host_name = null;
                }
              "
              >{{ form.reservation_title }}</a-tag
            >
          </div>
          <div
            v-if="form.reservation_id"
            class="flex justify-between items-center px-4 py-3"
          >
            <span class="w-1/3">Host Name</span>
            <a-tag color="geekblue">{{ form.host_name }}</a-tag>
          </div>
          <div class="flex justify-between items-center px-4 py-3">
            <div class="flex space-x-4 items-center">
              <span>Due Date</span>
              <a-switch v-model="form.enable_due_date" />
            </div>
            <vc-date-picker v-model="form.due_date">
              <template v-slot="{ inputValue, inputEvents }">
                <a-input
                  :value="inputValue"
                  v-on="inputEvents"
                  :disabled="!form.enable_due_date"
                />
              </template>
            </vc-date-picker>
          </div>
          <div class="flex flex-col items-center space-y-4 px-4 py-3">
            <span>Notes for host:</span>
            <a-textarea
              v-model="form.notes"
              placeholder="Enter maintenace descriptions or details for your host"
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
          </div>
          <div class="flex justify-between items-center space-x-4 px-4 py-3 pb-3">
            <a-button size="large" class="w-1/2" @click="$emit('close')">
              Cancel
            </a-button>
            <a-button
              type="primary"
              size="large"
              :loading="controls.loading"
              class="w-1/2"
              @click="submit()"
            >
              Send Request
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
