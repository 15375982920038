export const getDefaultModel = () => {
  return [
    {
      type: "monthly",
      price: 2,
      mileage: 300,
      enabled: true,
    },
    {
      type: "daily",
      price: 1,
      mileage: 100,
      enabled: true,
    },
    {
      type: "hourly",
      price: 3,
      mileage: 200,
      enabled: true,
    },
  ];
};

export const getDefaultVehicleModel = () => {
  return {
    id: null,
    make: null,
    make_id: null,
    model: null,
    model_id: null,
    color: null,
    year: null,
    plate_number: null,
    image: null,
    smartcar: false,
    // Store the Smart Car ID
    smartcar_id: null
  };
};
