// Define default language: Español here
var langEs = {
    header: {
        becomeAHost: "Hospeda",
        parking: "Estacionamiento",
        cars: "Autos",
        spaces: "Espacios",
        faqs: "Preguntas",
        contact: "Contacto",
        blog: "Blog",
        logIn: "Iniciar",
        signUp: "Regístrate",
        host: "Hospeda",
        list: "Registrar su propiedad",
        hello: "Hola",
        dashboard: "Dashboard",
        reservations: "Reservaciones",
        account: "Mi Cuenta",
        logOut: "cerrar la sesión",
    },

    mobileHeader: {
        becomeAHost: "Hospedar su propiedad",
        parking: "Estacionamiento",
        cars: "Autos",
        spaces: "Espacios",
        faqs: "Preguntas",
        contact: "Contacto",
        terms: "Términos y condiciones",
        privacy: "Privacidad",
        logIn: "Iniciar",
        signUp: "Regístrate",
    },

    landingPage: {
        rightTitle: "Vamos a empezar!",
        rightWhat: "¿Qué le gustaría reservar?",
        rightOptions: ["estacionamiento", "autos", "espacios"],
        rightLength: "¿Cuánto tiempo le gustaría reservar?",
        rightTime: ["hora", "diario", "mensual"],
        rightWhere: "¿Dónde necesitas esto?",
        rightWhen: "¿Cuándo lo necesitas?",
        search: "Busca",

        leftTitle: "Reserva un estacionamiento, un lugar a trabajar, o quédate cerca.",
        leftDescription: "Reserve un estacionamiento, un espacio de trabajo o un lugar para quedarse cuando lo necesite. Gana dinero cuando no lo necesitas.",
    },

    button:{
        socialFacebook: "Iniciar con Facebook",
        socialGoogle: "Iniciar con Google+",
        search: "Busca",
        reserve: "Reserva",
    },

    accountInfo: {

        first: "Nombre",
        last: "Apellido",
        email: "Email",
        phone: "Numbero",
        update: "Actualización",

        updateDetails: "Actualizar los detalles",
        updateAvailability: "Actualizar Horario",
        updatePrice: "Actualizar el precio",
        updating: "Actualizando...",
        loading: "Cargando...",
        connecting: "Conectando Coche...",
        reset: "Reiniciar llave digital",
        control: "Controla tu coche",

        license:"Licencia de conducir",
        registration: "Registración",
        insurance: "Prueba de seguro",
        lease: "Copia del contrato",
    },

    accountDetails: {
        first: "Nombre",
    },

    signIn:{
        welcome: "Bienvenido",
        email: "Email",
        password: "Contraseña",
        passwordBox: "Su Contraseña",
        forgetPassword: "¿Olvidó su contraseña?",
        signIn: "Iniciar",
    },

    signUp:{
        title: "Regístrate",
        first: "Nombre",
        last: "Apellido",
        email: "Email",
        phone: "Número de teléfono",
        password: "Forgot your password?",
        confirm: "Forgot your password?",
        agree: "I agree to the terms of service and privacy policy of Parqyt, Inc.",
        signUp: "Regístrate",
    },

    contactUs:{
        contact: "Contáctanos",
        contactDescription: "Por favor, utilice una de las opciones que se indican a continuación.",
        email: "Envíenos un email",
        emailDescription: "Envíenos un correo electrónico, y nos pondremos en contacto con usted lo antes posible.",
        call: "Llámanos",
        callDescription: "Si su asunto requiere de alguien ahora, por favor llámenos al 415-688-2011",
    },

    faq:{
        title: "Frequently Asked Questions",
        ////////////
        ////////////
        ////////////
        user: "User",
        how: "How does Parqyt work?",
        howDescription: "Parqyt is a parking reservation platform. We partner with various facilities to provide you with lots of parking options. Parqyt does not own or operate any of the locations listed, we do work very closely with the lots ensure you have a seamless parking experience every time you park!",
        speak: "Will I have to speak with the host?",
        speakDescription: "Many of our hosts choose to not meet with the parker, this would take a lot of time out of their day.",
        guaranteed: "Is my parking spot guaranteed?",
        guaranteedDescription: "Your parking will be guaranteed, but we cannot guarantee you a specific spot in the lot.",
        download: "Do I have to download the app?",
        downloadDescription: "You do not have to, though we encourage you to do so to keep all of your reservations in one place. We also send out frequent deals to our app that you may otherwise miss via email.",
        payment: "Is my payment information secure?",
        paymentDescription: "Yes! All of our payments and payouts are done with Stripe, a multi-million dollar company that operates in 20 countries, and we don’t store full credit card information anywhere on our servers. Checkout https://stripe.com/ for more information.",
        multiple: "What if I need to make multiple reservations?",
        multipleDescription: "Yes, you can make multiple reservations with Parqyt, we actually encourage you to do so! You deserve to get to your destination right away!",
        cancel: "What if the host cancels last minute?",
        cancelDescription: "We will find you a spot nearby, and fulfill your need.",
        ////////////
        ////////////
        ////////////
        host: "Host",
        who: "Will I know who is parking in my spot?",
        whoDescription: "Yes, you will know exactly who is parking in your spot. You will have the parkers name, car make and model, and the times they will are coming and going.",
        need: "What if something comes up and I need my space?",
        needDescription: "Please give us a call at 415-688-2011 and we will find an alternative parking space for the person.",
        paid: "How do I get paid?",
        paidDescription: "We can pay you right away via direct deposit or sending a check in the mail.",
        towing: "Can I request a towing company?",
        towingDescription: "Yes, we partner with local towing companies should the need arise to use one.",
    },


    parkingBenefits: {
        header: "Beneficios de Parqyt",
        guaranteedTitle: "Reserva garantizada",
        guaranteedDescription: "Sepa con confianza que su reservación estará disponible a su llegada.",
        safeTitle: "Seguro y protegido",
        safeDescription: "Los pagos se procesan con Stripe para garantizar la seguridad.",
        communityTitle: "Comunidad",
        communityDescription: "Ser parte de una comunidad que trabaja en conjunto para ganar juntos.",
        supportTitle: "Soporte",
        supportDescription: "Soporte 24/7 para encontrarte la reserva perfecta o ayuda cuando la necesites."
    },


    exploreNearby: {
        title: "Descubre Parqyt",
    },
    parkingNearby: {
        title: "Estacionamiento cerca",
        description: "Estacionamiento por hora, diario y mensual.",
        noData: "No pude encontrar ningún aparcamiento cerca.",
    },
    spacesNearby: {
        title: "Espacios cerca",
        description: "Casas, apartamentos y oficinas.",
        noData: "No pude encontrar ningún espacio cerca.",
    },
    carsNearby: {
        title: "Autos cerca",
        description: "Alquiler de coches por horas, días y meses.",
        noData: "No pude encontrar ningún coche cerca.",
    },

    download: {
        title: "Descargar Parqyt",
        description: "Para una mejor experiencia,",
        description1: "¡descarga nuestra aplicación!"
    },
    
    parkingShare: {
        title: "Estacionamiento extra?",
        title1: "Comparte y gana hoy.",
        description: "Comparte tu espacio de estacionamiento y gana. Al ir de vacaciones o al trabajo, siempre puedes ganar por hora diaria o mensual.",
    },


    footer: {
        rights: "Todos los derechos reservados",
        /////Book Parking
        bookParking: "Aparcamiento de libros",
        sfParking: "Estacionamiento en San Francisco",
        bostonParking: "Estacionamiento de Boston",
        chicagoParking: "Aparcamiento de Chicago",
        BottlerockParking: "Aparcamiento de Bottlerock",
        //////Solutions
        solutions: "Soluciones",
        carShare: "Compartir coche",
        enforcement: "Cumplimiento",
        fleet: "Fleet Management",
        ///// Company
        company: "Compañía",
        contact: "Contáctenos",
        faq: "Preguntas",
        terms: "Términos y condiciones",
        privacy: "Privacidad",
    },
    /////////// Become A Host Page ///////////////
    /////////// Become A Host Page ///////////////
    /////////// Become A Host Page ///////////////
    /////////// Become A Host Page ///////////////

    becomeAHost: {
        leftTitle: "Gana dinero en su auto, estacionamiento, o casa.",
        leftDescription: "Hacemos que sea sencillo llegar a miles de personas que buscan acceso a aparcamientos, coches y lugares para quedarse y trabajar",
        rightTitle: "¡Vamos a empezar!",
        rightWhat: "¿Qué te gustaría enumerar?",
        rightDropDown: "¿Qué te gustaría enumerar?",
    },

    howItWorks: {
        title: "Cómo funciona",
        listTitle: "La lista es gratuita",
        listDescription: "La inscripción de su propiedad es gratuita. Anunciamos y comercializamos su propiedad para llenarla o usarla.",
        priceTitle: "Precio fijo y disponibilidad",
        priceDescription: "Usted controla la disponibilidad, el precio y la forma en que interactúa con los clientes.",
        bookingTitle: "Reservas y pagos",
        bookingDescription: "Puede elegir entre aceptar instantáneamente reservas o solicitudes de reserva, con pagos automatizados.",
        paidTitle: "Cobrar",
        paidDescription: "Se le garantiza la seguridad de los pagos y los desembolsos. Los pagos se hacen mensualmente.",
    },

    /////Cars Landing Page////
    cars: {
        rightTitle: "¡Vamos a buscarte un coche!",
        rightLength: "¿Cuánto tiempo necesitas el coche?",
        rightTime: ["hora", "diario", "mensual"],
        rightWhere: "¿Dónde necesitas esto?",
        rightWhen: "¿Cuándo lo necesitas?",

        leftTitle: "Reserva coches cercas",
        leftDescription: "Reserva un coche cuando lo necesites, gana dinero cuando no, ¡comparte tu coche hoy!",
        socialFacebook: "Continuar con Facebook",
        socialGoogle: "Continuar con Google+",
    },

    carBenefits: {
        guaranteedTitle: "Coches garantizados",
        guaranteedDescription: "Reserve con confianza sabiendo que su coche estará listo cuando usted lo esté.",
        keyTitle: "Sin llave",
        keyDescription: "Entregar las llaves puede ser molesto, por lo que ofrecemos la entrada sin llave para todos nuestros coches.",
        safeTitle: "Seguro y protegido",
        safeDescription: "Tienes el control total de la disponibilidad, los precios y la forma en que interactúas con los usuarios.",
        verifiedTitle: "Verificado",
        verifiedDescription: "Verificamos a todos los usuarios y coches antes de darles acceso."
    },

    carShare: {
        title: "Comparte tu coche y gana.",
        description: "Conecta tu vehículo para ganar y compartir con los demás o para recibir servicios como gasolina, lavado de coches, entregas y más!",
        monthly: "Mensual",
        hourly: "Hora",
        daily: "Dia",
    },


    /////Spaces Landing Page////
    spaces: {
        rightTitle: "Reservar lugares para quedarse o trabajar",
        rightWhat: "¿Qué tipo de espacio necesitas?",
        rightOptions: ["Quedarse", "trabajo"],
        rightLength: "¿Cuánto tiempo necesitas el espacio?",
        rightTime: ["Hora", "Diario", "Mensual"],
        rightWhere: "Donde?",
        rightWhen: "¿Cuánto tiempo",

        leftTitle: "Reservar las casas, oficinas y escritorios cerca",
        leftDescription: "¡Alquile una casa, una habitación, una oficina o un escritorio! Accede a los espacios cuando lo necesites, gana dinero cuando no, ¡comparte tu espacio hoy!",
        socialFacebook: "Continuar con Facebook",
        socialGoogle: "Continuar con Google+",
    },

    spaceBenefits: {
        guaranteedTitle: "Reserva garantizada",
        guaranteedDescription: "Reserve con confianza sabiendo que su asiento, escritorio o casa estará disponible a su llegada.",
        verifiedTitle: "Verificado",
        verifiedDescription: "Todos nuestros espacios han sido verificados y aprobados. Llegaremos sabiendo todo hasta que nos ocupemos de ello.",
        communityTitle: "Comunidad",
        communityDescription: "Una comunidad que trabaja en conjunto para darle acceso a los coches, aparcamientos y espacios.",
        supportTitle: "Soporte",
        supportDescription: "Entendemos que a veces ocurren cosas que necesitan ayuda, por lo que ofrecemos apoyo las 24 horas del día, los 7 días de la semana."
    },

    spaceShare: {
        title: "¿Tienes un espacio extra? Tenemos a alguien para ello.",
        description: "Haga una lista de su casa o habitación para dar la bienvenida a los viajeros. Ponga su café o restaurante como un espacio de trabajo para extender sus ingresos durante el día.",
    },

    ///////DASHBOARD////

    dashboard:{
        earnings: "Verificar las ganancias",
        overview: "General",
        locate: "Localízame",
        address: "Dirección",
        city: "Ciudad",
        state: "Estado",
        zip: "Código postal",
        event: "Eventos",
        availability: "Disponibilidad",
        price: "Precio",
        yesNo: ["Si", "No"],
        day:"Día",
        from:"De",
        to: "Hasta",
        add: "Añadir",
        remove:"Retire",
        back: "Regresar",
        new: "Agregar nuevo listado",

        available:"¿Está disponible?",
        lot: "Nombre del lista",
        type: "Tipo de lista",
        typeCar: "¿En qué clase de espacio está estacionado tu auto?",
        homeListing: "Título de la lista espacial",
        typeQ: "¿Esto es un hogar o un negocio?",
        homeBusiness: ["Inicio", "negocios"],
        guestQ: "¿Qué tienen los invitados?",
        entirePrivate: ["Todo el lugar", "Cuarto privado","Escritorio/Oficina"],

        ////Amenities////
        amenities: "¿Qué servicios ofrece?",

        spaceTitle: "Amenidades",
        space: "Espacio",
        ac: "A/C",
        heat: "Calentador",
        wifi: "WiFi",
        desk: "Escritorio/Oficina",
        washer: "Lavadora y secadora",

        kitchen: "Cocina",
        fridge: "Refrigerador",
        gas: "Estufa de gas",
        electric: "Estufa eléctrica",
        pots: "Ollas y sartenes",
        dish: "Lavaplatos",

        bathroom: "Baño",
        shampoo: "Champú",
        towels: "Toallas",
        hair: "Secador de pelo",
        iron: "Plancha",

        safety: "Artículos de seguridad",
        fire: "Extintor de incendios",
        co: "Detector de CO y humo",
        first: "Kit de primeros auxilios",

        covered: "Espacio protegido",
        ev: "Carga de EV",
        gated: "Cerrado",
        guarded: "Protegido",
        lit: "Iluminado",

        parkingWhat: "¿Qué le cabe?",
        compact: "Compacto",
        mid: "Sedán de tamaño medio",
        large: "Gran",
        over: "Sobredimensionado (Camión o RV)",

        collect: "Recogeremos estos artículos para usted.",
        registration: "Registro",
        insurance: "Seguro",
        license: "Licencia / ID",

        towing: "¿Desea que le asignen una compañía de grúas?",
        yes: "Si",
        no: "No",
        own: "Tengo mi propio",

        packages: "¿Quieres que te paguen por recibir paquetes aquí?",
        packagePrice: "¿Cuántos meses de depósito debemos cobrar?",
        receiveTitle: "¿Cobrar el depósito?",
        receivePrice: "Depósito de meses ($)",

        continue: "Continúa",

        how: "¿Cómo llegan los invitados a tu espacio?",

        typeCharge: "Carga",
        onOff: "Apagado/Encendido",

        daily: "Diario",
        hourly: "Hora",
        monthly: "Mensual",

        change: "Cambiar imagen",
    },
    ////////////LISTING FLOW/////

    listing:{
        title: "¿Qué le gustaría alojar o alquilar",
        what: "¿Qué le gustaría alojar o alquilar",
        general: "Estacionamiento general",
        event: "Estacionamiento de eventos",
        car: "Coche (coche compartido)",
        stay: "Quédese (Casa/Sala/Condominio)",
        coWorking: "Trabajo en equipo (oficina/escritorio)",
        photo: "Añadir fotos",
        photoInfo: "Por favor, suba las fotos de su plaza de aparcamiento.",
    },
    /////////////////

    general:{
        find: "Vamos a encontrar su propiedad",
        locate: "Localízame",
        save: "Guardar y proceder",
        lot: "Localizar el lote",
        details: "Detalles",
        events: "Eventos",
        photos: "Añadir fotos",
        schedule: "Horario y precio",
        pay: "Pago",
        payInfo: "Información de pago",
        type: "¿Qué tipo de edificio es este?",
        type1: ["Residencial", "Negocios"],
        type2: "residencial",
        type3: "Residencial",
        type4: "Entrada del garaje",
        multi: "¿Es un edificio de varias unidades?",
        multi1: ["Si", "No"],
        information: "Información del espacio",
        kind: "¿Qué clase de espacio de estacionamiento tienes?",
        kind1: ["Entrada del garaje", "Garaje","Entrada del garaje bloqueado","Garaje de aparcamiento","Lote"],
        many: "¿Cuántas estaciones de aparcamiento tiene que alquilar?",
        space: "Detalles y requisitos del espacio",

        parkingCosts: "Costes de aparcamiento",
        availability: "Horario de estacionamiento",
        toggle: "Cambiar mensualmente para disponibilidad 24/7",

        paymentSignup: "Por favor, regístrese abajo para recibir los pagos.",
        existing: "Usar la cuenta existente",
        update: "Actualizar cuenta",
        stripe: "Continúe con Stripe",
        questions: "¿Tiene preguntas?",
        later: "Añade la información de pago más tarde",
        check: "Revisa tus ganancias",
        status: "Verificando su estado...",
        uploading:"Subiendo su estacionamiento...",
        loading: "Loading...",
        success: "Su estacionamiento ha sido cargado con éxito",

        typeSchedule: "Horario",
        price: "$ Precio",
        oversize: "$ Tamaño grande",
        onOff: "Apagado/Encendido",

    },

    carListing: {
        continue: "Continúa",

        carLocation: "Localizar",
        carDetails: "Detalles del coche",
        schedule: "Horario y Precio",
        verifyCar: "Verificar el coche",
        pay: "Pago",

        where: "¿Dónde está estacionado su auto?",
        locate: "Localizar el punto de recogida",
        verify1: "Verificar...",
        address: "Dirección",
        tell: "Cuéntenos sobre su coche",
        save: "Guardar y proceder",
        title: "Título de la lista de coches",
        transTitle: "Tipo de transmisión",
        transType: ["Automático","Manual"],
        instant: "¿Renta instantánea o solicitud de alquiler?",
        option: ["Instantánea","Solicite"],

        spaceInfo: "Información espacial",
        kind: "¿En qué clase de espacio está tu coche?",
        many: "¿Cuántos coches tienes?",

        photo: "Añadir fotos",
        photoDetails: "Por favor, suba fotos, no podemos listar su vehículo sin fotos.",

        details: "Detalles del coche",

        features:"Características",
        gps:"GPS",
        convertible: "Convertible",
        usb: "USB Input",
        bt: "Bluetooth",
        toll: "Pase de peaje",

        carType: "Tipo de coche",
        compact: "Compacto",
        mid: "Sedán de tamaño medio",
        suv: "SUV",
        truck: "Camión",
        luxury: "Lujo",

        powered: "Coche impulsado por",
        gas: "Alimentado por gas",
        ev: "Vehículo eléctrico",
        hybrid: "Híbrido",

        packages: "¿Quieres que te paguen por recibir paquetes en tu baúl?",
        yes: "Si",
        no: "No",

        how: "¿Cómo llegan los invitados a tu coche?",

        rentalCost: "Costos de alquiler & Límites de millas",
        rent: "Costos de alquiler",
        and: "&",
        miles: "Límites de millas",

        type: "Tipo",
        price: "Precio",
        mileage: "Millas",
        onOff: "Apagado/Encendido",

        package: "Recibir paquetes",
        packageDetails: "Le pagaremos por asegurar los paquetes en su maletero",
        packageButton: "Recibir un paquete $",

        availability: "Disponibilidad de coches",
        day: "Día",
        from: "De",
        to: "To",
        add: "Añade",

        verify: "Verifique su coche",
        please: "Por favor, registre su vehículo a continuación utilizando SmartCar Connect",
        connect: "Conectar el vehículo",

        priceMile: "Precio/Millas",
        access: "Acceso al coche",

    },

    spaceListing: {
        continue: "Continúa",

        location: "localizar",
        details: "Detalles",
        schedule: "Horario y Precio",
        pay: "Pago",
        maps: "Mostrar el mapa",

        where: "¿Dónde está tu espacio?",
        locate: "Localizar el espacio",
        address: "Dirección",
        save: "Guardar y proceder",

        title: "¿Qué tipo de espacio tienes?",
        listing: "Título de la lista",
        homeBusiness: "¿Es esta una casa o un negocio",
        header: ["Casa","Negocio"],
        guest: "¿Acceso de invitados?",
        guestOptions: ["Todo el lugar","Cuarto privado","Oficina/Escritorio"],

        info: "Información espacial",
        tell: "Cuéntanos sobre tu espacio",
        rooms: "¿Cuántas habitaciones / escritorios?",
        people: "¿Cuántas personas puedes alojar?",
        bathroom: "¿Cuántos baños?",
        what: "¿Qué tiene el espacio?",

        secureTitle: "Paquetes seguros",
        secure: "Can this location accept package deliveries for a fee?",

        how: "¿Cómo entran los invitados?",
        photoDetails: "No podemos listar su espacio sin fotos",
        rates: "Precio",

        availability: "Disponibilidad",
        // secure: "Paquetes seguros",
        packages: "¿Cuánto cobraría por cada entrega segura de un paquete de Amazon?",
        pricePackage: "Precio / Paquete",

        uploading: "Listando su espacio...",
        success: "Su espacio ha sido listado",
    },

    reservation: {
        your: "Sus Reservas",
        hosting: "Alojamiento",
        header: ["Host Name","Contact","Price","Location","Date"],
        profile: "Perfil",
        reservation: "Reservas",
        calendar: "Calendario",
    },

    profile: {
        account: "Información de la cuenta",
        host: "Ajustes del host",
        vehicle: "Información del vehículo",
        documents: "Documentos",
        payment: "Métodos de pago",
    },

}
import {
    defaultLang
} from "./lang-en"
langEs.default = defaultLang
export default langEs