<script>
import { reactive, onMounted, onBeforeUnmount } from "@vue/composition-api";
import startCase from "lodash/startCase";

import { getDocumentURL, getDropboxURL } from "src/views/components/util";

const FileSaver = require("file-saver");

export default {
  name: "DocumentViewer",
  components: {},
  props: {
    role: String,
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, ctx) {
    const controls = reactive({
      expand: false,
    });
    // const form = ref([]);

    const handleFileAdd = (file) => {
      console.log("handleFileAdd", file);
      ctx.emit("document-added");
    };

    const saveURLAs = (url, filename) => {
      FileSaver.saveAs(url, filename);
    };

    onMounted(async () => {
      if (props.form && props.form.files) {
        props.form.files.forEach(async (f) => {
          f.url = (await getDocumentURL(f)).url;
          console.log("getDocumentURL: ", f);
        });
      }
      if (props.form && props.form.signatures) {
        props.form.signatures.forEach(async (f) => {
          ctx.root.$set(f, "name", `Signed Document-${f.id}`);
          ctx.root.$set(f, "url", (await getDocumentURL(f)).url);
          console.log("getDocumentURL: ", f);
        });
      }
      if (props.form && props.form.report) {
        let document_front,
          photo_id_front,
          user_selfie,
          report_files = [];
        document_front = props.form.report.document_front;
        photo_id_front = props.form.report.photo_id_front;
        user_selfie = props.form.report.user_selfie;
        if (document_front)
          report_files.push({
            ...(await getDropboxURL(document_front)),
            name: "Document front",
          });
        if (photo_id_front)
          report_files.push({
            ...(await getDropboxURL(photo_id_front)),
            name: "ID front",
          });
        if (user_selfie)
          report_files.push({
            ...(await getDropboxURL(user_selfie)),
            name: "Selfie",
          });
        ctx.root.$set(props.form.report, "files", report_files);
      }
      ctx.root.$bus.$on("expand-document-viewer", () => (controls.expand = true));
    });

    onBeforeUnmount(async () => {
      ctx.root.$bus.$off("expand-document-viewer", () => (controls.expand = true));
    });

    return {
      controls,
      handleFileAdd,
      saveURLAs,
      startCase,
    };
  },
};
</script>
<template>
  <div class="flex flex-col">
    <div
      class="flex justify-between border rounded items-center hover:shadow-lg px-2 pt-4 pb-16 cursor-pointer relative"
      @click="() => (controls.expand = !controls.expand)"
    >
      <div class="flex items-center justify-center border rounded p-1 bg-gray-50">
        <svg-icon icon-class="document" class="w-6 h-6 text-parqay-primary" />
      </div>
      <span class="font-semibold text-sm text-gray-600">{{
        $deepGet(form, "template.name")
      }}</span>
      <a-tag
        color="geekblue"
        class="flex flex-col space-y-1 justify-center items-center cursor-pointer py-0.5"
        size="small"
      >
        <span>{{ startCase($deepGet(form, "type", "")) }}</span>
      </a-tag>
      <div class="absolute w-24 h-12 border-t border-r left-0 bottom-0">
        <div class="flex items-center justify-center h-full">
          <span>Status</span>
        </div>
      </div>
      <div class="absolute h-12 border-t left-24 bottom-0 right-0">
        <div
          class="flex items-center justify-between h-full overflow-x-auto px-2 no-scrollbar"
        >
          <a-tag v-if="form.status === 'requires_input'" color="pink" type="small">
            {{ startCase(form.status) }}
          </a-tag>

          <a-tag v-else-if="form.status === 'complete'" color="green" type="small">
            {{ startCase(form.status) }}
          </a-tag>

          <a-tag v-else-if="form.status === 'verified'" color="green" type="small">
            {{ startCase(form.status) }}
          </a-tag>

          <a-tag v-else-if="form.status === 'incomplete'" color="gray" type="small">
            Incomplete
          </a-tag>

          <a-tag v-else color="geekblue" type="small">
            {{ startCase(form.status) }}
          </a-tag>
        </div>
      </div>
    </div>

    <div
      v-if="controls.expand"
      class="
        flex
        py-2
        mt-4
        border-t border-b
        justify-between
        items-center
        px-6
        hover:bg-gray-100
        cursor-pointer
        hover:shadow-sm
      "
      style="margin-left: -1.5rem; margin-right: -1.5rem"
      @click="() => (controls.expand = !controls.expand)"
    >
      <span>Document Details</span>
      <svg-icon icon-class="chevron-down" class="h-5 w-5 text-gray-700" />
    </div>
    <div
      v-if="controls.expand"
      class="px-6 py-4 bg-gray-50 border-b"
      style="margin-left: -1.5rem; margin-right: -1.5rem"
    >
      <viewer
        v-if="form.files"
        :images="form.files.filter((f) => f.type.includes('image'))"
      >
        <div class="grid grid-cols-3 gap-2 my-3">
          <img
            v-for="(f, i) in form.files.filter((f) => f.type.includes('image'))"
            :key="`${f.url}-${i}`"
            style="max-height: 100px"
            class="w-full object-contain border rounded overflow-hidden"
            :src="f.url"
            alt=""
          />
          <div
            class="flex flex-col justify-center items-center h-full space-y-1 px-2 py-2 rounded cursor-pointer bg-parqay-primary hover:bg-blue-500"
          >
            <svg-icon icon-class="download" class="h-5 w-5 text-white" />
            <span class="font-semibold text-sm text-white"
              >{{
                form.files.filter((f) => f.type.includes("image")).length
              }}
              images</span
            >
          </div>
        </div>
      </viewer>

      <viewer v-if="form.report" :images="form.report.files">
        <div class="grid grid-cols-3 gap-2 my-3">
          <div
            v-for="(f, i) in form.report.files"
            :key="`identity-report-${i}`"
            class="flex flex-col space-y-2"
          >
            <img
              style="max-height: 100px"
              class="w-full object-contain border rounded overflow-hidden"
              :src="f.url"
              alt=""
            />
            <span class="text-center font-medium text-black">{{ f.name }}</span>
          </div>
        </div>
      </viewer>

      <div v-if="form.files" class="flex flex-col space-y-2">
        <div
          v-for="(f, i) in form.files.filter((f) => f.type.includes('pdf'))"
          :key="`${f.url}-${i}`"
          class="flex relative"
        >
          <a
            :href="f.url"
            target="_blank"
            class="flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full bg-white"
          >
            <div
              class="flex items-center justify-center border rounded p-2 bg-gray-50"
            >
              <span class="font-bold text-lg text-pink-500">PDF</span>
            </div>
            <span class="font-semibold text-sm text-gray-600 pb-1 mr-10">{{
              f.name
            }}</span>
          </a>
          <div class="absolute right-2 top-1/2 transform -translate-y-1/2">
            <div
              class="
                rounded-full
                bg-blue-500
                hover:bg-parqay-primary
                cursor-pointer
                text-white
                flex
                justify-center
                items-center
                p-1
              "
              @click="$downloadFileFromUrl(f)"
            >
              <svg-icon icon-class="download" class="h-4 w-4" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="form.signatures" class="flex flex-col space-y-2">
        <div
          v-for="(f, i) in form.signatures.filter((f) => f.dbx_path)"
          :key="`${f.url}-${i}`"
          class="flex relative"
        >
          <a
            v-if="f.url"
            :href="f.url"
            target="_blank"
            class="flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full bg-white"
          >
            <div
              class="flex items-center justify-center border rounded p-2 bg-gray-50"
            >
              <span class="font-bold text-lg text-pink-500">PDF</span>
            </div>
            <span class="font-semibold text-sm text-gray-600 pb-1 mr-10">
              {{ $deepGet(f, "name") }}
            </span>
          </a>
          <div class="absolute right-2 top-1/2 transform -translate-y-1/2">
            <div
              v-if="f.url"
              class="
                rounded-full
                bg-blue-500
                hover:bg-parqay-primary
                cursor-pointer
                text-white
                flex
                justify-center
                items-center
                p-1
              "
              @click="saveURLAs(f.url, `${f.name}.pdf`)"
            >
              <svg-icon icon-class="download" class="h-4 w-4" />
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col rounded bg-white px-4 py-2 mt-4 border">
        <div class="flex justify-between items-center py-2">
          <span class="text-sm font-medium text-gray-600">Name</span>
          <div class="bg-gray-50 rounded border px-2 py-1 text-sm">
            {{ $deepGet(form, "template.name") }}
          </div>
        </div>

        <div
          v-if="$deepGet(form, 'type') === 'identity'"
          class="flex justify-between items-center py-2"
        >
          <span class="text-sm font-medium text-gray-600">Document Number</span>
          <div class="px-2 py-1 text-parqay-primary">
            {{ $deepGet(form, "report.document_number", "N/A") }}
          </div>
        </div>

        <div class="flex justify-between items-center py-2">
          <span class="text-sm font-medium text-gray-600">Type</span>
          <a-tag
            color="geekblue"
            class="flex flex-col space-y-1 justify-center items-center cursor-pointer py-1"
          >
            <span v-if="$deepGet(form, 'type') === 'identity'">Identity</span>
            <span v-else-if="$deepGet(form, 'type') === 'sign'"
              >Signature Required</span
            >
            <span v-else-if="$deepGet(form, 'type') === 'upload'">Upload Only</span>
          </a-tag>
        </div>

        <div v-if="$deepGet(form, 'type') !== 'identity'" class="flex flex-col py-2">
          <div class="text-left w-full my-auto">
            <span class="text-sm font-medium text-gray-600">Host Message: </span>
          </div>
          <div class="mt-2">
            <div class="bg-white rounded border px-4 py-4">
              {{ $deepGet(form, "template.signer_message", "N/A") }}
            </div>
          </div>
        </div>

        <div v-if="$deepGet(form, 'type') === 'upload'" class="flex flex-col py-2">
          <div class="text-left w-full my-auto">
            <span class="text-sm font-medium text-gray-600">{{
              role === "tenant" ? "Notes for Host" : "Tenant Message"
            }}</span>
          </div>
          <div class="mt-2">
            <div v-if="role === 'tenant'" class="bg-white rounded border px-4 py-4">
              {{ $deepGet(form, "tenant_message", "N/A") }}
            </div>
            <div v-if="role === 'host'" class="bg-white rounded border px-4 py-4">
              {{ $deepGet(form, "host_message", "N/A") }}
            </div>
          </div>
        </div>

        <div
          v-if="$deepGet(form, 'type') === 'identity'"
          class="flex flex-col py-2 space-y-2"
        >
          <div class="text-left w-full my-auto">
            <span class="text-sm font-medium text-gray-600">
              Allowed Doc Types
            </span>
          </div>
          <div class="bg-white flex flex-wrap items-center">
            <div
              v-for="(v, i) in form.template.allowed_doc_types.filter(
                (v) => v.checked
              )"
              :key="`allowed-doc-type-${i}`"
              class="px-2 py-1"
            >
              <a-tag color="geekblue">
                {{ startCase(v.name) }}
              </a-tag>
            </div>
          </div>
          <div class="flex items-center justify-between py-2">
            <span class="text-sm font-medium text-gray-600">
              ID Number Check
            </span>
            <svg-icon
              v-if="form.template.id_number_check"
              icon-class="check"
              class="h-5 w-5 text-green-400"
            />
            <svg-icon
              v-else
              icon-class="close-circle"
              class="h-5 w-5 text-pink-500"
            />
          </div>
          <div class="flex items-center justify-between py-2">
            <span class="text-sm font-medium text-gray-600">
              Selfie Check
            </span>
            <svg-icon
              v-if="form.template.selfie_check"
              icon-class="check"
              class="h-5 w-5 text-green-400"
            />
            <svg-icon
              v-else
              icon-class="close-circle"
              class="h-5 w-5 text-pink-500"
            />
          </div>
        </div>
        <div class="py-4"><slot /></div>
      </div>
    </div>
  </div>
</template>
