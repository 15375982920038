import request from "src/services/api/request";

export const API = {
  list(role) {
    return request.get("maintenance", { role });
  },
  create({reservation_id, title, notes}) {
    return request.post(`reservations/${reservation_id}/maintenance`, { title, notes });
  }
};
