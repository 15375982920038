<script>
import { ref, onMounted } from "@vue/composition-api";

import ListingSearchItem from "./ListingSearchItem";
import ReservationSearchItem from "./ReservationSearchItem";
import EventSearchItem from "./EventSearchItem";
import DocumentSearchItem from "./DocumentSearchItem";
import TenantSearchItem from "./TenantSearchItem";

export default {
  name: "StarredSearchItem",
  components: {
    ListingSearchItem,
    ReservationSearchItem,
    EventSearchItem,
    DocumentSearchItem,
    TenantSearchItem
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    const navToReservationDetails = it => {
      console.log(JSON.stringify(it));
      // ctx.emit("dismissModal");
      // ctx.root.$router.push("/dashboard/reservations/abc");
    };
    return {
      navToReservationDetails
    };
  }
};
</script>
<template>
  <div>
    <listing-search-item
      v-if="model.type == 'listing'"
      starred-mode
      :index="index"
      :model="model"
      @dismiss="$emit('dismiss')"
    />
    <reservation-search-item
      v-if="model.type == 'reservation'"
      starred-mode
      :index="index"
      :model="model"
      @dismiss="$emit('dismiss')"
    />
    <event-search-item
      v-if="model.type == 'event'"
      starred-mode
      :index="index"
      :model="model"
      @dismiss="$emit('dismiss')"
    />
    <document-search-item
      v-if="model.type == 'document'"
      starred-mode
      :index="index"
      :model="model"
      @dismiss="$emit('dismiss')"
    />
    <tenant-search-item
      v-if="model.type == 'tenant'"
      starred-mode
      :index="index"
      :model="model"
      @dismiss="$emit('dismiss')"
    />
  </div>
</template>
