import request from "src/services/api/request";

export const API = {
  // Chat
  listConversations({ type, role, page, filter }) {
    return request.get(`messaging/conversations`, {
      type,
      role,
      page,
      filter
    });
  },
  getConversation(room_id) {
    return request.get(`messaging/conversations/${room_id}`);
  },
  getDirectConversation(sender_id, receiver_id, role) {
    return request.get(`messaging/conversation/direct/${sender_id}/to/${receiver_id}/${role}`);
  },
  sendDirectMessage(sender_id, receiver_id, role, message) {
    return request.post(`messaging/conversation/direct/${sender_id}/to/${receiver_id}/${role}/message`, { message });
  },
  fullfillDirectConversationMissingInfo(tenant_id, payload) {
    return request.post(`messaging/conversation/direct/fullfill-info/${tenant_id}`, payload);
  },
  getConversationLastMessage(id) {
    return request.get(`messaging/conversations/${id}/last-message`);
  },
  // Lead
  createLeads(payload) {
    return request.post("messaging/leads", payload);
  },
  checkDuplicateLeadMeta(type, value) {
    const payload = {}
    payload[`${type}`] = value;
    return request.get(`messaging/lead/check-duplicate/${type}`, payload);
  },
  // Email
  listEmails(category) {
    return request.get("messaging/emails/" + category);
  },
  getUniqueEmailAddress() {
    return request.get("messaging/email/unique-address");
  },
  sendEmail(payload) {
    return request.post("messaging/email", payload);
  },
  updateEmailReadStatus(email_id) {
    return request.post(`messaging/emails/${email_id}/read`);
  }
}