var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex space-x-2 items-center"},[(_vm.typeFilter)?_c('a-tag',{attrs:{"color":"geekblue"}},[_vm._v("\n    "+_vm._s(_vm.typeFilter)+"\n  ")]):_vm._e(),_c('dropdown',{attrs:{"input-mode":"","input-mode-props":{
      model: _vm.keyword,
      placeholder: 'Search Reservations',
      customDataSource: true,
      dataSource: _vm.dataSourceSearchResults,
      loading: _vm.loading,
      disabled: _vm.disable,
      onSearch: function onSearch(searchText) {
        _vm.loading = true;
        _vm.console.log('onSearch', searchText);
        _vm.dataSourceSearchResults = !searchText
          ? _vm.dataSource.slice(0, 20)
          : _vm.dataSource
              .filter(function (mString) { return JSON.parse(mString.value)
                  .listing.name.toLowerCase()
                  .includes(searchText.toLowerCase()); }
              )
              .slice(0, 20);
      },
      onSelect: _vm.selectResult,
      onChange: function onChange(value) {
        _vm.console.log('onChange', value);
        _vm.keyword = value;
        _vm.loading = true;
        if (_vm.dataSourceSearchResults.length == 0) {
          _vm.loading = false;
        }
      }
    },"width":72},on:{"update-input-mode-model":function (m) {
        _vm.console.log('update-input-mode-model', m);
        if (_vm.autoSelectFirstOnBlur) {
          _vm.selectResult(m.value);
        }
      }},scopedSlots:_vm._u([{key:"custom-search-input-menu",fn:function(){return _vm._l((_vm.dataSourceSearchResults),function(ds,i){return _c('a-select-option',{key:("reservation-search-input-select-" + i),attrs:{"value":ds.value}},[_c('div',{staticClass:"flex space-x-2"},[_c('div',[_c('a-tag',{attrs:{"color":"geekblue"}},[_vm._v("\n              "+_vm._s(_vm.$deepGet(JSON.parse(ds.value), "listing.type"))+"\n            ")])],1),_c('div',{staticClass:"flex flex-col space-y-1"},[_c('span',{staticClass:"text-parqay-primary font-medium text-xs"},[_vm._v("\n              #"+_vm._s(ds.text)+"\n            ")]),_c('div',[_c('a-tag',{attrs:{"color":"orange"}},[_vm._v("\n                Host: "+_vm._s(JSON.parse(ds.value).host_name || "N/A")+"\n              ")])],1),_c('span',{staticClass:"text-xs font-light whitespace-normal"},[_vm._v("\n              "+_vm._s(_vm.$deepGet(JSON.parse(ds.value), "listing.name"))+"\n            ")])])])])})},proxy:true},{key:"custom-search-input-search-footer",fn:function(){return [(_vm.showSelectAll)?_c('a-select-option',{key:"all",staticClass:"show-all"},[_c('div',{staticClass:"flex space-x-2"},[_c('div',{staticClass:"flex flex-col space-y-1"},[_c('span',{staticClass:"text-parqay-primary font-medium"},[_vm._v("Any Reservation")]),_c('span',{staticClass:"text-xs font-light whitespace-normal"},[_vm._v("\n              Show all types of reservations under your name")])])])]):_vm._e(),_c('a-select-option',{key:"intro",staticClass:"show-all",attrs:{"disabled":""}},[_c('a',[_vm._v(" Search Reservation by Name ")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }