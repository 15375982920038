<script>
import { reactive, ref } from "@vue/composition-api";
import { StripeElements, StripeElement } from "vue-stripe-elements-plus";
import { API } from "src/views/components/api";

export default {
  components: {
    StripeElements,
    StripeElement,
  },
  props: {
    theme: {
      type: String,
      default: "simple",
    },
  },
  setup(props, ctx) {
    const loading = reactive({
      saving: false,
    });
    const paymentMethod = reactive({
      name: null,
    });
    const stripeKey = ref(process.env.VUE_APP_STRIPE_KEY); // test key, don't hardcode
    const instanceOptions = reactive({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    });
    const elementsOptions = reactive({
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    });
    const elms = ref(null);
    const cardElms = ref(null);
    const savePaymentMethod = async (card) => {
      loading.saving = true;
      // ref in template
      const groupComponent = elms.value;
      // Get stripe element
      const cardElement = card.stripeElement;

      try {
        // Access instance methods, e.g. createToken()
        const result = await groupComponent.instance.createToken(cardElement, { name: paymentMethod.name });
        if (result.token) {
          await API.savePaymentMethod(result.token.id);
          ctx.emit("refresh-list");
        } else {
          ctx.emit("error", result.error.message);
        }
      } catch (error) {
        console.log("savePaymentMethod -> error: ", error);
        ctx.emit("error", error.message);
      } finally {
        loading.saving = false;
      }
    };
    const baseStyle = {
      base: {
        backgroundColor: props.theme == "simple" ? "white" : "rgb(26,86,219)",
        iconColor: props.theme == "simple" ? "rgb(88,80,236)" : "#c4f0ff",
        color: props.theme == "simple" ? "rgb(88,80,236)" : "white",
        fontWeight: "500",
        fontSize: "14px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: props.theme == "simple" ? "rgba(156, 163, 175, 1)" : "white",
        },
      },
    };
    const fetchCardTheme = () => {
      if (props.theme == "indigo-gradient") {
        return "bg-gradient-to-r from-payment-method-card-from to-payment-method-card-to";
      }
      if (props.theme == "simple") {
        return "bg-gray-100";
      }
      return "";
    };
    const fetchInputTheme = () => {
      if (props.theme == "indigo-gradient") {
        return "bg-blue-700 placeholder-white text-white py-2";
      }
      if (props.theme == "simple") {
        return "bg-white placeholder-gray-500 text-black-600 py-2";
      }
      return "";
    };
    const fetchIconTheme = () => {
      if (props.theme == "indigo-gradient") {
        return "text-white";
      }
      if (props.theme == "simple") {
        return "text-blue-600";
      }
      return "";
    };
    return {
      elms,
      cardElms,
      loading,
      paymentMethod,
      stripeKey,
      baseStyle,
      instanceOptions,
      elementsOptions,
      savePaymentMethod,
      fetchCardTheme,
      fetchInputTheme,
      fetchIconTheme,
    };
  },
};
</script>
<template>
  <div class="rounded" :class="fetchCardTheme()">
    <stripe-elements #default="{ elements }" ref="elms" :stripe-key="stripeKey" :instance-options="instanceOptions"
      :elements-options="elementsOptions">
      <div class="flex">
        <div class="my-auto flex space-x-2 items-center" :class="theme == 'simple' ? 'text-blue-600' : 'text-white'">
          <svg-icon icon-class="card" class="h-8 w-10" />
          <span class="font-bold">+ Add Payment Method</span>
        </div>
      </div>
      <div class="mb-4">
        <div class="mt-4 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg-icon icon-class="users" class="h-4 w-4" :class="fetchIconTheme()" />
          </div>
          <input id="frmNameCC" v-model="paymentMethod.name" name="ccname" required autocomplete="cc-name"
            class="border focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
            :class="fetchInputTheme()" placeholder="Name on card">
        </div>
        <div class="mt-4 relative rounded-md shadow-sm overflow-hidden">
          <stripe-element ref="cardElms" type="cardNumber" :class="fetchInputTheme()"
            class="focus:ring-blue-500 focus:border-blue-500 block w-full pl-3 sm:text-sm border border-gray-300 rounded-md"
            :elements="elements" :options="{
              placeholder: 'Card Number',
              showIcon: true,
              style: baseStyle,
            }" />
        </div>
        <div class="flex flex-row justify-between mt-4 space-x-2">
          <div class="rounded-md shadow-sm w-1/2 relative overflow-hidden">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg-icon icon-class="calendar" class="h-4 w-4" :class="fetchIconTheme()" />
            </div>
            <stripe-element type="cardExpiry" :class="fetchInputTheme()"
              class="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border border-gray-300 rounded-md"
              :elements="elements" :options="{
                style: baseStyle,
              }" />
          </div>
          <div class="rounded-md shadow-sm w-1/2 relative overflow-hidden">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg-icon icon-class="lock" class="h-4 w-4" :class="fetchIconTheme()" />
            </div>
            <stripe-element type="cardCvc" :class="fetchInputTheme()"
              class="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border border-gray-300 rounded-md"
              :elements="elements" :options="{
                style: baseStyle,
              }" />
          </div>
        </div>

        <div class="mt-4 flex rounded-md shadow-sm space-x-2">
          <button
            class="bg-parqay-primary hover:bg-blue-500 disabled:bg-blue-500 text-white font-bold py-2 px-4 rounded w-full"
            :disabled="loading.saving" @click="savePaymentMethod($refs['cardElms'])">
            <span class="my-auto">Save new card</span>
            <svg-icon v-if="loading.saving" icon-class="circular-loader"
              class="animate-spin h-4 w-4 ml-2 my-auto text-white" />
          </button>
          <slot name="extraAction" />
        </div>
      </div>
    </stripe-elements>
  </div>
</template>