<script>
import { ref, reactive, onMounted, onBeforeUnmount } from "@vue/composition-api";
import ApproveDenyTag from "src/views/reservations/components/ApproveDenyTag";
import StatusTag from "src/views/reservations/components/StatusTag";
import startCase from "lodash/startCase";

import { API } from "src/views/reservations/api";

export default {
  components: {
    ApproveDenyTag,
    StatusTag
  },
  setup(props, ctx) {
    const form = reactive({});
    const loading = ref(false);
    const visible = ref(false);
    const moreDetails = () => {
      close();
      setTimeout(() => {
        ctx.root.$router.push(`/dashboard/reservations/${form.id}`);
      }, 500);
    };
    const close = () => {
      if (ctx.root.$controls.drawers.reservation.visible.secondary) {
        ctx.root.$controls.drawers.reservation.visible.secondary = false;
        ctx.root.$controls.drawers.reservation.switching.secondary = false;
        setTimeout(() => {
          visible.value = false;
        }, 500);
      } else {
        visible.value = false;
      }
    };
    const openSubDrawer = (type) => {
      ctx.root.$controls.drawers.reservation.switching.secondary = true;
      if (type === "messenger") {
        ctx.root.$controls.drawers.reservation.visible.secondary = true;
        ctx.root.$bus.$emit('close-documents-drawer');
        ctx.root.$bus.$emit('close-tenant-drawer');
        ctx.root.$bus.$emit('close-identity-drawer');
        ctx.root.$bus.$emit('update-contact-drawer-mode', 'phone-number');
        ctx.root.$openContactSlideCard(form);
      }
      if (type === "email") {
        ctx.root.$bus.$emit("toggle-email-editor", { tenant_id: form.customer });
      }
      if (type === "document") {
        ctx.root.$controls.drawers.reservation.visible.secondary = true;
        ctx.root.$bus.$emit('close-contact-drawer');
        ctx.root.$bus.$emit('close-tenant-drawer');
        ctx.root.$bus.$emit('close-identity-drawer');
        ctx.root.$openDocSlideCard('host', null, form.id);
      }
      if (type === "identity") {
        ctx.root.$controls.drawers.reservation.visible.secondary = true;
        ctx.root.$bus.$emit('close-contact-drawer');
        ctx.root.$bus.$emit('close-documents-drawer');
        ctx.root.$bus.$emit('close-tenant-drawer');
        ctx.root.$bus.$emit('open-identity-drawer', 'host',
          {
            tenant_id: form.customer
          });
      }
      if (type === "tenant") {
        ctx.root.$controls.drawers.reservation.visible.secondary = true;
        ctx.root.$bus.$emit('close-contact-drawer');
        ctx.root.$bus.$emit('close-documents-drawer');
        ctx.root.$bus.$emit('close-identity-drawer');
        ctx.root.$bus.$emit('open-tenant-drawer', {
          tenant_id: form.customer,
          on_behalf_of: ctx.root.$isAdmin() ? form.user : null
        });
      }
      setTimeout(() => {
        ctx.root.$controls.drawers.reservation.switching.secondary = false;
      }, 800);
    }
    const handleMultiLevelSubdrawerClose = () => {
      console.log("multilevel-subdrawer-close");
      if (ctx.root.$controls.drawers.reservation.visible.secondary) {
        if (ctx.root.$controls.drawers.reservation.switching.secondary) {
          // Do nothing if under switching
        } else {
          ctx.root.$controls.drawers.reservation.visible.secondary = false;
        }
      }
    }

    const getReservationDetails = async (reservationId) => {
      try {
        loading.value = true;
        const { data } = await API.details(reservationId, {
          index: true
        });
        Object.assign(form, data);
        loading.value = false;
      } catch (error) {
        loading.value = false;
        ctx.root.$openNotification(
          "Reservation Error",
          error.message,
          10,
          "error"
        );
        return;
      }
    };

    const handleOpenReservationDrawer = async (_form, reservationId) => {
      visible.value = true;
      if (reservationId) {
        await getReservationDetails(reservationId);
      } else {
        Object.assign(form, _form);
      }
    }

    onMounted(() => {
      ctx.root.$bus.$on('open-reservation-drawer', handleOpenReservationDrawer);
      ctx.root.$bus.$on("multilevel-subdrawer-close", handleMultiLevelSubdrawerClose);
    })
    onBeforeUnmount(() => {
      ctx.root.$bus.$off('open-reservation-drawer', handleOpenReservationDrawer);
      ctx.root.$bus.$off("multilevel-subdrawer-close", handleMultiLevelSubdrawerClose);
    })
    return {
      form,
      visible,
      loading,
      openSubDrawer,
      moreDetails,
      close,
      startCase
    };
  }
};
</script>
<template>
  <a-drawer width="400px" :wrap-style="{
    right: ($controls.drawers.reservation.visible.secondary ? 400 : 0) + 'px'
  }" :visible="visible" placement="right" :footer="null" @close="close()">
    <template #title>
      <div class="flex flex-col space-y-2">
        <div class="flex items-center space-x-3">
          <span class="font-bold text-lg text-gray-900">
            Reservation
          </span>
          <a-tag color="geekblue">{{ startCase($deepGet(form, "listing.type", "")) }}</a-tag>
          <status-tag :status="$deepGet(form, 'status')" :deny-message="$deepGet(form, 'deny_message')" />
        </div>
      </div>
    </template>
    <div v-if="loading" class="flex-grow flex items-center justify-center" style="height: 65vh;">
      <a-icon type="loading" style="font-size: 35px;" />
    </div>
    <div v-else class="flex flex-col space-y-4">
      <div class="flex flex-col space-y-3">
        <span class="font-medium text-sm text-gray-900" @click="() => childDrawerVisible = true">Confirmation
          Number</span>
        <a-tooltip title="Copied!" trigger="click">
          <div class="flex items-center space-x-4" @click.stop="$copyToClipboard($deepGet(form, 'id'))">
            <a-input disabled confirmation
              class="font-medium shadow-sm text-parqay-primary focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              :value="$deepGet(form, 'id')">
              <a-button type="primary" size="small" slot="suffix" style="font-size: 0.75rem">Copy</a-button>
            </a-input>
          </div>
        </a-tooltip>
      </div>
      <div class="flex flex-col space-y-4">
        <div class="flex-col space-y-3">
          <span class="font-medium text-sm text-gray-900">Tenant name</span>
          <a-input disabled tenant
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            :value="$deepGet(form, 'tenant_name')">
            <a-button type="primary" size="small" slot="suffix" style="font-size: 12px; font-weight: 500;"
              @click.stop="openSubDrawer('tenant')">Details</a-button>
          </a-input>
        </div>
        <div class="grid grid-cols-2 gap-3 w-full">
          <button
            class="flex items-center justify-between bg-orange-400 hover:bg-yellow-300 focus:outline-none px-4 py-2 text-sm rounded-md"
            @click.stop="openSubDrawer('messenger')">
            <span class="text-white font-medium">Messenger</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-white">
              <path fill-rule="evenodd"
                d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                clip-rule="evenodd" />
            </svg>
            <a-badge :count="$store.getters.getUnreadMessagesCountForReservation(form)" />
          </button>
          <button
            class="flex items-center justify-between bg-blue-500 hover:bg-blue-600 focus:outline-none disabled:opacity-50 px-4 py-2 text-sm rounded-md"
            @click.stop="openSubDrawer('email')">
            <span class="text-white font-medium">Email</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-white">
              <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
              <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
            </svg>
          </button>
          <button
            class="flex items-center justify-between bg-gray-500 hover:bg-gray-600 focus:outline-none px-4 py-2 text-sm rounded-md"
            @click.stop="openSubDrawer('document')">
            <span class="text-white font-medium">Documents</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-white">
              <path fill-rule="evenodd"
                d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm6.905 9.97a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72V18a.75.75 0 001.5 0v-4.19l1.72 1.72a.75.75 0 101.06-1.06l-3-3z"
                clip-rule="evenodd" />
              <path
                d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
            </svg>
            <a-badge v-if="false" :count="0" />
          </button>
          <button
            class="flex items-center justify-between bg-green-400 hover:bg-green-500 focus:outline-none px-4 py-2 text-sm rounded-md"
            @click.stop="openSubDrawer('identity')">
            <span class="text-white font-medium">Identity</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-white">
              <path fill-rule="evenodd"
                d="M4.5 3.75a3 3 0 00-3 3v10.5a3 3 0 003 3h15a3 3 0 003-3V6.75a3 3 0 00-3-3h-15zm4.125 3a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-3.873 8.703a4.126 4.126 0 017.746 0 .75.75 0 01-.351.92 7.47 7.47 0 01-3.522.877 7.47 7.47 0 01-3.522-.877.75.75 0 01-.351-.92zM15 8.25a.75.75 0 000 1.5h3.75a.75.75 0 000-1.5H15zM14.25 12a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H15a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3.75a.75.75 0 000-1.5H15z"
                clip-rule="evenodd" />
            </svg>
            <a-badge v-if="false" :count="0" />
          </button>
        </div>
        <template v-if="$deepGet(form, 'request_message')">
          <a-divider style="font-size: 0.85rem;">Request Message</a-divider>
          <div class="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-md mx-auto">
            <div class="bg-gray-100 px-4 py-3 flex items-center space-x-2">
              <avatar :user-id="form.customer" show-name />
            </div>
            <div class="p-4 relative">
              <div class="bg-gray-100 rounded-lg py-2 px-3 mb-1 inline-block">
                <span class="text-gray-900 leading-tight">{{ form.request_message }}</span>
              </div>
              <div class="absolute right-0 bottom-0 border-t-8 border-gray-100 border-solid transform rotate-45 -mr-4">
              </div>
            </div>
          </div>
        </template>
        <template v-if="$deepGet(form, 'status') === 'request'">
          <div class="flex justify-between items-center py-2">
            <span class="font-medium text-sm text-gray-900">Actions</span>
            <approve-deny-tag :form="form" expand @refresh="() => {
              close();
              $bus.$emit('reservation-request-status-change');
            }
              " />
          </div>
          <a-divider v-if="$deepGet(form, 'request_message')"></a-divider>
        </template>
        <div v-if="$deepGet(form, 'event')" class="mb-3 flex flex-col space-y-2">
          <label for="r-event" class="font-medium text-sm text-gray-900">Associated Event</label>
          <span class="rounded-md border border-gray-300 text-parqay-primary font-medium px-3 py-2">
            {{ form.event }}
          </span>
          <a-button block size="large" disabled style="display: flex; align-items: center; justify-content: center;">
            <span class="mr-2">View Event</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
            </svg>

          </a-button>
        </div>
        <div class="flex flex-col space-y-3">
          <label for="r-listing" class="font-medium text-sm text-gray-900">Associate Listing</label>
          <input id="r-listing" type="text" name="r-listing" readonly="readonly"
            class="font-medium shadow-sm text-parqay-primary focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            :value="$deepGet(form, 'listing.name')" />
          <a-button block size="large" style="display: flex; align-items: center; justify-content: center;" @click.stop="$router.push({
            name: 'ListingsDetail',
            params: {
              id: $deepGet(form, 'listing.id')
            }
          })">
            <span>View Listing</span>
            <a-icon type="unordered-list" />
          </a-button>
        </div>
        <div class="flex flex-col space-y-3">
          <span class="font-medium text-sm text-gray-900">Host Income</span>
          <a-input disabled
            class="font-medium shadow-sm text-parqay-primary focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            :value="$formatCurrency($deepGet(form, 'host_income'))">
            <a-button type="primary" size="small" slot="suffix" style="font-size: 12px; font-weight: 500;"
              @click.stop="moreDetails()">Details</a-button>
          </a-input>
          <a-button block size="large" style="display: flex; align-items: center; justify-content: center;"
            @click.stop="moreDetails()">
            <span>Payment Method</span>
            <a-icon type="credit-card" />
          </a-button>
        </div>
      </div>
      <div class="flex flex-col space-y-3 py-2">
        <span class="font-medium text-sm text-gray-900">Booking Intervals</span>
        <div v-for="(duration, i) in $deepGet(form, 'durations', [])" :key="`reservation-duration-${i}`"
          class="flex flex-col space-y-2">
          <div class="grid grid-cols-2 gap-3 w-full">
            <div class="flex flex-col space-y-2">
              <span class="font-medium text-sm text-parqay-primary">From</span>
              <a-tag color="geekblue">
                {{ $formatDate(duration.from, "MMM Do, h:mm a") }}
              </a-tag>
            </div>
            <div class="flex flex-col space-y-2">
              <span class="font-medium text-sm text-parqay-primary">To</span>
              <a-tag color="geekblue">
                {{ $formatDate(duration.to, "MMM Do, h:mm a") }}
              </a-tag>
            </div>
          </div>
        </div>
        <a-button block size="large" style="display: flex; align-items: center; justify-content: center;"
          @click.stop="() => {
            close();
            $router.push('/dashboard/reservations#calendar');
          }">
          <span>View Calendar</span>
          <a-icon type="calendar" />
        </a-button>
      </div>
      <div class="flex flex-col space-y-3 py-2">
        <div class="flex items-center justify-between">
          <span class="font-medium text-sm text-gray-900">Created At</span>
          <a-tag>{{
            $deepGet(form, "date") ? $formatDate(form.date, "MMM DD, h:mm a") : "N/A"
          }}</a-tag>
        </div>
        <div v-if="$deepGet(form, 'status') !== 'denied'" class="flex items-center justify-center pt-4 mb-16">
          <button @click="moreDetails()" class="
          flex
          items-center
          space-x-3
            w-full
            justify-center
            py-2.5
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-semibold
            text-white
            bg-parqay-primary
            hover:bg-blue-500
            focus:outline-none
          ">
            <span>Reservation Details</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
            </svg>

          </button>
        </div>
      </div>
    </div>
  </a-drawer>
</template>
<style lang="scss" scoped>
.ant-tag {
  @apply py-1 font-semibold;
}

::v-deep {
  .ant-input {
    height: 40px;
  }

  .ant-input[disabled][confirmation] {
    @apply cursor-pointer;
  }

  .ant-input[disabled][tenant] {
    @apply text-parqay-primary font-medium cursor-pointer;
  }

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    cursor: text;
    opacity: 1;
  }
}
</style>
<style>
.ant-drawer.ant-drawer-right.ant-drawer-open {
  transition: right 0.5s ease-in-out 0s !important;
}
</style>