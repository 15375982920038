<script>
import { onMounted } from "@vue/composition-api";

export default {
  setup(props, ctx) {
    onMounted(() => {
      setTimeout(() => {
        ctx.root.$router.push({
          name: ctx.root.$route.params.next
        });
      }, 500);
    });
    return {};
  }
};
</script>
<template>
  <div class="fixed inset-0 z-30">
    <div class="w-screen h-screen flex bg-gray-50">
      <div
        class="absolute transform left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 flex flex-col space-y-4 justify-center items-center">
        <img v-if="$getWhiteLabelImages().logoURL" :src="$getWhiteLabelImages().logoURL" class="h-20 w-auto object-contain">
        <svg-icon v-else icon-class="PARQAY-logo" class="h-20 w-auto" />
        <svg-icon icon-class="circular-loader" class="w-8 h-8 animate-spin text-blue-500" />
      </div>
    </div>
  </div>
</template>
