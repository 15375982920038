<script>
import StateHead from "src/views/dashboard/components/StateHead";
import { ref, onMounted, watch, nextTick, reactive } from "@vue/composition-api";
import { API } from "src/views/components/api/account";
import { getAvatarURL } from "src/views/components/util";

const menuItemFactory = (label, iconName, link = "") => ({
  label,
  iconName,
  link,
  isNativeLink: link.startsWith("http"),
});

export default {
  name: "host-menu",
  components: {
    StateHead,
  },
  setup(_, ctx) {
    const form = reactive({
      email: null,
      phone_number: null,
      picture: null,
      first_name: null,
      last_name: null,
      plan: null
    });

    const menus = ref([
      menuItemFactory("Overview", "home", "/dashboard"),
      menuItemFactory(
        "Reservations & Calendar",
        "calendar",
        "/dashboard/reservations"
      ),
      menuItemFactory("Listings & Properties", "view-list", "/dashboard/listings"),
      menuItemFactory("Payments & Transactions", "card", "/dashboard/payments"),
      menuItemFactory("Tenants & Leads", "users", "/dashboard/tenants"),
      menuItemFactory("Messaging & Email", "chat", "/dashboard/messaging"),
      //TODO: Release in Beta version -> Event
      // menuItemFactory("Events", "cake", "/dashboard/events"),
      menuItemFactory("Documents", "document", "/dashboard/documents"),
      menuItemFactory("Automation", "automation", "/dashboard/automation"),
      //TODO: Release in Beta version -> Team
      // menuItemFactory("Team", "user-group", "/dashboard/team"),
      menuItemFactory("Vehicles", "car-keys-outlined", "/dashboard/vehicles"),
      menuItemFactory("Towing", "tow-truck", "/dashboard/towing"),
      menuItemFactory("Maintenance", "truck", "/dashboard/maintenance"),
    ]);

    const isActive = (path) => {
      const currentPath = ctx.root.$route.path;
      if (path.endsWith("dashboard")) {
        return currentPath === path;
      }

      return currentPath.startsWith(path);
    };

    // Variable to control the margin value if notification is shown
    const notificationReady = ref(true);

    const getRequestsCountNotification = () => {
      const count = ctx.root.$store.state.requestsCount;
      console.log("getRequestsCountNotification >> ", count);
      let notification = "";
      if (count > 0 && count < 10) {
        if (count === 1) {
          notification = "1 request";
        } else {
          notification = count + " requests";
        }
      } else if (count >= 10 && count < 20) {
        notification = "10+ requests";
      } else if (count >= 20 && count < 30) {
        notification = "20+ requests";
      } else if (count >= 30 && count < 40) {
        notification = "30+ requests";
      } else if (count >= 40 && count < 50) {
        notification = "40+ requests";
      } else if (count >= 50 && count < 100) {
        notification = "50+ requests";
      } else if (count >= 99) {
        notification = "99+ requests";
      }
      return notification;
    };

    const handleRequestsClick = () => {
      ctx.root.$router.push("/dashboard#requests");
      nextTick(() => {
        const hash = ctx.root.$route.hash.replace("#", "");
        console.log("hash >> ", hash);
        if (hash === "requests") {
          setTimeout(() => {
            document.getElementById(hash).scrollIntoView({ behavior: "instant" });
          }, 500);
        }
      });
    };

    const fetchData = async () => {
      let resp;
      try {
        resp = await API.getUser(null, true);
        form.email = resp.data.email;
        form.phone_number = resp.data.phone_number;
        form.picture = await getProfilePicture(resp.data.picture);
        form.first_name = resp.data.first_name;
        form.last_name = resp.data.last_name;
        form.plan = resp.data.plan;
        ctx.root.$store.commit("setAdmin", resp.data.is_admin === true);
      } catch (error) {
        console.warn(error);
      }
    };

    const getProfilePicture = async (path) => {
      return await getAvatarURL(path);
    };

    onMounted(() => {
      fetchData();
    });

    return {
      form,
      isActive,
      notificationReady,
      menus,
      getRequestsCountNotification,
      handleRequestsClick,
    };
  },
};
</script>
<template>
  <div id="host-menu" class="hidden md:flex md:flex-shrink-0 overflow-hidden">
    <div class="flex flex-col" :style="{ minWidth: '20vw' }">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white" style="height: 100vh">
        <smooth-scroll>
          <div class="flex flex-col overflow-y-auto">
            <div class="flex flex-col items-center justify-center px-4 pt-6 pb-5 space-y-2">
              <router-link tag="a" to="/" class="flex justify-center items-center">
                <img v-if="$getWhiteLabelImages().logoURL" :src="$getWhiteLabelImages().logoURL"
                  class="h-16 object-contain" :style="{ width: '220px' }">
                <svg-icon v-else icon-class="PARQAY-logo" class="h-16" :style="{ width: '220px' }" />
              </router-link>
              <a-tag v-if="!$isAdmin()" color="geekblue">Host Dashboard</a-tag>
              <div v-else class="flex items-center">
                <div class="w-1.5 h-1.5 bg-pink-500 rounded-full -mr-2 mb-0.5" />
                <span class="text-black text-sm font-bold border-b-2 border-black pl-4 pr-2 py-1">Admin Dashboard</span>
              </div>
            </div>
            <nav class="flex-1 px-1 bg-white space-y-2">
              <component :is="menu.isNativeLink ? 'a' : 'router-link'" v-for="(menu, index) in menus" :key="menu.link"
                :href="menu.link" :to="menu.link" class="
                          group
                          h-12
                          flex
                          items-center
                          relative
                          px-4
                          py-1
                          text-sm
                          leading-5
                          font-light
                          rounded
                          hover:font-medium
                          focus:outline-none
                          focus:text-white
                          focus:bg-blue-600
                          transition
                          ease-in-out
                          duration-150
                        " :class="{
                          'bg-white text-gray-600 hover:text-blue-600': !isActive(menu.link),
                          'bg-blue-600 text-white font-medium hover:font-semibold hover:bg-blue-500 hover:text-white': isActive(
                            menu.link
                          ),
                        }">
                <svg-icon :icon-class="menu.iconName" class="h-5 w-5 group-focus:text-white" :class="{
                  'text-white': isActive(menu.link),
                  'mr-3': notificationReady,
                  'mr-6': !notificationReady,
                }" />
                {{ menu.label }}
                <div v-if="index == 0 && $store.state.requestsCount > 0" class="absolute right-2 my-auto"
                  @click.prevent="handleRequestsClick()">
                  <!-- Prevent parent element from triggering redundant click events -->
                  <a-tag class="request-tag">
                    <svg class="mr-1.5 h-2 w-2 text-blue-400" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                    {{ getRequestsCountNotification() }}
                  </a-tag>
                </div>
                <div v-if="index == 1" class="absolute right-2 my-auto">
                  <a-badge :count="$store.getters.getUnreadMessagesCount()" />
                </div>
              </component>
            </nav>
          </div>
        </smooth-scroll>
        <div class="px-2 mt-1">
          <state-head :pic="form.picture" :name="form.first_name" online :plan="form.plan" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .request-tag {
    @apply cursor-pointer flex items-center space-x-2 text-xs font-medium border border-indigo-200 bg-blue-100 hover:bg-blue-200 text-blue-800 whitespace-nowrap py-1;
  }
}
</style>