<script>
import { reactive, ref, onMounted, onBeforeUnmount } from "@vue/composition-api";
import TemplateUploader from "src/views/documents/components/Uploader.vue";
import ReservationSearchInput from "src/views/components/ReservationSearchInput.vue";
import TemplateRequest from "src/views/documents/components/TemplateRequestPrompt.vue";
import startCase from "lodash/startCase";

import { API } from "src/views/documents/api";

export default {
    components: {
        TemplateUploader,
        ReservationSearchInput,
        TemplateRequest
    },
    setup(props, ctx) {
        const controls = reactive({
            loading: {
                create: false,
                invite: false,
                review: false
            },
            visible: false,
            expand: {
                message: false,
                intervalMode: false,
                listingType: false
            },
            showComplete: false,
            selected: {
                reservation: null
            },
            currentStep: 0,
            model: {
                name: null,
                signer_message: null,
                doc_sign_required: false,
                type: "upload",
                subtypes: [
                    "parking", "stay", "coworking", "car-rental"
                ],
                interval_modes: [
                    "monthly", "daily", "hourly"
                ],
                editable: true,
                docs_to_sign: []
            },
            templateReviewConsent: false
        })
        const templates = ref([
            controls.model
        ]);
        const getSteps = (temp) => {
            let steps = [
                {
                    title: "Info",
                    icon: "form"
                },
                {
                    title: "Options",
                    icon: "unordered-list"
                },
                {
                    title: "Upload",
                    icon: "cloud-upload"
                },

            ]
            if (temp.type === "upload") {
                steps.push({
                    title: "Invite",
                    icon: "file-done"
                })
            }
            if (temp.type === "sign") {
                steps.push({
                    title: "Review",
                    icon: "file-sync"
                })
            }
            return steps;
        }
        const getDisplayStepPrev = () => {
            if (controls.currentStep > 0) {
                if (controls.model.type === "upload") {
                    if (controls.currentStep <= 2) {
                        return true;
                    }
                }
                if (controls.model.type === "sign") {
                    return true;
                }
            }
            return false;
        }
        const getStepNameNext = () => {
            if (controls.currentStep == 2) {
                if (controls.model.type === "upload") {
                    return "Create Document";
                }
            }
            return "Next";
        }
        const getStepStatus = (i) => {
            if (controls.currentStep < i) {
                return "wait";
            }
            if (controls.currentStep == i) {
                return "process";
            }
            if (controls.currentStep > i) {
                return "finish";
            }
        }
        const getDisableStepNext = () => {
            // return false;
            if (controls.currentStep == 0) {
                if (!controls.model.name) {
                    return true;
                }
            }
            if (controls.currentStep == 1) {
                if (!controls.model.signer_message) {
                    return true;
                }
            }
            if (controls.currentStep == 2) {
                if (controls.model.type === "sign") {
                    if (controls.model.docs_to_sign.length == 0) {
                        return true;
                    }
                }
            }
            return false;
        }
        const getStepNextAction = async () => {
            if (controls.currentStep == 2) {
                if (controls.model.type === "upload") {
                    createNewTemplate().then(() => {
                        controls.currentStep += 1;
                    })
                    return;
                }
            }
            controls.currentStep += 1;
        }
        const createNewTemplate = async () => {
            try {
                controls.loading.create = true;
                const { data } = await API.createNewTemplate(controls.model);
                ctx.root.$bus.$emit("refresh-document-templates-list");
                setTimeout(() => {
                    controls.loading.create = false;
                }, 1000);
                return data;
            } catch (error) {
                ctx.root.$openNotification("New Document Template", error.message, 10, "error");
            }
        }
        const openModal = () => {
            controls.visible = true;
        }
        const closeModal = () => {
            controls.visible = false;
            setTimeout(() => {
                controls.currentStep = 0;
                controls.model.name = null;
                controls.model.signer_message = null;
                controls.model.doc_sign_required = false;
                controls.model.type = "upload";
                controls.model.docs_to_sign = [];
            }, 1500)
        }
        onMounted(() => {
            ctx.root.$bus.$on("open-new-document-template-modal", openModal);
        })
        onBeforeUnmount(() => {
            ctx.root.$bus.$off("open-new-document-template-modal", openModal);
        })
        return {
            controls,
            templates,
            getSteps,
            getDisplayStepPrev,
            getStepNameNext,
            getStepStatus,
            getDisableStepNext,
            getStepNextAction,
            closeModal,
            createNewTemplate,
            startCase,
            console
        }
    }
}
</script>

<template>
    <a-modal :visible="controls.visible" title="Create Document for Tenants"
        :width="(controls.model.type === 'sign' && controls.currentStep === 3) ? '65vw' : '600px'" :mask-closable="false"
        @cancel="closeModal()">
        <div class="new-doc-template flex flex-col space-y-2">
            <a-steps>
                <a-step v-for="(step, i) in getSteps(controls.model)" :key="`template-step-${i}`" :status="getStepStatus(i)"
                    :title="step.title">
                    <a-icon slot="icon" :type="step.icon" />
                </a-step>
            </a-steps>
            <div class="flex flex-col items-center justify-between space-y-8" style="min-height: 300px;">
                <keep-alive>
                    <div v-for="(temp, i) in templates.filter(t => ['upload', 'sign'].includes(t.type))"
                        :key="`doc-template-${i}`" class="flex justify-center py-4 w-full rounded-xl border">
                        <div v-if="controls.currentStep == 0"
                            class="flex flex-col items-center justify-center space-y-4 border-gray-400 p-4">
                            <img v-if="temp.type === 'upload'" class="w-16 h-16 object-contain"
                                src="/static/img/doc-upload.png">
                            <img v-if="temp.type === 'sign'" class="w-16 h-16 object-contain"
                                src="/static/img/doc-sign.png">
                            <label-item label="Document type" desc="Choose a document type that fits your requirements.">
                                <template #actions>
                                    <a-tag color="green">{{ startCase(temp.type) }}</a-tag>
                                </template>
                                <div class="flex justify-center space-x-4">
                                    <div class="border w-1/2 flex flex-col items-center justify-start rounded-lg hover:bg-blue-100 hover:text-blue-500 px-4 py-4 relative cursor-pointer"
                                        :class="temp.type === 'upload' ? 'border-2 border-blue-500 text-parqay-primary bg-blue-50' : 'bg-gray-50'"
                                        @click.stop="() => {
                                            temp.type = 'upload';
                                            temp.doc_sign_required = false;
                                        }">
                                        <span class="text-lg font-bold pb-4">Upload</span>
                                        <span class="text-sm" :class="{ 'text-blue-500': temp.type === 'upload' }">The
                                            document type that allows your tenant to
                                            upload files.</span>
                                        <div v-if="temp.type === 'upload'" class="absolute right-2 top-2">
                                            <a-icon type="check-circle" style="font-size: 25px;" />
                                        </div>
                                    </div>
                                    <div class="border w-1/2 flex flex-col items-center justify-start rounded-lg hover:bg-blue-100 hover:text-blue-500 px-4 py-4 relative cursor-pointer"
                                        :class="temp.type === 'sign' ? 'border-2 border-blue-500 text-parqay-primary bg-blue-50' : 'bg-gray-50'"
                                        @click.stop="() => {
                                            temp.type = 'sign';
                                            temp.doc_sign_required = true;
                                        }">
                                        <span class="text-lg font-bold pb-4">Sign</span>
                                        <span class="text-sm" :class="{ 'text-blue-500': temp.type === 'sign' }">The
                                            document type that requires tenant or you
                                            to sign.</span>
                                        <div v-if="temp.type === 'sign'" class="absolute right-2 top-2">
                                            <a-icon type="check-circle" style="font-size: 25px;" />
                                        </div>
                                    </div>
                                </div>
                            </label-item>
                            <label-item label="Enter Document Name"
                                desc="The document name will be shown in tenant document email invitation and notification as the name of the required document."
                                class="w-full">
                                <a-input v-model="temp.name" placeholder="Enter document name." allow-clear size="large" />
                            </label-item>
                        </div>
                        <div v-if="controls.currentStep == 1"
                            class="flex flex-col pt-4 space-y-4 h-full w-full items-center justify-center border-gray-400 overflow-y-auto">
                            <img v-if="temp.type === 'upload'" class="w-16 h-16 object-contain"
                                src="/static/img/doc-upload.png">
                            <img v-if="temp.type === 'sign'" class="w-16 h-16 object-contain"
                                src="/static/img/doc-sign.png">
                            <div class="flex flex-col w-full px-4 space-y-3">
                                <label-item label="Message for Tenants"
                                    desc="Enter any message, instruction or guidance for the document." class="w-full">
                                    <a-textarea v-model="temp.signer_message" allow-clear placeholder="Message for tenants."
                                        :auto-size="{ minRows: 3, maxRows: 6 }" :disabled="!temp.editable" size="large" />
                                </label-item>
                                <a-divider />
                                <div v-if="!controls.expand.intervalMode" class="flex justify-between items-center">
                                    <span class="font-medium text-sm">Allowed durations</span>
                                    <div class="flex items-center">
                                        <a-tag v-if="temp.interval_modes.length == 3" color="green">All</a-tag>
                                        <a-tag v-else-if="temp.interval_modes.length != 1" color="geekblue">{{
                                            temp.interval_modes.length }}
                                            selected</a-tag>
                                        <a-tag v-else color="geekblue">{{ startCase(temp.interval_modes[0]) }}</a-tag>
                                        <a-button v-if="temp.editable"
                                            @click="() => controls.expand.intervalMode = true">Edit</a-button>
                                    </div>
                                </div>
                                <template v-else>
                                    <div class="flex items-center justify-between">
                                        <span class="font-medium text-sm">This template applies to:</span>
                                        <a-button type="primary"
                                            @click="() => controls.expand.intervalMode = false">Confirm</a-button>
                                    </div>
                                    <a-checkbox-group class="listing-type-checkbox-group" v-model="temp.interval_modes">
                                        <div class="flex flex-wrap">
                                            <a-checkbox value="monthly" class="mx-2 my-1">
                                                Monthly
                                            </a-checkbox>
                                            <a-checkbox value="daily" class="mx-2 my-1">
                                                Daily
                                            </a-checkbox>
                                            <a-checkbox value="hourly" class="mx-2 my-1">
                                                Hourly
                                            </a-checkbox>
                                        </div>
                                    </a-checkbox-group>
                                </template>
                                <a-divider />
                                <div v-if="!controls.expand.listingType" class="flex justify-between items-center">
                                    <span class="font-medium text-sm">Allowed listing types</span>
                                    <div class="flex items-center">
                                        <a-tag v-if="temp.subtypes.length == 4" color="green">All</a-tag>
                                        <a-tag v-else-if="temp.subtypes.length != 1" color="geekblue">{{
                                            temp.subtypes.length }}
                                            selected</a-tag>
                                        <a-tag v-else color="geekblue">{{ startCase(temp.subtypes[0]) }}</a-tag>
                                        <a-button v-if="temp.editable"
                                            @click="() => controls.expand.listingType = true">Edit</a-button>
                                    </div>
                                </div>
                                <template v-else>
                                    <div class="flex items-center justify-between">
                                        <span class="font-medium text-sm">This template applies to:</span>
                                        <a-button type="primary"
                                            @click="() => controls.expand.listingType = false">Confirm</a-button>
                                    </div>
                                    <a-checkbox-group class="listing-type-checkbox-group" v-model="temp.subtypes">
                                        <div class="flex flex-wrap">
                                            <a-checkbox value="parking" class="mx-2 my-1">
                                                Parking
                                            </a-checkbox>
                                            <a-checkbox value="stay" class="mx-2 my-1">
                                                Stay
                                            </a-checkbox>
                                            <a-checkbox value="coworking" class="mx-2 my-1">
                                                Coworking
                                            </a-checkbox>
                                            <a-checkbox value="car-rental" class="mx-2 my-1">
                                                Car Rental
                                            </a-checkbox>
                                        </div>
                                    </a-checkbox-group>
                                </template>
                            </div>
                        </div>
                        <template v-if="controls.currentStep == 2">
                            <div v-if="temp.type === 'sign'"
                                class="flex flex-col items-center justify-center h-full w-full pt-4 space-y-4 px-4 pb-2 overflow-y-auto">
                                <img v-if="temp.type === 'upload'" class="w-16 h-16 object-contain"
                                    src="/static/img/doc-upload.png">
                                <img v-if="temp.type === 'sign'" class="w-16 h-16 object-contain"
                                    src="/static/img/doc-sign.png">
                                <template-uploader :files="controls.model.docs_to_sign" class="w-full" @uploaded="(files) => {
                                    controls.model.docs_to_sign = files;
                                }" />
                            </div>
                            <div v-if="temp.type === 'upload'"
                                class="flex flex-col items-center justify-center h-full w-full overflow-hidden pt-4 space-y-4 px-4 pb-2">
                                <img v-if="temp.type === 'upload'" class="w-16 h-16 object-contain"
                                    src="/static/img/doc-upload.png">
                                <img v-if="temp.type === 'sign'" class="w-16 h-16 object-contain"
                                    src="/static/img/doc-sign.png">
                                <div class="flex flex-col space-y-2 items-center px-4">
                                    <span class="font-medium text-lg text-gray-500">Upload Only</span>
                                    <span class="leading-6 font-light text-sm text-gray-700 text-center">We'll prompt your
                                        tenants to upload
                                        the
                                        <a-tag v-if="temp.name" color="geekblue">{{ temp.name }}</a-tag>document you
                                        specified
                                    </span>
                                    <span class="font-semibold">Please proceed to next step to complete the document
                                        creation.</span>
                                </div>
                            </div>
                        </template>
                        <template v-if="controls.currentStep == 3">
                            <div v-if="temp.type === 'upload'"
                                class="flex flex-col items-center justify-center space-y-2 border-gray-400 px-8 py-4">
                                <a-icon type="check-circle" theme="twoTone" style="font-size: 4rem;" />
                                <span class="text-lg font-semibold pt-2">Document has been created</span>
                                <span class="text-center text-sm font-light pb-4">{{ temp.name }} will apply for all future
                                    incoming reservations. If you wish to send the document invitation to existing
                                    reservations,
                                    please select. </span>
                                <a-divider></a-divider>
                                <label-item label="Select A Reservation"
                                    :desc="`Select an existing reservation to send invitation of ${temp.name}`"
                                    class="w-full pt-4">
                                    <reservation-search-input
                                        @select="reservation => controls.selected.reservation = reservation" />
                                </label-item>
                            </div>
                            <div v-if="temp.type === 'sign'" class="px-8 py-4">
                                <div v-if="controls.showComplete"
                                    class="flex flex-col items-center justify-center space-y-2 border-gray-400 px-8 py-4">
                                    <a-icon type="clock-circle" theme="twoTone" style="font-size: 4rem;" />
                                    <span class="text-lg font-semibold pt-2">Document has been created</span>
                                    <span class="text-center text-sm font-light pb-4">{{ temp.name }} will apply for all
                                        future
                                        incoming reservations upon approval of your request.</span>
                                </div>
                                <template-request v-else :controls="controls" :funcs="{ createNewTemplate }" />
                            </div>
                        </template>
                    </div>
                </keep-alive>
            </div>
        </div>
        <template #footer>
            <div class="flex items-center justify-between space-x-2">
                <a-button v-if="getDisplayStepPrev() && !controls.showComplete"
                    @click.stop="() => controls.currentStep -= 1">
                    Previous
                </a-button>
                <div v-else />
                <a-button v-if="controls.currentStep < 3" type="primary" :loading="controls.loading.create"
                    :disabled="getDisableStepNext()" @click.stop="getStepNextAction()">
                    {{ getStepNameNext() }}
                </a-button>
                <div v-if="controls.model.type === 'upload' && controls.currentStep == 3"
                    class="flex items-center space-x-4">
                    <a-button type="primary" :loading="controls.loading.invite" :disabled="!controls.selected.reservation"
                        @click.stop="getStepNextAction()">
                        Invite
                    </a-button>
                    <a-button @click.stop="closeModal()">
                        Skip
                    </a-button>
                </div>
                <a-button v-if="controls.model.type === 'sign' && controls.currentStep == 3 && controls.showComplete"
                    type="primary" @click.stop="closeModal()">
                    Complete
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<style lang="scss">
.new-doc-template {
    .ant-steps-icon {
        @apply flex items-center justify-end;
    }
}
</style>