import dayjs from "dayjs";
import { shortUID } from "src/plugins/prototype";
import { formatMomentForBackend } from "src/plugins/prototype";

// Note: Use FORM ONLY for communicating with BACKEND
export const getDefaultReservationDetailsForm = routeType => {
  return {
    id: routeType === "new" ? shortUID() : null,
    title: null,
    status: "pending",
    source: "manual",
    type: routeType === "new" ? null : routeType,
    vehicle: null,
    smartcar: {
      prompt_tenant: true,
      prompt_reason: "We recommend connecting Smartcar virtual key to streamline our service.",
      trunk_delivery: false,
      delivery_address: null,
      delivery_price: 2.0,
      unavailable: false,
      unavailable_reason: null
    },
    intervals: [
      // Example -> { id: shortUID(), start: "2021-10-26T18:30:00+08:00", end: "2021-10-30T18:30:00+08:00" },
    ],
    review: null, // Review items, attach when using review in marketplace flow
    listing: null,
    spaces: null, // Only for reservations from source=marketplace
    drivers: [],
    guests: [],
    transaction: {
      request_user_enter_payment_method: true,
      follow_listing_price: true,
      send_receipt: true,
      list_price: 0,
      interval_mode: "hourly",
      pricing: {
        guest_meta: null,
        host_meta: null,
        stripe_meta: null
      },
      // Meaning one-time(deposit) payment: charge 1 time over 1 hour duration
      charge_duration: {
        amount: 1,
        unit: "hours"
      },
      final_charge: 0,
      host_income: 0,
      charge_type: "subscription",
      next_charge_date: formatMomentForBackend(dayjs()),
      final_charge_date: formatMomentForBackend(dayjs()),
      payment_method: null,
      invoice_items: []
    },
    document_templates: [],
    tenant: null,
    host: null, //Fill by backend, not frontend.
    combo_listing: null,
    created_at: dayjs()
  };
};

export const getDefaultGuestForm = () => {
  return {
    id: null,
    name: null,
    phone_number: null,
    doc_number: null
  };
  // if (['Stays', 'Coworking'].includes(type)) {
  //   return [
  //     {
  //       id: "example_driver_id1",
  //       name: "Alex",
  //       phone_number: "+1 (734)272-6106",
  //       doc_number: "ABC123",
  //     },
  //     {
  //       id: "example_driver_id2",
  //       name: "Olivia",
  //       phoneNumber: "+1 (734)272-6106",
  //       docNumber: "ABC123",
  //     },
  //   ]
  // }
  // return null
};

// Note: Use CONTROLS ONLY for controlling UI
export const getDefaultReservationDetailsControls = routeType => {
  const createMode = routeType === "new";
  return {
    createMode: createMode,
    showCreateNewTenant: createMode,
    editMode: createMode,
    editingTitle: createMode,
    editModeTenant: false,
    identity_verification: null,
    visible: {
      typeSelect: false
    },
    showCalendar: false,
    showPricingDetails: false,
    showIncomeDetails: false,
    showListingInputSearch: createMode,
    showTenantInputSearch: createMode,
    disableListingSearchInput: false,
    intervals: [
      //   {
      //     start: moment("2021-01-01", "YYYY-MM-DD"),
      //     end: moment("2021-01-02", "YYYY-MM-DD"),
      //   }
    ],
    drivers: [],
    guests: [],
    transaction: {
      chargeTypes: ["subscription", "deposit"],
      showDuration: true,
      disableFinalChargeDate: false,
      nextChargeDate: dayjs(),
      finalChargeDate: dayjs(),
      editingInvoiceItems: false,
      invoiceItems: [
        {
          description: null,
          amount: null
        }
      ]
    },
    tenant: null,
    newTenant: {
      id: null,
      firstname: null,
      lastname: null,
      name: null,
      city: null,
      address: null,
      email: null,
      phone_number: null,
      invitation_code: shortUID(),
      invite_by: {
        email: true,
        phone_number: false
      },
      invitation_message: null,
      validate: p => {
        if (!p.firstname) {
          return "First name is required";
        }
        if (!p.lastname) {
          return "Last name is required";
        }
        p.name = p.firstname + " " + p.lastname;
        if (!p.email) {
          return "Email is required";
        }
        return null;
      }
    },
    listing: {
      selected: null,
      mileagePenaltyOptions: [
        { text: "YES", value: true },
        { text: "NO", value: false }
      ],
      visible: {
        activeConfirmation: false
      }
    },
    errors: [],
    loading: {
      getReservationDetails: false,
      createReservation: false,
      pendingReminder: false,
      documentTemplates: false
    },
    keys: {
      tenantSearchInput: 0
    }
  };
};

export const getDefaultGuestControls = editMode => {
  return {
    editMode
  };
};
