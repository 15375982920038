<script>
import { reactive, toRaw } from "@vue/composition-api";

import { API } from "src/views/documents/api";

export default {
    props: {
        controls: {
            type: Object,
            required: true
        },
        funcs: {
            type: Object,
            required: true
        }
    },
    setup(props, ctx) {
        const form = reactive({
            signature_type: "simple_signature",
            request_message: null,
            contact_type: "email",
            contact_method: null,
            templates: null
        })
        const createTemplateReviewRequest = async () => {
            props.controls.loading.review = true;
            try {
                const { data: { id } } = await props.funcs.createNewTemplate();
                const payload = {
                    ...toRaw(form)
                };
                const template = toRaw(props.controls.model);
                template.id = id;
                payload.templates = [template];
                await API.createTemplateReviewRequest(payload);
                setTimeout(() => {
                    props.controls.loading.review = false;
                }, 2000);
                props.controls.showComplete = true;
            } catch (error) {
                ctx.root.$openNotification("Error", error.message);
                props.controls.loading.review = false;
            }
        }
        return {
            form,
            createTemplateReviewRequest
        }
    }
}
</script>

<template>
    <div class="flex flex-col space-y-5">
        <span class="text-center text-lg font-semibold">Template Review</span>
        <span class="text-center text-base font-medium">Let our team know how your document
            template is
            going to work
        </span>
        <p class="text-sm text-gray-500">
            Choose an option that best fits your requirement below. If you prefer more template fields customization,
            choose the second option. Please specify the details in the text box and our team will help you insert those
            fields into your template.
            A review normally takes about less than a day, after that, your template will be available to your tenants
            for all new reservations.
        </p>
        <div class="flex flex-col space-y-2 p-6 rounded-lg border bg-white">
            <a-radio-group v-model="form.signature_type">
                <a-radio :style="{
                    display: 'block',
                    height: '30px',
                    lineHeight: '30px',
                }" value="simple_signature">
                    I just need a signature field at the bottom of the template
                </a-radio>
                <a-radio :style="{
                    display: 'block',
                    height: '30px',
                    lineHeight: '30px',
                }" value="advanced_signature">
                    I need more customizations: more fields for tenants to input
                </a-radio>
            </a-radio-group>
        </div>
        <a-textarea v-if="form.signature_type === 'advanced_signature'" v-model="form.request_message"
            placeholder="Enter any instructions or guidance for our review team about positions of each input fields, and any questions you wish to ask"
            :auto-size="{ minRows: 5, maxRows: 8 }" />
        <a-divider>Associated templates</a-divider>
        <div class="grid grid-cols-2 gap-4 py-4">
            <div v-for="(temp, i) in [controls.model]" :key="`template-to-review-${i}`"
                class="flex items-center border rounded space-x-4 px-4 py-2">
                <svg-icon class="w-8 h-8 text-parqay-primary" icon-class="document" />
                <div class="flex items-center justify-between w-full">
                    <span class="text-sm font-medium text-black">{{ temp.name }}</span>
                    <a-tag color="geekblue">{{ temp.docs_to_sign.length }} files attached</a-tag>
                </div>
            </div>
        </div>
        <a-divider>Contact info</a-divider>
        <div class="grid grid-cols-3 justify-items-center">
            <span class="text-sm my-auto col-span-2">
                If your contact method is different from that in your account setting, please specify
            </span>
            <a-input-group compact v-model="form.contact_type">
                <a-select default-value="email">
                    <a-select-option value="email">
                        Email
                    </a-select-option>
                    <a-select-option value="phone_number">
                        Phone
                    </a-select-option>
                </a-select>
                <a-input v-model="form.contact_method" placeholder="Contact method" style="width: 65%" />
            </a-input-group>
        </div>
        <a-alert type="info">
            <template #message>
                <div class="flex items-center justify-between space-x-2">
                    <a-checkbox v-model="controls.templateReviewConsent">
                        I understand the process of templates review, let me continue
                    </a-checkbox>
                    <a-button type="primary" :loading="controls.loading.review"
                        :disabled="!controls.templateReviewConsent" @click.stop="createTemplateReviewRequest()">
                        Submit Request
                    </a-button>
                </div>
            </template>
        </a-alert>
    </div>
</template>