<script>
import VehicleForm from "src/views/listings/new/car-rentals/components/VehicleForm.vue";
import VehicleVerification from "src/views/components/VehicleVerification.vue";
import Map from "src/views/map/View";
import {
  computed,
  onMounted,
  reactive,
  ref,
  watch
} from "@vue/composition-api";
import { API } from "src/views/components/api";
import ntc from "ntc";
import { getDefaultVehicleModel } from "src/views/listings/new/car-rentals/components/util";
import startCase from 'lodash/startCase';

export default {
  components: {
    VehicleForm,
    VehicleVerification,
    "pq-map": Map
  },
  props: {
    vehicleId: {
      type: String,
      require: false
    },
    model: {
      type: Object
    },
    title: {
      type: String
    },
    onBehalfOf: {
      type: String,
      default: null
    }
  },
  setup(props, ctx) {
    const deepGet = ctx.root.$deepGet;
    const tinycolor = require("tinycolor2");
    const vehicleForm = ref(null);
    const _vehicle = props.model || getDefaultVehicleModel();
    const vehicle = reactive({ ..._vehicle });
    const manualMode = ref(false);
    const savedVehicle = ref(null);
    if (props.model && props.model.id) {
      savedVehicle.value = props.model;
    }
    watch(
      () => props.model,
      val => {
        if (val.id) {
          savedVehicle.value = val;
        }
      }
    );
    watch(
      () => savedVehicle.value,
      val => {
        fetchSmartCarVehicleInfo(val);
      }
    );
    const loading = reactive({
      save: false,
      operations: {
        unlock: false,
        lock: false,
        openTrunk: false
      }
    });
    const controls = reactive({
      showBottomSectionMask: false,
      showReconnect: false,
      vehicleOperationStatus: null,
      tirePressures: null,
      odometerDistance: null,
      fuelLevel: null,
      mapCenter: { lat: 36.964, lon: -122.015, text: "Vehicle" },
      age: null
    });
    const enableSaveButton = computed(() => {
      return (
        vehicle.make &&
        vehicle.model &&
        vehicle.color &&
        vehicle.plate_number &&
        vehicle.year
      );
    });
    const getColorName = hex => {
      var n_match = ntc.name(hex);
      const n_rgb = n_match[0]; // RGB value of closest match
      const n_name = n_match[1]; // Text string: Color name
      const n_exactmatch = n_match[2]; // True if exact color match
      return n_name;
    };
    const isColorBright = hex => {
      return tinycolor(hex).isLight();
    };
    const fetchSmartCarVehicleInfo = v => {
      if (deepGet(v, "smartcar")) {
        vehicleOperation(v, "tire_pressure").then(resp => {
          controls.tirePressures = deepGet(resp, "data.data.tires");
        });
        vehicleOperation(v, "odometer").then(resp => {
          controls.odometerDistance = deepGet(resp, "data.data.distance");
        });
        vehicleOperation(v, "fuel_level").then(resp => {
          controls.fuelLevel = deepGet(resp, "data.data");
        });
        vehicleOperation(v, "locate").then(resp => {
          controls.mapCenter.lat = deepGet(resp, "data.data.latitude");
          controls.mapCenter.lon = deepGet(resp, "data.data.longitude");
          controls.age = deepGet(resp, "data.age");
        });
      }
    };
    const updateVerifiedInfo = v => {
      vehicle.id = v.id;
      vehicle.make = v.make;
      vehicle.model = v.model;
      vehicle.color = v.color;
      vehicle.plate_number = v.plate_number;
      vehicle.smartcar = true;
      console.log("updateVerifiedInfo: ", v);
      vehicle.smartcar_id = deepGet(v, "smartcar_meta.id", null);
      vehicleForm.value.selectMakeAndModel(v.make, v.model);
    };
    const getVehicle = async () => {
      let resp;
      try {
        resp = await API.getVehicle(props.vehicleId);
      } catch (error) {
        console.log("getVehicle >> error", error);
        return;
      }
      savedVehicle.value = resp.data;
    };
    const saveVehicle = async v => {
      loading.save = true;
      let res;
      if (!v.id) {
        try {
          res = await API.saveVehicle(v, props.onBehalfOf);
        } catch (error) {
          console.log("saveVehicle >> error", error);
          return;
        }
        v.id = res.data.id;
      } else {
        try {
          res = await API.updateVehicle(v, props.onBehalfOf);
        } catch (error) {
          console.log("updateVehicle >> error", error);
          return;
        }
        console.log("updateVehicle", res.data);
      }
      if (!vehicle.smartcar_id) {
        v.smartcar_id = deepGet(res.data, "smartcar_id", null);
      }
      savedVehicle.value = v;
      if (savedVehicle.value.smartcar) {
        vehicleOperation(v, "tire_pressure").then(resp => {
          controls.tirePressures = deepGet(resp, "data.data.tires");
        });
        vehicleOperation(v, "odometer").then(resp => {
          controls.odometerDistance = deepGet(resp, "data.data.distance");
        });
        vehicleOperation(v, "fuel_level").then(resp => {
          controls.fuelLevel = deepGet(resp, "data.data");
        });
        vehicleOperation(v, "locate").then(resp => {
          controls.mapCenter.lat = deepGet(resp, "data.data.latitude");
          controls.mapCenter.lon = deepGet(resp, "data.data.longitude");
          controls.age = deepGet(resp, "data.age");
        });
      }
      ctx.emit("added-vehicle", v);
      loading.save = false;
    };
    const editVehicle = (cancel = false) => {
      if (!cancel) {
        savedVehicle.value = null;
        manualMode.value = true;
      } else {
        savedVehicle.value = vehicle;
        manualMode.value = false;
      }
    };
    const vehicleOperation = async (v, operation) => {
      let resp;
      loading.operations[operation] = true;
      if (v["id"]) {
        try {
          resp = await API.smartCarOperations(
            null,
            v.id,
            operation == "openTrunk" ? "unlock" : operation,
            props.onBehalfOf
          );
          console.log("vehicleOperation: ", resp.data);
        } catch (error) {
          console.log("Smartcar operation error: ", error);
          controls.showReconnect = true;
          loading.operations[operation] = false;
          controls.vehicleOperationStatus = "Please reconnect";
          savedVehicle.value.smartcar = false;
          return resp;
        }
      }
      controls.showReconnect = false;
      loading.operations[operation] = false;
      controls.vehicleOperationStatus = `Success! ${startCase(
        operation
      )} done`;
      setTimeout(() => {
        controls.vehicleOperationStatus = null;
      }, 3000);
      return resp;
    };
    const deleteVehicle = async (permanent = false) => {
      const vehicleId = savedVehicle.value.id;
      savedVehicle.value = null;
      Object.assign(vehicle, _vehicle);
      manualMode.value = false;
      var res;
      try {
        if (permanent) {
          res = await API.deleteVehicle(vehicleId, props.onBehalfOf);
        }
        ctx.emit("delete-vehicle", vehicleId);
      } catch (error) {
        console.log("deleteVehicle => error", error, permanent);
      }
      console.log("deleteVehicle", res, permanent);
    };
    onMounted(async () => {
      if (props.vehicleId) {
        await getVehicle();
      }
      fetchSmartCarVehicleInfo(savedVehicle.value);
    });
    return {
      tinycolor,
      vehicleForm,
      vehicle,
      savedVehicle,
      manualMode,
      controls,
      loading,
      enableSaveButton,
      updateVerifiedInfo,
      getColorName,
      isColorBright,
      saveVehicle,
      editVehicle,
      vehicleOperation,
      deleteVehicle
    };
  }
};
</script>
<template>
  <div v-if="$isDesktopView()" class="flex bg-white border shadow-sm mx-6 my-2 rounded-lg py-4">
    <template v-if="!savedVehicle">
      <div class="w-1/2 relative border-r">
        <vehicle-verification v-if="!manualMode" min-width-none width="full" :title="title" :on-behalf-of="onBehalfOf"
          class="px-8" @vehicle-verified="updateVerifiedInfo" />
        <div v-else class="px-12 flex items-center h-full">
          <div class="flex flex-col items-center justify-between w-full space-y-4">
            <div v-if="!vehicle.smartcar" class="flex flex-col space-y-2">
              <span class="text-lg font-semibold whitespace-nowrap text-center">Enter Vehicle Information
                Manually</span>
              <span class="text-md text-center">Please make sure your guest can access vehicle key after
                purchase</span>
            </div>
            <div v-else class="flex flex-col space-y-2">
              <span class="text-lg font-semibold whitespace-nowrap text-center">Edit Vehicle Info</span>
              <span class="text-md text-center">Only partial infomation can be edited. To change car make or
                model, please reconnect a new vehicle</span>
            </div>
            <div class="relative">
              <svg-icon v-if="!vehicle.smartcar" class="w-24 h-24" icon-class="car-key" />
              <svg-icon v-else class="w-32 h-12" icon-class="smartcar" />
              <div v-if="!vehicle.smartcar" class="bg-white w-10 h-10 rounded-lg shadow-md absolute"
                style="right: -1rem; bottom: -1rem">
                <div class="flex items-center justify-center h-full">
                  <svg-icon class="w-6 h-6" icon-class="exchange" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="absolute left-4 h-6 right-4" style="bottom: -0.75rem">
          <div class="flex justify-between items-center w-full">
            <a v-if="savedVehicle"
              class="hover:text-blue-800 font-medium text-parqay-primary whitespace-nowrap text-xs leading-6"
              @click="editVehicle(true)">
              Cancel Edit
            </a>
            <a class="hover:text-blue-800 font-medium text-parqay-primary whitespace-nowrap text-xs leading-6" @click="() => {
    if (vehicle.smartcar) {
      deleteVehicle();
    } else {
      manualMode = !manualMode;
    }
  }
    ">
              {{
    manualMode
      ? vehicle.smartcar
        ? "Reconnect New Vehicle"
        : "Use Smartcar"
      : "Transfer Key Manually"
  }}
            </a>
          </div>
        </div>
      </div>

      <vehicle-form ref="vehicleForm" class="w-1/2" :vehicle="vehicle" :manual-mode="manualMode">
        <template v-slot:footer>
          <div class="text-center mt-4">
            <button :disabled="!enableSaveButton"
              class="flex mx-auto px-4 py-2 bg-parqay-primary text-white font-medium rounded text-md disabled:opacity-50"
              @click="saveVehicle(vehicle)">
              Save Vehicle
              <svg-icon v-if="loading.save" icon-class="circular-loader" class="ml-2 animate-spin h-4 w-4" />
            </button>
          </div>
        </template>
      </vehicle-form>
    </template>
    <template v-else>
      <div class="grid grid-cols-5 w-full mx-4">
        <div class="col-span-2 rounded-lg overflow-hidden mr-2">
          <img class="h-full w-full object-cover" :src="savedVehicle.image" />
        </div>
        <div class="col-span-2 px-6 py-2 mx-2 border rounded-xl flex items-center justify-center">
          <div class="grid grid-cols-3">
            <div class="h-16 col-span-3">
              <img class="h-16 w-full object-contain" :src="`//logo.clearbit.com/${savedVehicle.make.replace(
    /\s/g,
    ''
  )}.com`
    " />
            </div>
            <div class="col-span-3 h-8 flex space-x-4 justify-center">
              <span class="text-xl font-semibold leading-8">{{
    savedVehicle.make
  }}</span>
              <span class="text-lg font-medium leading-8">{{
      savedVehicle.model
    }}</span>
            </div>

            <div class="col-span-3 h-8 flex space-x-2 justify-center items-center">
              <!-- <div class="w-6 h-6 rounded" :style="{backgroundColor: savedVehicle.color}"></div> -->
              <span class="text font-medium leading-6 rounded px-2 border" :style="{
      color: isColorBright(savedVehicle.color)
        ? tinycolor(savedVehicle.color)
          .darken(30)
          .toString()
        : 'white',
      'border-color': isColorBright(savedVehicle.color)
        ? tinycolor(savedVehicle.color)
          .darken(20)
          .toString()
        : savedVehicle.color,
      backgroundColor: savedVehicle.color
    }">{{ getColorName(savedVehicle.color) }}</span>
              <span class="text-lg font-medium leading-8 uppercase">{{
    savedVehicle.plate_number
  }}</span>
            </div>
            <div class="col-span-3 h-12 flex space-x-6 items-center justify-center">
              <div>
                <button class="border border-parqay-primary rounded px-2 py-1 text-parqay-primary text-sm font-medium"
                  @click="editVehicle()">
                  Edit
                </button>
              </div>
              <div>
                <a-popconfirm
                  title="Do you want to remove vehicle just from this reservation or also permanently delete vehicle"
                  ok-text="Permanently Delete" cancel-text="Remove Association" @confirm="deleteVehicle(true)"
                  @cancel="deleteVehicle()">
                  <button class="rounded px-2 py-1 bg-red-500 text-sm font-medium text-white focus:outline-none">
                    Delete
                  </button>
                </a-popconfirm>
              </div>
            </div>
            <div class="col-span-3 flex items-center justify-center space-x-2">
              <slot name="info" />
            </div>
          </div>
        </div>
        <div class="rounded-xl overflow-hidden ml-2 border py-2 hover:shadow-xl cursor-pointer">
          <div class="flex flex-col space-y-2 h-full items-center">
            <svg-icon class="w-full h-6" icon-class="smartcar" />
            <button v-if="!controls.vehicleOperationStatus &&
    !savedVehicle.smartcar &&
    !controls.showReconnect
    " class="rounded px-2 py-1 bg-parqay-primary text-xs font-medium text-white" @click="() => {
    editVehicle();
    manualMode = false;
  }
    ">
              Connect
            </button>
            <button v-if="controls.showReconnect"
              class="rounded px-2 py-1 bg-parqay-primary text-xs font-medium text-white" @click="() => {
    editVehicle();
    manualMode = false;
  }
    ">
              Reconnect
            </button>
            <span class="text-sm px-2 py-1 text-center text-parqay-primary">{{
    controls.vehicleOperationStatus
  }}</span>
            <div v-if="savedVehicle.smartcar && !controls.showReconnect" class="flex w-full px-4 py-1 justify-center"
              :style="{ height: '180px' }">
              <div class="shadow-lg rounded-2xl w-full xl:w-24 h-full overflow-hidden">
                <div
                  class="flex h-1/3 w-full border-b border-white bg-gray-500 hover:bg-gray-300 relative items-center justify-center"
                  @click="vehicleOperation(savedVehicle, 'lock')">
                  <svg-icon v-if="!loading.operations.lock" class="w-6 h-6 text-white" icon-class="lock" />
                  <svg-icon v-else class="w-6 h-6 text-white animate-spin" icon-class="circular-loader" />
                  <div class="absolute border-2 border-white bg-gray-500 rounded-full h-2 left-3 right-3 z-20"
                    style="bottom: -0.25rem" />
                </div>
                <div
                  class="flex h-1/3 w-full border-b border-white bg-gray-500 hover:bg-gray-300 relative items-center justify-center"
                  @click="vehicleOperation(savedVehicle, 'openTrunk')">
                  <span v-if="!loading.operations.openTrunk" class="text-white font-medium">Open Trunk</span>
                  <svg-icon v-else class="w-6 h-6 text-white animate-spin" icon-class="circular-loader" />
                  <div class="absolute border-2 border-white bg-gray-500 rounded-full h-2 left-3 right-3 z-20"
                    style="bottom: -0.25rem" />
                </div>
                <div class="flex h-1/3 w-full bg-gray-500 hover:bg-gray-300 items-center justify-center"
                  @click="vehicleOperation(savedVehicle, 'unlock')">
                  <svg-icon v-if="!loading.operations.unlock" class="w-6 h-6 text-white" icon-class="lock-open" />
                  <svg-icon v-else class="w-6 h-6 text-white animate-spin" icon-class="circular-loader" />
                </div>
              </div>
            </div>
            <a-popover v-else title="Smartcar is disabled">
              <template #content>
                <span class="text-parqay-primary font-medium">To connect, click the "Connect" button above</span>
              </template>
              <div class="flex w-full px-4 py-1 justify-center" :style="{ height: '180px' }">
                <div class="shadow-lg rounded-2xl w-full xl:w-24 h-full overflow-hidden opacity-50 pointer-events-none">
                  <div
                    class="flex h-1/3 w-full border-b border-white bg-gray-500 hover:bg-gray-300 relative items-center justify-center">
                    <svg-icon class="w-6 h-6 text-white" icon-class="lock" />
                    <div class="absolute border-2 border-white bg-gray-500 rounded-full h-2 left-3 right-3 z-20"
                      style="bottom: -0.25rem" />
                  </div>
                  <div
                    class="flex h-1/3 w-full border-b border-white bg-gray-500 hover:bg-gray-300 relative items-center justify-center">
                    <span class="text-white font-medium">Open Trunk</span>
                    <div class="absolute border-2 border-white bg-gray-500 rounded-full h-2 left-3 right-3 z-20"
                      style="bottom: -0.25rem" />
                  </div>
                  <div class="flex h-1/3 w-full bg-gray-500 hover:bg-gray-300 items-center justify-center">
                    <svg-icon class="w-6 h-6 text-white" icon-class="lock-open" />
                  </div>
                </div>
              </div>
            </a-popover>
          </div>
        </div>
        <div class="col-span-5 border mt-4 rounded-lg relative overflow-hidden"
          @mouseover="() => (controls.showBottomSectionMask = true)"
          @mouseout="() => (controls.showBottomSectionMask = false)">
          <div class="flex items-center space-x-4 px-4 py-2">
            <div
              class="h-24 w-24 rounded bg-gray-50 border flex flex-col space-y-1 items-center justify-center py-2 px-1 cursor-pointer">
              <img class="w-8 h-8 rounded overflow-hidden object-contain" src="/img/icons/speedometer.png" />
              <span class="text-xs font-medium">Odometer</span>
              <span class="text-xs font-medium text-parqay-primary whitespace-nowrap">{{
    controls.odometerDistance
      ? Math.round(controls.odometerDistance)
      : "N/A"
  }}
                KM</span>
            </div>
            <div class="h-24 w-48 rounded-lg bg-gray-50 border relative">
              <!-- <div class="w-full text-center top-1/2 -translate-y-1/2 transform absolute">Tire Pressure</div> -->
              <img class="w-3/5 h-3/5 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 transform absolute"
                src="/img/icons/vehicle-top-view.jpeg" />
              <div class="
                  px-1
                  h-6
                  bg-gray-400
                  absolute
                  left-1
                  top-0.5
                  rounded
                  text-xs
                  font-medium
                  text-white
                  hover:bg-parqay-primary
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                ">
                {{
      Math.round(
        $deepGet(controls.tirePressures, "frontRight", "N/A")
      )
    }}
                kPa
              </div>
              <div class="
                  px-1
                  h-6
                  bg-gray-400
                  absolute
                  right-1
                  top-0.5
                  rounded
                  text-xs
                  font-medium
                  text-white
                  hover:bg-parqay-primary
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                ">
                {{
      Math.round(
        $deepGet(controls.tirePressures, "backRight", "N/A")
      )
    }}
                kPa
              </div>
              <div class="
                  px-1
                  h-6
                  bg-gray-400
                  absolute
                  left-1
                  bottom-0.5
                  rounded
                  text-xs
                  font-medium
                  text-white
                  hover:bg-parqay-primary
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                ">
                {{
      Math.round(
        $deepGet(controls.tirePressures, "frontLeft", "N/A")
      )
    }}
                kPa
              </div>
              <div class="
                  px-1
                  h-6
                  bg-gray-400
                  absolute
                  right-1
                  bottom-0.5
                  rounded
                  text-xs
                  font-medium
                  text-white
                  hover:bg-parqay-primary
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                ">
                {{
      Math.round(
        $deepGet(controls.tirePressures, "backLeft", "N/A")
      )
    }}
                kPa
              </div>
            </div>
            <div
              class="h-24 flex-grow flex rounded bg-white border flex flex-col space-y-1 px-4 py-2 items-center justify-center cursor-pointer">
              <div class="flex space-x-2 items-center">
                <svg-icon icon-class="gas-station" class="h-4 w-4" />
                <span class="text-sm font-medium">Fuel Level</span>
              </div>
              <a-progress :percent="Math.round(
      $deepGet(controls.fuelLevel, 'percentRemaining', 1) * 100
    )
    " />
              <div class="flex space-x-4">
                <div class="rounded bg-gray-50 border flex items-center divide-x py-1.5">
                  <span class="font-bold text-gray-600 text-sm pl-2 pr-1">Range</span>
                  <span class="pl-1 pr-2 text-sm font-medium text-parqay-primary">{{
    Math.round($deepGet(controls.fuelLevel, "range", 0))
  }}KM</span>
                </div>
                <div class="rounded bg-gray-50 border flex items-center divide-x py-1.5">
                  <span class="font-bold text-gray-600 text-sm pl-2 pr-1">Gas</span>
                  <span class="pl-1 pr-2 text-sm font-medium text-parqay-primary">{{
      Math.round(
        $deepGet(controls.fuelLevel, "amountRemaining", 0) * 10
      ) / 10
    }}L</span>
                </div>
              </div>
            </div>
            <div
              class="h-24 w-24 rounded bg-gray-50 border flex flex-col space-y-1 items-center justify-center py-2 px-1 cursor-pointer">
              <svg-icon icon-class="PARQAY-marker" class="h-8 w-8" />
              <span class="text-xs font-medium">Location</span>

              <a-popover title="Vehicle Last Known Location">
                <template slot="content">
                  <div class="flex flex-col space-y-4 items-center justify-center">
                    <p>Updated at: {{ $deepGet(controls, "age", "") }}</p>
                    <div class="w-64 h-48 relative rounded-xl overflow-hidden">
                      <pq-map ref="VehicleMap" class="absolute inset-0" :center="controls.mapCenter"
                        marker-icon-url="/img/icons/vehicle-top-view-removebg.png" :marker-icon-sizes="{
    normal: {
      width: 60,
      height: 60 * 1.28
    },
    large: {
      width: 80,
      height: 80 * 1.28
    }
  }" simple-focus disable-scroll-zoom />
                    </div>
                  </div>
                </template>
                <span class="text-xs font-medium text-parqay-primary whitespace-nowrap">Show Map</span>
              </a-popover>
            </div>
          </div>
          <template v-if="!savedVehicle.smartcar">
            <div v-if="controls.showBottomSectionMask"
              class="absolute inset-0 cursor-pointer flex justify-center items-center pointer-events-none">
              <div class="opacity-50 bg-gray-900 w-full h-full" />
            </div>
            <span v-if="controls.showBottomSectionMask" class="
                absolute
                rounded
                bg-white
                text-gray-500
                font-medium
                px-2
                py-1
                transform
                left-1/2
                -translate-x-1/2
                top-1/2
                -translate-y-1/2
                cursor-pointer
                pointer-events-none
              ">Connect Smartcar to use these features</span>
          </template>
        </div>
      </div>
    </template>
  </div>
  <div v-else class="flex flex-col bg-white py-2 relative">
    <div v-if="!savedVehicle" class="flex flex-col sm:flex-row space-y-2 sm:space-x-8 sm:space-y-0">
      <div class="flex flex-col space-y-2">
        <vehicle-verification v-if="!manualMode" min-width-none width="full" :title="title" :on-behalf-of="onBehalfOf"
          @vehicle-verified="updateVerifiedInfo" />
        <div v-else class="flex items-center h-full">
          <div class="flex flex-col items-center justify-between w-full space-y-4">
            <div v-if="!vehicle.smartcar" class="flex flex-col space-y-2">
              <span class="text-lg font-semibold whitespace-nowrap text-center">Enter Vehicle Information
                Manually</span>
              <span class="text-md text-center">Please make sure your guest can access vehicle key after
                purchase</span>
            </div>
            <div v-else class="flex flex-col space-y-2">
              <span class="text-lg font-semibold whitespace-nowrap text-center">Edit Vehicle Info</span>
              <span class="text-md text-center">Only partial infomation can be edited. To change car make or
                model, please reconnect a new vehicle</span>
            </div>
            <div class="relative">
              <svg-icon v-if="!vehicle.smartcar" class="w-24 h-24" icon-class="car-key" />
              <svg-icon v-else class="w-32 h-12" icon-class="smartcar" />
              <div v-if="!vehicle.smartcar" class="bg-white w-10 h-10 rounded-lg shadow-md absolute"
                style="right: -1rem; bottom: -1rem">
                <div class="flex items-center justify-center h-full">
                  <svg-icon class="w-6 h-6" icon-class="exchange" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center w-full">
          <a v-if="savedVehicle"
            class="hover:text-blue-800 font-medium text-parqay-primary whitespace-nowrap text-xs leading-6"
            @click="editVehicle(true)">
            Cancel Edit
          </a>
          <a class="hover:text-blue-800 font-medium text-parqay-primary whitespace-nowrap text-xs leading-6" @click="() => {
    if (vehicle.smartcar) {
      deleteVehicle();
    } else {
      manualMode = !manualMode;
    }
  }
    ">
            {{
    manualMode
      ? vehicle.smartcar
        ? "Reconnect New Vehicle"
        : "Use Smartcar"
      : "Transfer Key Manually"
  }}
          </a>
        </div>
      </div>

      <vehicle-form ref="vehicleForm" :vehicle="vehicle" :manual-mode="manualMode">
        <template v-slot:footer>
          <div class="text-center mt-4">
            <button :disabled="!enableSaveButton"
              class="flex mx-auto px-4 py-2 bg-parqay-primary text-white font-medium rounded text-md disabled:opacity-50"
              @click="saveVehicle(vehicle)">
              Save Vehicle
              <svg-icon v-if="loading.save" icon-class="circular-loader" class="ml-2 animate-spin h-4 w-4" />
            </button>
          </div>
        </template>
      </vehicle-form>
    </div>
    <div v-else class="grid grid-cols-5 w-full mx-4">
      <div class="col-span-2 rounded-lg overflow-hidden mr-2">
        <img class="h-full w-full object-cover" :src="savedVehicle.image" />
      </div>
      <div class="col-span-2 px-6 py-2 mx-2 border rounded-xl flex items-center justify-center">
        <div class="grid grid-cols-3">
          <div class="h-16 col-span-3">
            <img class="h-16 w-full object-contain" :src="`//logo.clearbit.com/${savedVehicle.make.replace(
    /\s/g,
    ''
  )}.com`
    " />
          </div>
          <div class="col-span-3 h-8 flex space-x-4 justify-center">
            <span class="text-xl font-semibold leading-8">{{
    savedVehicle.make
  }}</span>
            <span class="text-lg font-medium leading-8">{{
      savedVehicle.model
    }}</span>
          </div>

          <div class="col-span-3 h-8 flex space-x-2 justify-center items-center">
            <!-- <div class="w-6 h-6 rounded" :style="{backgroundColor: savedVehicle.color}"></div> -->
            <span class="text font-medium leading-6 rounded px-2 border" :style="{
      color: isColorBright(savedVehicle.color)
        ? tinycolor(savedVehicle.color)
          .darken(30)
          .toString()
        : 'white',
      'border-color': isColorBright(savedVehicle.color)
        ? tinycolor(savedVehicle.color)
          .darken(20)
          .toString()
        : savedVehicle.color,
      backgroundColor: savedVehicle.color
    }">{{ getColorName(savedVehicle.color) }}</span>
            <span class="text-lg font-medium leading-8 uppercase">{{
    savedVehicle.plate_number
  }}</span>
          </div>
          <div class="col-span-3 h-12 flex space-x-6 items-center justify-center">
            <div>
              <button class="border border-parqay-primary rounded px-2 py-1 text-parqay-primary text-sm font-medium"
                @click="editVehicle()">
                Edit
              </button>
            </div>
            <div>
              <a-popconfirm
                title="Do you want to remove vehicle just from this reservation or also permanently delete vehicle"
                ok-text="Permanently Delete" cancel-text="Remove Association" @confirm="deleteVehicle(true)"
                @cancel="deleteVehicle()">
                <button class="rounded px-2 py-1 bg-red-500 text-sm font-medium text-white focus:outline-none">
                  Delete
                </button>
              </a-popconfirm>
            </div>
          </div>
          <div class="col-span-3 flex items-center justify-center space-x-2">
            <slot name="info" />
          </div>
        </div>
      </div>
      <div class="rounded-xl overflow-hidden ml-2 border py-2 hover:shadow-xl cursor-pointer">
        <div class="flex flex-col space-y-2 h-full items-center">
          <svg-icon class="w-full h-6" icon-class="smartcar" />
          <button v-if="!controls.vehicleOperationStatus &&
    !savedVehicle.smartcar &&
    !controls.showReconnect
    " class="rounded px-2 py-1 bg-parqay-primary text-xs font-medium text-white" @click="() => {
    editVehicle();
    manualMode = false;
  }
    ">
            Connect
          </button>
          <button v-if="controls.showReconnect"
            class="rounded px-2 py-1 bg-parqay-primary text-xs font-medium text-white" @click="() => {
    editVehicle();
    manualMode = false;
  }
    ">
            Reconnect
          </button>
          <span class="text-sm px-2 py-1 text-center text-parqay-primary">{{
    controls.vehicleOperationStatus
  }}</span>
          <div v-if="savedVehicle.smartcar && !controls.showReconnect" class="flex w-full px-4 py-1 justify-center"
            :style="{ height: '180px' }">
            <div class="shadow-lg rounded-2xl w-full xl:w-24 h-full overflow-hidden">
              <div
                class="flex h-1/3 w-full border-b border-white bg-gray-500 hover:bg-gray-300 relative items-center justify-center"
                @click="vehicleOperation(savedVehicle, 'lock')">
                <svg-icon v-if="!loading.operations.lock" class="w-6 h-6 text-white" icon-class="lock" />
                <svg-icon v-else class="w-6 h-6 text-white animate-spin" icon-class="circular-loader" />
                <div class="absolute border-2 border-white bg-gray-500 rounded-full h-2 left-3 right-3 z-20"
                  style="bottom: -0.25rem" />
              </div>
              <div
                class="flex h-1/3 w-full border-b border-white bg-gray-500 hover:bg-gray-300 relative items-center justify-center"
                @click="vehicleOperation(savedVehicle, 'openTrunk')">
                <span v-if="!loading.operations.openTrunk" class="text-white font-medium">Open Trunk</span>
                <svg-icon v-else class="w-6 h-6 text-white animate-spin" icon-class="circular-loader" />
                <div class="absolute border-2 border-white bg-gray-500 rounded-full h-2 left-3 right-3 z-20"
                  style="bottom: -0.25rem" />
              </div>
              <div class="flex h-1/3 w-full bg-gray-500 hover:bg-gray-300 items-center justify-center"
                @click="vehicleOperation(savedVehicle, 'unlock')">
                <svg-icon v-if="!loading.operations.unlock" class="w-6 h-6 text-white" icon-class="lock-open" />
                <svg-icon v-else class="w-6 h-6 text-white animate-spin" icon-class="circular-loader" />
              </div>
            </div>
          </div>
          <a-popover v-else title="Smartcar is disabled">
            <template #content>
              <span class="text-parqay-primary font-medium">To connect, click the "Connect" button above</span>
            </template>
            <div class="flex w-full px-4 py-1 justify-center" :style="{ height: '180px' }">
              <div class="shadow-lg rounded-2xl w-full xl:w-24 h-full overflow-hidden opacity-50 pointer-events-none">
                <div
                  class="flex h-1/3 w-full border-b border-white bg-gray-500 hover:bg-gray-300 relative items-center justify-center">
                  <svg-icon class="w-6 h-6 text-white" icon-class="lock" />
                  <div class="absolute border-2 border-white bg-gray-500 rounded-full h-2 left-3 right-3 z-20"
                    style="bottom: -0.25rem" />
                </div>
                <div
                  class="flex h-1/3 w-full border-b border-white bg-gray-500 hover:bg-gray-300 relative items-center justify-center">
                  <span class="text-white font-medium">Open Trunk</span>
                  <div class="absolute border-2 border-white bg-gray-500 rounded-full h-2 left-3 right-3 z-20"
                    style="bottom: -0.25rem" />
                </div>
                <div class="flex h-1/3 w-full bg-gray-500 hover:bg-gray-300 items-center justify-center">
                  <svg-icon class="w-6 h-6 text-white" icon-class="lock-open" />
                </div>
              </div>
            </div>
          </a-popover>
        </div>
      </div>
      <div class="col-span-5 border mt-4 rounded-lg relative overflow-hidden"
        @mouseover="() => (controls.showBottomSectionMask = true)"
        @mouseout="() => (controls.showBottomSectionMask = false)">
        <div class="flex items-center space-x-4 px-4 py-2">
          <div
            class="h-24 w-24 rounded bg-gray-50 border flex flex-col space-y-1 items-center justify-center py-2 px-1 cursor-pointer">
            <img class="w-8 h-8 rounded overflow-hidden object-contain" src="/img/icons/speedometer.png" />
            <span class="text-xs font-medium">Odometer</span>
            <span class="text-xs font-medium text-parqay-primary whitespace-nowrap">{{
    controls.odometerDistance
      ? Math.round(controls.odometerDistance)
      : "N/A"
  }}
              KM</span>
          </div>
          <div class="h-24 w-48 rounded-lg bg-gray-50 border relative">
            <!-- <div class="w-full text-center top-1/2 -translate-y-1/2 transform absolute">Tire Pressure</div> -->
            <img class="w-3/5 h-3/5 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 transform absolute"
              src="/img/icons/vehicle-top-view.jpeg" />
            <div class="
                  px-1
                  h-6
                  bg-gray-400
                  absolute
                  left-1
                  top-0.5
                  rounded
                  text-xs
                  font-medium
                  text-white
                  hover:bg-parqay-primary
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                ">
              {{
      Math.round(
        $deepGet(controls.tirePressures, "frontRight", "N/A")
      )
    }}
              kPa
            </div>
            <div class="
                  px-1
                  h-6
                  bg-gray-400
                  absolute
                  right-1
                  top-0.5
                  rounded
                  text-xs
                  font-medium
                  text-white
                  hover:bg-parqay-primary
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                ">
              {{
      Math.round(
        $deepGet(controls.tirePressures, "backRight", "N/A")
      )
    }}
              kPa
            </div>
            <div class="
                  px-1
                  h-6
                  bg-gray-400
                  absolute
                  left-1
                  bottom-0.5
                  rounded
                  text-xs
                  font-medium
                  text-white
                  hover:bg-parqay-primary
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                ">
              {{
      Math.round(
        $deepGet(controls.tirePressures, "frontLeft", "N/A")
      )
    }}
              kPa
            </div>
            <div class="
                  px-1
                  h-6
                  bg-gray-400
                  absolute
                  right-1
                  bottom-0.5
                  rounded
                  text-xs
                  font-medium
                  text-white
                  hover:bg-parqay-primary
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                ">
              {{
      Math.round(
        $deepGet(controls.tirePressures, "backLeft", "N/A")
              )
              }}
              kPa
            </div>
          </div>
          <div
            class="h-24 flex-grow flex rounded bg-white border flex flex-col space-y-1 px-4 py-2 items-center justify-center cursor-pointer">
            <div class="flex space-x-2 items-center">
              <svg-icon icon-class="gas-station" class="h-4 w-4" />
              <span class="text-sm font-medium">Fuel Level</span>
            </div>
            <a-progress :percent="Math.round(
      $deepGet(controls.fuelLevel, 'percentRemaining', 1) * 100
    )
    " />
            <div class="flex space-x-4">
              <div class="rounded bg-gray-50 border flex items-center divide-x py-1.5">
                <span class="font-bold text-gray-600 text-sm pl-2 pr-1">Range</span>
                <span class="pl-1 pr-2 text-sm font-medium text-parqay-primary">{{
                  Math.round($deepGet(controls.fuelLevel, "range", 0))
                  }}KM</span>
              </div>
              <div class="rounded bg-gray-50 border flex items-center divide-x py-1.5">
                <span class="font-bold text-gray-600 text-sm pl-2 pr-1">Gas</span>
                <span class="pl-1 pr-2 text-sm font-medium text-parqay-primary">{{
                  Math.round(
                  $deepGet(controls.fuelLevel, "amountRemaining", 0) * 10
                  ) / 10
                  }}L</span>
              </div>
            </div>
          </div>
          <div
            class="h-24 w-24 rounded bg-gray-50 border flex flex-col space-y-1 items-center justify-center py-2 px-1 cursor-pointer">
            <svg-icon icon-class="PARQAY-marker" class="h-8 w-8" />
            <span class="text-xs font-medium">Location</span>

            <a-popover title="Vehicle Last Known Location">
              <template slot="content">
                <div class="flex flex-col space-y-4 items-center justify-center">
                  <p>Updated at: {{ $deepGet(controls, "age", "") }}</p>
                  <div class="w-64 h-48 relative rounded-xl overflow-hidden">
                    <pq-map ref="VehicleMap" class="absolute inset-0" :center="controls.mapCenter"
                      marker-icon-url="/img/icons/vehicle-top-view-removebg.png" :marker-icon-sizes="{
    normal: {
      width: 60,
      height: 60 * 1.28
    },
    large: {
      width: 80,
      height: 80 * 1.28
    }
  }" simple-focus disable-scroll-zoom />
                  </div>
                </div>
              </template>
              <span class="text-xs font-medium text-parqay-primary whitespace-nowrap">Show Map</span>
            </a-popover>
          </div>
        </div>
        <template v-if="!savedVehicle.smartcar">
          <div v-if="controls.showBottomSectionMask"
            class="absolute inset-0 cursor-pointer flex justify-center items-center pointer-events-none">
            <div class="opacity-50 bg-gray-900 w-full h-full" />
          </div>
          <span v-if="controls.showBottomSectionMask" class="
                absolute
                rounded
                bg-white
                text-gray-500
                font-medium
                px-2
                py-1
                transform
                left-1/2
                -translate-x-1/2
                top-1/2
                -translate-y-1/2
                cursor-pointer
                pointer-events-none
              ">Connect Smartcar to use these features</span>
        </template>
      </div>
    </div>
  </div>
</template>
