import { shortUID } from "src/plugins/prototype";
import { API } from "src/views/components/api";
import EventBus from "src/services/util/event-bus";
import { Tokens } from "src/services/constants";

import { Dropbox } from "dropbox";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import AWS from "aws-sdk/global";
import mime from 'mime-types';

const removeS3QueryStringFromURL = (url) => {
  return url.split("?")[0];
}
export const imageDataUploader = async (
  imageDataUrl,
  currentIndex,
  totalCount
) => {
  const imageBlob = await (await fetch(imageDataUrl)).blob();
  const imageMimeType = imageBlob.type;
  const imageExt = mime.extension(imageMimeType);
  const s3_path = `listings/images/${shortUID()}.${imageExt}`;
  const publicURL = await uploadFileToS3({ path: s3_path, fileBlob: imageBlob, isPublicAccess: true });
  const overallProgress = (currentIndex + 1) / totalCount;
  EventBus.$emit("image-upload-progress-update", overallProgress);
  return removeS3QueryStringFromURL(publicURL);
};

const recognizePlateNumberInImage = async (image) => {
  image.recognizing = true;
  var res;
  try {
    res = await API.recognizePlateNumberInImage(image.url);
  } catch (error) {
    console.log("recognizePlateNumberInImage => error", error);
    image.recognizing = false;
    return;
  }
  console.log("recognizePlateNumberInImage", res);
  image.recognizing = false;
  image.vehicleData.plate_number = res.data.plate_number;
  image.vehicleData.plate_number_confidence = res.data.plate_number_confidence;
};

export const recognizeCarInImage = async (image) => {
  image.recognizing = true;
  var res;
  try {
    res = await API.recognizeCarInImage(image.url);
  } catch (error) {
    console.log("recognizeCarInImage => error", error);
    image.recognizing = false;
    return;
  }
  console.log("recognizeCarInImage", res);
  image.recognizing = false;
  image.vehicleData = res.data;
  recognizePlateNumberInImage(image);
};

export const uploadFileToS3 = async ({
  path,
  fileBlob,
  options = { ...Tokens.S3_BUCKET },
  isPublicAccess = false,
  expiresIn = 3600
}) => {
  const credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: options.identityPoolId,
  });
  credentials.accessKeyId = options.accessKeyId;
  credentials.secretAccessKey = options.secretAccessKey;

  const contentType = mime.lookup(path) || 'application/octet-stream'; // detect the content type

  let target = { Bucket: options.bucketName, Key: path, Body: fileBlob, ContentType: contentType }; // set the ContentType property in the target object

  if (isPublicAccess) {
    target.ACL = "public-read";
  }
  const clientOptions = {
    region: options.bucketRegion,
    params: { Bucket: options.bucketName },
    credentials,
  };
  try {
    const client = new S3Client(clientOptions);
    const parallelUploads3 = new Upload({
      client: client,
      queueSize: 4, // optional concurrency configuration
      partSize: "5MB", // optional size of each part
      leavePartsOnError: false, // optional manually handle dropped parts
      params: target,
    });

    parallelUploads3.on("httpUploadProgress", (progress) => {
      console.log(progress);
    });

    await parallelUploads3.done();
    const command = new GetObjectCommand(target);
    if (isPublicAccess) {
      return `https://${options.bucketName}.s3.amazonaws.com/${path}`;
    } else {
      return await getSignedUrl(client, command, { expiresIn });
    }
  } catch (e) {
    console.log(e);
  }
};

export const getS3DownloadUrl = async ({
  path,
  options = { ...Tokens.S3_BUCKET },
}) => {
  const credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: options.identityPoolId,
  });
  credentials.accessKeyId = options.accessKeyId;
  credentials.secretAccessKey = options.secretAccessKey;
  const target = { Bucket: options.bucketName, Key: path };
  const clientOptions = {
    region: options.bucketRegion,
    params: { Bucket: options.bucketName },
    credentials,
  };
  try {
    const client = new S3Client(clientOptions);
    const command = new GetObjectCommand(target);
    const url = await getSignedUrl(client, command, { expiresIn: 3600 });
    return url;
  } catch (e) {
    console.log("getS3DownloadUrl -> error: ", e);
  }
};

// Note Avatar URL could be absolute string or S3 path
export const getAvatarURL = async (path) => {
  const r = new RegExp("^(?:[a-z]+:)?//", "i");
  // Test if path is absolute URL
  if (r.test(path)) {
    return path;
  } else {
    return await getS3DownloadUrl({ path });
  }
};

const fileBlobToUrl = (b) => {
  var urlCreator = window.URL || window.webkitURL;
  var imageUrl = urlCreator.createObjectURL(b);
  return imageUrl;
};

export const getDropboxURL = async (path) => {
  const dbx = new Dropbox({ accessToken: Tokens.DROPBOX_ACCESS_TOKEN });
  let resp;
  try {
    resp = await dbx.filesGetTemporaryLink({ path });
    console.log("resp => ", resp);
  } catch (error) {
    console.log("filesDownload >> error: ", error);
  }
  resp.result.url = resp.result.link;
  return resp.result;
};

export const getDocumentURL = async (f) => {
  if (f.dbx_path) {
    return getDropboxURL(f.dbx_path);
  } else if (f.s3_path) {
    return { url: await getS3DownloadUrl({ path: f.s3_path }) };
  } else if (f.url) {
    return f;
  }
};
