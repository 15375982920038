<script>
import { reactive, ref } from "@vue/composition-api";
import PQDropzone from "src/views/components/PQDropzone";
import { shortUID, convertFileToDataURL } from "src/plugins/prototype";
import { API } from "src/views/towing/api";

export default {
  name: "TowRequestModal",
  components: {
    "pq-dropzone": PQDropzone,
  },
  props: {
    visible: Boolean,
  },
  setup(props, ctx) {
    const controls = reactive({
      loading: false,
    });

    const form = reactive({
      title: "Please help us towing a vehicle",
      desc: "A red Ferrari F430 is blocking our driveway",
      address: "6100 Ohio Drive, Plano, TX",
      host: {
        phone_number: "+17342726106",
      },
      vehicle: {
        make: "Ferrari",
        model: "F430",
        color: "red",
        plate_number: "MA1502",
        image:
          "https://www.vivernoexterior.com/wp-content/uploads/2018/04/ferrari-f430.jpeg",
      },
      enable_due_date: false,
      due_date: new Date(),
    });

    const addVehicleImage = async (f) => {
      form.vehicle.image = await convertFileToDataURL(f);
    };

    const submit = async () => {
      let resp;
      controls.loading = true;
      resp = await API.create(form);
      console.log("submit >> ", resp.data);
      controls.loading = false;
      ctx.root.$openNotification("Tow request created successfully", "", 5, "info");
      ctx.emit("close");
    };

    return {
      controls,
      form,
      addVehicleImage,
      submit,
    };
  },
};
</script>
<template>
  <a-modal
    :visible="visible"
    :closable="true"
    width="900px"
    :footer="null"
    dialog-class=""
    :mask-style="{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }"
    @ok="() => $emit('close')"
    @cancel="() => $emit('close')"
  >
    <div class="flex flex-col">
      <span class="font-bold text-center text-lg pb-3 border-b">
        Tow Request Form
      </span>
      <div class="flex">
        <div class="w-1/2 flex flex-col items-center space-y-4 px-4 py-3 border-r">
          <span class="">Vehicle Image</span>
          <viewer
            v-if="form.vehicle.image"
            :images="[form.vehicle.image]"
            class="flex items-center justify-center rounded-lg overflow-hidden relative"
          >
            <img
              :src="form.vehicle.image"
              class="w-full object-cover"
              style="height:300px;"
            />
            <div class="absolute top-2 right-2">
              <a-button
                type="danger"
                shape="circle"
                icon="close"
                @click="() => (form.vehicle.image = null)"
              />
            </div>
          </viewer>
          <pq-dropzone
            v-else
            intro="Drop Image of Towing Vehicle"
            height="300px"
            @file-added="addVehicleImage"
          />
          <span class="">Vehicle Info</span>
          <div
            class="grid grid-cols-2 gap-x-4 gap-y-2 px-4 py-2 rounded-lg border bg-white"
          >
            <div class="flex flex-col space-y-2">
              <span>Make</span>
              <a-input v-model="form.vehicle.make" />
            </div>
            <div class="flex flex-col space-y-2">
              <span>Model</span>
              <a-input v-model="form.vehicle.model" />
            </div>
            <div class="flex flex-col space-y-2">
              <span>Color</span>
              <a-input v-model="form.vehicle.color" />
            </div>
            <div class="flex flex-col space-y-2">
              <span>Plate Number</span>
              <a-input v-model="form.vehicle.plate_number" />
            </div>
          </div>
        </div>
        <div class="w-1/2 flex flex-col">
          <div class="flex justify-between items-center px-4 pt-6 pb-3">
            <span class="w-1/3">Title</span>
            <a-input size="large" v-model="form.title" />
          </div>
          <div class="flex justify-between items-center px-4 pt-6 pb-3">
            <span class="w-1/3">Address</span>
            <a-input size="large" v-model="form.address" />
          </div>
          <div class="flex justify-between items-center px-4 pt-6 pb-3">
            <span class="w-1/3">Phone Number</span>
            <a-input size="large" v-model="form.host.phone_number" />
          </div>
          <!-- <div class="flex justify-between items-center px-4 py-3">
            <span class="w-1/3">Host/Company Name</span>
            <a-tag color="geekblue">{{ form.host.name }}</a-tag>
          </div> -->
          <div class="flex justify-between items-center px-4 py-3">
            <div class="flex space-x-4 items-center">
              <span>Due Date</span>
              <a-switch v-model="form.enable_due_date" />
            </div>
            <vc-date-picker v-model="form.due_date">
              <template v-slot="{ inputValue, inputEvents }">
                <a-input
                  :value="inputValue"
                  v-on="inputEvents"
                  :disabled="!form.enable_due_date"
                />
              </template>
            </vc-date-picker>
          </div>
          <div class="flex flex-col items-center space-y-4 px-4 py-3">
            <span>Description:</span>
            <a-textarea
              v-model="form.desc"
              placeholder="Enter descriptions or details for towing company"
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
          </div>
          <div class="flex justify-between items-center space-x-4 px-4 py-3 pb-3">
            <a-button size="large" class="w-1/2" @click="$emit('close')">
              Cancel
            </a-button>
            <a-button
              type="primary"
              size="large"
              :loading="controls.loading"
              class="w-1/2"
              @click="submit()"
            >
              Request Tow
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
