/**
 * Vue + Component management
 */
import Vue from "vue";
import App from "./App";
// Algolia Search Engine
import "./plugins/algolia";
// Vuelidate Validator
import "./plugins/validator";
import "./plugins/v-calendar";
import VueI18n from "vue-i18n";
import VeeValidate from "vee-validate";
// import VueAnalytics from "vue-analytics"
import router from "services/routing/router";
import SvgIcon from "assets/icons/svg";
import ParqayComponents from "src/plugins/parqay-components";
import LazyImage from "src/plugins/lazy-image";
import VueIntersect from "src/plugins/intersect";
import "./plugins/country-select";
// import TestComponents from "src/plugins/test-components";
import VueCompositionAPI from "@vue/composition-api";
import Viewer from "v-viewer";
// Be careful with the ORDER of CSS import!
import "./plugins/dayjs";
import "./plugins/antd";
import "./plugins/meta-tags";
import "./plugins/qr-code";
import "src/assets/tw.css";
import "tui-calendar/dist/tui-calendar.css";
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
import "src/assets/pq.css";
import 'animate.css';

Vue.use(VueCompositionAPI);
Vue.use(SvgIcon);
Vue.use(ParqayComponents);
Vue.use(LazyImage);
// Vue.use(TestComponents);
Vue.use(VueIntersect);

Vue.use(Viewer);
import "viewerjs/dist/viewer.css";

import VueMasonry from "vue-masonry-css";
Vue.use(VueMasonry);

import VueDummy from "vue-dummy";
Vue.use(VueDummy);

import "./registerServiceWorker";

import VueRouter from "vue-router";

const vvconfig = {
  fieldsBagName: "vv-fields" // solve "fields" naming conflicts.
};
Vue.use(VeeValidate, vvconfig);

import smoothscroll from "smoothscroll-polyfill";
// kick off the polyfill!
smoothscroll.polyfill();

import VueGeolocation from "vue-browser-geolocation";
Vue.use(VueGeolocation);

Vue.use(VueRouter);

import intLangs from "./langs/i18n";

Vue.use(VueI18n);
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "en", // set locale
  messages: intLangs // set locale messages
});

// Hot updates
if (module.hot) {
  module.hot.accept(
    ["./langs/lang-en", "./langs/lang-es", "./langs/lang-chs"],
    function () {
      i18n.setLocaleMessage("en", intLangs.en);
      i18n.setLocaleMessage("chs", intLangs.chs);
      i18n.setLocaleMessage("es", intLangs.es);
    }
  );
}

import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);

import hljs from "highlight.js/lib/core";
import http from "highlight.js/lib/languages/http";
import javascript from "highlight.js/lib/languages/javascript";
import python from "highlight.js/lib/languages/python";
import hljsVuePlugin from "@highlightjs/vue-plugin";

hljs.registerLanguage("http", http);
hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("python", python);
Vue.use(hljsVuePlugin);

import store from "./state/dashboard";

import VueSocketIO from "vue-socket.io";
import io from 'socket.io-client';

import { Configs, Urls } from "services/constants.js";

const socketConnection = io(Urls.SOCKET_URL());
if (Configs.ENABLE_SOCKET) {
  Vue.use(
    new VueSocketIO({
      // debug: true,
      connection: socketConnection,
      vuex: {
        store,
        actionPrefix: "SOCKET_",
        mutationPrefix: "SOCKET_"
      },
      options: {} //Optional options
    })
  );
}

// Error Handling
// import "src/plugins/rollbar";
// import { initIntercom, launchIntercom } from "src/plugins/intercom";
// initIntercom();

const createApp = async () => {
  try {
    const seoMeta = (
      await import(
        /* webpackChunkName: "seo_pre_config" */
        "../seo.config.json"
      )
    ).default;
    localStorage.setItem("seoMeta", JSON.stringify(seoMeta));
    console.log("Successfully loaded from seo.config.json => ", seoMeta);
  } catch (error) {
    console.error(
      "Cannot load seo.config.json, using local defaults. Error: ",
      error
    );
  }
  return new Vue({
    i18n,
    created() {
      this.$bus.$on("update-socket-auth-state", this.onSocketAuthStateChange);
    },
    mounted() {
      if (window.location !== window.parent.location) {
        // The page is in an iframe
      } else {
        // The page is not in an iframe
        // launchIntercom();
      }
      document.addEventListener(
        "swUpdated",
        e => {
          console.log("swUpdated");
          this.$openNotification(
            "New Features Available",
            "Please refresh the page to use latest Parqay Dashboard",
            0,
            "success",
            null,
            h => {
              return h(
                "a-button",
                {
                  props: {
                    type: "primary",
                    size: "large"
                  },
                  on: {
                    click: () => {
                      const registration = e.detail;
                      // Protect against missing registration.waiting.
                      if (!registration || !registration.waiting) {
                        return;
                      }
                      registration.waiting.postMessage({ type: "skipWaiting" });
                    }
                  }
                },
                "Refresh"
              );
            }
          );
        },
        false
      );
      // Refresh all open app tabs when a new service worker is installed.
      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        });
      }
      this.$nextTick(() => {
        setTimeout(() => {
          const event = new CustomEvent("render-event");
          // Listen for the event.
          document.addEventListener(
            "render-event",
            e => {
              console.log("dispatchEvent");
            },
            false
          );
          document.dispatchEvent(event);
        }, 2000);
      });
    },
    methods: {
      onSocketAuthStateChange: ({ state, user_uid }) => {
        console.log("onSocketAuthStateChange -> ", state, user_uid);
        socketConnection.emit(
          `notification-${state}-room`,
          {
            user_uid
          },
          () => {
            console.log(`notification-${state}-room -> `, user_uid);
            if (state === "join") {
              socketConnection.emit("get-all-notifications", {
                user_uid
              });
            }
            if (state === "leave") {
              store.commit("clearNotifications");
            }
          }
        );
      }
    },
    destroyed() {
      this.$bus.$off("update-socket-auth-state", this.onSocketAuthStateChange);
    },
    render: h => h(App),
    store,
    router: router
  }).$mount("#app-container");
};

createApp();

import "./plugins/scroll";

import "./plugins/prototype";
// Only for console local testing purpose, comment line below in production!
// import "./test";
