<script>
import { ref, watch, computed, onMounted, onBeforeUnmount } from "@vue/composition-api";

export default {
  name: "SlideBar",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showCenter: {
      type: Boolean,
      default: false,
    },
    width: Number,
    theme: {
      type: String,
      default: "",
    },
    backdrop: {
      type: Boolean,
      default: false,
    },
    closeByMask: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const originalParentRef = ref(null);
    const slideBarRef = ref(null);
    const container = ref(props.value);
    const show = ref(props.value);
    const windowWidth = ref(window.innerWidth);
    const centerSlotPadding = ref(30);
    const themeClass = ref(
      computed(() => {
        switch (props.theme) {
          case "dark":
            return "slide-bar--dark";
          default:
            return "";
        }
      })
    );
    const dialogWidth = ref(
      computed(() => {
        const MIN_WIDTH = 400;
        return props.width && props.width > MIN_WIDTH ? props.width : MIN_WIDTH;
      })
    );

    const close = () => {
      show.value = false;
      ctx.root.$bus.$emit("multilevel-subdrawer-close");
      setTimeout(() => {
        container.value = false;
        ctx.emit("close-slide-bar");
        ctx.emit("input", false);
      }, 200);
    };

    watch(
      () => props.value,
      (val) => {
        if (val) {
          show.value = val;
          container.value = val;
        } else close();
        toggleSlideBarDOM(val);
      }
    );

    const outSideClick = (e) => {
      const target = e.target.querySelector(".slide-bar__content");
      if (target && props.closeByMask) {
        close();
      }
      // if(!e.path.some(val=>val.className && typeof val.className === 'string' &&
      //  val.className.includes('slide-bar__content'))) {
      //  this.close();
      // }
    };

    const toggleSlideBarDOM = (onOff) => {
      if (onOff) {
        if (slideBarRef.value) {
          const root = document.createElement('div')
          root.classList.add("pq-slide-bar");
          document.body.appendChild(root);
          root.appendChild(slideBarRef.value);
        }
      } else {
        if (originalParentRef.value) {
          if (slideBarRef.value) {
            const parentEl = slideBarRef.value.parentNode;
            originalParentRef.value.appendChild(slideBarRef.value);
            if (parentEl && parentEl.classList.contains("pq-slide-bar")) {
              parentEl.remove();
            }
          }
        }
      }
    }

    onMounted(() => {
      if (slideBarRef.value) {
        originalParentRef.value = slideBarRef.value.parentNode;
      }
    })

    onBeforeUnmount(() => {
      if (slideBarRef.value) {
        const parentEl = slideBarRef.value.parentNode;
        if (parentEl && parentEl.classList.contains("pq-slide-bar")) {
          parentEl.remove();
        }
      }
    })

    return {
      slideBarRef,
      container,
      show,
      windowWidth,
      centerSlotPadding,
      themeClass,
      dialogWidth,
      close,
      outSideClick,
    };
  },
};
</script>
<template>
  <div ref="slideBarRef" v-show="container">
    <div class="slide-bar-container">
      <div v-if="showCenter" class="absolute overflow-y-hidden rounded-lg" :style="{
        top: centerSlotPadding + 'px',
        width: windowWidth - dialogWidth - 2 * centerSlotPadding + 'px',
        left: centerSlotPadding + 'px',
        bottom: centerSlotPadding + 'px'
      }">
        <slot name="center" />
      </div>
      <div class="slide-bar-mask-trigger absolute overflow-y-hidden rounded-lg pointer-events-auto" :style="{
        top: 0,
        width: windowWidth - dialogWidth * ($controls.drawers.reservation.visible.secondary ? 2 : 1) + 'px',
        left: 0,
        bottom: 0
      }" @click.stop="close()" />
      <div class="slide-bar__dialog">
        <transition name="slide-bar--fade-right">
          <div v-show="show" class="slide-bar__content" :class="themeClass" :style="{ width: dialogWidth + 'px' }">
            <div class="slide-bar__body">
              <smooth-scroll>
                <slot :closeSlideBar="close" />
              </smooth-scroll>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <transition name="slide-bar--fade">
      <div v-if="backdrop" v-show="show" class="slide-bar__overlay bg-gray-500 opacity-25" />
    </transition>
  </div>
</template>
<style lang="scss">
.slide-bar-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;

  .slide-bar__dialog {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;

    .slide-bar__content {
      pointer-events: all;
      background-color: white;
      display: flex;
      flex-direction: column;
      min-width: 400px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

      .slide-bar__body {
        display: block;
        flex: 1;
        overflow: auto;
      }

      &.slide-bar--dark {
        background-color: #454c4d;

        .slide-bar__title {
          .slide-bar__title-content {
            color: white;
          }

          .slide-bar__title-close {
            color: #aaa;

            &:hover {
              color: white;
            }
          }
        }
      }
    }
  }
}

div.slide-bar__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 190;
  // opacity: 0.4;
  // background-color: black;
}

/* overlay fade in and out */
.slide-bar--fade-enter-active,
.slide-bar--fade-leave-active {
  will-change: opacity;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
}

.slide-bar--fade-enter,
.slide-bar--fade-leave-to {
  will-change: opacity;
  opacity: 0 !important;
}

/* slide bar fade in right */
.slide-bar--fade-right-enter-active {
  transition: transform 0.3s ease;
  will-change: transform;
}

.slide-bar--fade-right-leave-active {
  transition: transform 0.6s ease;
  will-change: transform;
}

.slide-bar--fade-right-enter,
.slide-bar--fade-right-leave-to {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  will-change: transform;
}
</style>
