import loadScript from "simple-load-script";

const URL ='https://maps.googleapis.com/maps/api/js?key=AIzaSyBtJl4S98QiMsc_Ep7m72Qeo76GKqOGTRA&libraries=places';

let maps = null
let isFetching = false;

const wait = window.requestAnimationFrame || function(cb) {setTimeout(cb, 60)};

export default function() {
  return {
    getMap() {
      return new Promise((resolve, reject) => {
        if (maps) {
          resolve(maps);
        } else if (isFetching){
          wait(function w () {
            if (isFetching) {
              wait(w)
            } else {
              resolve(maps);
            }
          })
        } else {
          isFetching = true;
          loadScript(URL).then(() => {
            maps = window.google.maps;
            resolve(maps);
          }).catch(reject).finally(() => {isFetching = false});
        }
      })
    }
  }
}
