import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);
import { reactive } from "@vue/composition-api";
import axios from "axios";
import store from "../state/dashboard";
import EventBus from "src/services/util/event-bus";
import TokenManager from "src/services/authentication/token-manager";
import jwt_decode from "jwt-decode";
import get from "lodash/get";
import set from "lodash/set";
import reduce from "lodash/reduce";
import isEmpty from "lodash/isEmpty";
import dayjs from "dayjs";
import mime from 'mime-types';

import { launchIntercom } from "./intercom";

// Convert rem unit to pixels
const convertRemToPixels = rem => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};
// Calculate dashboard data table width
const calculateDataTableWidth = (unit = true) => {
  // The menu width is 16 rem
  let px = convertRemToPixels(16);
  console.log("convertRemToPixels => " + px);
  let updatedTableSize = window.innerWidth - px - convertRemToPixels(5);
  console.log("table size updated => " + updatedTableSize);
  if (unit) {
    return updatedTableSize + "px";
  } else {
    return updatedTableSize;
  }

  // return "100%"
};
// Calculate dashboard main content area width
const calculateDashboardContentWidth = () => {
  console.log("convertRemToPixels => " + menuWidth());
  let contentWidth = window.innerWidth - menuWidth();
  console.log("contentWidth => " + contentWidth);
  return contentWidth + "px";
};

/**
 * Formats a date using the specified format string and optionally humanizes it.
 *
 * @param {string | Date} dt - The date to format.
 * @param {string} [fmt="MMMM Do YYYY, h:mm a"] - The format string to use. Default is "MMMM Do YYYY, h:mm a".
 * @param {boolean} [useHumanize=false] - Whether to humanize the date (e.g. use "today" or "yesterday" instead of the actual date).
 * @returns {string} The formatted date string.
 */
const formatDate = (dt, fmt = "MMMM Do YYYY, h:mm a", useHumanize = false) => {
  const date = dayjs(dt); // Parse the input date string using dayjs

  if (useHumanize) {
    const now = dayjs(); // Get the current date and time
    const diffDays = now.diff(date, "day");
    const diffDaysInStartOfDay = now.clone().startOf("day").diff(date.clone().startOf("day"), "day");

    if (diffDaysInStartOfDay === 0) {
      // If the input date is on the same day as today
      return date.format("h:mmA");
    }

    if (diffDays < 7) {
      // If the input date is within the last seven days, use a different format
      return date.format("ddd, h:mmA");
    }

    const diffYears = now.diff(date, "year");

    if (diffYears > 0) {
      // If the input date is in a different year, use the full date format
      return date.format("MM/DD/YYYY");
    }

    // If the input date is in a different month but the same year, use month day but omit year, hour and minutes
    return date.format("MMM D");
  }

  // If useHumanize is false, use the default format
  return date.format(fmt);
};

const formatServerUTCDateToLocal = (utcDateTimeString, fmt = null) => {
  // Parse the UTC date time string using dayjs
  const utcDateTime = dayjs.utc(utcDateTimeString);

  // Convert the UTC date time to the local time zone
  const localDateTime = utcDateTime.local();

  let timeString;

  if (fmt) {
    // Format the local date time as a string
    timeString = localDateTime.format(fmt);
  } else {
    // Get the relative time string
    timeString = localDateTime.fromNow();
  }

  return timeString;
};

export const serverNow = () => {
  return formatMomentForBackend(dayjs());
};

const autoBreakText = (text, maxLength) => {
  const regex = new RegExp(`.{1,${maxLength}}`, 'g');
  return text.match(regex).join('\n');
}

export const formatLongTextIfNeeded = (str) => {
  const maxLength = 30;
  const substrings = str.split(' ');
  const longestSubstring = substrings.reduce((a, b) => a.length > b.length ? a : b);
  if (longestSubstring.length > maxLength) {
    // Long string - break into smaller chunks
    return autoBreakText(str, 30);
  } else {
    // Short string - use CSS word-wrap
    return str;
  }
}

// Upload flow -> use date string without timezone
export const formatMomentForBackendUp = (mm, fmt = "YYYY-MM-DDTHH:mm") => {
  return mm.format(fmt);
};

// Download flow -> use date string with timezone
export const formatMomentForBackend = (mm, fmt = "YYYY-MM-DDTHH:mm:ssZ") => {
  return mm.format(fmt);
};

export const convertBackendDateStringForCalendar = dtString => {
  return new Date(
    formatMomentForBackend(
      loadBackendDate(dtString, false),
      "YYYY-MM-DDTHH:mm:ss"
    )
  );
};

// export const attachTimeZoneOffsetToDtString = (dtString) => {
//   const dtStringNoTz= formatMomentForBackend(loadBackendDate(dtString, false), "YYYY-MM-DDTHH:mm:ss"));
//   return moment(dtStringNoTz).utcOffset(tz.utcOffset(), true).format("YYYY-MM-DDTHH:mm:ssZ")
// }

const parseBackendDate = (
  dtString,
  targetFmt = "MMMM Do YYYY, h:mm a",
  convertToLocal = true
) => {
  if (convertToLocal) {
    const mm = dayjs(dtString);
    return mm.format(targetFmt);
  } else {
    return dayjs.tz(dtString).format(targetFmt);
  }
};

const loadBackendDate = (dtString, convertToLocal = true) => {
  if (convertToLocal) {
    return dayjs(dtString);
  } else {
    return dayjs.tz(dtString, dayjs.tz.guess());
  }
};

const humanizeDate = (mm, fmt = "h:mm A") => {
  var humanizeText = "";
  const now = dayjs();
  // get the difference between the moments
  const diff = now.diff(mm);
  //express as a duration
  const diffDuration = dayjs.duration(diff);
  if (diffDuration.asHours() > 4) {
    humanizeText = mm.fromNow();
  }
  return [humanizeText, formatMomentForBackend(mm, fmt)];
};

// /** Ignore input moment timezone and add target timezone to it */
// export const addTimezoneForMoment = (mm, l, fmt = "YYYY-MM-DDTHH:mm:ss") => {
//   const tz = geoTz(l.lat, l.lon)[0];
//   return dayjs.tz(mm.format(fmt), tz);
// };

// /** Ignore input moment timezone and add target timezone to it */
// // ALT -> keep the same local time: https://momentjscom.readthedocs.io/en/latest/moment/03-manipulating/09-utc-offset/
// export const addTimezoneForDtString = (
//   dtString,
//   l,
//   targetFmt = "YYYY-MM-DDTHH:mm:ssZ"
// ) => {
//   const tz = geoTz(l.lat, l.lon)[0];
//   const dtStringNoTz = formatMomentForBackend(
//     loadBackendDate(dtString, false),
//     "YYYY-MM-DDTHH:mm:ss"
//   );
//   return dayjs.tz(dtStringNoTz, tz).format(targetFmt);
// };

export const addTimezoneForDtString = (dtString, l) => {
  return formatMomentForBackendUp(dayjs(dtString));
};

import { notification, message } from "ant-design-vue";
export const openNotification = (
  message,
  description,
  duration = 4.5,
  type = "info",
  placement = "bottomRight",
  btn = null
) => {
  notification[type]({
    message: message,
    description: description,
    btn,
    placement,
    duration
  });
};

import NodeGeocoder from "node-geocoder";

const geocoderOptions = {
  provider: "google",
  httpAdapter: "https", // Default
  apiKey: "AIzaSyBtJl4S98QiMsc_Ep7m72Qeo76GKqOGTRA",
  formatter: null // 'gpx', 'string', ...
};

const geocoder = NodeGeocoder(geocoderOptions);

const reverseLocationSearch = l => {
  return geocoder.reverse({ lat: l.lat, lon: l.lon });
};

import short from "short-uuid";
export const shortUID = short.generate;

const deg2rad = deg => {
  return deg * (Math.PI / 180);
};

// Create our number formatter.
const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const formatCurrency = amount => {
  return currencyFormatter.format(amount);
};

const getDistanceFromLatLonInMiles = (location1, location2, km = false) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(location2.lat - location1.lat); // deg2rad below
  var dLon = deg2rad(location2.lon - location1.lon);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(location1.lat)) *
    Math.cos(deg2rad(location2.lat)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  if (km) {
    return d;
  } else {
    return d * 0.621371192;
  }
};

const getPrice = amount => {
  return `$${amount.toFixed(2)}`;
};

const getUserUID = () => {
  const token = TokenManager().accessToken;
  if (token) {
    const decoded = jwt_decode(token);
    return decoded.user_uid;
  }
  return null;
};

// IndexedDB prototypes
import { get as idbGet, set as idbSet } from "idb-keyval";
const setValueForIndexedDB = async (namespace, data) => {
  try {
    idbSet(namespace, data);
  } catch (error) {
    console.log("setValueForIndexedDB => error", error);
  }
  console.log("setValueForIndexedDB => done");
};
const getValueFromIndexedDB = async namespace => {
  var val;
  try {
    val = await idbGet(namespace);
  } catch (error) {
    console.log("getValueFromIndexedDB => error", error);
  }
  console.log("getImagesFromIndexedDB => done", val);
  return val;
};

import copy from "copy-to-clipboard";

const toggleLoadingIndicator = (visible, title = "Loading") => {
  EventBus.$emit("toggle-loading-indicator", visible, title);
};
const openDocSlideCard = (
  role = null,
  doc = null,
  reservation_id = null
) => {
  EventBus.$emit(
    "open-documents-drawer",
    role,
    {
      doc,
      reservation_id
    }
  );
};
const openContactSlideCard = (reservation) => {
  EventBus.$emit("open-contact-drawer", { reservation });
};
const openReservationSlide = item => {
  EventBus.$emit("open-slide-bar", "reservation", item);
};
const openPaymentDrawer = role => {
  EventBus.$emit("open-payment-method-drawer", role);
};
const openPlanSelectSlideCard = () => {
  EventBus.$emit("open-slide-bar", "plan", {});
};
const openNotificationSlideCard = () => {
  EventBus.$emit("open-slide-bar", "notification");
};
const openListingDetailsDrawer = data => {
  EventBus.$emit("open-listing-details", data);
};

// import metaInfoGenerator from "src/views/seo/metaInfo";

import parseAddress from "parse-address";

const formatPhoneNumber = phoneNumberString => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const deepGet = (obj, keyPath, defaultVal = null) => {
  return get(obj, keyPath) ? get(obj, keyPath) : defaultVal;
};

export const deepSet = set;

const subArray = (arr, ...args) => {
  const sub = arr.slice(...args);
  if (sub.length === 0) {
    return arr;
  }
  return sub;
};

const getDateFormat = intervalMode => {
  switch (intervalMode) {
    case "hourly":
      return "MMM D";
    case "daily":
      return "MMM D";
    case "monthly":
      return "MMM D";
    default:
      return null;
  }
};
const getTimeFormat = intervalMode => {
  switch (intervalMode) {
    case "hourly":
      return "h:mm a";
    case "daily":
      return "h:mm a";
    case "monthly":
      return "YYYY";
    default:
      return null;
  }
};

export const getDateTimeFormatBasedOnIntervalMode = intervalMode => {
  let dateFmt = getDateFormat(intervalMode);
  let timeFmt = getTimeFormat(intervalMode);
  let defaultFmt = `${dateFmt || ""}, ${timeFmt || ""}`;
  return {
    date: dateFmt,
    time: timeFmt,
    default: isEmpty(defaultFmt) ? null : defaultFmt
  };
};

const controls = reactive({
  windowWidth: window.innerWidth,
  drawers: {
    reservation: {
      visible: {
        secondary: false
      },
      switching: {
        secondary: false
      }
    },
    payment: {
      visible: false,
      role: "tenant",
      controls: null,
      form: null
    },
    listing: {
      visible: false,
      form: null
    }
  },
  modal: {
    loading: {
      visible: false,
      title: null
    }
  }
});

const menuWidth = () => {
  const hostMenu = document.querySelector("#host-menu");
  const tenantMenu = document.querySelector("#tenant-menu");
  if (hostMenu) {
    return hostMenu.clientWidth;
  } else if (tenantMenu) {
    return tenantMenu.clientWidth;
  } else {
    return 0;
  }
};

const toggleIntercomVisibility = visible => {
  // if (visible) {
  //   window.Intercom("boot", window.intercomSettings);
  // } else {
  //   window.Intercom("shutdown");
  // }
};
const toggleIntercomPosition = (toggle, displacement = { x: 400, y: 0 }) => {
  // if (toggle) {
  //   window.intercomSettings.horizontal_padding = displacement.x + 20;
  //   window.intercomSettings.vertical_padding = displacement.y + 30;
  // } else {
  //   window.intercomSettings.horizontal_padding = 20;
  //   window.intercomSettings.vertical_padding = 30;
  // }
  // window.Intercom("boot", window.intercomSettings);
};

const openUrlInNewTab = (url) => {
  window.open(url, '_blank');
}

const openIntercom = () => {
  // window.Intercom("show");
};

const logoutIntercom = () => {
  // window.Intercom("shutdown");
}

export const stripeClient = () => {
  if (window.Stripe) {
    return window.Stripe(process.env.VUE_APP_STRIPE_KEY);
  }
};

const historyGoBack = ({ $router = null, defaultPath = null }) => {
  if (history.length <= 2) {
    if (defaultPath && $router) {
      $router.push(defaultPath);
    }
  } else {
    history.back();
  }
};

export const convertFileToDataURL = f => {
  return new Promise(resolve => {
    if (f.type.includes("/pdf")) {
      const result = URL.createObjectURL(f);
      resolve(result);
      return;
    }
    var fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsDataURL(f);
  });
};

export const getChargeDuration = pricing => {
  const { guest_meta } = pricing;
  const { rates_details } = guest_meta;
  const units = deepGet(rates_details, "[0].units");
  const amount = reduce(
    rates_details,
    (sum, v) => {
      return sum + v.amount;
    },
    0
  );
  console.log(rates_details);
  console.log(amount);
  return { units, amount };
};

export const downloadFileFromUrl = async f => {
  let resp, blob;
  resp = await fetch(f.url);
  blob = await resp.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = f.name;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const scrollToHashSection = (anchor) => {
  document
    .querySelector(anchor)
    .scrollIntoView({ behavior: "smooth", block: "center" });
}

export const isDesktopView = () => {
  return controls.windowWidth > 1024;
}

export const logout = ({ skipEvent = false } = {}) => {
  store.commit("setAdmin", false);
  EventBus.$emit("update-socket-auth-state", {
    state: "leave",
    user_uid: getUserUID()
  });
  // store.commit("clearNotifications");
  TokenManager().removeAccessToken();
  TokenManager().removeRefreshToken();
  removeUserData();
  if (skipEvent) {
    return
  }
  logoutIntercom();
  launchIntercom();
  resetWhiteLabelImages();
  EventBus.$emit("auth-required-login", { target: window.location.pathname.startsWith("/dashboard") ? "dashboard" : "tenant", silent: true });
}

export const toggleOptionalExtra = (value) => {
  localStorage.setItem("optionalExtraPopedUp", value)
}

export const getOptionalExtra = () => {
  let value = localStorage.getItem("optionalExtraPopedUp");
  if (value) {
    value = JSON.parse(value);
  } else {
    value = false;
  }
  return value;
}

export const authCallback = () => {
  EventBus.$emit("update-socket-auth-state", {
    state: "join",
    user_uid: getUserUID()
  });
  EventBus.$emit("set-white-label-images-if-needed");
  launchIntercom();
}

export const isLoggedIn = () => {
  return localStorage.getItem("access_token") !== null;
}

export const saveUserData = (data, updateMode = false) => {
  if (!updateMode) {
    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("refresh_token", data.refresh_token);
  }
  const { user: userData, extra } = data;
  const { legacy } = userData;
  localStorage.setItem(
    "userData",
    JSON.stringify({
      id: userData.id,
      first_name: userData.first_name,
      last_name: userData.last_name,
      email: userData.email,
      picture: userData.picture,
      oauth_provider: userData.oauth_provider,
      extra,
      legacy: legacy !== null
    })
  );
  toggleOptionalExtra(false);
};

export const loadUserData = () => {
  let userData;
  try {
    const u = localStorage.getItem("userData");
    userData = JSON.parse(u);
  } catch (error) {
    console.log("loadUserData, error: ", error)
  }
  return userData;
};

export const removeUserData = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("userData");
};

export const setWhiteLabelImages = ({ logoURL, coverURL }) => {
  localStorage.setItem("logoURL", logoURL);
  localStorage.setItem("coverURL", coverURL)
}

export const getWhiteLabelImages = () => {
  const logoURL = localStorage.getItem("logoURL");
  const coverURL = localStorage.getItem("coverURL");
  return { logoURL, coverURL }
}

export const resetWhiteLabelImages = () => {
  localStorage.removeItem("logoURL");
  localStorage.removeItem("coverURL");
}

export const mimeTypeLookUp = (path) => {
  return mime.lookup(path);
}

export const generateShortLink = async (long_url) => {
  const apiKey = 'iUG44fs3N1tz6UrFUG93uz4Y7R02P0KznNlc8tZIG3DiuNKmyzYqOeQMvoSb'; // t.ly API key
  const endpoint = "https://t.ly/api/v1/link/shorten";
  try {
    const response = await axios.post(endpoint, { long_url }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      }
    });
    return response.data.short_url;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const isAdmin = () => {
  return store.getters.isAdmin;
}

const runtimeENV = () => {
  return process.env.VUE_APP_ENV;
}

const imageFetchPolicy = () => {
  if (runtimeENV() === "production") {
    return "anonymous";
  }
  return null;
}

Vue.prototype.$convertRemToPixels = convertRemToPixels;
Vue.prototype.$calculateDataTableWidth = calculateDataTableWidth;
Vue.prototype.$calculateDashboardContentWidth = calculateDashboardContentWidth;
Vue.prototype.$formatDate = formatDate;
Vue.prototype.$formatServerUTCDateToLocal = formatServerUTCDateToLocal;
Vue.prototype.$serverNow = serverNow;
Vue.prototype.$formatLongTextIfNeeded = formatLongTextIfNeeded;
Vue.prototype.$formatMomentForBackend = formatMomentForBackend;
Vue.prototype.$formatMomentForBackendUp = formatMomentForBackendUp;
Vue.prototype.$parseBackendDate = parseBackendDate;
Vue.prototype.$loadBackendDate = loadBackendDate;
Vue.prototype.$convertBackendDateStringForCalendar = convertBackendDateStringForCalendar;
Vue.prototype.$humanizeDate = humanizeDate;
// Vue.prototype.$addTimezoneForMoment = addTimezoneForMoment;
Vue.prototype.$addTimezoneForDtString = addTimezoneForDtString;
Vue.prototype.$openNotification = openNotification;
Vue.prototype.$openListingDetailsDrawer = openListingDetailsDrawer;
Vue.prototype.$toggleLoadingIndicator = toggleLoadingIndicator;
Vue.prototype.$message = message;
Vue.prototype.$reverseLocationSearch = reverseLocationSearch;
Vue.prototype.$shortUID = shortUID;
Vue.prototype.$getDistanceFromLatLonInMiles = getDistanceFromLatLonInMiles;
Vue.prototype.$getPrice = getPrice;
Vue.prototype.$getUserUID = getUserUID;
Vue.prototype.$setValueForIndexedDB = setValueForIndexedDB;
Vue.prototype.$getValueFromIndexedDB = getValueFromIndexedDB;
Vue.prototype.$copyToClipboard = copy;
Vue.prototype.$openDocSlideCard = openDocSlideCard;
Vue.prototype.$openContactSlideCard = openContactSlideCard;
Vue.prototype.$openReservationSlide = openReservationSlide;
Vue.prototype.$openPaymentDrawer = openPaymentDrawer;
Vue.prototype.$openPlanSelectSlideCard = openPlanSelectSlideCard;
Vue.prototype.$openNotificationSlideCard = openNotificationSlideCard;
// Vue.prototype.$metaInfoGenerator = metaInfoGenerator;
Vue.prototype.$formatCurrency = formatCurrency;
Vue.prototype.$formatPhoneNumber = formatPhoneNumber;
Vue.prototype.$parseAddress = parseAddress.parseLocation;
Vue.prototype.$deepGet = deepGet;
Vue.prototype.$deepSet = deepSet;
Vue.prototype.$subArray = subArray;
Vue.prototype.$getDateTimeFormatBasedOnIntervalMode = getDateTimeFormatBasedOnIntervalMode;
Vue.prototype.$controls = controls;
Vue.prototype.$menuWidth = menuWidth;
Vue.prototype.$toggleIntercomPosition = toggleIntercomPosition;
Vue.prototype.$toggleIntercomVisibility = toggleIntercomVisibility;
Vue.prototype.$openUrlInNewTab = openUrlInNewTab;
Vue.prototype.$openIntercom = openIntercom;
Vue.prototype.$historyGoBack = historyGoBack;
Vue.prototype.$convertFileToDataURL = convertFileToDataURL;
Vue.prototype.$downloadFileFromUrl = downloadFileFromUrl;
Vue.prototype.$scrollToHashSection = scrollToHashSection;
Vue.prototype.$bus = EventBus;
Vue.prototype.$isDesktopView = isDesktopView;
Vue.prototype.$saveUserData = saveUserData;
Vue.prototype.$loadUserData = loadUserData;
Vue.prototype.$removeUserData = removeUserData;
Vue.prototype.$setWhiteLabelImages = setWhiteLabelImages;
Vue.prototype.$getWhiteLabelImages = getWhiteLabelImages;
Vue.prototype.$resetWhiteLabelImages = resetWhiteLabelImages;
Vue.prototype.$isLoggedIn = isLoggedIn;
Vue.prototype.$generateShortLink = generateShortLink;
Vue.prototype.$isAdmin = isAdmin;
Vue.prototype.$runtimeENV = runtimeENV;
Vue.prototype.$imageFetchPolicy = imageFetchPolicy;
Vue.prototype.$logout = logout;

