<script>
import { ref, onMounted, computed } from "@vue/composition-api";
import ClickOutside from "vue-click-outside";

export default {
  name: "CommonDropdown",
  directives: {
    ClickOutside,
  },
  props: {
    inputMode: {
      type: Boolean,
      default: false,
    },
    inputModeProps: {
      type: Object,
      default: () => {
        return {
          placeholder: null,
        };
      },
    },
    selectedValue: {
      type: Object,
      default: null,
    },
    customTextColor: {
      type: String,
      default: "text-gray-700",
    },
    namespace: {
      type: String,
      default: "dropdown",
    },
    small: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    model: {
      /* 
      [
          {
              name: *,
              id: *,
              **:**
              ...
          }
      ]
      */
      type: Array,
    },
    selectedIndex: {
      type: Number,
      default: 0,
    },
    width: {
      type: [Number, String],
      default: 48,
    },
    autoSelect: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const showSelect = ref(false);

    const iconMode = ref(
      computed(() => {
        var _iconMode = false;
        props.model.map((v, i) => {
          if (v.icon) {
            _iconMode = true;
          }
        });
        return _iconMode;
      })
    );

    const calculatedWidth = computed(() => {
      return `w-${props.width}`;
    });

    const selected = ref(props.selectedValue);

    if (selected.value == null && !props.inputMode) {
      selected.value = props.model[props.selectedIndex];
    }

    const close = () => {
      showSelect.value = false;
    };

    const setSelected = (m, i) => {
      selected.value = m;
      ctx.emit("dropdown-select", i, m);
      console.log("dropdown-select", i, m);
      close();
    };
    return {
      selected,
      showSelect,
      iconMode,
      calculatedWidth,
      setSelected,
      close,
      console,
    };
  },
};
</script>

<template>
  <div>
    <template v-if="small">
      <div
        v-click-outside="close"
        :class="`relative inline-block text-left ${calculatedWidth} pr-1`"
      >
        <div>
          <button
            id="options-menu"
            type="button"
            :class="customTextColor"
            class="
              whitespace-nowrap
              px-0.5
              inline-flex
              justify-between
              w-full
              rounded
              py-2
              bg-white
              text-sm
              font-medium
              hover:bg-gray-50
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500
            "
            aria-haspopup="true"
            aria-expanded="true"
            @click="showSelect = !showSelect"
          >
            {{ selected.name }}
            <svg-icon
              icon-class="chevron-down"
              class="h-5 w-5 text-gray-700"
            />
          </button>
        </div>
        <div
          v-show="showSelect"
          :class="`z-10 origin-top-right absolute right-0 mt-2 ${calculatedWidth} rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`"
        >
          <div
            class="py-1 overflow-scroll no-scrollbar"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
            :style="{ 'max-height': '300px' }"
          >
            <span
              v-for="(m, i) in model"
              :key="`${namespace}-${i}`"
              class="
                whitespace-nowrap
                block
                w-full
                py-2
                h-10
                font-medium
                cursor-pointer
                text-gray-700
                hover:bg-parqay-primary hover:text-white
                inline-flex
                justify-between
              "
              :class="{ 'px-2': iconMode, 'px-4': !iconMode }"
              role="menuitem"
              @click="setSelected(m, i)"
            >
              <img
                v-if="iconMode == true && m.icon"
                :src="m.icon"
                alt=""
                class="flex-shrink-0 h-6 w-6 mr-2"
              >
              <div class="w-full text-center">
                {{ m.name }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-click-outside="close"
        :class="`relative inline-block text-left ${calculatedWidth}`"
      >
        <div class="p-0.5">
          <div v-if="inputMode">
            <a-auto-complete
              v-model="inputModeProps.model"
              size="large"
              class="w-full"
              :data-source="inputModeProps.dataSource"
              :placeholder="inputModeProps.placeholder"
              :disabled="inputModeProps.disabled"
              @dropdownVisibleChange="
                (open) => {
                  console.log('dropdownVisibleChange', open);
                  console.log('dataSource', inputModeProps.dataSource.length);
                  if (inputModeProps.dataSource.length > 0 && !open && autoSelect) {
                    const updatedModel = inputModeProps.dataSource[0];
                    console.log('model', updatedModel);
                    $emit('update-input-mode-model', updatedModel);
                  }
                }
              "
              @search="inputModeProps.onSearch"
              @select="inputModeProps.onSelect"
              @change="
                (v) => {
                  inputModeProps.onChange(v);
                }
              "
            >
              <a-input>
                <svg-icon
                  v-if="!inputModeProps.loading"
                  slot="suffix"
                  icon-class="chevron-down"
                  class="h-full w-5"
                />
                <svg-icon
                  v-else
                  slot="suffix"
                  icon-class="circular-loader"
                  class="animate-spin h-full w-5 text-parqay-primary"
                />
              </a-input>
              <template
                v-if="inputModeProps.customDataSource"
                slot="dataSource"
              >
                <slot name="custom-search-input-menu" />
                <slot name="custom-search-input-search-footer" />
              </template>
            </a-auto-complete>
          </div>
          <button
            v-else
            id="options-menu"
            type="button"
            class="
              whitespace-nowrap
              inline-flex
              justify-between
              items-center
              w-full
              rounded
              py-2
              bg-white
              text-sm
              font-medium
              text-gray-700
              hover:bg-gray-50
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500
            "
            :class="{
              'px-2': iconMode,
              'px-4': !iconMode,
              'border border-gray-300 shadow-sm': !borderless,
            }"
            aria-haspopup="true"
            aria-expanded="true"
            @click="showSelect = !showSelect"
          >
            <img
              v-if="iconMode == true && selected.icon"
              :src="selected.icon"
              alt=""
              class="flex-shrink-0 h-6 w-6"
            >
            <span>{{ selected.name }}</span>
            <svg-icon
              icon-class="chevron-down"
              class="-mr-1 ml-2 h-5 w-5"
            />
          </button>
        </div>
        <div
          v-if="!inputMode"
          v-show="showSelect"
          :class="`z-10 origin-top-right absolute right-0 mt-2 ${calculatedWidth} rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`"
        >
          <div
            class="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <span
              v-for="(m, i) in model"
              :key="`${namespace}-${i}`"
              class="
                whitespace-nowrap
                block
                w-full
                py-2
                h-10
                font-medium
                cursor-pointer
                text-gray-700
                hover:bg-parqay-primary hover:text-white
                inline-flex
                justify-between
              "
              :class="{ 'px-2': iconMode, 'px-4': !iconMode }"
              role="menuitem"
              @click="setSelected(m, i)"
            >
              <img
                v-if="iconMode == true && m.icon"
                :src="m.icon"
                alt=""
                class="flex-shrink-0 h-6 w-6 mr-2"
              >
              <div class="w-full text-center">
                {{ m.name }}
              </div>
            </span>
            <slot name="action" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped>
.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>