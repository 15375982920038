var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-bar',{attrs:{"width":400,"backdrop":"","close-by-mask":""},on:{"close-slide-bar":function($event){return _vm.handleCloseEvent()}},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('div',{staticClass:"flex justify-between items-center border-b px-6 py-6"},[_c('span',{staticClass:"font-bold text-lg text-gray-900 my-auto"},[_vm._v("\n      "+_vm._s(_vm.controls.role === "host" ? "Tenant Documents" : "View Documents")+"\n    ")]),_c('span',{on:{"click":slotProps.closeSlideBar}},[_c('svg-icon',{staticClass:"cursor-pointer w-6 h-6 text-blue-900",attrs:{"icon-class":"close"}})],1)]),(!_vm.controls.loading.reservation)?_c('div',{staticClass:"h-full flex flex-col justify-between space-y-4 px-6 py-4"},_vm._l((_vm.controls.docs),function(form,i){return _c('div',{key:("doc-" + i),staticClass:"flex flex-col justify-between space-y-4"},[(_vm.controls.docs.length > 1)?_c('a-divider',[_vm._v("Document #"+_vm._s(i + 1))]):_vm._e(),_c('document-viewer',{key:("document-viewer-" + (_vm.controls.key)),attrs:{"role":_vm.controls.role,"form":form}}),(form.type === 'upload')?_c('a-button',{attrs:{"type":"primary","size":"large","disabled":!(form.files && form.files.length > 0)},on:{"click":function () {
          _vm.$bus.$emit('expand-document-viewer');
        }}},[_vm._v("\n        View Attachments\n      ")]):_vm._e(),(_vm.controls.type === 'sign')?_c('a-button',{attrs:{"type":"primary","size":"large","disabled":!(
        form.signatures &&
        form.signatures.length > 0 &&
        form.status === 'complete'
      )},on:{"click":function () {
  _vm.$bus.$emit('expand-document-viewer');
}}},[_vm._v("\n        View Signatures\n      ")]):_vm._e(),(form.type === 'upload')?[(form.$expand.uploader)?_c('document-uploader',{key:("document-uploader-" + (_vm.controls.key)),attrs:{"doc-id":form.id,"template":form.template,"reservation-id":form.reservation_id,"host-id":form.host},on:{"document-uploaded":function () {
            _vm.$bus.$emit('document-upload-refresh');
            _vm.handleCloseEvent();
          }}}):_c('a-button',{attrs:{"size":"large"},on:{"click":function () { return (form.$expand.uploader = true); }}},[_vm._v("\n          Upload New Document\n        ")])]:_vm._e(),_c('div',{staticClass:"flex flex-col space-y-4 justify-between mb-6"},[(_vm.controls.role === 'host' && form.type === 'upload')?_c('a-button',{attrs:{"size":"large","disabled":!(form.files && form.files.length > 0),"loading":_vm.controls.loading.download},on:{"click":function () {
            _vm.controls.loading.download = true;
            _vm.$bus.$emit('document-download-start', form);
          }}},[_vm._v("\n          Download Attachments\n        ")]):_vm._e(),(form.type === 'sign')?[(form.status === 'complete')?_c('a-popconfirm',{attrs:{"title":"This document is complete. Please confirm if you want to start a new sign request","ok-text":"Sign"},on:{"confirm":function () {
              _vm.$bus.$emit('start-new-document-signature-request', form);
              _vm.handleCloseEvent();
            }}},[_c('a-button',{attrs:{"type":"primary","size":"large"}},[_vm._v("\n              Restart Signing\n            ")])],1):_c('a-button',{attrs:{"type":"primary","size":"large"},on:{"click":function () {
            _vm.$bus.$emit('start-new-document-signature-request', form);
            _vm.handleCloseEvent();
          }}},[_vm._v("\n            Start Signing\n          ")])]:_vm._e(),(form.type !== 'identity' &&
          _vm.controls.role === 'host' &&
          form.status !== 'incomplete'
          )?_c('a-popconfirm',{attrs:{"title":"Requesting document update will invalidate current uploads and reset document status to incomplete. Are you sure to make this action?","ok-text":"Yes","cancel-text":"No"},on:{"confirm":function($event){return _vm.requestUpdate(form.id)},"cancel":function () { }}},[_c('a-button',{attrs:{"size":"large","loading":_vm.controls.loading.requestUpdate}},[_vm._v("\n            Request for Update\n          ")])],1):_vm._e(),(form.type !== 'identity' &&
          _vm.controls.role === 'host' &&
          form.status !== 'complete'
          )?_c('a-button',{attrs:{"loading":form.$loading,"size":"large"},on:{"click":function($event){return _vm.reinviteTenant(form)}}},[_vm._v("\n          Invite Tenant\n        ")]):_vm._e(),(_vm.controls.type === 'identity' && form.status !== 'verified')?_c('a-button',{attrs:{"type":"primary","size":"large"},on:{"click":function () {
          _vm.$bus.$emit(
            'start-new-document-identity-verification',
            _vm.controls.template
          );
          _vm.handleCloseEvent();
        }}},[_vm._v("\n          Start Identity Verification\n        ")]):_vm._e()],2)],2)}),0):_c('div',{staticClass:"h-16 flex flex-col items-center justify-center"},[_c('a-button',{attrs:{"type":"link","size":"large","loading":""}},[_vm._v("Fetching Details...")])],1)]}}]),model:{value:(_vm.controls.visible),callback:function ($$v) {_vm.$set(_vm.controls, "visible", $$v)},expression:"controls.visible"}})}
var staticRenderFns = []

export { render, staticRenderFns }