import request from "src/services/api/request";
import { API as AccountAPI } from "src/views/components/api/account";
import { API as ListingAPI } from "src/views/listings/api/";
import { API as ReservationAPIHost } from "src/views/reservations/api";
import { getDefaultReservationDetailsForm } from "src/views/reservations/util";
import {
  deepGet,
  getDateTimeFormatBasedOnIntervalMode,
  getChargeDuration
} from "src/plugins/prototype";

export const API = {
  list(filter={}) {
    return request.get("reservations", {
      filter: {
        role: "tenant",
        ...filter
      }
    });
  },
  details(id) {
    return request.get(`reservations/${id}`);
  },
  async create({
    intervals,
    listing,
    pricing,
    interval_mode,
    payment_method,
    invoice_items,
    selected_spaces
  }) {
    const tenant = (await AccountAPI.getUser()).data;
    console.log("tenant >> ", tenant);
    const form = getDefaultReservationDetailsForm("new");
    const spaces = selected_spaces.map(v => ({
      space_id: v.space_id,
      intervals: v.intervals.map(interval => ({
        from: interval.start,
        to: interval.end,
        reservation_id: form.id
      }))
    }));
    let resp;
    for (const space of spaces) {
      resp = await ListingAPI.fillBookedIntervals(
        listing.id,
        space.space_id,
        space.intervals
      );
      console.log("fillBookedIntervals: ", resp.data);
    }
    form.title = deepGet(listing, "summary.title");
    form.source = "marketplace";
    if (!listing.is_auto_approve) {
      // Note: status of request will not auto-complete the reservation since host need to be approved first
      form.status = "request";
    }
    form.intervals = intervals;
    form.tenant = tenant;
    form.listing = listing;
    form.spaces = spaces;
    form.type = listing.type;
    form.smartcar.prompt_tenant = true;
    form.smartcar.prompt_reason =
      "Smartcar is a recommended way to allow host manage your vehicle while you are parking";
    form.transaction.pricing = pricing;
    form.transaction.list_price = pricing.guest_meta.price;
    form.transaction.final_charge = pricing.guest_meta.total;
    form.transaction.host_income = pricing.host_meta.income;
    const { units, amount } = getChargeDuration(pricing);
    console.log("getChargeDuration: ", amount);
    form.transaction.charge_duration.amount = amount;
    form.transaction.charge_duration.unit = units;
    // TODO: next_charge_date, final_charge_date
    if (units === "months" && amount > 1) {
      form.transaction.charge_type = "subscription";
    } else {
      form.transaction.charge_type = "deposit";
    }
    form.transaction.payment_method = payment_method;
    form.transaction.invoice_items = invoice_items;
    form.transaction.request_user_enter_payment_method = false;
    const fmts = getDateTimeFormatBasedOnIntervalMode(interval_mode);
    form.review = {
      intervals,
      title: deepGet(listing, "summary.title"),
      images: deepGet(listing, "summary.images", []),
      host: deepGet(listing, "summary.host"),
      about_host: deepGet(listing, "settings.about_host"),
      getting_there: deepGet(listing, "settings.getting_there"),
      amenities: deepGet(listing, "settings.amenities", []),
      location: deepGet(listing, "summary.location"),
      pricing: deepGet(pricing, "guest_meta"),
      time_format: fmts.default,
      created_at: deepGet(listing, "created_at")
    };
    console.log("form >> ", form);
    const reservationResp = await ReservationAPIHost.create(form, listing.user);
    const chargeResp = await this.charge(reservationResp.data);
    console.log("chargeResp >> ", chargeResp.data);
    return reservationResp;
  },
  associateInvitationCode(code) {
    return request.post(`reservations/invite-confirm/${code}`);
  },
  updateReservationVehicle(id, vehicle) {
    return request.put(`reservations/${id}/vehicle`, { vehicle });
  },
  deleteReservationVehicle(id, vehicle_id) {
    return request.delete(`reservations/${id}/vehicle/${vehicle_id}`);
  },
  charge(form, on_behalf_of = null) {
    const payload = {
      charge_type: form.transaction.charge_type,
      reservation_id: form.id,
      total: form.transaction.final_charge,
      fee: form.transaction.pricing.guest_meta.fee,
      interval_mode: form.transaction.interval_mode,
      interval_count: form.transaction.charge_duration.amount,
      invoice_items: form.transaction.invoice_items,
      tenant_email: form.tenant.email,
      payment_method: form.transaction.payment_method,
      host_uid: form.host.id
    };
    if (on_behalf_of) {
      payload.on_behalf_of = on_behalf_of;
    }
    return request.post(`payments/charge`, payload);
  }
};
