// Define default language: English here
const _langEn = {
    header: {
        becomeAHost: "Become a host",
        parking: "Parking",
        cars: "Cars",
        spaces: "Spaces",
        faqs: "FAQs",
        contact: "Contact",
        blog: "Blog",
        logIn: "Log in",
        signUp: "Sign Up",
        host: "Listings",
        list: "List your space",
        hello: "Hello",
        dashboard: "Dashboard",
        reservations: "Reservations",
        account: "My Account",
        logOut: "Log Out",
    },

    mobileHeader: {
        becomeAHost: "Become a host",
        parking: "Parking",
        cars: "Cars",
        spaces: "Spaces",
        faqs: "FAQs",
        contact: "Contact",
        terms: "Terms & Conditions",
        privacy: "Privacy",
        logIn: "Log in",
        signUp: "Sign Up",
    },
    //Add Coworking to rightOptions Below
    landingPage: {
        rightTitle: "Let's get started!",
        rightWhat: "What would you like to reserve?",
        rightOptions: ["parking", "spaces"],
        rightLength: "How long would you like to reserve?",
        rightTime: ["hourly", "daily", "monthly"],
        rightWhere: "Where do you need this?",
        rightWhen: "When do you need it?",
        search: "Search",

        leftTitle: "Reserve parking, a workplace, or stay nearby.",
        leftDescription: "Rent parking, a work space, or a place to stay when you need it. Earn money when you don't.",
    },

    button:{
        socialFacebook: "Continue with Facebook",
        socialGoogle: "Continue with Google",
        search: "Search",
        reserve: "Reserve",
    },

    accountInfo: {
        first: "First Name",
        last: "Last Name",
        email: "Email",
        phone: "Phone",
        update: "Update",

        updateDetails: "Update Details",
        updatePrice: "Update Price",
        updateAvailability: "Update Availability",
        updating: "Updating...",
        loading: "Loading...",
        connecting: "Connecting Vehicle...",
        reset: "Reset Digital Key",
        control: "Control Your Vehicle",

        license:"Drivers License",
        registration: "Registration",
        insurance: "Proof of Insurance",
        lease: "Copy of Lease",
    },

    accountDetails: {
        first: "First Name",
    },

    signIn:{
        welcome: "Welcome",
        email: "Email",
        password: "Password",
        passwordBox: "Your Password",
        forgetPassword: "Forgot your password?",
        signIn: "Sign In",
    },

    signUp:{
        title: "Sign Up",
        first: "First Name",
        last: "Last Name",
        email: "Email Address",
        phone: "Phone Number",
        password: "Password",
        confirm: "Confirm Password",
        agree: "I agree to the terms of service and privacy policy of Parqyt, Inc.",
        signUp: "Sign Up!",
    },

    contactUs:{
        contact: "Contact Us",
        contactDescription: "Please use one of the options listed below.",
        email: "Send us an Email",
        emailDescription: "Email us, and we will get back to you ASAP.",
        call: "Give us a call",
        callDescription: "If your matter requires someone now, please give us a call at 415-688-2011",
    },

    faq:{
        title: "Frequently Asked Questions",
        ////////////
        ////////////
        ////////////
        user: "User",
        how: "How does Parqay work?",
        howDescription: "Parqay is a parking reservation platform. We partner with various facilities to provide you with lots of parking options. Parqay does not own or operate any of the locations listed, we do work very closely with the lots ensure you have a seamless parking experience every time you park!",
        speak: "Will I have to speak with the host?",
        speakDescription: "Many of our hosts choose to not meet with the parker, this would take a lot of time out of their day.",
        guaranteed: "Is my parking spot guaranteed?",
        guaranteedDescription: "Your parking will be guaranteed, but we cannot guarantee you a specific spot in the lot.",
        download: "Do I have to download the app?",
        downloadDescription: "You do not have to, though we encourage you to do so to keep all of your reservations in one place. We also send out frequent deals to our app that you may otherwise miss via email.",
        payment: "Is my payment information secure?",
        paymentDescription: "Yes! All of our payments and payouts are done with Stripe, a multi-million dollar company that operates in 20 countries, and we don’t store full credit card information anywhere on our servers. Checkout https://stripe.com/ for more information.",
        multiple: "What if I need to make multiple reservations?",
        multipleDescription: "Yes, you can make multiple reservations with Parqay, we actually encourage you to do so! You deserve to get to your destination right away!",
        cancel: "What if the host cancels last minute?",
        cancelDescription: "We will find you a spot nearby, and fulfill your need.",
        ////////////
        ////////////
        ////////////
        host: "Host",
        who: "Will I know who is parking in my spot?",
        whoDescription: "Yes, you will know exactly who is parking in your spot. You will have the parkers name, car make and model, and the times they will are coming and going.",
        need: "What if something comes up and I need my space?",
        needDescription: "Please give us a call at 415-688-2011 and we will find an alternative parking space for the person.",
        paid: "How do I get paid?",
        paidDescription: "We can pay you right away via direct deposit or sending a check in the mail.",
        towing: "Can I request a towing company?",
        towingDescription: "Yes, we partner with local towing companies should the need arise to use one.",
    },


    parkingBenefits: {
        header: "Parqay Benefits",
        guaranteedTitle: "Guaranteed Reservation",
        guaranteedDescription: "Confidently know your reservation will be available upon your arrival.",
        safeTitle: "Safe & Secure",
        safeDescription: "Payments are processed with Stripe to guarantee security.",
        communityTitle: "Community",
        communityDescription: "Be part of a community that works together to win together.",
        supportTitle: "Support",
        supportDescription: "24/7 support to find you the perfect reservation or help when needed."
    },

    exploreNearby: {
        title: "Explore Parqay",
    },
    parkingNearby: {
        title: "Parking Nearby",
        description: "Hourly, daily and monthly parking.",
        noData: "Could not find any nearby parking",
    },
    spacesNearby: {
        title: "Spaces Nearby",
        description: "Homes, apartments and, office spaces.",
        noData: "Could not find any nearby spaces",
    },
    carsNearby: {
        title: "Cars Nearby",
        description: "Hourly, daily, and monthly car rentals.",
        noData: "Could not find any nearby cars",
    },

    download: {
        title: "Download Parqay",
        description: "For a better experience,",
        description1: "download our app!",
    },

    parkingShare: {
        title: "Extra parking?",
        title1: "Share and earn today.",
        description: "Share your parking space and earn. Going on vacation or off to work, you can always earn hourly daily, or monthly.",
    },

    footer: {
        rights: "All rights reserved",
        /////Book Parking
        bookParking: "Book Parking",
        sfParking: "San Francisco Parking",
        bostonParking: "Boston Parking",
        chicagoParking: "Chicago Parking",
        BottlerockParking: "Bottlerock Parking",
        //////Solutions
        solutions: "Solutions",
        carShare: "Car Share",
        enforcement: "Enforcement",
        fleet: "Fleet Management",
        ///// Company
        company: "Company",
        contact: "Contact Us",
        faq: "FAQs",
        terms: "Terms & Conditions",
        privacy: "Privacy",
    },
    /////////// Become A Host Page ///////////////

    becomeAHost: {
        leftTitle: "Earn money on your parking, office space or home.",
        leftDescription: "We make it simple to reach thousands of people looking for parking, places to work and stay.",
        rightTitle: "Let's get started!",
        rightWhat: "What would you like to list?",
        rightDropDown: ["parking", "home/room/condo", "cafe/bar", "office/desk", ],
    },

    howItWorks: {
        title: "How it works",
        listTitle: "List for free",
        listDescription: "Listing your space is free. We advertise your property to get your space filled or used.",
        priceTitle: "Set Price & Availability",
        priceDescription: "You're in control of the availability, pricing, and how you interact with renters.",
        bookingTitle: "Booking & Payments",
        bookingDescription: "You can choose to instantly accept bookings or reservation requests, with automated payments.",
        paidTitle: "Get Paid",
        paidDescription: "You are guaranteed secure payments and payouts. Payouts happen monthly.",
    },

    /////Cars Landing Page////
    cars: {
        rightTitle: "Let's find you a car!",
        rightLength: "How long do you need the car?",
        rightTime: ["hourly", "daily", "monthly"],
        rightWhere: "Where do you need this?",
        rightWhen: "When do you need it?",

        leftTitle: "Instantly rent cars nearby",
        leftDescription: "Rent a car when you need it, make money when you don't, share your car today!",
        socialFacebook: "Continue with Facebook",
        socialGoogle: "Continue with Google",
    },

    carBenefits: {
        guaranteedTitle: "Guaranteed Cars",
        guaranteedDescription: "Rent confidently knowing your car will be ready when you are.",
        keyTitle: "Keyless",
        keyDescription: "Handing off keys can be bothersome, which is why we offer Keyless entry for all our cars.",
        safeTitle: "Safe & Secure",
        safeDescription: "You're in full control of availability, pricing, and how you interact with users.",
        verifiedTitle: "Verified",
        verifiedDescription: "We verify all users and cars before giving them access."
    },

    carShare: {
        title: "Share your car and earn.",
        description: "Connect your vehicle to earn and share with others or to receive services like gas, car washes, deliveries, and more!",
        monthly: "Monthly",
        hourly: "Hourly",
        daily: "Daily",
    },


    /////Spaces Landing Page////
    spaces: {
        rightTitle: "Book unique places to stay or work",
        rightWhat: "What kind of space do you need?",
        rightOptions: ["stay", "work"],
        rightLength: "How long do you need the space?",
        rightTime: ['hourly', 'daily', 'monthly'],
        rightWhere: "Where?",
        rightWhen: "How long?",

        leftTitle: "Reserve homes, offices, and desks nearby",
        leftDescription: "Rent a house, a room, an office or a desk! Reserve spaces when you need it, make money when you don't, share your space today!",
        socialFacebook: "Continue with Facebook",
        socialGoogle: "Continue with Google",
    },

    spaceBenefits: {
        guaranteedTitle: "Guaranteed Reservation",
        guaranteedDescription: "Reserve confidently knowing your seat, desk, or home will be available upon your arrival.",
        verifiedTitle: "Verified",
        verifiedDescription: "All of our spaces have been verified and approved. Arrive knowing everything till be taken care of.",
        communityTitle: "Community",
        communityDescription: "A community that works together to give you access to cars, parking and spaces.",
        supportTitle: "Support",
        supportDescription: "We understand that things happen sometimes you need help, which is hy we offer 24/7 support."
    },

    spaceShare: {
        title: "Have an extra space? We have someone for it.",
        description: "List your home or room to welcome travelers. List your cafe or restaurant as a coworking space to extend your day time revenue!",
    },

    ///////DASHBOARD////

    dashboard:{
        earnings: "Check Earnings",
        overview: "Overview",
        locate: "Locate Me",
        address: "Address",
        city: "City",
        state: "State",
        zip: "Zip Code",
        event: "Events",
        availability: "Availability",
        price: "Price",
        yesNo: ["Yes", "No"],
        day:"Day",
        from:"From",
        to: "To",
        add: "Add",
        remove:"Remove",
        back: "Back",
        new: "Add New Listing",

        available:"Is this available?",
        lot: "Lot Name",
        type: "Lot Type",
        typeCar: "What kind of space is your car parked in?",
        homeListing: "Home Listing Title",
        typeQ: "Is this a Home or a Business?",
        homeBusiness: ["Home", "Business"],
        guestQ: "What guests Have?",
        entirePrivate: ["Entire Place", "Private Room","Desk/Office"],

        ////Amenities////
        amenities: "What amenities do you offer?",

        spaceTitle: "Space Amenities",
        space: "Space",
        ac: "A/C",
        heat: "Heat",
        wifi: "WiFi",
        desk: "Desk/workspace",
        washer: "Washer & Dryer",

        kitchen: "Kitchen",
        fridge: "Fridge",
        gas: "Gas Stove",
        electric: "Electric Stove",
        pots: "Pots & Pans",
        dish: "Dishwasher",

        bathroom: "Bathroom",
        shampoo: "Shampoo",
        towels: "Towels",
        hair: "Hair Dryer",
        iron: "Iron",

        safety: "Safety Items",
        fire: "Fire Extinguisher",
        co: "CO & Smoke Detector",
        first: "First Aid Kit",

        covered: "Covered Space",
        ev: "EV Charging",
        gated: "Gated",
        guarded: "Guarded",
        lit: "Lit",

        parkingWhat: "What can you fit ?",
        compact: "Compact",
        mid: "Mid-sized Sedan",
        large: "Large",
        over: "Oversized (Truck or RV)",

        collect: "We will collect these for you.",
        registration: "Registration",
        insurance: "Insurance",
        license: "License / ID",

        towing: "Would you like an assigned towing company ?",
        yes: "Yes",
        no: "No",
        own: "I have my own",

        packages: "Want to get paid to receive packages here ?",
        packagePrice: "How many months deposit should we collect?",
        receiveTitle: "Collect Deposit?",
        receivePrice: "Months Deposit ($)",

        continue: "Continue",

        how: "How do guests get to your space ?",

        typeCharge: "Type",
        onOff: "Off/On",


        daily: "Daily",
        hourly: "Hourly",
        monthly: "Monthly",

        change: "Change image",
    },
    ////////////LISTING FLOW/////

    listing:{
        title: "List your property",
        what: "What would you like to list?",
        general: "General Parking",
        event: "Event Parking",
        //car: "Car (car-share)",
        stay: "Stay (Home/Room/Condo)",
        coWorking: "Coworking Space (Office/Desk/Cafe)",
        photo: "Add Photos",
        photoInfo: "Please upload pictures of your parking space.",
    },
    /////////////////

    general:{
        find: "Let's find your lot",
        locate: "Locate Me",
        save: "Save and Proceed",
        verify: "Verify...",
        lot: "Locate lot",
        details: "Listing details",
        events: "Pick Events",
        photos: "Add Photos",
        schedule: "Schedule & Price",
        pay: "Payout",
        payInfo: "Payout Information",
        type: "What type of building is this?",
        type1: ["Residential", "Business"],
        type2: "residential",
        type3: "Residential",
        type4: "Driveway",
        multi: "Is this a muli-unit building?",
        multi1: ["Yes", "No"],
        information: "Space Information",
        kind: "What kind of parking space do you have ?",
        kind1: [{name:"Driveway"}, {name:"Garage"},{name:"Blocked Driveway"},{name: "Parking Garage"},{name:"Lot"}],
        many: "How many parking spaces do you have to rent out ?",
        space: "Space Details & Requirements",

        parkingCosts: "Parking Costs",
        availability: "Parking Availability",
        toggle: "Toggle Monthly for 24/7 availability",

        paymentSignup: "Please sign up below to receive payouts.",
        existing: "Use Existing Account",
        update: "Update Current Account",
        stripe: "Continue with Stripe",
        questions: "Have Questions?",
        later: "Add payout information later",
        check: "Check your earnings",
        loading: "Loading...",
        status: "Checking your status...",
        uploading:"Uploading your parking lot...",
        success: "Your parking lot has successfully been uploaded",

        typeSchedule: "Type",
        price: "$ Price",
        oversize: "$ Oversize",
        onOff: " Off/On",

    },

    ///////Car Listing/////

    carListing: {
        continue: "Continue",

        carLocation: "Car Location",
        carDetails: "Car Details",
        schedule: "Schedule & Price",
        verifyCar: "Verify Car",
        pay: "Payout",

        where: "Where is your car parked?",
        locate: "Locate Pickup Spot",
        verify1: "Verifying...",
        address: "Address",
        save: "Save and Proceed",
        tell: "Tell us about your car",
        title: "Car Listing Title",
        transTitle: "Type of Transmission",
        transType: ["Automatic","Manual"],
        instant: "Instant or Request Rental?",
        option: ["Instant","Request"],

        spaceInfo: "Space Information",
        kind: "What kind of space is your car in?",
        many: "How many cars do you have?",

        photo: "Add Photos",
        photoDetails: "Please upload photos, we cannot list your vehicle without photos.",

        details: "Car Details",

        features:"Features",
        gps:"GPS",
        convertible: "Convertible",
        usb: "USB Input",
        bt: "Bluetooth",
        toll: "Toll Pass",

        carType: "Type of Car",
        compact: "Compact",
        mid: "Mid-sized Sedan",
        suv: "SUV",
        truck: "Truck",
        luxury: "Luxury",

        powered: "Car powered by",
        gas: "Gas Powered",
        ev: "Electric Vehicle",
        hybrid: "Hybrid",

        packages: "Want to get paid to receive packages in your trunk?",
        yes: "Yes",
        no: "No",

        how: "How do guest get to your car?",

        rentalCost: "Rental Costs & Mileage limits",
        rent: "Rental Cost",
        and: "&",
        miles: "Mileage Limits",

        type: "Type",
        price: "Price",
        mileage: "Mileage",
        onOff: "Off/On",

        package: "Receive Packages",
        packageDetails: "We will pay you to secure packages in your trunk",
        packageButton: "Receive a package $",

        availability: "Car Availability",
        day: "Day",
        from: "From",
        to: "To",
        add: "Add",

        verify: "Verify Your car",
        please: "Please connect your vehicle using Smartcar",
        altPlease: "Add a vehicle for your guest",
        connect: "Connect Vehicle",

        priceMile: "Price/Miles",
        access: "Car Access",
    

    },

    spaceListing: {
        continue: "Continue",

        location: "Location",
        details: "Details",
        schedule: "Schedule & Cost",
        pay: "Payout",
        maps: "Show Map",

        where: "Where is your space?",
        locate: "Locate Space",
        address: "Address",
        save: "Save and Proceed",

        title: "What kind of space do you have?",
        listing: "Listing Title",
        homeBusiness: "Is this a home or business",
        header: ["Home","Business"],
        guest: "Guest access?",
        guestOptions: ["Entire Place","Private Room","Office/Desk"],

        info: "Space Information",
        tell: "Tell us about your space",
        rooms: "How many rooms / desks?",
        people: "How many people can you host?",
        bathroom: "How many bathrooms?",
        what: "What does the space have?",

        secureTitle: "Secure Deliveries",
        secure: "Can this location accept package deliveries for a fee?",

        depositTitle: "Deposit",
        deposit: "Please insert the deposit amount we should collect.",
        depositAmount: " Deposit",

        how: "How do guests enter?",
        photoDetails: "We cannot list your space without photos",
        rates: "Rates",

        availability: "Availability",
        // secure: "Secure Deliveries",
        packages: "How much would you charge for each secure Amazon package deliveries?",
        pricePackage: "Price / Package",

        uploading: "Uploading your space...",
        success: "Your space has successfully been listed",
    },

    reservation: {
        your: "Your Reservations",
        hosting: "You are Hosting",
        header: ["Host Name","Contact","Price","Location","Date"],
        profile: "Profile",
        reservation: "Reservations",
        calendar: "Calendar",
    },

    profile: {
        account: "Account info",
        host: "Host Settings",
        vehicle: "Vehicle Info",
        documents: "Documents",
        payment: "Payment Methods",
    },



}
import cloneDeep from 'lodash/cloneDeep'
const langEn = cloneDeep(_langEn)
langEn.default = _langEn
export default langEn
export const defaultLang = _langEn