<script>
import { reactive } from "@vue/composition-api";

import { API } from "src/views/reservations/api";

export default {
  props: {
    form: Object,
    expand: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {

    const loading = reactive({
      approve: false,
      deny: false
    })

    const approveReservation = async () => {
      try {
        loading.approve = true;
        await API.approveReservation(props.form.id);
        loading.approve = false;
        props.form.status = "complete";
        ctx.emit("refresh");
      } catch (error) {
        loading.approve = false;
        ctx.root.$openNotification(
          "Reservation Action",
          error.message,
          10,
          "error"
        );
      }
    };
    const denyReservation = async () => {
      try {
        loading.deny = true;
        await API.denyReservation(props.form.id, props.form.deny_message);
        loading.deny = false;
        props.form.status = "denied";
        ctx.emit("refresh");
      } catch (error) {
        loading.deny = false;
        ctx.root.$openNotification(
          "Reservation Action",
          error.message,
          10,
          "error"
        );
      }
    };
    return {
      loading,
      approveReservation,
      denyReservation
    };
  }
};
</script>

<template>
  <div class="flex justify-between">
    <a-tag approve color="green" @click.stop="approveReservation(form)">
      <span v-if="expand" class="px-1.5">Approve</span>
      <a-icon v-if="loading.approve" type="loading" />
      <svg-icon v-else icon-class="check-var-1" class="w-4 h-4 text-green-400" />
    </a-tag>
    <a-popconfirm ok-text="Yes" cancel-text="No" @confirm="denyReservation(form)" @cancel="() => { }">
      <template #title>
        <span>Are you sure to deny this request?</span>
        <div class="flex py-2">
          <a-textarea v-model="form.deny_message" placeholder="Enter reason of denial to this tenant"
            :auto-size="{ minRows: 3, maxRows: 3 }" />
        </div>
      </template>
      <a-tag deny color="pink">
        <span v-if="expand" class="px-1.5">Deny</span>
        <a-icon v-if="loading.deny" type="loading" />
        <svg-icon v-else icon-class="close" class="w-4 h-4 text-pink-500" />
      </a-tag>
    </a-popconfirm>
  </div>
</template>

<style lang="scss">
.ant-tag[approve] {
  &:hover {
    span {
      @apply text-green-500 font-semibold;
    }

    .svg-icon {
      @apply text-green-500 transform scale-110;
    }

    @apply bg-green-100 border-green-400;
  }

  @apply flex items-center justify-center p-1 cursor-pointer;
}

.ant-tag[deny] {
  &:hover {
    span {
      @apply text-pink-500 font-semibold;
    }

    .svg-icon {
      @apply text-pink-500 transform scale-110;
    }

    @apply bg-pink-100 border-pink-400;
  }

  @apply flex items-center justify-center p-1 cursor-pointer;
}
</style>
