<script>
import { reactive, computed } from '@vue/composition-api';

export default {
    props: {
        userId: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const state = reactive({
            pattern: null
        });

        const coverStyle = computed(() => ({
            width: "300px",
            height: "150px",
            background: state.pattern && state.pattern.toDataUrl()
        }));

        const generatePattern = () => {
            var GeoPattern = require('geopattern');
            state.pattern = GeoPattern.generate(props.userId);
        };

        generatePattern();

        return {
            state,
            coverStyle
        };
    }
};
</script>
<template>
    <a-card :bordered="false" hoverable @click="$emit('click')">
        <div slot="cover" :style="coverStyle" />
        <div class="relative flex flex-col space-y-2 items-center justify-center" style="min-height: 80px;">
            <div class="absolute left-1/2 transform -translate-x-1/2" style="top: -64px;">
                <slot name="avatar" />
            </div>
            <slot name="info" />
        </div>
        <template #actions>
            <slot name="actions" />
        </template>
    </a-card>
</template>