import VueRouter from "vue-router";
import routes from "./routes";
import store from "src/state/dashboard";
import { metaInfoGenerator, metaInfoRouterInjector } from "../../plugins/meta-tags.js"
import { loadUserData, toggleOptionalExtra, getOptionalExtra, isLoggedIn, isAdmin } from "src/plugins/prototype";
import UrlPattern from "url-pattern";
import { notification } from "ant-design-vue";
import { detectIncognito } from "detectincognitojs";

const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active",
  mode: "history",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

// // Check previous route before navigating to login, ONLY restore routes with requiresAuth = true
// const checkLoginPreviousRoutes = ({ to, from }) => {
//   let previousRoute;
//   if (to.path === "/login" && from.meta.requiresAuth) {
//     previousRoute = from.fullPath;
//   }
//   if (to.path === "/login/tenant" && from.meta.requiresAuth) {
//     previousRoute = from.fullPath;
//   }
//   console.log(`checkLoginPreviousRoutes -> from: ${from.path}, to: ${to.path}`);
//   console.log(from);
//   console.log(to);
//   console.log(`checkLoginPreviousRoutes -> previousRoute: `, previousRoute);
//   return previousRoute;
// }

// All requiresFullProfile routes requires full required host profile information
const checkShouldRedirectToFullfillProfile = (to, from, next) => {
  if (to.meta.requiresFullProfile) {
    try {
      const { extra, legacy } = loadUserData();
      if (extra && extra.filter(v => !v.startsWith("optional:")).length > 0) {
        return next({
          name: "fullfill-profile"
        })
      }
      if (extra && extra.filter(v => v.startsWith("optional:")).length > 0 && !getOptionalExtra()) {
        toggleOptionalExtra(true)
        return next({
          name: "fullfill-profile"
        })
      }
    } catch (error) {
      console.log("checkShouldRedirectToFullfillProfile: error", error)
    }
  }
}

const mobileViewRoutes = [
  "/",
  "/pricing", // this path includes: /pricing#demo
  "/white-label",
  "/sign-up",
  "/sign-up/tenant",
  "/login",
  "/org/login",
  "/login/:id",
  "/tenant/home",
  "/tenant/documents",
  "/tenant/documents/action/identity-verification",
  "/tenant/documents/action/upload",
  "/tenant/documents/action/sign",
  "/tenant/reservations",
  "/tenant/reservations/:id",
  "/tenant/payments",
  "/tenant/settings",
  "/attendant/login",
  "/attendant/reservations",
  "/password-reset/:token",
  "/towing",
  "/next/:next",
  "/desktop-only"
]

router.beforeEach(async (to, from, next) => {

  if (to.name === "incognito") {
    next();
    return;
  }

  const { browserName, isPrivate } = await detectIncognito();
  if (isPrivate) {
    next({
      name: "incognito",
      params: { browserName }
    });
    return;
  } else {
    console.log('Incognito mode is NOT activated')
  }

  // await verifyDashboardToken({ to, next });

  // Prevent admin user from accessing tenant dashboard: because admin user will never become tenant of anyone.
  if (isAdmin()) {
    if (to.path.startsWith("/tenant")) {
      notification.warning({
        message: "Admin Dashboard",
        description: "Admin user might only access dashboard: you will never become tenant of anyone.",
        duration: 0
      });
      next("/dashboard");
      return;
    }
    if (to.path.startsWith("/listing")) {
      notification.warning({
        message: "WIP",
        description: "Admin listing is a work in progress, come back later.",
        duration: 0
      });
      next("/dashboard");
      return;
    }
  }

  // Cancel all ongoing Axios requests if route is changing, won't cancel if only route params / query change.
  if (to.name !== from.name) {
    store.dispatch('resetCancelToken');
  }

  if (to.name === "page-not-found") {
    to.meta.title = "Parqay Dashboard";
    to.meta.metaTags = [
      { name: "robots", content: "noindex" }
    ];
  } else {
    const { title, meta } = metaInfoGenerator(to.path)
    to.meta.title = title;
    to.meta.metaTags = meta;
  }
  console.log("metaInfoGenerator -> ", to)
  metaInfoRouterInjector(to, from, next)

  checkShouldRedirectToFullfillProfile(to, from, next)

  // const previousRoute = checkLoginPreviousRoutes({ to, from })
  // if (previousRoute) {
  //   to.query.next = previousRoute;
  // }
  
  if (window.innerWidth >= 1024) {
    if (to.meta.requiresAuth && !isLoggedIn()) {
      if (to.path.startsWith("/tenant")) {
        next(`/login/tenant?next=${to.fullPath}`);
      } else {
        next(`/login?next=${to.fullPath}`);
      }
    } else {
      next();
    }
    return
  } else {
    console.log("to.path -> ", to.path);
    let isMobileRoute = false;
    for (let r of mobileViewRoutes) {
      const pattern = new UrlPattern(r);
      const result = pattern.match(to.path);
      console.log("match result -> ", result);
      if (result) {
        isMobileRoute = true;
      }
    }
    if (isMobileRoute || to.name === "page-not-found") {
      if (to.meta.requiresAuth && !isLoggedIn()) {
        next("/login");
      } else {
        next();
      }
      return
    } else {
      console.log("beforeEach -> ", to)
      next("/desktop-only");
      return
    }
  }
});

export default router;
