var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col justify-center lg:px-6"},[_c('div',{staticClass:"flex justify-between items-center py-1 mb-2"},[_c('div',{staticClass:"flex space-x-2 items-center"},[_vm._m(0),_c('div',[(_vm.vehicle.image)?_c('div',{staticClass:"\n            h-auto\n            w-36\n            bg-gray-200\n            rounded-xl\n            overflow-hidden\n            hover:overflow-auto\n            hover:scale-150\n            transform\n          "},[_c('img',{staticClass:"h-auto w-auto object-contain text-center",attrs:{"src":_vm.vehicle.image}})]):_vm._e()])])]),_c('div',{staticClass:"grid grid-cols-2 gap-2 border-t pt-2"},[_c('dropdown',{attrs:{"input-mode":"","input-mode-props":{
        model: _vm.vehicle.make,
        placeholder: 'Car Brand',
        dataSource: _vm.vehicleMakesSearchResults,
        loading: _vm.showMakeLoading,
        disabled: _vm.fieldsStates.make == 'disabled',
        onSearch: function onSearch(searchText) {
          _vm.showMakeLoading = true;
          _vm.console.log('vehicleMakes', _vm.vehicleMakes);
          _vm.vehicleMakesSearchResults = !searchText
            ? []
            : _vm.vehicleMakes
              .filter(function (make) { return make.text.toLowerCase().includes(searchText.toLowerCase()); }
              )
              .slice(0, 20);
        },
        onSelect: function (value) {
          _vm.console.log('onSelect', value);
          var selectedMake = _vm.vehicleMakes.filter(
            function (m) { return m.value === value; }
          )[0];
          _vm.console.log('selectedMake', selectedMake);
          _vm.vehicle.make = selectedMake.text;
          _vm.vehicle.make_id = selectedMake.value;
          _vm.showMakeLoading = false;
          _vm.showModelLoading = true;
          _vm.listCarModels(selectedMake.value);
        },
        onChange: function onChange(value) {
          _vm.console.log('onChange', value);
          _vm.vehicle.make = value;
          _vm.showMakeLoading = true;
        },
      },"width":'full'},on:{"update-input-mode-model":function (m) {
          _vm.console.log('update-input-mode-model', m);
          _vm.vehicle.make = m.text;
          _vm.vehicle.make_id = m.value;
          _vm.showMakeLoading = false;
          _vm.showModelLoading = true;
          _vm.listCarModels(m.value);
        }}}),_c('dropdown',{attrs:{"input-mode":"","input-mode-props":{
        model: _vm.vehicle.model,
        placeholder: 'Car Model',
        dataSource: _vm.vehicleModelsSearchResults,
        loading: _vm.showModelLoading,
        disabled: _vm.fieldsStates.model == 'disabled',
        onSearch: function onSearch(searchText) {
          if (searchText) {
            _vm.vehicleModelsSearchResults = _vm.vehicleModels.filter(function (model) { return model.text.toLowerCase().includes(searchText.toLowerCase()); }
            );
          } else {
            _vm.vehicleModelsSearchResults = _vm.vehicleModels;
          }
        },
        onSelect: function (value) {
          _vm.console.log('onSelect', value);
          var selectedModel = _vm.vehicleModels.filter(
            function (m) { return m.value === value; }
          )[0];
          _vm.console.log('selectedModel', selectedModel);
          _vm.vehicle.model = selectedModel.text;
          _vm.vehicle.model_id = selectedModel.value;
          _vm.getCarImage();
        },
        onChange: function onChange(value) {
          _vm.console.log('onChange', value);
          _vm.vehicle.model = value;
        },
      },"width":'full'},on:{"update-input-mode-model":function (m) {
          _vm.console.log('update-input-mode-model', m);
          _vm.vehicle.model = m.text;
          _vm.vehicle.model_id = m.value;
          _vm.getCarImage();
        }}}),_c('a-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"size":"large","placeholder":"Plate Number","error":_vm.errors.first('Plate number'),"disabled":_vm.fieldsStates.plate_number == 'disabled'},on:{"change":function (e) {
          var val = e.target.value;
          if (e.target.value) {
            _vm.vehicle.plate_number = val.toUpperCase();
          }
          _vm.console.log('plate number', _vm.vehicle.plate_number);
        }},model:{value:(_vm.vehicle.plate_number),callback:function ($$v) {_vm.$set(_vm.vehicle, "plate_number", $$v)},expression:"vehicle.plate_number"}}),_c('a-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"size":"large","placeholder":"Year","error":_vm.errors.first('Year'),"disabled":_vm.fieldsStates.year == 'disabled'},model:{value:(_vm.vehicle.year),callback:function ($$v) {_vm.$set(_vm.vehicle, "year", $$v)},expression:"vehicle.year"}}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.showColorPicker = false); }),expression:"() => (showColorPicker = false)"}],staticClass:"\n        flex\n        py-1\n        items-center\n        justify-between\n        px-2\n        rounded-full\n        border\n        relative\n        z-30\n      ",class:_vm.fieldsStates.color == 'disabled'
          ? 'opacity-50 pointer-events-none'
          : ''},[_c('span',{},[_vm._v("Choose Color")]),(_vm.vehicle.color)?_c('div',{staticClass:"w-7 h-7 rounded-full cursor-pointer hover:opacity-50",style:({ backgroundColor: _vm.pickedColor.hex }),on:{"click":function () { return (_vm.showColorPicker = !_vm.showColorPicker); }}}):_c('div',{staticClass:"w-7 h-7 rounded-full cursor-pointer hover:opacity-50 checkered",on:{"click":function () { return (_vm.showColorPicker = !_vm.showColorPicker); }}}),(_vm.showColorPicker)?_c('swatches-picker',{staticClass:"absolute w-24 h-12 left-0 right-0 top-9",on:{"input":function () {
            _vm.vehicle.color = _vm.pickedColor.hex;
            _vm.showColorPicker = false;
          }},model:{value:(_vm.pickedColor),callback:function ($$v) {_vm.pickedColor=$$v},expression:"pickedColor"}}):_vm._e()],1)],1),_vm._t("footer")],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('h2',{staticClass:"text-lg font-bold"},[_vm._v("\n          Vehicle Info\n        ")]),_c('span',[_vm._v("Make sure the infomation below are accurate and complete")])])}]

export { render, staticRenderFns }