const env = {
  api: "production",
  socket: "production"
  // socket: "local"
}

const Configs = {
  // Disable socket in development mode to avoid memory leak
  ENABLE_SOCKET: true,
  HELLO_SIGN_TEST_MODE: true
};

const Tokens = {
  STRIPE_CONNECT_CLIENT_ID: process.env.VUE_APP_STRIPE_CONNECT_CLIENT_ID, //test key
  GOOGLE_MAPS_API_KEY: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  INTERCOM_APP_ID: process.env.VUE_APP_INTERCOM_APP_ID, //Test app id
  SMARTCAR_CLIENT_ID: "cad331f9-d35e-4c81-b2ae-dbfdae1d025d",
  DROPBOX_ACCESS_TOKEN:
    "0B9fuMg9ZigAAAAAAAAAAQqJd1frR4EZvaCAoOEGH8snyS0LOLLS1wa7GLNldpJ4",
  HELLO_SIGN_CLIENT_ID: process.env.VUE_APP_HELLO_SIGN_CLIENT_ID,
  S3_BUCKET: {
    bucketName: "parqay-dashboard",
    bucketRegion: "us-west-2",
    identityPoolId: "us-west-2:990e5136-d4f7-4172-bece-621ca55283c2",
    accessKeyId: "AKIAT2ETXGUAL7YT6IAF",
    secretAccessKey: "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"
  }
};

const Urls = {
  API_BASE_URL: () => {
    if (env.api == "local") {
      return "http://" + require("os").hostname() + ":5599" + "/api/"; //Testing backend server port: 5599
    }
    return process.env.VUE_APP_BACKEND_BASE_URL + "/api/";
  },
  SOCKET_URL: () => {
    // if (env.socket == "local") {
    //   return "http://" + require("os").hostname() + ":5588" + "/socket"; //Testing socket server port: 5588
    // }
    // return process.env.VUE_APP_WS_BASE_URL + "/socket";
    if (env.socket == "local") {
      return "http://" + require("os").hostname() + ":5599" + "/socket"; //Testing backend server port: 5599
    }
    return process.env.VUE_APP_BACKEND_BASE_URL + "/socket";
  }
};

const StorageKeys = {
  ACCESS_TOKEN_KEY: "access_token",

  REFRESH_TOKEN_KEY: "refresh_token",

  ANONYMOUS_UID: "anonymous_uid"
};

export { Configs, Tokens, Urls, StorageKeys };

export const DATE_FORMAT = "YYYY-MM-DDTHH:mm";
export const STRIPE_KEY = process.env.VUE_APP_STRIPE_KEY;
export const STRIPE_ENV = process.env.VUE_APP_ENV === "production" ? "live" : "test";