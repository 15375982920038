<script>
import { reactive, ref, watch, onMounted } from "@vue/composition-api";
import ClickOutside from "vue-click-outside";
import debounce from "lodash/debounce";

import { API } from "src/views/listings/api";

export default {
  directives: {
    ClickOutside
  },
  props: {
    type: {
      type: String,
      default: null
    }
  },
  setup(props, ctx) {
    const loading = ref(false);
    const keyword = ref('');
    const listings = ref([]);
    const selectedListing = ref(null);
    const showDropdown = ref(false);

    const handleInput = debounce(async (event) => {
      try {
        loading.value = true;
        if (event) {
          keyword.value = event.target.value;
        }
        const { data } = await API.searchListingsForReservations({ listing_type: props.type, keyword: keyword.value });
        listings.value = data;
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    }, 500, { trailing: true });

    const handleFocus = () => {
      showDropdown.value = true;
      ctx.emit("error", "A host MUST be selected.");
    };

    const handleBlur = () => {
      if (showDropdown.value) {
        showDropdown.value = false;
      }
    }

    onMounted(() => {
      handleInput();
      watch(selectedListing, (newValue) => {
        if (newValue) {
          ctx.emit("select", newValue.id);
          ctx.emit("error", null);
          keyword.value = "";
        } else {
          ctx.emit("select", null);
          ctx.emit("error", "A listing MUST be selected.");
        }
      })
    })

    return {
      loading,
      keyword,
      listings,
      selectedListing,
      showDropdown,
      handleInput,
      handleFocus,
      handleBlur
    };
  }
};
</script>
<template>
  <div class="w-full flex flex-col space-y-2 relative" v-click-outside="handleBlur">
    <div v-if="selectedListing" class="flex items-center space-x-4">
      <a-tag color="cyan">
        {{
          selectedListing.type || ""
        }}
      </a-tag>
      <a-tag listing>
        <span class="text-black font-medium">
          {{
            selectedListing.name || ""
          }}
        </span>
        <div @click.stop="() => selectedListing = null">
          <svg-icon icon-class="close" class="w-4 h-4 text-gray-400 hover:text-black" />
        </div>
      </a-tag>
    </div>
    <template v-else>
      <a-input-search v-model="keyword" :loading="loading" @input="handleInput" @focus="handleFocus"
        placeholder="Search listing" size="large" allow-clear />
      <ul v-if="showDropdown"
        class="absolute z-30 top-full left-0 w-full bg-white rounded shadow-md border-l border-b border-r">
        <li v-if="listings.length == 0"
          class="flex items-center justify-center px-4 py-3 border-t hover:bg-gray-100 cursor-pointer">
          No Result.
        </li>
        <li v-for="(listing, i) in listings" :key="`reservation-listing-result-${i}`"
          class="flex items-center justify-between px-4 py-3 border-t hover:bg-gray-100 cursor-pointer"
          @click.stop="() => selectedListing = listing">
          <span class="text-black font-medium">
            {{
              listing.name || ""
            }}
          </span>
          <div class="flex items-center justify-end">
            <a-tag v-if="listing.is_active" color="green">Active</a-tag>
            <a-tag v-else color="pink">Inactive</a-tag>
            <a-tag color="cyan">
              {{
                listing.type || ""
              }}
            </a-tag>
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.ant-tag[listing] {
  span {
    @apply pr-4;
  }

  @apply inline-flex items-center justify-center text-sm font-medium px-4 py-1.5 cursor-pointer border-black bg-white;
}
</style>