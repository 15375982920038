<script>
import { API } from "src/views/components/api";
import Dropdown from "src/views/dropdowns/View.vue";
import { onMounted, reactive, ref, watch } from "@vue/composition-api";
import { Swatches } from "vue-color";
import ClickOutside from "vue-click-outside";

import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import debounce from 'lodash/debounce';

export default {
  components: {
    Dropdown,
    "swatches-picker": Swatches,
  },
  directives: {
    ClickOutside,
  },
  props: {
    loading: Boolean,
    vehicle: {
      type: Object,
      require: true,
    },
    manualMode: {
      type: Boolean,
      default: true,
    }
  },
  setup(props, ctx) {
    var vehicleMakes = ref([]);
    const vehicleMakesSearchResults = ref([]);
    const vehicleModels = ref([]);
    const vehicleModelsSearchResults = ref([]);
    const showMakeLoading = ref(false);
    const showModelLoading = ref(false);
    const showColorPicker = ref(false);
    const pickedColor = reactive({ hex: "#4f46e5" });
    const fieldsStates = reactive({
      make: "disabled",
      model: "disabled",
      color: "disabled",
      plate_number: "disabled",
      year: "disabled",
    });

    watch(
      () => props.manualMode,
      (val) => {
        toggleFieldsState(val);
      }
    );

    const toggleFieldsState = (manualModeEnabled) => {
      if (manualModeEnabled) {
        fieldsStates.make = "enabled";
        fieldsStates.model = "enabled";
        fieldsStates.color = "enabled";
        fieldsStates.plate_number = "enabled";
        fieldsStates.year = "enabled";
      } else {
        fieldsStates.make = "disabled";
        fieldsStates.model = "disabled";
        fieldsStates.color = "disabled";
        fieldsStates.plate_number = "disabled";
        fieldsStates.year = "disabled";
      }
      if (props.vehicle.smartcar) {
        fieldsStates.make = "disabled";
        fieldsStates.model = "disabled";
      }
    };
    toggleFieldsState(props.manualMode);
    // watch(
    //   () => props.vehicle,
    //   (val) => {
    //     console.log("props.vehicle", val)
    //     if (val.make && val.model) {
    //       getCarImage().then(() => {})
    //     }
    //   },
    //   {deep: true}
    // );
    // watch(
    //   () => props.vehicle.color,
    //   (val) => {
    //     getCarImage();
    //   }
    // );
    const getSmartCarInfo = async () => {
      var res;
      try {
        res = await API.getSmartCarInfo();
      } catch (error) {
        console.log("getSmartCarInfo => error", error);
      }
      console.log("getSmartCarInfo", res);
    };
    const listCarMakes = async () => {
      fieldsStates.make = "disabled";
      showMakeLoading.value = true;
      var res;
      try {
        res = await API.listCarMakes();
      } catch (error) {
        console.log("listCarMakes => error", error);
      }
      console.log("listCarMakes", res);
      vehicleMakes.value = [];
      res.data.Results.forEach((make) => {
        // vehicleMakes.ids.push(make.MakeID);
        vehicleMakes.value.push({
          value: `${make.Make_ID}`,
          text: startCase(toLower(make.Make_Name)),
        });
      });
      if (props.manualMode) {
        fieldsStates.make = "enabled";
      }
      showMakeLoading.value = false;
    };
    const _listCarModels = async (makeID) => {
      props.vehicle.model = null;
      var res;
      try {
        res = await API.listCarModels(makeID);
      } catch (error) {
        console.log("listCarModels => error", error);
      }
      console.log("listCarModels", res);
      vehicleModels.value = [];
      res.data.Results.forEach((model) => {
        vehicleModels.value.push({
          value: `${model.Model_ID}`,
          text: model.Model_Name,
        });
      });
      vehicleModelsSearchResults.value = vehicleModels.value;
      showModelLoading.value = false;
    };
    const listCarModels = debounce(_listCarModels, 300);
    const getCarImage = debounce(async () => {
      if (!props.vehicle.make || !props.vehicle.model) {
        return;
      }
      var res;
      props.vehicle.image = null;
      try {
        res = await API.getCarImage(
          props.vehicle.make,
          props.vehicle.model,
          props.vehicle.color
        );
      } catch (error) {
        console.log("getCarImage => error", error);
      }
      console.log("getCarImage", res);
      if (res.data.length > 0) {
        props.vehicle.image = res.data[0];
      }
    }, 1000);
    const selectMakeAndModel = async (make, model) => {
      toggleFieldsState(true);
      const selectedMake = vehicleMakes.value.filter(
        (m) => m.text === startCase(toLower(make))
      )[0];
      console.log("selectedMake", selectedMake);
      props.vehicle.make = selectedMake.text;
      props.vehicle.make_id = selectedMake.value;
      showMakeLoading.value = false;
      showModelLoading.value = true;
      await _listCarModels(selectedMake.value);
      const selectedModel = vehicleModels.value.filter(
        (m) => m.text === model
      )[0];
      console.log("selectedModel", selectedModel);
      props.vehicle.model = selectedModel.text;
      props.vehicle.model_id = selectedModel.value;
      getCarImage();
    };
    onMounted(() => {
      // getSmartCarInfo();
      listCarMakes();
    });
    return {
      vehicleMakes,
      vehicleMakesSearchResults,
      vehicleModels,
      vehicleModelsSearchResults,
      showMakeLoading,
      showModelLoading,
      showColorPicker,
      pickedColor,
      listCarModels,
      fieldsStates,
      getCarImage,
      selectMakeAndModel,
      toggleFieldsState,
      console,
    };
  },
};
</script>
<template>
  <div class="flex flex-col justify-center lg:px-6">
    <div class="flex justify-between items-center py-1 mb-2">
      <div class="flex space-x-2 items-center">
        <div class="">
          <h2 class="text-lg font-bold">
            Vehicle Info
          </h2>
          <span>Make sure the infomation below are accurate and complete</span>
        </div>
        <div>
          <div
            v-if="vehicle.image"
            class="
              h-auto
              w-36
              bg-gray-200
              rounded-xl
              overflow-hidden
              hover:overflow-auto
              hover:scale-150
              transform
            "
          >
            <img
              class="h-auto w-auto object-contain text-center"
              :src="vehicle.image"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-2 border-t pt-2">
      <dropdown
        input-mode
        :input-mode-props="{
          model: vehicle.make,
          placeholder: 'Car Brand',
          dataSource: vehicleMakesSearchResults,
          loading: showMakeLoading,
          disabled: fieldsStates.make == 'disabled',
          onSearch(searchText) {
            showMakeLoading = true;
            console.log('vehicleMakes', vehicleMakes);
            vehicleMakesSearchResults = !searchText
              ? []
              : vehicleMakes
                .filter((make) =>
                  make.text.toLowerCase().includes(searchText.toLowerCase())
                )
                .slice(0, 20);
          },
          onSelect: (value) => {
            console.log('onSelect', value);
            const selectedMake = vehicleMakes.filter(
              (m) => m.value === value
            )[0];
            console.log('selectedMake', selectedMake);
            vehicle.make = selectedMake.text;
            vehicle.make_id = selectedMake.value;
            showMakeLoading = false;
            showModelLoading = true;
            listCarModels(selectedMake.value);
          },
          onChange(value) {
            console.log('onChange', value);
            vehicle.make = value;
            showMakeLoading = true;
          },
        }"
        :width="'full'"
        @update-input-mode-model="
          (m) => {
            console.log('update-input-mode-model', m);
            vehicle.make = m.text;
            vehicle.make_id = m.value;
            showMakeLoading = false;
            showModelLoading = true;
            listCarModels(m.value);
          }
        "
      />
      <dropdown
        input-mode
        :input-mode-props="{
          model: vehicle.model,
          placeholder: 'Car Model',
          dataSource: vehicleModelsSearchResults,
          loading: showModelLoading,
          disabled: fieldsStates.model == 'disabled',
          onSearch(searchText) {
            if (searchText) {
              vehicleModelsSearchResults = vehicleModels.filter((model) =>
                model.text.toLowerCase().includes(searchText.toLowerCase())
              );
            } else {
              vehicleModelsSearchResults = vehicleModels;
            }
          },
          onSelect: (value) => {
            console.log('onSelect', value);
            const selectedModel = vehicleModels.filter(
              (m) => m.value === value
            )[0];
            console.log('selectedModel', selectedModel);
            vehicle.model = selectedModel.text;
            vehicle.model_id = selectedModel.value;
            getCarImage();
          },
          onChange(value) {
            console.log('onChange', value);
            vehicle.model = value;
          },
        }"
        :width="'full'"
        @update-input-mode-model="
          (m) => {
            console.log('update-input-mode-model', m);
            vehicle.model = m.text;
            vehicle.model_id = m.value;
            getCarImage();
          }
        "
      />
      <a-input
        v-model="vehicle.plate_number"
        v-validate="'required'"
        size="large"
        placeholder="Plate Number"
        :error="errors.first('Plate number')"
        :disabled="fieldsStates.plate_number == 'disabled'"
        @change="
          (e) => {
            const val = e.target.value;
            if (e.target.value) {
              vehicle.plate_number = val.toUpperCase();
            }
            console.log('plate number', vehicle.plate_number);
          }
        "
      />
      <a-input
        v-model="vehicle.year"
        v-validate="'required'"
        size="large"
        placeholder="Year"
        :error="errors.first('Year')"
        :disabled="fieldsStates.year == 'disabled'"
      />
      <div
        v-click-outside="() => (showColorPicker = false)"
        :class="
          fieldsStates.color == 'disabled'
            ? 'opacity-50 pointer-events-none'
            : ''
        "
        class="
          flex
          py-1
          items-center
          justify-between
          px-2
          rounded-full
          border
          relative
          z-30
        "
      >
        <span class="">Choose Color</span>
        <div
          v-if="vehicle.color"
          class="w-7 h-7 rounded-full cursor-pointer hover:opacity-50"
          :style="{ backgroundColor: pickedColor.hex }"
          @click="() => (showColorPicker = !showColorPicker)"
        />
        <div
          v-else
          class="w-7 h-7 rounded-full cursor-pointer hover:opacity-50 checkered"
          @click="() => (showColorPicker = !showColorPicker)"
        />
        <swatches-picker
          v-if="showColorPicker"
          v-model="pickedColor"
          class="absolute w-24 h-12 left-0 right-0 top-9"
          @input="
            () => {
              vehicle.color = pickedColor.hex;
              showColorPicker = false;
            }
          "
        />
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<style scoped>
.checkered {
  background: -webkit-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.0980392) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.0980392) 75%,
      rgba(0, 0, 0, 0.0980392) 0
    ),
    -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent
          75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0),
    white;
  background: -moz-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.0980392) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.0980392) 75%,
      rgba(0, 0, 0, 0.0980392) 0
    ),
    -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent
          75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0),
    white;
  background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.0980392) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.0980392) 75%,
      rgba(0, 0, 0, 0.0980392) 0
    ),
    linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.0980392) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.0980392) 75%,
      rgba(0, 0, 0, 0.0980392) 0
    ),
    white;
  background-repeat: repeat, repeat;
  background-position: 0px 0, 5px 5px;
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  -webkit-background-origin: padding-box, padding-box;
  background-origin: padding-box, padding-box;
  -webkit-background-clip: border-box, border-box;
  background-clip: border-box, border-box;
  -webkit-background-size: 10px 10px, 10px 10px;
  background-size: 10px 10px, 10px 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-transform: scaleX(1) scaleY(1) scaleZ(1);
  transform: scaleX(1) scaleY(1) scaleZ(1);
}
</style>