<script>
import {
  reactive,
  onMounted,
  onBeforeUnmount,
} from "@vue/composition-api";
import SlideBar from "src/views/components/components/SlideBar.vue";

import { API } from "../api";

export default {
  name: "tenant-drawer",
  components: {
    SlideBar
  },
  setup(props, ctx) {

    const controls = reactive({
      key: 0,
      visible: false,
      tenant: {
        name: null,
        email: null,
        phone_number: null,
        created_at: null,
        reservations_count: 0
      }
    });

    const close = () => {
      controls.visible = false;
    };

    const getTenant = async (tenant_id, on_behalf_of) => {
      try {
        const { data } = await API.getTenant(tenant_id, on_behalf_of);
        controls.tenant = data;
      } catch (error) {
        ctx.root.$openNotification(
          "Tenant Info",
          error.message,
          10,
          "info"
        );
      }
    }

    const handleOpenEvent = async ({ tenant_id, tenant, on_behalf_of }) => {
      controls.visible = true;
      controls.key += 1;
      if (tenant_id) {
        await getTenant(tenant_id, on_behalf_of);
      }
      if (tenant) {
        controls.tenant = tenant;
      }
    };
    const handleCloseEvent = () => {
      close();
    };

    onMounted(() => {
      ctx.root.$bus.$on("open-tenant-drawer", handleOpenEvent);
      ctx.root.$bus.$on("close-tenant-drawer", handleCloseEvent);
    });

    onBeforeUnmount(() => {
      ctx.root.$bus.$off("open-tenant-drawer", handleOpenEvent);
      ctx.root.$bus.$off("close-tenant-drawer", handleCloseEvent);
    });

    return {
      controls,
      close,
      handleCloseEvent
    };
  },
};
</script>
<template>
  <slide-bar v-slot:default="slotProps" v-model="controls.visible" :width="400" backdrop close-by-mask
    @close-slide-bar="handleCloseEvent()">
    <div class="flex justify-between items-center border-b px-6 py-6">
      <span class="font-bold text-lg text-gray-900 my-auto">
        Tenant Details
      </span>
      <span @click="slotProps.closeSlideBar">
        <svg-icon icon-class="close" class="cursor-pointer w-6 h-6 text-blue-900" />
      </span>
    </div>
    <div class="h-full flex-col justify-between px-6 py-4">
      <div :key="controls.key" class="px-2">
        <div class="flex flex-col space-y-3">
          <span class="text-base font-medium">Tenant Name</span>
          <a-input v-model="controls.tenant.name" />
          <span class="text-base font-medium">Contact</span>
          <div class="
                  text-center text-xs
                  font-medium
                  border
                  rounded
                  bg-gray-100
                  px-4
                  py-2
                  whitespace-nowrap
                ">
            Email: {{ controls.tenant.email || "N/A" }}
          </div>
          <div class="
                  text-center text-xs
                  font-medium
                  border
                  rounded
                  bg-gray-100
                  px-4
                  py-2
                  whitespace-nowrap
                ">
            Phone: {{ controls.tenant.phone_number || "N/A" }}
          </div>
          <span class="text-base font-medium">Joined Since</span>
          <div class="
                  text-center text-xs
                  font-medium
                  border
                  rounded
                  bg-gray-100
                  px-4
                  py-2
                  whitespace-nowrap
                ">
            {{ $formatDate(controls.tenant.created_at) }}
          </div>
          <span class="text-base font-medium">Reservations</span>
          <a-button v-if="!$isAdmin()" size="large" block @click="() => {
            $router.push('/dashboard/reservations#calendar');
            close();
          }">
            + Create New Reservation
          </a-button>
          <a-button type="primary" size="large" block :disabled="controls.tenant.reservations_count == 0" @click="() => {
            if ($route.name === 'Tenants') {
              $bus.$emit('tenant-drawer-expand-tenant', controls.tenant);
            } else {
              $router.push({
                name: 'Tenants',
                query: {
                  focus: $isAdmin() ? `${controls.tenant.id},${controls.tenant.host}` : controls.tenant.id
                }
              })
            }
            close();
          }">
            View Reservations ({{ controls.tenant.reservations_count }})
          </a-button>
        </div>
      </div>
    </div>
  </slide-bar>
</template>