<template>
  <div class="absolute inset-0 overflow-hidden">
    <a-config-provider :locale="locale">
      <template #renderEmpty>
        <a-empty />
      </template>
      <router-view />
    </a-config-provider>
    <modal-loading-indicator />
    <document-sign-embed-modal />
    <identity-verification-result-modal />
    <forgot-password-modal />
  </div>
</template>
<script>
import {
  ref,
  onMounted,
  nextTick,
  onBeforeUnmount
} from "@vue/composition-api";
import en_US from "ant-design-vue/lib/locale-provider/en_US";


export default {
  name: "parqay-dashboard",
  setup(props, { root }) {
    const { $controls } = root;
    const locale = ref(en_US);
    const mobileViewRoutes = ref([
      "/"
    ])

    const handleEventBusRouterPush = (payload) => {
      root.$router.push(payload);
    }

    onMounted(() => {
      window.addEventListener(
        "resize",
        () => {
          $controls.windowWidth = window.innerWidth;
          console.log("$controls.windowWidth > ", $controls.windowWidth);
        }
      );
      root.$bus.$on("event-bus-router-push", handleEventBusRouterPush);
    });
    nextTick(() => {
      window.postMessage("parqay-demo-iframe-ready");
    });
    onBeforeUnmount(() => {
      root.$bus.$off("event-bus-router-push", handleEventBusRouterPush);
    })
    return {
      locale
    };
  }
};
</script>
<style lang="scss" scoped>
::v-deep {
  .ant-table-body {
    @apply text-sm;
  }

  .ant-table-fixed {
    @apply text-sm;
  }

  /* Pagination Arrows */
  .ant-pagination-item-link {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

}
</style>
