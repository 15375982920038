import request from "src/services/api/request";

export const API = {
  searchUser(role, keyword) {
    return request.post(`user/search/${role}`, {
      filter: {
        keyword
      }
    });
  },
  getUser(on_behalf_of = null, protectFromCancel = false) {
    let options = {}
    if (protectFromCancel) {
      options["protectFromCancel"] = true;
    }
    if (on_behalf_of) {
      return request.get("user/account?on_behalf_of=" + on_behalf_of, options);
    } else {
      return request.get("user/account", options);
    }
  },
  updatePassword(current_password, new_password) {
    return request.put("user/account/password", {
      current_password,
      new_password
    });
  }
};
