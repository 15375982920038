<script>
import { ref, watch, onMounted } from "@vue/composition-api";
import ClickOutside from "vue-click-outside";
import StatusTag from "src/views/reservations/components/StatusTag.vue";
import debounce from "lodash/debounce";
import startCase from "lodash/startCase";

import { API } from "./api/reservation";

export default {
    directives: {
        ClickOutside
    },
    props: {
        role: {
            type: String,
            default: "host"
        }
    },
    components: {
        StatusTag
    },
    setup(props, ctx) {
        const loading = ref(false);
        const keyword = ref('');
        const reservations = ref([]);
        const selectedReservation = ref({});
        const showDropdown = ref(false);

        watch(selectedReservation, (newValue) => {
            console.log("selectedReservation change -> ", newValue);
            if (newValue) {
                if (newValue.id) {
                    ctx.emit("select", newValue.id);
                    ctx.emit("select-tenant", newValue.tenant);
                } else {
                    ctx.emit("select", null);
                }
                ctx.emit("error", null);
                keyword.value = "";
            } else {
                ctx.emit("error", "A reservation MUST be selected.");
            }
        })

        const handleInput = debounce(async (event) => {
            try {
                loading.value = true;
                if (event) {
                    keyword.value = event.target.value;
                }
                const { data } = await API.search(props.role, keyword.value);
                reservations.value = data;
                loading.value = false;
            } catch (error) {
                loading.value = false;
            }
        }, 500, { trailing: true });

        const handleFocus = () => {
            showDropdown.value = true;
            ctx.emit("error", "A reservation MUST be selected.");
        };

        const handleBlur = () => {
            if (showDropdown.value) {
                showDropdown.value = false;
            }
        }

        onMounted(() => {
            handleInput();
        })

        return {
            loading,
            keyword,
            reservations,
            selectedReservation,
            showDropdown,
            handleInput,
            handleFocus,
            handleBlur,
            startCase
        };
    }
};
</script>
<template>
    <div class="flex flex-col space-y-2 relative" v-click-outside="handleBlur">
        <div v-if="Object.keys(selectedReservation).length > 0">
            <a-tag reservation color="cyan" @click.stop="() => $bus.$emit('open-reservation-drawer', null, selectedReservation.id)">
                <span class="text-black hover:text-parqay-primary font-medium">
                    {{
                        selectedReservation.title || ""
                    }}
                </span>
                <div @click.stop="() => selectedReservation = {}">
                    <svg-icon icon-class="close" class="w-4 h-4 text-gray-400 hover:text-black" />
                </div>
            </a-tag>
        </div>
        <template v-else>
            <a-input-search v-model="keyword" :loading="loading" @input="handleInput" @focus="handleFocus"
                placeholder="Search reservations" size="large" allow-clear />
            <ul v-if="showDropdown"
                class="absolute z-30 top-full left-0 w-full pb-4 bg-white rounded shadow-md border-l border-b border-r">
                <li v-if="reservations.length == 0"
                    class="flex items-center justify-center px-4 py-3 border-t hover:bg-gray-100 cursor-pointer">
                    No Result.
                </li>
                <li v-for="(reservation, i) in reservations" :key="`reservation-${i}`"
                    class="flex items-center justify-between px-4 py-3 border-t hover:bg-gray-100 cursor-pointer"
                    @click.stop="() => selectedReservation = reservation">
                    <div class="flex flex-col justify-between items-start space-y-2">
                        <div class="flex items-center space-x-2">
                            <span class="text-black font-medium">
                                {{
                                    reservation.title || ""
                                }}
                            </span>
                            <status-tag :status="reservation.status"></status-tag>
                        </div>
                        <span class="text-xs font-semibold text-gray-400">
                            Created at: {{
                                $formatDate($deepGet(reservation, "created_at.$date", new Date()), "MMM Do, YYYY, h:mm A")
                            }}
                        </span>
                    </div>
                    <div class="flex flex-col justify-between items-end space-y-2">
                        <span class="text-xs font-semibold text-parqay-primary">Booked by {{ $deepGet(reservation,
                            "tenant.name") }}</span>
                        <span class="text-xs font-semibold text-gray-400">{{ $deepGet(reservation, "tenant.email") }}</span>
                    </div>
                </li>
            </ul>
        </template>
    </div>
</template>
<style lang="scss" scoped>
.ant-tag[reservation] {
    @apply inline-flex items-center text-sm font-medium px-3 py-1.5 space-x-2 cursor-pointer;
}
</style>