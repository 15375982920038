<template>
  <svg
    :class="svgClass"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use :xlink:href="iconName" />
  </svg>
</template>
<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconName () {
      return `#${this.iconClass}`
    },
    svgClass () {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    }
  }
}
</script>
<style lang="scss">
.svg-icon {
  display: inline-block;
  min-width: 12px;
  min-height: 12Px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
