<script>
import { ref } from "@vue/composition-api";
import VehicleInfo from "src/views/listings/new/car-rentals/components/VehicleInfo";
import { getDefaultVehicleModel } from "../listings/new/car-rentals/components/util";
export default {
  name: "VehicleModal",
  components: {
    VehicleInfo
  },
  props: {
    visible: Boolean,
    form: {
      type: Object,
      default: getDefaultVehicleModel()
    }
  },
  setup() {
    return {};
  }
};
</script>
<template>
  <a-modal
    :visible="visible"
    :closable="true"
    destroy-on-close
    width="800px"
    dialog-class=""
    :mask-style="{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }"
    @ok="() => $emit('close')"
    @cancel="() => $emit('close')"
  >
    <div class="flex flex-col space-y-4">
      <span class="font-bold text-center text-base">
        Vehicle Virtual Key
      </span>
      <vehicle-info
        title="Or, you can help your guest to connect"
        :model="form"
        :on-behalf-of="$deepGet(form, 'tenant.id')"
        @added-vehicle="vehicle => {}"
        @delete-vehicle="id => {}"
      />
    </div>
  </a-modal>
</template>
