<script>
import { ref, onMounted } from "@vue/composition-api";
import SearchModal from "./SearchModal";
import Dropdown from "src/views/dropdowns/View";

export default {
  components: {
    Dropdown,
    SearchModal
  },
  props: {},
  setup(props) {
    const searchTrigger = ref(null);
    const hostModel = ref([
      {
        name: "Anything",
        type: "all"
      },
      {
        name: "Tenants",
        type: "tenant"
      },
      {
        name: "Listings",
        type: "listing"
      },
      {
        name: "Reservations",
        type: "reservation"
      },
      {
        name: "Events",
        type: "event"
      },
      {
        name: "Documents",
        type: "document"
      }
    ]);
    const selectedSearchType = ref(hostModel.value[0].type);
    const showModal = ref(false);
    const searchTypeSelected = i => {
      console.log("searchTypeSelected: " + i);
      selectedSearchType.value = hostModel.value[i].type;
    };
    const handleKeyPress = event => {
      // Use ⌘ + K to quick open search
      // console.log(event.keyCode);
      if (event.key == "k" && (event.ctrlKey || event.metaKey)) {
        console.log("dashboard keypress: search - ⌘ + " + event.key);
        trigger();
      }
    };
    onMounted(() => {
      window.addEventListener("keydown", handleKeyPress);
    });

    const trigger = () => {
      searchTrigger.value.blur();
      showModal.value = !showModal.value;
    };
    const dismiss = () => {
      showModal.value = false;
    };

    return {
      searchTrigger,
      hostModel,
      showModal,
      selectedSearchType,
      searchTypeSelected,
      trigger,
      dismiss
    };
  }
};
</script>
<template>
  <div>
    <div
      class="flex items-center relative rounded-md shadow-sm border border-gray-300"
    >
      <div class="">
        <dropdown
          borderless
          :model="hostModel"
          :width="30"
          custom-text-color="text-parqay-primary"
          namespace="search-bar"
          @dropdown-select="searchTypeSelected"
        />
      </div>
      <input
        id="search-content"
        ref="searchTrigger"
        type="text"
        autocomplete="off"
        name="search-content"
        class="focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-transparent"
        :style="{ width: '10rem' }"
        placeholder="Quick Search"
        @click="trigger"
      />
      <div class="pr-2">
        <div class="flex items-center space-x-2 h-full">
          <button
            class="w-6 h-6 rounded bg-gray-50 border border-gray-300 text-gray-400 text-center text-sm"
            @click="trigger"
          >
            ⌘
          </button>
          <button
            class="w-6 h-6 rounded bg-gray-50 border border-gray-300 text-gray-400 text-center text-sm"
            @click="trigger"
          >
            K
          </button>
        </div>
      </div>
    </div>
    <search-modal
      v-if="showModal == true"
      :search-type="selectedSearchType"
      @dismiss="dismiss"
    />
  </div>
</template>
