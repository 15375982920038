import request from "src/services/api/request";
import { Urls } from "services/constants";
import TokenManager from "src/services/authentication/token-manager";

export const API = {
  search(search_type, requests) {
    return fetch(Urls.API_BASE_URL() + "alg/search/" + search_type, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + TokenManager().accessToken
      },
      body: JSON.stringify({ requests })
    }).then(res => res.json());
  }
};
