import Vue from "vue";
import get from "lodash/get";

const defaultModel = [
    {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: "Dashboard for Everything",
        // all titles will be injected into this template
        title_template: "Parqay | %s",
        description: "Find Parking, Workplaces and Places to Stay Nearby",
        image: "https://parqay.com/cdn/img/social.png",
        pathname: "*",
        disable_app_banner: false,
    },
];

const getMetaModel = (currentPath) => {
    var metaModel = {};
    var model = defaultModel;
    const seoMeta = localStorage.getItem("seoMeta");
    if (seoMeta) {
        model = JSON.parse(seoMeta);
    }
    console.log("currentPath >> ", currentPath);
    metaModel = get(
        model.filter((m) => m.pathname === currentPath),
        "0",
        model.filter((m) => m.pathname === "*")[0]
    );
    console.log("metaModel >> ", metaModel);
    return metaModel;
};

export const metaInfoGenerator = (currentPath = window.location.pathname) => {
    const metaModel = getMetaModel(currentPath);
    var { title, description, image, title_template, disable_app_banner } = metaModel;
    var meta = [
        { name: 'description', content: description, id: 'desc' },
        { itemprop: 'name', content: title, id: 'gName' },
        { itemprop: 'description', content: description, id: 'gDesc' },
        { itemprop: 'image', content: image, id: 'gImage' },
        { property: 'fb:app_id', content: image, id: '1140470129358515' },
        { property: 'og:site_name', content: 'Parqay', id: 'ogSiteName' },
        { property: 'og:title', content: title, id: 'ogTitle' },
        { property: 'og:description', content: description, id: 'ogDesc' },
        { property: 'og:url', content: window.location.href, id: 'ogUrl' },
        { property: 'og:type', content: 'website', id: 'ogType' },
        { property: 'og:image', content: image, id: 'ogImage' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '628' },
        { property: 'og:site_name', content: image, id: 'parqyt' },
        { property: 'twitter:card', content: 'product' },
        { property: 'twitter:site', content: '@parqyt' },
        { property: 'twitter:title', content: title },
        { property: 'twitter:description', content: description },
        { property: 'twitter:creator', content: '@parqyt' },
        {
            property: 'twitter:image',
            content: image
        },
        {
            name: 'robots', content: `noodp,noydir,nofollow,max-snippet:${description.length}`
        }
    ]

    var payload = {
        title: title,
        meta: meta
    }
    if (title_template) {
        payload['title'] = title_template.replace("%s", title)
    }
    return payload
}

Vue.prototype.$metaInfoGenerator = metaInfoGenerator;

export const metaInfoRouterInjector = (to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));
}