<script>
import { reactive, ref, computed, watch, onMounted } from "@vue/composition-api";
import { getAvatarURL } from "src/views/components/util";
import UserCard from './UserCard.vue';

import { API } from "src/views/components/api/account";
import { API as DocumentAPI } from "src/views/documents/api";
import { API as TenantAPI } from "src/views/tenants/api";
import { API as MessagingAPI } from "src/views/messaging/api";

export default {
  props: {
    path: {
      type: String,
      default: null,
    },
    userId: {
      type: String,
      default: null,
    },
    showName: {
      type: Boolean,
      default: false
    },
    popover: {
      type: Boolean,
      default: true
    },
    popoverPlacement: {
      type: String,
      default: "top"
    },
    showVerify: {
      type: Boolean,
      default: false
    },
    // The relative role override: the user role relative to current account
    relativeRole: {
      type: String,
      default: null
    },
    adminOnBehalfOf: {
      type: String,
      default: null
    }
  },
  components: {
    UserCard
  },
  setup(props, ctx) {
    const elem = ref({ clientHeight: null });
    const controls = reactive({
      loading: false,
      actionsLoading: null,
      url: null,
      initial: null,
      name: null,
      user: {},
      isVerified: null,
      tenant: {},
      style: {
        avatarWidth: 32
      }
    });
    const role = computed(() => {
      if (props.relativeRole) {
        if (props.relativeRole === "host") {
          return "tenant"
        }
        if (props.relativeRole === "tenant") {
          return "host"
        }
      }
      if (ctx.root.$route.path.startsWith("/dashboard")) {
        return "host"
      }
      if (ctx.root.$route.path.startsWith("/tenant")) {
        return "tenant"
      }
    })
    const reverseRole = computed(() => {
      if (props.relativeRole) {
        if (props.relativeRole === "host") {
          return "Host"
        }
        if (props.relativeRole === "tenant") {
          return "Tenant"
        }
      }
      if (ctx.root.$route.path.startsWith("/dashboard")) {
        return "Tenant"
      }
      if (ctx.root.$route.path.startsWith("/tenant")) {
        return "Host"
      }
    })
    watch(() => props.path, val => {
      if (val) {
        fetchAvatarURL();
      }
    })
    watch(() => props.userId, val => {
      if (val) {
        fetchUser();
      }
    })
    watch(() => elem.clientHeight, val => {
      controls.style.avatarWidth = val;
    })
    const fetchAvatarURL = async () => {
      if (!props.path) {
        return;
      }
      controls.loading = true;
      try {
        controls.url = await getAvatarURL(props.path);
        controls.loading = false;
      } catch (error) {
        controls.url = null;
        controls.loading = false;
      }
    }
    const fetchUser = async () => {
      if (!props.userId) {
        return;
      }
      controls.loading = true;
      try {
        const user = await API.getUser(props.userId);
        controls.initial = user.data.first_name.substring(0, 1);
        controls.name = user.data.name;
        controls.url = await getAvatarURL(user.data.picture);
        controls.loading = false;
        controls.user = user.data;
        ctx.emit("user-data-ready", user.data);
      } catch (error) {
        controls.url = null;
        controls.loading = false;
      }
    }

    const getIdentityVerificationResult = async () => {
      try {
        const { data: { is_verified } } = await DocumentAPI.getIdentityVerificationResult({
          is_host: role.value === "tenant",
          on_behalf_of: props.userId
        });
        controls.isVerified = is_verified;
      } catch (error) {
        controls.isVerified = false;
        console.log("getIdentityVerificationResult, error: ", error);
      }
    };

    const getTenant = async () => {
      let tenantId, on_behalf_of;
      if (role.value === "tenant") {
        tenantId = ctx.root.$getUserUID();
        on_behalf_of = controls.user.id;
      }
      if (role.value === "host") {
        tenantId = controls.user.id;
        // if (ctx.root.$isAdmin()) {
        //   if (!props.adminOnBehalfOf) {
        //     ctx.root.$openNotification(
        //       "Tenant Info",
        //       "To proceed, the admin user must specify the host they are representing",
        //       10,
        //       "info"
        //     );
        //     return
        //   }
        // }
      }
      try {
        const { data } = await TenantAPI.getTenant(tenantId, on_behalf_of);
        controls.tenant = data;
      } catch (error) {
        controls.tenant = {};
        ctx.root.$openNotification(
          "Tenant Info",
          error.message,
          10,
          "info"
        );
      }
    }

    const startDirectChat = async () => {
      try {
        let room = {};
        if (ctx.root.$isAdmin()) {
          if (!props.adminOnBehalfOf) {
            return
          }
          const { data } = await MessagingAPI.getDirectConversation(props.adminOnBehalfOf, props.userId, "host");
          room = data;
        } else {
          await getTenant();
          if (role.value === "tenant") {
            const { data } = await MessagingAPI.getDirectConversation(controls.tenant.id, controls.tenant.host, "tenant");
            room = data;
          }
          if (role.value === "host") {
            const { data } = await MessagingAPI.getDirectConversation(controls.tenant.host, controls.tenant.id, "host");
            room = data;
          }
        }
        ctx.root.$bus.$emit("open-contact-drawer", { room_id: room.id });
      } catch (error) {
        console.log("startDirectChat -> error: ", error);
      }
    }

    const performAction = async (action) => {
      controls.actionsLoading = action;
      if (["message", "phone"].includes(action)) {
        await startDirectChat();
      }
      if (action === "mail") {
        if (role.value === "tenant") {
          await startDirectChat();
        }
        if (role.value === "host" || props.relativeRole === "host") {
          ctx.root.$bus.$emit("toggle-email-editor", { tenant_id: controls.user.id });
        }
      }
      setTimeout(() => {
        controls.actionsLoading = null;
      }, 1500);
    }

    const openTenantDrawer = () => {
      // if (ctx.root.$isAdmin()) {
      //   if (!props.adminOnBehalfOf) {
      //     ctx.root.$openNotification(
      //       "WIP",
      //       "Please switch to normal host account to use full features of tenant profile card. \
      //       Stay tuned for the release of more admin features.",
      //       20,
      //       "info"
      //     );
      //     ctx.root.$openNotification(
      //       "Tenant Profile Card",
      //       "Admin is only allowed to send email at the moment using tenant profile card. \
      //       To use direct chat, go to > Messaging & Email to send on behalf of other hosts. \
      //       Stay tuned, new admin features will release soon.",
      //       30,
      //       "info"
      //     );
      //     return
      //   }
      // }
      const options = {
        tenant_id: controls.user.id
      }
      if (ctx.root.$isAdmin() && props.adminOnBehalfOf) {
        options.on_behalf_of = props.adminOnBehalfOf;
      }
      ctx.root.$bus.$emit('open-tenant-drawer', options);
    }

    onMounted(() => {
      fetchAvatarURL();
      fetchUser();
      if (props.showVerify) {
        getIdentityVerificationResult();
      }
    });
    return {
      elem,
      controls,
      role,
      reverseRole,
      performAction,
      openTenantDrawer,
      getIdentityVerificationResult
    };
  },
};
</script>
<template>
  <div ref="elem" class="flex flex-shrink-0 items-center relative"
    :class="popover ? 'hover:opacity-50 cursor-pointer' : ''" @click.stop="$emit('click')">
    <a-popover v-if="popover" :placement="popoverPlacement" overlay-class-name="avatar" @visibleChange="(visible) => {
      if (!showVerify && visible) {
        getIdentityVerificationResult();
      }
    }">
      <template #content>
        <user-card :user-id="userId" @click="() => {
          if (role === 'tenant') {
            performAction('message');
          }
          if (role === 'host') {
            openTenantDrawer();
          }
        }">
          <template #avatar>
            <lazy-img v-if="controls.url" :src="controls.url" :crossOrigin="$imageFetchPolicy()"
              class="rounded-full transform hover:scale-105 cursor-pointer" style="width: 64px; height: 64px;" />
            <a-avatar v-else-if="controls.initial" :size="64" class="text-white"
              style="background-color: #f56a00; font-size: 36px; font-weight: 700;">{{
                controls.initial.toUpperCase()
              }}</a-avatar>
            <a-avatar v-else :size="64" style="background-color: #f56a00;">
              <template #icon>
                <a-icon type="user" theme="outlined" />
              </template>
            </a-avatar>
          </template>
          <template #info>
            <span class="text-xl hover:text-blue-500 font-semibold cursor-pointer">{{ controls.name }}</span>
            <span v-if="$deepGet(controls, 'user.email')"
              class="text-sm font-medium text-parqay-primary hover:text-blue-500 cursor-pointer"
              @click.stop="$bus.$emit('toggle-email-editor', { tenant_id: controls.user.id })">{{
                $deepGet(controls, "user.email") }}</span>
            <div v-if="$isAdmin() && relativeRole === 'host'" class="flex items-center justify-center space-x-2"
              @click.stop="() => { }">
              <a-icon type="phone" class="text-orange-500" />
              <div style="max-width: 150px;">
                <span class="text-orange-500 hover:text-orange-400 cursor-pointer text-xs font-medium">{{
                  $deepGet(controls, "user.phone_number", "N/A")
                }}</span>
              </div>
            </div>
            <div class="pt-1">
              <a-tag v-if="controls.isVerified === true" verify-status color="green">
                <span>Verified {{ reverseRole }}</span>
                <a-icon type="check-circle" theme="twoTone" two-tone-color="#52c41a" />
              </a-tag>
              <a-tag v-else-if="controls.isVerified === false" verify-status color="pink">
                <span>Unverified {{ reverseRole }}</span>
                <a-icon type="clock-circle" />
              </a-tag>
              <div v-else class="flex items-center justify-center">
                <a-icon type="loading" />
              </div>
            </div>
          </template>
          <template #actions>
            <a-icon v-if="!($isAdmin() && !adminOnBehalfOf)"
              :type="controls.actionsLoading === 'message' ? 'loading' : 'message'"
              @click.stop="performAction('message')" />
            <a-icon :type="controls.actionsLoading === 'mail' ? 'loading' : 'mail'" @click.stop="performAction('mail')" />
            <a-icon v-if="!($isAdmin() && !adminOnBehalfOf)"
              :type="controls.actionsLoading === 'phone' ? 'loading' : 'phone'" @click.stop="performAction('phone')" />
          </template>
        </user-card>
      </template>
      <a-badge v-if="showVerify && controls.isVerified !== null">
        <a-icon v-if="controls.isVerified" slot="count" type="check-circle" theme="twoTone" two-tone-color="#52c41a" />
        <a-icon v-else slot="count" type="clock-circle" theme="twoTone" />
        <template>
          <lazy-img v-if="controls.url" :src="controls.url" :crossOrigin="$imageFetchPolicy()"
            class="h-8 w-8 rounded-full" />
          <a-avatar v-else-if="controls.initial" class="text-white" style="background-color: #f56a00;">{{
            controls.initial.toUpperCase()
          }}</a-avatar>
          <a-avatar v-else style="background-color: #f56a00;">
            <template #icon>
              <a-icon type="user" theme="outlined" />
            </template>
          </a-avatar>
        </template>
      </a-badge>
      <template v-else>
        <lazy-img v-if="controls.url" :src="controls.url" :crossOrigin="$imageFetchPolicy()"
          class="h-8 w-8 rounded-full" />
        <a-avatar v-else-if="controls.initial" class="text-white" style="background-color: #f56a00;">{{
          controls.initial.toUpperCase()
        }}</a-avatar>
        <a-avatar v-else style="background-color: #f56a00;">
          <template #icon>
            <a-icon type="user" theme="outlined" />
          </template>
        </a-avatar>
      </template>
    </a-popover>
    <template v-else>
      <a-avatar v-if="controls.url" :src="controls.url" crossOrigin="anonymous" />
      <a-avatar v-else-if="controls.initial" class="text-white" style="background-color: #f56a00;">{{
        controls.initial.toUpperCase()
      }}</a-avatar>
      <a-avatar v-else style="background-color: #f56a00;">
        <template #icon>
          <a-icon type="user" theme="outlined" />
        </template>
      </a-avatar>
    </template>
    <span v-if="showName" class="text-xs font-medium ml-1.5">{{ controls.name }}</span>
    <div v-if="controls.loading" class="absolute inset-0" :style="{ width: `${controls.style.avatarWidth}px` }">
      <div class="flex items-center justify-center w-full h-full rounded-full" style="background-color: #f56a00;">
        <a-icon type="loading" style="color: #fff; font-size: 1rem;" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-avatar {
    @apply flex justify-center items-center;
  }
}
</style>
<style>
.avatar .ant-popover-inner-content {
  padding: 0px !important;
}
</style>
<style lang="scss" scoped>
.ant-tag[verify-status] {
  font-size: 0.65rem;
  line-height: 0.85rem;
  @apply flex items-center space-x-2 py-1 font-semibold;
}
</style>