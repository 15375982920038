import axios from "axios";
import request from "src/services/api/request";
import { Urls } from "src/services/constants";

const baseUrl = url => {
  return Urls.API_BASE_URL() + url;
};
export const API = {
  list({ role = null, company_id = null }) {
    if (company_id) {
      return axios({
        method: "get",
        url: baseUrl("towing/" + company_id)
      });
    }
    return request.get(`towing/${role}`);
  },
  create(payload) {
    return request.post("towing", { ...payload });
  }
};
