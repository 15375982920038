import request from "src/services/api/request";

export const API = {
  login(payload) {
    return request.post("auth/login", payload);
  },
  // testToken() {
  //   return request.get("auth/protected");
  // },
  signUp(payload) {
    return request.post("auth/sign-up", payload);
  },
  loginWithOauth(provider, payload) {
    return request.post(`oauth/login/${provider}`, payload);
  },
  signUpTenant(payload) {
    return request.post("auth/sign-up/tenant", payload);
  },
  /**
   * Get Intercom Hash
   */
  getIntercomCredential() {
    return request.post("user/service/intercom");
  },
  checkEmailDuplication(email) {
    return request.post("auth/verify-email/check-duplicate", { email });
  },
  initVerifyEmail(email) {
    return request.post("auth/verify-email/init", { email });
  },
  verifyEmail(email, code) {
    return request.post("auth/verify-email/validate", { email, code });
  },
  initVerifyPhoneNumber(number, country) {
    return request.post("auth/verify-phone-number/init", { number, country });
  },
  verifyPhoneNumber(number, country, code) {
    return request.post("auth/verify-phone-number/validate", {
      number,
      country,
      code
    });
  },
  phoneNumberTokenExchange(number, country, code) {
    return request.post("auth/phone-number-login", {
      number,
      country,
      code
    });
  },
  verifyDashboardToken(tid) {
    return request.post(
      "auth/token-exchange/verify-dashboard-token",
      {
        token: tid
      },
      {},
      true
    );
  },
  fullfillProfile(payload) {
    return request.post("auth/fullfill-profile", payload);
  },
  getWhiteLabelDetails(url_slug) {
    return request.get("user/account/white-label", { url_slug });
  },
  loginTarget(target_uid) {
    return request.post(`auth/login/${target_uid}`);
  },
  initResetPassword(email) {
    request.post("auth/password-reset", { email, target: "dashboard" })
  },
  updateResetPassword(reset_token, new_password) {
    request.put(`auth/password-reset/${reset_token}`, {
      new_password
    })
  }
};
