import request from "src/services/api/request";
import pickBy from "lodash/pickBy";
import { Urls } from "src/services/constants";
import TokenManager from "src/services/authentication/token-manager";
import EventBus from "src/services/util/event-bus";
import axios from "axios";

const baseUrl = (url) => {
  return Urls.API_BASE_URL() + url;
};
export const API = {
  listHostDocuments(payload = {}) {
    return request.get("documents/host", payload);
  },
  listTenantDocuments(payload = {}) {
    return request.get("documents/tenant", payload);
  },
  getDocumentsByReservation({ role, reservation_id }) {
    const payload = {
      role
    };
    let queryString = new URLSearchParams(pickBy(payload)).toString();
    return request.get(`documents/reservation/${reservation_id}?${queryString}`);
  },
  getDocumentById({ id }) {
    return request.get(`documents/${id}`);
  },
  updateDocument({ id, payloads }) {
    return request.put(`documents/${id}`, payloads);
  },
  getIdentityVerificationTemplate() {
    return request.get("documents/identity/template/host");
  },
  getDocumentTemplates(on_behalf_of = null) {
    if (on_behalf_of) {
      return request.get(`documents/templates?on_behalf_of=${on_behalf_of}`);
    } else {
      return request.get("documents/templates");
    }
  },
  async createNewTemplate(
    template,
    current_retry = 1,
    max_retry = 2
  ) {
    const formData = new FormData();
    formData.append("template", JSON.stringify(template));
    template.docs_to_sign.forEach(doc_to_sign => {
      formData.append(doc_to_sign.id, doc_to_sign.blob);
      delete doc_to_sign["blob"];
    });
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    if (TokenManager().accessToken) {
      headers["Authorization"] = "Bearer " + TokenManager().accessToken;
    }
    try {
      return await axios({
        method: "post",
        url: baseUrl("documents/template"),
        data: formData,
        headers
      })
    } catch (error) {
      if (error.response.status === 401) {
        if (current_retry <= max_retry) {
          console.log("***** re-auth error ***** " + error.response.status);
          var next_retry = current_retry + 1;
          API.refreshAccessToken().then(
            () => {
              EventBus.$emit("access-token-refresh");
              API.createNewTemplate(template, next_retry);
            },
            (error) => {
              // notify to resign-in
              console.warn("Failed to refresh the auth token...", error);
              EventBus.$emit("auth-required-login");
            }
          );
        } else {
          EventBus.$emit("auth-required-login");
        }
      }
    }
  },
  getListingDocumentTemplates({ listing_id, on_behalf_of = null }) {
    if (on_behalf_of) {
      return request.get(
        `listings/${listing_id}/document/templates?on_behalf_of=${on_behalf_of}`
      );
    } else {
      return request.get(`listings/${listing_id}/document/templates`);
    }
  },
  sendDocumentTemplateInvite(templates, reservation) {
    return request.post("documents/templates/send-invite", {
      templates,
      reservation
    });
  },
  updateDocumentTemplate(id, payload) {
    return request.put(`documents/templates/${id}`, payload);
  },
  // async updateDocumentTemplatesBlob(
  //   updates,
  //   current_retry = 1,
  //   max_retry = 2
  // ) {
  //   // const updatesString = JSON.stringify(updates);
  //   const { identity_verification_options, templates, docs_to_sign } = updates;
  //   let formData = new FormData();
  //   formData.append("identity_verification_options", JSON.stringify(identity_verification_options));
  //   formData.append("templates", JSON.stringify(templates));
  //   for (const k in docs_to_sign) {
  //     const f = docs_to_sign[k];
  //     // var fileBlob = new Blob([templates], { type: "application/pdf" });
  //     formData.append(k, f);
  //   }
  //   const headers = {
  //     "Content-Type": "multipart/form-data",
  //   };
  //   if (TokenManager().accessToken) {
  //     headers["Authorization"] = "Bearer " + TokenManager().accessToken;
  //   }
  //   try {
  //     return await axios({
  //       method: "put",
  //       url: baseUrl("documents/templates/blob"),
  //       data: formData,
  //       headers
  //     })
  //   } catch (error) {
  //     if (error.response.status === 401) {
  //       if (current_retry <= max_retry) {
  //         console.log("***** re-auth error ***** " + error.response.status);
  //         var next_retry = current_retry + 1;
  //         API.refreshAccessToken().then(
  //           () => {
  //             EventBus.$emit("access-token-refresh");
  //             API.updateDocumentTemplatesBlob(updates, next_retry);
  //           },
  //           (error) => {
  //             // notify to resign-in
  //             console.warn("Failed to refresh the auth token...", error);
  //             EventBus.$emit("auth-required-login");
  //           }
  //         );
  //       } else {
  //         EventBus.$emit("auth-required-login");
  //       }
  //     }
  //   }
  // },
  // updateDocumentTemplates(updates) {
  //   return request.put("documents/templates", { ...updates });
  // },
  deleteDocumentTemplate(id) {
    return request.delete(`documents/templates/${id}`);
  },
  getIdentityVerificationResult(
    { is_host = false, on_behalf_of = null } = { is_host: false, on_behalf_of: null }
  ) {
    let payload = {};
    if (on_behalf_of) {
      payload["on_behalf_of"] = on_behalf_of;
    }
    if (is_host) {
      payload["is_host"] = is_host;
    }
    return request.get(`documents/identity/verification-results`, payload);
  },
  getAdminIdentityVerificationResults() {
    return request.get(`documents/identity/verification-results/admin`);
  },
  getSignatureSignUrl(payload) {
    return request.post("documents/signature/sign-url", { ...payload });
  },
  updateSignatureStateToSigned(doc_id) {
    return request.post(`documents/signature/${doc_id}/signed`);
  },
  verifyIdentity(payload) {
    return request.post("documents/identity/create-session", {
      ...payload,
    });
  },
  hostRequestUpdate(doc_id) {
    return request.post(`documents/${doc_id}/request-update`);
  },
  hostInviteDocumentTenant(doc_id) {
    return request.post(`documents/${doc_id}/invite`);
  },
  // New: Zoho Sign
  getPreviewSignURL(template_id) {
    return request.post(`documents/signature/sign-url/preview/${template_id}`);
  },
  createTemplateReviewRequest(payload) {
    return request.post("documents/templates/review-request", payload);
  },
  // Admin
  getAdminTemplateReviewRequests() {
    return request.get("documents/templates/review-requests");
  },
  updateAdminTemplateReviewRequestStatus(request_id, status, templates) {
    return request.put(`documents/templates/review-request/${request_id}/${status}`, { templates });
  },
  getAdminZohoSignStatus() {
    return request.get("documents/admin/zoho-status");
  },
  initAdminZohoSignTokens() {
    return request.post("documents/admin/zoho-init");
  },
  refreshAccessToken() {
    //Check whether there's refresh token set or not.
    if (!TokenManager().refreshToken) {
      return Promise.reject("no refresh token");
    }

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + TokenManager().refreshToken;

    return axios["post"](baseUrl("auth/refresh_token"), {}).then(
      (success) => {
        // update access token
        TokenManager().accessToken = success.data.data.access_token;
        console.log(
          "success.data.access_token: " + success.data.data.access_token
        );
        return Promise.resolve();
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
};
