<script>
import dayjs from "dayjs";

export default {
  name: "TenantSearchItem",
  props: {
    // Determine if current item is recent search item
    recentMode: {
      type: Boolean,
      default: false
    },
    // Determine if current item is starred search item
    starredMode: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    const navToResult = it => {
      console.log("navToResult: ", it);
      ctx.root.$store.commit("addRecentSearch", it);
      console.log("addRecentSearch", ctx.root.$store.state.recentSearches);
      ctx.emit("dismiss");
      ctx.root.$router.push("/dashboard/tenants");
    };
    const toggleStar = it => {
      // toggleRecentSearchStar
      ctx.root.$store.commit("toggleStarSearch", it);
      console.log(JSON.stringify(ctx.root.$store.state.starredSearches));
    };
    const starred = m => {
      var starred = ctx.root.$store.getters.checkIsStarred(m.objectID);
      console.log("starred >> " + starred);
      if (props.starredMode) {
        // In starred mode, the star icon does not need to be highlighted
        return false;
      }
      return starred;
    };
    const removeFromRecentSearch = () => {
      console.log("removeFromRecentSearch >> " + props.index);
      ctx.root.$store.commit("removeRecentSearch", props.index);
      console.log(JSON.stringify(ctx.root.$store.state.recentSearches));
    };
    return {
      navToResult,
      toggleStar,
      starred,
      removeFromRecentSearch
    };
  }
};
</script>
<template>
  <div
    class="cursor-pointer rounded-lg flex justify-between bg-white hover:bg-gray-100 border shadow-sm items-center text-sm"
  >
    <div class="flex items-center" @click="navToResult(model)">
      <div class="inline-flex">
        <svg-icon
          :icon-class="starredMode ? 'star' : 'users'"
          class="h-8 w-6 mx-4"
        />
      </div>
      <div class="flex flex-col py-2">
        <p class="my-auto text-base font-medium">
          Tenant
        </p>
        <div class="grid grid-cols-2 gap-y-2 gap-x-4 py-2">
          <div class="flex items-center py-2 space-x-4">
            <img
              class="h-10 w-10 rounded-full object-cover ring-2 ring-indigo-500"
              :src="model.avator"
            />
            <span class="my-auto text-parqay-primary font-medium">{{
              model.name
            }}</span>
          </div>
          <span class="my-auto">{{ model.address }}</span>
        </div>
      </div>
    </div>
    <div class="flex items-center space-x-4">
      <div @click="toggleStar(model)">
        <svg-icon
          :icon-class="starredMode ? 'delete' : 'star'"
          class="h-16 w-6 mx-2 hover:text-yellow-300 cursor-pointer"
          :class="{ 'text-yellow-300': starred(model) }"
        />
      </div>
      <div v-if="recentMode" @click="removeFromRecentSearch">
        <svg-icon
          icon-class="delete"
          class="h-16 w-6 mr-4 hover:text-yellow-300 cursor-pointer"
        />
      </div>
    </div>
  </div>
</template>
