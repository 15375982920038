const LandingFrame = () =>
  import(
    /* webpackChunkName: "prerender" */ "src/views/landing-pages/Index.vue"
  );

const DashboardFrame = () =>
  import(/* webpackChunkName: "prerender" */ "src/views/dashboard/Index.vue");

const ListingFrame = () =>
  import(
    /* webpackChunkName: "prerender" */ "src/views/listings/new/Index.vue"
  );

const PrerenderView = () => import("src/views/seo/Prerender.vue");

export default [
  {
    path: "/pre",
    component: LandingFrame,
    children: [
      {
        path: "home",
        name: "PrerenderedLandingPage",
        component: PrerenderView
      },
      {
        path: "marketplace",
        name: "PrerenderedMarketplace",
        component: PrerenderView
      },
      {
        path: "pricing",
        name: "PrerenderedPricing",
        component: PrerenderView
      },
      // {
      //   path: "contact-sales",
      //   name: "PrerenderedContactSales",
      //   component: PrerenderView
      // }
    ]
  },
  {
    path: "/pre/sign-up",
    name: "PrerenderedSignUp",
    component: PrerenderView
  },
  {
    path: "/pre/login",
    name: "PrerenderedLogin",
    component: PrerenderView
  },
  {
    path: "/pre",
    component: ListingFrame,
    children: [
      {
        path: "listing",
        name: "PrerenderedNewListing",
        component: PrerenderView
      }
    ]
  },
  {
    path: "/pre/docs/:sectionName?",
    name: "PrerenderedDocsHome",
    component: PrerenderView
  }
];
