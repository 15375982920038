<script>
import {
  ref,
  onMounted,
  watch,
  reactive,
  onBeforeUnmount,
  computed,
  nextTick
} from "@vue/composition-api";
import { API } from "src/views/components/api";
import { Tokens, Urls } from "services/constants";
import Smartcar from "@smartcar/auth";
import LeaderLine from "leader-line";
import SmartcarCard from "src/views/components/components/SmartcarCard";
import { observer } from "vue-mutation-observer";

export default {
  name: "VehicleVerification",
  components: {
    SmartcarCard
  },
  directives: { observer },
  props: {
    minWidthNone: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Please connect your vehicle using Smartcar"
    },
    width: {
      type: String,
      default: "1/3"
    },
    onBehalfOf: {
      type: String,
      default: null
    }
  },
  setup(props, ctx) {
    var arrow = null;
    const arrowFrom = ref(null);
    const arrowTo = ref(null);
    const smartcar = ref(null);
    const verifyVehicleMessage = reactive({
      show: false,
      message: "",
      type: "info"
    });
    const shouldContinue = ref(false);
    const verificationPending = ref(false);
    const calculatedWidth = computed(() => {
      return `w-${props.width}`;
    });
    const connectButtonTitle = computed(() => {
      if (verificationPending.value) {
        return "Verifying...";
      }
      if (shouldContinue.value) {
        return "Reconnect";
      }
      return "Connect Vehicle";
    });
    const connectVehicle = () => {
        verificationPending.value = true;
        verifyVehicleMessage.message = "Please login using the pop up dialog";
        verifyVehicleMessage.show = true;
        smartcar.value.openDialog({ forcePrompt: true });
        setTimeout(() => {
          verificationPending.value = false;
        }, 2000);
      },
      onScComplete = (err, code, status) => {
        if (err) {
          verifyVehicleMessage.message =
            "User closed dialog. Use button below to connect a vehicle";
          verificationPending.value = false;
          return;
        }
        verificationPending.value = true;
        console.log("smartcar code: " + code);
        API.saveSmartCarVehicle(code, props.onBehalfOf)
          .then(res => {
            console.log(JSON.stringify(res.data));
            let vehicle = res.data;
            verifyVehicleMessage.message = `Vehicle ${vehicle.make} ${vehicle.model} has been connected`;
            verifyVehicleMessage.show = true;
            shouldContinue.value = true;
            verificationPending.value = false;
            ctx.emit("vehicle-verified", vehicle);
          })
          .catch(error => {
            verifyVehicleMessage.show = true;
            verifyVehicleMessage.message = error.message;
            verifyVehicleMessage.type = "error";
            shouldContinue.value = false;
            verificationPending.value = false;
          });
      };
    const { $nextTick } = ctx.root;
    const handleDOMUpdates = () => {
      console.log("handleDOMUpdates");
    };
    onMounted(() => {
      arrow = new LeaderLine(arrowFrom.value, arrowTo.value, {
        dash: { animation: true }
      });
      smartcar.value = new Smartcar({
        clientId: Tokens.SMARTCAR_CLIENT_ID,
        redirectUri: `https://javascript-sdk.smartcar.com/v2/redirect?app_origin=${window.location.origin}`,
        scope: [
          "required:control_security",
          "required:control_security:unlock",
          "required:control_security:lock",
          "read_vehicle_info",
          "read_location",
          "read_fuel",
          "read_odometer",
          "read_tires"
        ],
        testMode: true,
        singleSelect: true,
        onComplete: onScComplete
      });
    });
    nextTick(() => {
      console.log("nextTick");
      if (!document.querySelector(".pq-leader-line")) {
        var t = document.querySelector(".leader-line");
        // t.style.backgroundColor = "red";
        // t.style.width = "100px"
        t.style.left = "auto";
        t.style.right = "auto";
        t.style.width = "100%";
        t.classList.remove("leader-line");
        t.classList.add("pq-leader-line");
        document.querySelector(".leader-line-anchor").appendChild(t);
      }
    });
    onBeforeUnmount(() => {});
    return {
      arrowFrom,
      arrowTo,
      smartcar,
      verifyVehicleMessage,
      shouldContinue,
      verificationPending,
      calculatedWidth,
      connectButtonTitle,
      connectVehicle,
      onScComplete,
      handleDOMUpdates
    };
  }
};
</script>
<template>
  <div
    v-observer:subtree.childList="handleDOMUpdates"
    :class="`${calculatedWidth} relative flex`"
    :style="minWidthNone ? '' : 'min-width: 450px'"
  >
    <smartcar-card title="">
      <template slot="message">
        <span class="py-2 text-sm">
          {{ title }}
        </span>
        <span
          v-if="verifyVehicleMessage.show"
          class="h-8 text-center leading-8"
          :class="{
            'text-parqay-primary': verifyVehicleMessage.type == 'info',
            'text-red-500': verifyVehicleMessage.type == 'error'
          }"
        >
          {{ verifyVehicleMessage.message }}
        </span>
      </template>
      <template slot="info">
        <div ref="arrowFrom">
          <svg-icon class="ml-2 w-16 h-12" icon-class="car-side" />
        </div>
        <div class="leader-line-anchor ml-0 mr-4" />
        <div ref="arrowTo">
          <svg-icon class="mr-2 w-10 h-8" icon-class="car-key" />
        </div>
      </template>
      <template slot="button">
        <div class="mx-auto">
          <button
            class="flex bg-parqay-primary hover:bg-blue-500 disabled:opacity-50 rounded px-4 py-2 font-medium text-white text-md items-center"
            :disabled="verificationPending"
            @click="connectVehicle"
          >
            {{ connectButtonTitle }}
            <svg-icon
              v-if="verificationPending"
              icon-class="circular-loader"
              class="ml-2 animate-spin h-4 w-4"
            />
          </button>
        </div>
      </template>
    </smartcar-card>
  </div>
</template>
