<template>
    <a-modal v-model="visible" title="Password Reset">
        <div class="flex flex-col space-y-3">
            <p>Enter your email address to reset your password:</p>
            <a-input v-model="email" placeholder="Email" size="large" />
        </div>
        <template #footer>
            <a-button @click="visible = false">Cancel</a-button>
            <a-button type="primary" :loading="loading" :disabled="!email" @click="initResetPassword">Reset
                Password</a-button>
        </template>
    </a-modal>
</template>
  
<script>
import { ref, onMounted, onBeforeUnmount } from '@vue/composition-api';
import { API } from "./api"

export default {
    setup(props, ctx) {

        const loading = ref(false);

        const visible = ref(false);

        const email = ref(null);

        const initResetPassword = async () => {
            try {
                loading.value = true;
                await API.initResetPassword(email.value);
                setTimeout(() => {
                    ctx.root.$openNotification("Password Reset", `The password reset email has been sent to ${email.value}`);
                    loading.value = false;
                    visible.value = false;
                }, 1500);
            } catch (error) {
                ctx.root.$openNotification("Password Reset Error", error.message);
            }
        };

        const onOpenPasswordReset = () => {
            visible.value = true;
        }

        onMounted(() => {
            ctx.root.$bus.$on("open-password-reset", onOpenPasswordReset);
        })

        onBeforeUnmount(() => {
            ctx.root.$bus.$off("open-password-reset", onOpenPasswordReset);
        })

        return {
            loading,
            visible,
            email,
            initResetPassword,
        };
    },
};
</script>
  