<script>
import SlideBar from "src/views/components/components/SlideBar";
import {
  ref,
  watch,
  onMounted,
  computed,
  onBeforeUnmount,
} from "@vue/composition-api";

export default {
  name: "AutomationSlideCard",
  components: {
    SlideBar,
  },
  props: {
    model: {
      type: Object,
      //   required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, ctx) {
    const { $nextTick } = ctx.root;

    const show = ref(false);

    watch(
      () => props.visible,
      (val) => {
        show.value = val;
      }
    );

    const close = () => {
      ctx.emit("close-automation-slide-card");
    };

    onMounted(() => {});

    onBeforeUnmount(() => {});

    return {
      show,
      close,
    };
  },
};
</script>
<template>
  <SlideBar
    v-slot:default="slotProps"
    v-model="show"
    :width="400"
    backdrop
    close-by-mask
    @close-slide-bar="close"
  >
    <div class="h-full flex-col justify-between">
      <div class="flex justify-between items-center border-b px-6 py-6">
        <p class="font-bold text-lg text-gray-900 my-auto">
          Automation
        </p>
        <span @click="slotProps.closeSlideBar">
          <svg-icon
            icon-class="close"
            class="cursor-pointer w-6 h-6 text-blue-900"
          />
        </span>
      </div>
      <div class="px-4">
        <div class="flex flex-col space-y-4 h-full bg-white p-4 divide-y">
          <div class="pt-4 pb-2">
            <div
              class="h-24 rounded-lg bg-white shadow-sm border hover:shadow-xl relative"
            >
              <div class="grid grid-cols-4 h-full">
                <div
                  class="flex border-r col-span-1 items-center justify-center"
                >
                  <div
                    class="w-16 h-16 rounded-full border-4 border-parqay-primary p-1"
                  >
                    <svg-icon
                      icon-class="link"
                      class="w-12 h-12 text-parqay-primary"
                    />
                  </div>
                </div>
                <div
                  class="flex flex-col col-span-3 items-center justify-center"
                >
                  <span>Email listing links</span>
                  <div class="px-4 py-2">
                    <div class="mt-1 flex rounded-md shadow-sm">
                      <span
                        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                      >
                        <svg-icon
                          icon-class="mail"
                          class="h-6 w-6"
                        />
                      </span>
                      <input
                        id="email"
                        type="text"
                        name="email"
                        class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                        placeholder="user@domain.com"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button class="px-4 py-2 bg-green-400 rounded text-white">
                + Add to Workflow
              </button>
            </div>
          </div>
          <div class="pt-8 pb-2">
            <div
              class="h-24 rounded-lg bg-white shadow-sm border hover:shadow-xl relative"
            >
              <div class="grid grid-cols-4 h-full">
                <div
                  class="flex border-r col-span-1 items-center justify-center"
                >
                  <div
                    class="w-16 h-16 rounded-full border-4 border-parqay-primary p-3"
                  >
                    <svg-icon
                      icon-class="send"
                      class="w-8 h-8 text-parqay-primary"
                    />
                  </div>
                </div>
                <div
                  class="flex flex-col col-span-3 items-center justify-center"
                >
                  <span>Autopost listing to Craigslist</span>
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button class="px-4 py-2 bg-green-400 rounded text-white">
                + Add to Workflow
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SlideBar>
</template>