var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.files.length > 0)?_c('div',{},[_c('viewer',{attrs:{"images":_vm.files.filter(function (f) { return _vm.detectIsFileType(f, 'image'); })}},[_c('div',{staticClass:"grid grid-cols-3 gap-2"},_vm._l((_vm.files.filter(function (f) { return _vm.detectIsFileType(f, 'image'); }
          )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"relative",on:{"mouseover":function () {
  _vm.$set(f, '$visible.remove', true);
  _vm.files.forEach(function (f, j) {
    if (i == j) { return }
    _vm.$set(f, '$visible.remove', false);
  })
}}},[_c('img',{staticClass:"w-full object-cover hover:opacity-50 cursor-pointer border rounded overflow-hidden",staticStyle:{"max-height":"150px"},attrs:{"src":f.url,"alt":""}}),(!_vm.readOnly && _vm.$deepGet(f, '$visible.remove'))?_c('div',{staticClass:"absolute",staticStyle:{"right":"-8px","top":"-8px"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(f)}}},[_c('div',{staticClass:"flex flex-shrink-0 rounded-full bg-red-500 hover:bg-red-600 cursor-pointer p-1"},[_c('svg-icon',{staticClass:"w-4 h-4 text-white",attrs:{"icon-class":"close"}})],1)]):_vm._e()])}),0)]),_c('div',{staticClass:"flex flex-col space-y-2 pb-3"},_vm._l((_vm.files.filter(function (f) { return _vm.detectIsFileType(f, 'video'); }
        )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"relative",on:{"mouseover":function () {
  _vm.$set(f, '$visible.remove', true);
  _vm.files.forEach(function (f, j) {
    if (i == j) { return }
    _vm.$set(f, '$visible.remove', false);
  })
}}},[_c('video',{staticClass:"w-full object-cover hover:opacity-50 cursor-pointer border rounded overflow-hidden",attrs:{"controls":""}},[_c('source',{attrs:{"src":f.url,"type":f.type}}),_vm._v("\n            Your browser does not support the video tag.\n          ")]),(!_vm.readOnly && _vm.$deepGet(f, '$visible.remove'))?_c('div',{staticClass:"absolute",staticStyle:{"right":"-8px","top":"-8px"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(f)}}},[_c('div',{staticClass:"flex flex-shrink-0 rounded-full bg-red-500 hover:bg-red-600 cursor-pointer p-1"},[_c('svg-icon',{staticClass:"w-4 h-4 text-white",attrs:{"icon-class":"close"}})],1)]):_vm._e()])}),0),_c('div',{staticClass:"flex flex-col space-y-2 pb-3"},[_vm._l((_vm.files.filter(function (f) { return _vm.detectIsFileType(f, 'audio'); }
        )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"flex relative"},[_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":f.url,"type":f.type}}),_vm._v("\n            Your browser does not support the audio element.\n          ")]),(!_vm.readOnly)?_c('div',{staticClass:"absolute right-2 top-1/2 transform -translate-y-1/2"},[_c('div',{staticClass:"\n                rounded-full\n                bg-red-500 \n                hover:bg-red-600\n                cursor-pointer\n                text-white\n                flex\n                justify-center\n                items-center\n                p-1\n              ",on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(f)}}},[_c('svg-icon',{staticClass:"h-4 w-4",attrs:{"icon-class":"close"}})],1)]):_vm._e()])}),_vm._l((_vm.files.filter(function (f) { return _vm.detectIsFileType(f, 'pdf'); }
        )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"flex relative"},[_c('a',{staticClass:"flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.previewMedia(f)}}},[_vm._m(0,true),_c('span',{staticClass:"font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis"},[_vm._v("\n              "+_vm._s(f.name)+"\n            ")])]),(!_vm.readOnly)?_c('div',{staticClass:"absolute right-2 top-1/2 transform -translate-y-1/2"},[_c('div',{staticClass:"\n                rounded-full\n                bg-red-500 \n                hover:bg-red-600\n                cursor-pointer\n                text-white\n                flex\n                justify-center\n                items-center\n                p-1\n              ",on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(f)}}},[_c('svg-icon',{staticClass:"h-4 w-4",attrs:{"icon-class":"close"}})],1)]):_vm._e()])}),_vm._l((_vm.files.filter(function (f) { return _vm.detectIsFileType(f, 'word'); }
        )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"flex relative"},[_c('a',{staticClass:"flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.previewMedia(f)}}},[_vm._m(1,true),_c('span',{staticClass:"font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis"},[_vm._v("\n              "+_vm._s(f.name)+"\n            ")])]),(!_vm.readOnly)?_c('div',{staticClass:"absolute right-2 top-1/2 transform -translate-y-1/2"},[_c('div',{staticClass:"\n                rounded-full\n                bg-red-500 \n                hover:bg-red-600\n                cursor-pointer\n                text-white\n                flex\n                justify-center\n                items-center\n                p-1\n              ",on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(f)}}},[_c('svg-icon',{staticClass:"h-4 w-4",attrs:{"icon-class":"close"}})],1)]):_vm._e()])}),_vm._l((_vm.files.filter(function (f) { return _vm.detectIsFileType(f, 'excel'); }
        )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"flex relative"},[_c('a',{staticClass:"flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.previewMedia(f)}}},[_vm._m(2,true),_c('span',{staticClass:"font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis"},[_vm._v("\n              "+_vm._s(f.name)+"\n            ")])]),(!_vm.readOnly)?_c('div',{staticClass:"absolute right-2 top-1/2 transform -translate-y-1/2"},[_c('div',{staticClass:"\n                rounded-full\n                bg-red-500 \n                hover:bg-red-600\n                cursor-pointer\n                text-white\n                flex\n                justify-center\n                items-center\n                p-1\n              ",on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(f)}}},[_c('svg-icon',{staticClass:"h-4 w-4",attrs:{"icon-class":"close"}})],1)]):_vm._e()])}),_vm._l((_vm.files.filter(function (f) { return _vm.detectIsFileType(f, 'ppt'); }
        )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"flex relative"},[_c('a',{staticClass:"flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.previewMedia(f)}}},[_vm._m(3,true),_c('span',{staticClass:"font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis"},[_vm._v("\n              "+_vm._s(f.name)+"\n            ")])]),(!_vm.readOnly)?_c('div',{staticClass:"absolute right-2 top-1/2 transform -translate-y-1/2"},[_c('div',{staticClass:"\n                rounded-full\n                bg-red-500 \n                hover:bg-red-600\n                cursor-pointer\n                text-white\n                flex\n                justify-center\n                items-center\n                p-1\n              ",on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(f)}}},[_c('svg-icon',{staticClass:"h-4 w-4",attrs:{"icon-class":"close"}})],1)]):_vm._e()])})],2)],1):_vm._e(),_vm._t("default"),(!_vm.readOnly)?_c('div',{staticClass:"flex items-center justify-center my-3 bg-gray-100"},[(_vm.loading)?_c('div',{staticClass:"flex items-center justify-center",staticStyle:{"height":"222px"}},[_c('a-icon',{staticStyle:{"font-size":"35px"},attrs:{"type":"loading"}})],1):_c('pq-dropzone',{attrs:{"height":"280px","intro":"Drop PDF file to sign here"},on:{"file-added":_vm.handleFileAdd}})],1):_vm._e(),_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"flex items-center space-x-4"},_vm._l((_vm.errors),function(error,i){return _c('a-tag',{key:("error-" + i),attrs:{"color":"pink"}},[_vm._v("\n          "+_vm._s(error)+"\n        ")])}),1)])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-center border rounded p-2 bg-gray-50"},[_c('span',{staticClass:"font-bold text-lg text-pink-500"},[_vm._v("PDF")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-center border rounded p-2 bg-gray-50"},[_c('span',{staticClass:"font-bold text-lg text-parqay-primary"},[_vm._v("Word")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-center border rounded p-2 bg-gray-50"},[_c('span',{staticClass:"font-bold text-lg text-green-400"},[_vm._v("Excel")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-center border rounded p-2 bg-gray-50"},[_c('span',{staticClass:"font-bold text-lg text-orange-400"},[_vm._v("PPT")])])}]

export { render, staticRenderFns }