import Geohash from "latlon-geohash";

/** 
The cell sizes of geohashes of different lengths are as follows; note that the cell width reduces moving away from the equator (to 0 at the poles):

Geohash length	Cell width	Cell height
1	≤ 5,000km	×	5,000km
2	≤ 1,250km	×	625km
3	≤ 156km	×	156km
4	≤ 39.1km	×	19.5km
5	≤ 4.89km	×	4.89km
6	≤ 1.22km	×	0.61km
7	≤ 153m	×	153m
8	≤ 38.2m	×	19.1m
9	≤ 4.77m	×	4.77m
10	≤ 1.19m	×	0.596m
11	≤ 149mm	×	149mm
12	≤ 37.2mm	×	18.6mm
 */
export const convertLocationToGeohash = (l, precision=5) => {
    const geohash = Geohash.encode(l.lat, l.lon, precision);
    console.log("geohash >> ", geohash);
    return geohash;
}

export const searchAround = (l) => {
    const centerHash = convertLocationToGeohash(l);
    const neighboursObj = Geohash.neighbours(centerHash);
    const neighboursArr = Object.keys(neighboursObj).map(n => neighboursObj[n]);
    console.log("neighbours >> ", neighboursArr);
    return [centerHash, ...neighboursArr]
}

export const encodeLocation = (l) => {
    const preciseHash = convertLocationToGeohash(l, 12);
    console.log("preciseHash >> ", preciseHash);
    return preciseHash;
}