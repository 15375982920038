import SvgIcon from 'src/views/components/SvgIcon'

export default {
  install (Vue) {
    Vue.component('SvgIcon', SvgIcon)
  }
}

const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('./', true, /\.svg$/)
requireAll(req)