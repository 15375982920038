<script>
import { ref, onMounted, computed } from "@vue/composition-api";
import "instantsearch.css/themes/algolia-min.css";

import RecentSearchItem from "./components/RecentSearchItem.vue";
import StarredSearchItem from "./components/StarredSearchItem.vue";
import ListingSearchItem from "./components/ListingSearchItem.vue";
import ReservationSearchItem from "./components/ReservationSearchItem.vue";
import DocumentSearchItem from "./components/DocumentSearchItem.vue";
import TenantSearchItem from "./components/TenantSearchItem.vue";

import { API } from "./api";

import debounce from "lodash/debounce";

export default {
  components: {
    RecentSearchItem,
    StarredSearchItem,
    ListingSearchItem,
    ReservationSearchItem,
    DocumentSearchItem,
    TenantSearchItem,
  },
  props: {
    searchType: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const searchBar = ref(null);
    const hovering = ref(false);
    const keywords = ref("");
    const searchClient = ref({
      search(requests) {
        return API.search(props.searchType, requests);
      },
    });
    // Example project index: demo_ecommerce
    const searchIndexName = ref("search");
    const searchFunction = (helper) => {
      // Note: this has to use debounce function to avoid thread blocking
      updateKeywords(helper.state.query);
      helper.search();
    };
    const starredSearches = ref(
      computed(() => {
        return ctx.root.$store.state.starredSearches;
      })
    );
    const recentSearches = ref(
      computed(() => {
        return ctx.root.$store.state.recentSearches;
      })
    );
    const searchEngineReady = () => [console.log("searchEngineReady")];
    const updateKeywords = debounce(async (kw) => {
      keywords.value = kw;
      console.log("updateKeywords: " + keywords.value);
    }, 300);
    const clearSearchBarAndKeywords = () => {
      if (searchBar.value) {
        keywords.value = "";
        searchBar.value.value = keywords.value;
        searchBar.value.dispatchEvent(new Event("input"));
      }
    };
    const handleKeyPress = (event) => {
      if (event.key == "Escape") {
        dismiss();
      }
    };
    const removeRecentSearch = (i) => {
      ctx.root.$store.commit("removeRecentSearch", i);
      console.log(JSON.stringify(recentSearches.value));
    };
    const dismiss = () => {
      ctx.emit("dismiss");
    };

    const { $nextTick } = ctx.root;

    onMounted(() => {
      window.addEventListener("keyup", handleKeyPress);
      $nextTick(() => {
        console.log("Dom loaded => " + searchBar.value);
        // ctx.root.$store.commit("clearAllRecentSearches");
      });
    });
    return {
      searchBar,
      keywords,
      hovering,
      recentSearches,
      starredSearches,
      searchClient,
      searchIndexName,
      searchFunction,
      searchEngineReady,
      clearSearchBarAndKeywords,
      removeRecentSearch,
      dismiss,
    };
  },
  methods: {},
};
</script>
<template>
  <div class="dashboard-search-modal fixed z-10 inset-0 overflow-hidden">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-25" @click="dismiss" />
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-2xl
          px-4
          pt-5
          pb-4
          text-left
          shadow-xl
          transform
          transition-all
          sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6
        "
        style="height: 550px"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <ais-instant-search
          :search-client="searchClient"
          :search-function="searchFunction"
          :index-name="searchIndexName"
          @hook:mounted="searchEngineReady"
        >
          <ais-search-box autofocus>
            <div slot-scope="{ currentRefinement, isSearchStalled, refine }">
              <div
                class="
                  bg-white
                  flex
                  items-center
                  rounded-lg
                  shadow-sm
                  border
                  py-1
                  px-2
                  hover:border-blue-300 hover:border-2
                "
              >
                <span class="w-auto flex justify-end items-center text-gray-500 p-2">
                  <svg-icon
                    v-if="!isSearchStalled"
                    icon-class="search-icon"
                    class="h-8 w-8 text-parqay-primary"
                  />
                  <svg-icon v-else icon-class="circular-loader" class="animate-spin h-8 w-8" />
                </span>
                <button
                  v-if="searchType != 'all'"
                  class="
                    px-4
                    py-2
                    rounded-full
                    bg-gray-50
                    border border-gray-300
                    text-center text-md
                  "
                >
                  {{ searchType }}
                </button>
                <form autocomplete="off" class="w-full">
                  <input
                    ref="searchBar"
                    class="
                      w-full
                      rounded
                      px-4
                      py-0
                      border-none
                      focus:outline-none focus:ring-0
                      text-lg
                    "
                    type="search"
                    :value="currentRefinement"
                    placeholder="Search by name, confirmation #, city or, address"
                    @input="refine($event.currentTarget.value)"
                  />
                </form>

                <button
                  v-if="keywords"
                  class="p-2 text-gray-400 text-center"
                  @click="clearSearchBarAndKeywords"
                >
                  <svg-icon
                    icon-class="delete"
                    class="w-6 h-6 hover:text-gray-600 cursor-pointer"
                  />
                </button>
                <button
                  class="
                    w-10
                    h-6
                    rounded
                    bg-gray-50
                    border border-gray-300
                    text-gray-400 text-center text-sm
                  "
                  @click="dismiss"
                >
                  esc
                </button>
              </div>
            </div>
          </ais-search-box>

          <template v-if="!keywords">
            <div class="overflow-hidden pb-8" style="height: 450px">
              <div
                v-if="recentSearches.length == 0 && starredSearches.length == 0"
                class="flex items-center justify-center h-full"
              >
                <svg-icon icon-class="PARQAY-watermark" class="w-auto h-auto pt-24" />
              </div>
              <smooth-scroll v-else>
                <div v-if="recentSearches.length > 0">
                  <div class="my-3 text-base font-bold text-gray-600 flex items-center">
                    <svg-icon icon-class="history" class="h-6 w-6 mx-2" />
                    <span>Recent Searches</span>
                  </div>
                  <div v-for="(item, i) in recentSearches" :key="`recent-search-${i}`" class="py-1">
                    <recent-search-item :index="i" :model="item" @dismiss="dismiss()" />
                  </div>
                </div>
                <div v-if="starredSearches.length > 0">
                  <div class="my-3 text-base font-bold text-gray-600">
                    <span>Starred</span>
                  </div>
                  <div
                    v-for="(item, i) in starredSearches"
                    :key="`starred-search-${i}`"
                    class="py-1"
                  >
                    <starred-search-item :index="i" :model="item" @dismiss="dismiss()" />
                  </div>
                </div>
              </smooth-scroll>
            </div>
          </template>
          <template v-else>
            <smooth-scroll class="mt-4">
              <ais-hits
                :class-names="{
                  'ais-Hits': 'search-results',
                  'ais-Hits-item': 'search-results-item',
                }"
              >
                <div slot="item" slot-scope="{ item, index }">
                  <!-- Listing Search Item -->
                  <listing-search-item
                    v-if="item.index_type == 'listing'"
                    :index="index"
                    :model="item"
                    @dismiss="dismiss()"
                  />
                  <!-- Reservation Search Item -->
                  <reservation-search-item
                    v-if="item.index_type == 'reservation'"
                    :index="index"
                    :model="item"
                    @dismiss="dismiss()"
                  />
                  <!-- Event Search Item -->
                  <!-- TODO: beta v2 - event search -->
                  <!-- <event-search-item
                    v-if="item.type == 'event'"
                    :index="index"
                    :model="item"
                  /> -->
                  <!-- Tenant Search Item -->
                  <tenant-search-item
                    v-if="item.index_type == 'tenant'"
                    :index="index"
                    :model="item"
                    @dismiss="dismiss()"
                  />
                  <!-- Document Search Item -->
                  <document-search-item
                    v-if="item.index_type == 'document'"
                    :index="index"
                    :model="item"
                    @dismiss="dismiss()"
                  />
                </div>
              </ais-hits>
            </smooth-scroll>
            <ais-pagination
              :class-names="{
                'ais-Pagination': 'mt-2',
                'ais-Pagination-link': 'text-parqay-primary',
              }"
            />
          </template>
        </ais-instant-search>
        <div class="absolute inset-x-0 bottom-0 h-8 ...">
          <div class="absolute bottom-4 right-4">
            <img
              class="h-auto w-36 object-contain"
              src="/static/img/dashboard/search-by-algolia-light-background.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.dashboard-search-modal .search-results {
  height: 380px !important;
}
.dashboard-search-modal .search-results-item {
  @apply my-2;
}
.dashboard-search-modal .ais-Hits-item {
  width: 100% !important;
  margin-top: 0.5rem !important;
  margin-left: 1rem !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
}
.dashboard-search-modal .ais-Hits-list {
  padding: 1rem 0 !important;
}
.ais-Pagination-link {
  --tw-text-opacity: 1;
  color: rgba(88, 80, 236, var(--tw-text-opacity)) !important;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  --tw-text-opacity: 1;
  background-color: rgba(88, 80, 236, var(--tw-text-opacity)) !important;
  border-color: rgba(88, 80, 236, var(--tw-text-opacity)) !important;
  color: white !important;
}
</style>
