import { deepGet, deepSet } from "src/plugins/prototype";

const HostDashbord = () =>
  import(/* webpackChunkName: "hosting" */ "src/views/dashboard/Index.vue");

const ListingFrame = () =>
  import(/* webpackChunkName: "listing" */ "src/views/listings/new/Index.vue");
const TenantFrame = () =>
  import(/* webpackChunkName: "tenant" */ "src/views/non-host/Index.vue");

let routes = [
  {
    path: "/dashboard",
    meta: {
      requiresAuth: true,
      requiresFullProfile: true
    },
    component: HostDashbord,
    children: [
      {
        path: "reservations",
        name: "dashboard-reservations",
        component: () => import("src/views/reservations/View")
      },
      {
        path: "reservations/new",
        name: "dashboard-new-reservation",
        component: () => import("src/views/reservations/new/View.vue")
      },
      {
        path: "reservations/:id",
        name: "ReservationsDetails",
        component: () => import("src/views/reservations/details/View.vue")
      },
      {
        path: "/",
        name: "dashboard",
        component: () => import("src/views/dashboard/View.vue")
      },
      // Admin
      {
        path: "/admin/payout",
        name: "admin-payout",
        component: () => import("src/views/admin/Payout.vue")
      },
      {
        path: "/admin/seo",
        name: "admin-seo-editor",
        component: () => import("src/views/admin/SEOEditor.vue")
      },
      {
        path: "listings",
        name: "Listings",
        component: () => import("src/views/listings/list/View.vue")
      },
      {
        path: "listings/:id",
        name: "ListingsDetail",
        component: () => import("src/views/listings/detail/View.vue")
      },
      {
        path: "payments",
        name: "payment-transactions",
        component: () => import("src/views/payments/View.vue")
      },
      {
        path: "tenants",
        name: "Tenants",
        component: () => import("src/views/tenants/View.vue")
      },
      {
        path: "tenant/invite",
        name: "AddTenant",
        component: () => import("src/views/tenants/Invite.vue")
      },
      {
        path: "messaging",
        name: "dashboard-messaging",
        component: () => import("src/views/messaging/View.vue")
      },
      {
        path: "account-settings",
        name: "HostDashboardAccountSettings",
        component: () => import("src/views/settings/View.vue")
      },
      {
        path: "events",
        name: "HostDashboardEvents",
        component: () => import("src/views/events/View.vue")
      },
      {
        path: "documents",
        name: "dashboard-documents",
        component: () => import("src/views/documents/View.vue")
      },
      {
        path: "documents/templates",
        name: "DashboardDocumentsTemplates",
        component: () => import("src/views/documents/ManageTemplate.vue")
      },
      {
        path: "automation",
        name: "Automation",
        component: () => import("src/views/automation/View.vue")
      },
      {
        path: "vehicles",
        name: "dashboard-vehicles",
        component: () => import("src/views/vehicles/View.vue")
      },
      {
        path: "towing",
        name: "dashboard-towing",
        component: () => import("src/views/towing/View.vue")
      },
      {
        path: "maintenance",
        name: "dashboard-maintenance",
        component: () => import("src/views/maintenance/View.vue")
      },
      {
        path: "team",
        name: "TeamManagement",
        component: () => import("src/views/team/View.vue")
      },
      {
        path: "team/invite",
        name: "TeamManagementInvite",
        component: () => import("src/views/team/Invite.vue")
      },
      {
        path: "developers",
        name: "dashboard-developers",
        component: () => import("src/views/developers/View.vue")
      }
    ]
  },
  // Listing new flow
  {
    path: "/listing",
    meta: {
      requiresAuth: true, // change this to true in production mode
      requiresFullProfile: true
    },
    component: ListingFrame,
    children: [
      {
        path: "",
        name: "AddHostingList",
        component: () => import("src/views/listings/new/View.vue")
      },
      {
        path: "parking",
        name: "AddHostingListGeneralFlow",
        component: () =>
          import("src/views/listings/new/general-parking/View.vue")
      },
      {
        path: "event-parking",
        name: "AddHostingListEventParking",
        component: () => import("src/views/listings/new/event-parking/View.vue")
      },
      {
        path: "stay-coworking",
        name: "AddHostingListStayAndCoworkingListing",
        component: () =>
          import("src/views/listings/new/stay-coworking/View.vue")
      },
      {
        path: "car-rentals",
        name: "AddHostingListCarListing",
        component: () => import("src/views/listings/new/car-rentals/View.vue")
      },
      {
        path: "services",
        name: "AddHostingListServiceListing",
        component: () => import("src/views/listings/new/services/View.vue")
      }
    ]
  },
  // Tenant flow
  {
    path: "/tenant",
    meta: {
      // requiresAuth: true, // change this to true in production mode
      requiresFullProfile: true
    },
    component: TenantFrame,
    children: [
      // tenant pages start
      {
        path: "home",
        name: "tenant-home",
        component: () => import("src/views/non-host/home/View.vue"),
        props: route => ({ ...route.query, ...route.params })
      },
      // {
      //   path: "reservations",
      //   name: "TenantReservations",
      //   component: () => import("src/views/non-host/reservations/View.vue")
      // },
      {
        path: "reservations",
        name: "tenant-reservations",
        component: () => import("src/views/non-host/reservations/View.vue")
      },
      {
        path: "reservations/:id",
        name: "TenantReservationsDetails",
        component: () => import("src/views/non-host/reservations/Details.vue")
      },
      {
        path: "documents",
        name: "tenant-documents",
        component: () => import("src/views/non-host/documents/View.vue")
      },
      {
        path: "documents/action/:actionName",
        name: "tenant-documents-actions",
        component: () => import("src/views/non-host/documents/View.vue")
      },
      {
        path: "vehicles",
        name: "tenant-vehicles",
        component: () => import("src/views/non-host/vehicles/View.vue")
      },
      {
        path: "payments",
        name: "payments-history",
        component: () => import("src/views/non-host/payments/View.vue")
      },
      {
        path: "settings",
        name: "TenantSettings",
        component: () => import("src/views/non-host/settings/View.vue")
      },
      {
        path: "maintenance",
        name: "TenantMaintenance",
        component: () => import("src/views/non-host/maintenance/View.vue")
      }
    ]
  },
  // Attendant
  {
    path: "/attendant",
    meta: {
      requiresAuth: false,
      requiresFullProfile: false
    },
    component: () => import(/* webpackChunkName: "attendant" */ "src/views/team/mobile/attendant/Index.vue"),
    children: [
      {
        path: "reservations",
        name: "attendant-reservations",
        component: () => import(/* webpackChunkName: "attendant" */ "src/views/team/mobile/attendant/Reservation.vue")
      }
    ]

  },
  // Docs flow
  {
    path: "/docs/:sectionName?",
    name: "DocsHome",
    component: () => import("src/views/docs/View.vue")
  },
  // routes without /dashboard prefix
  {
    path: "/login",
    name: "login",
    component: () => import("src/views/auth/Login.vue")
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("src/views/auth/SignUp.vue")
  },
  {
    path: "/login/tenant",
    name: "tenant-login",
    component: () => import("src/views/auth/TenantLogin.vue")
  },
  {
    path: "/login/:url_slug",
    name: "white-label-login",
    component: () => import("src/views/auth/WhiteLabelLogin.vue")
  },
  {
    path: "/sign-up/tenant",
    name: "tenant-signup",
    component: () => import("src/views/auth/TenantSignUp.vue")
  },
  {
    path: "/invite/reservation/:invitation_code",
    name: "reservation-invite",
    component: () =>
      import("src/views/reservations/components/redirect/Invite.vue")
  },
  // Redirects
  {
    path: "/redirects/stripe-connect",
    name: "redirect-stripe-connect",
    component: () =>
      import("src/views/redirects/StripeConnect.vue")
  }
]

// All child-routes should inherit parent requiresAuth state
routes.forEach(route => {
  if (deepGet(route, "meta.requiresAuth", false)) {
    route.children.forEach(childRoute => {
      deepSet(childRoute, "meta.requiresAuth", true);
    })
  }
  if (deepGet(route, "meta.requiresFullProfile", false)) {
    route.children.forEach(childRoute => {
      deepSet(childRoute, "meta.requiresFullProfile", true);
    })
  }
})
export default routes;
