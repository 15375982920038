<script>
import Dropdown from "src/views/dropdowns/View";
import { nextTick, onMounted, reactive, ref } from "@vue/composition-api";
import { API as HostAPI } from "src/views/reservations/api";
import { API as TenantAPI } from "src/views/non-host/reservations/api";

export default {
  components: {
    Dropdown
  },
  props: {
    role: String,
    typeFilter: {
      type: String
    },
    disable: {
      type: Boolean,
      default: false
    },
    autoSelectFirstOnBlur: {
      type: Boolean,
      default: true
    },
    showSelectAll: {
      type: Boolean,
      default: true
    },
    preSelectReservationId: {
      type: String,
      default: null
    }
  },
  setup(props, ctx) {
    const loading = ref(false);
    const reservations = ref([]);
    const dataSource = ref([]);
    const dataSourceSearchResults = ref([]);
    const keyword = ref(null);
    const API = props.role === "host" ? HostAPI : TenantAPI;
    const selectResult = value => {
      console.log("onSelect", value);
      if (value === "all") {
        resetSelect();
        return;
      }
      const selectedString = dataSource.value.filter(m => m.value === value)[0]
        .value;
      const selected = JSON.parse(selectedString);
      console.log("selected", selected);
      keyword.value = selected.listing.name;
      loading.value = false;
      ctx.emit("selected", selected);
    };
    const resetSelect = () => {
      ctx.emit("reset");
    };
    onMounted(() => {
      loading.value = true;
      API.list().then(res => {
        console.log(`reservation list result[${props.role}]:`, res);
        reservations.value = res.data;

        dataSource.value = res.data.map(v => ({
          value: JSON.stringify(v),
          text: v.id
        }));
        dataSourceSearchResults.value = dataSource.value;
        loading.value = false;
        nextTick(() => {
          setTimeout(() => {
            if (props.preSelectReservationId) {
              const preSelectReservation = reservations.value.find(
                v => v.id === props.preSelectReservationId
              );
              if (preSelectReservation) {
                keyword.value = preSelectReservation.id;
              }
            }
          }, 500);
        });
      });
    });
    return {
      loading,
      keyword,
      reservations,
      dataSource,
      dataSourceSearchResults,
      selectResult,
      resetSelect,
      JSON,
      console
    };
  }
};
</script>
<template>
  <div class="flex space-x-2 items-center">
    <a-tag v-if="typeFilter" color="geekblue">
      {{ typeFilter }}
    </a-tag>
    <dropdown
      input-mode
      :input-mode-props="{
        model: keyword,
        placeholder: 'Search Reservations',
        customDataSource: true,
        dataSource: dataSourceSearchResults,
        loading: loading,
        disabled: disable,
        onSearch(searchText) {
          loading = true;
          console.log('onSearch', searchText);
          dataSourceSearchResults = !searchText
            ? dataSource.slice(0, 20)
            : dataSource
                .filter(mString =>
                  JSON.parse(mString.value)
                    .listing.name.toLowerCase()
                    .includes(searchText.toLowerCase())
                )
                .slice(0, 20);
        },
        onSelect: selectResult,
        onChange(value) {
          console.log('onChange', value);
          keyword = value;
          loading = true;
          if (dataSourceSearchResults.length == 0) {
            loading = false;
          }
        }
      }"
      :width="72"
      @update-input-mode-model="
        m => {
          console.log('update-input-mode-model', m);
          if (autoSelectFirstOnBlur) {
            selectResult(m.value);
          }
        }
      "
    >
      <template #custom-search-input-menu>
        <a-select-option
          v-for="(ds, i) in dataSourceSearchResults"
          :key="`reservation-search-input-select-${i}`"
          :value="ds.value"
        >
          <div class="flex space-x-2">
            <div>
              <a-tag color="geekblue">
                {{ $deepGet(JSON.parse(ds.value), "listing.type") }}
              </a-tag>
            </div>
            <div class="flex flex-col space-y-1">
              <span class="text-parqay-primary font-medium text-xs">
                #{{ ds.text }}
              </span>
              <div>
                <a-tag color="orange">
                  Host: {{ JSON.parse(ds.value).host_name || "N/A" }}
                </a-tag>
              </div>
              <span class="text-xs font-light whitespace-normal">
                {{ $deepGet(JSON.parse(ds.value), "listing.name") }}
              </span>
            </div>
          </div>
        </a-select-option>
      </template>
      <template #custom-search-input-search-footer>
        <a-select-option v-if="showSelectAll" key="all" class="show-all">
          <div class="flex space-x-2">
            <div class="flex flex-col space-y-1">
              <span class="text-parqay-primary font-medium">Any Reservation</span>
              <span class="text-xs font-light whitespace-normal">
                Show all types of reservations under your name</span
              >
            </div>
          </div>
        </a-select-option>
        <a-select-option key="intro" disabled class="show-all">
          <a> Search Reservation by Name </a>
        </a-select-option>
      </template>
    </dropdown>
  </div>
</template>
