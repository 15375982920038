// Define default language: 简体中文 here
var langChs = {
    header: {
        becomeAHost: "成为资源提供者",
        parking: "停车",
        cars: "租车",
        spaces: "租房",
        faqs: "FAQs",
        contact: "联系我们",
        blog: "博客",
        logIn: "登录",
        signUp: "新用户",
        host: "注册新资源",
        list: "注册你的车位",
        hello: "你好",
        dashboard: "资源管理",
        reservations: "订单",
        account: "我的账号",
        logOut: "注销",
    },

    mobileHeader: {
        becomeAHost: "成为资源提供者",
        parking: "停车",
        cars: "租车",
        spaces: "租房",
        faqs: "FAQs",
        contact: "联系我们",
        terms: "法律条款",
        privacy: "隐私政策",
        logIn: "登录",
        signUp: "新用户",
    },

    landingPage: {
        rightTitle: "让我们开始吧!",
        rightWhat: "您今天想要预定什么?",
        rightOptions: ["车位", "汽车", "房间"],
        rightLength: "您想要租多久呢?",
        rightTime: ["小时", "天", "月"],
        rightWhere: "出行地点?",
        rightWhen: "您何时需要?",
        search: "搜索",

        leftTitle: "帮您找到最近的私人车位, 靓车和房间出租",
        leftDescription: "当您需要的时候尽管继续使用您的车位, 车辆, 和房间，当您不需要的时候可以使用这些资源赚钱",
    },

    button:{
        socialFacebook: "使用Faceboook登录",
        socialGoogle: "使用Google+登录",
        search: "搜索",
        reserve: "预定",
    },

    accountInfo: {
        first: "名字",
        last: "姓",
        email: "Email",
        phone: "电话",
        update: "更新",

        updateDetails: "更新 详情",
        updatePrice: "更新 价格",
        updateAvailability: "更新 允许使用时间",
        updating: "正在更新...",
        loading: "载入中...",
        connecting: "连接您的车辆...",
        reset: "重置电子钥匙",
        control: "控制您的车辆",

        license:"驾照",
        registration: "车辆注册信息",
        insurance: "保险证明",
        lease: "出租证明",
    },

    accountDetails: {
        first: "名字",
    },

    signIn:{
        welcome: "欢迎回来",
        email: "Email",
        password: "密码",
        passwordBox: "密码",
        forgetPassword: "忘记密码了?",
        signIn: "登录",
    },

    signUp:{
        title: "新用户",
        first: "名字",
        last: "姓",
        email: "Email地址",
        phone: "电话号码",
        password: "密码",
        confirm: "确认密码",
        agree: "我同意Parqyt, Inc.公司的法律条款和隐私政策",
        signUp: "注册!",
    },

    contactUs:{
        contact: "联系我们",
        contactDescription: "请在以下选项中选出一个",
        email: "给我们发Email",
        emailDescription: "给我们发送email，我们会尽快回复您",
        call: "给我们打电话",
        callDescription: "如果您需要即刻联系到我们，请拨415-688-2011",
    },

    faq:{
        title: "常见问题FAQ",
        ////////////
        ////////////
        ////////////
        user: "用户",
        how: "如何使用Parqyt?",
        howDescription: "Parqyt is a parking reservation platform. We partner with various facilities to provide you with lots of parking options. Parqyt does not own or operate any of the locations listed, we do work very closely with the lots ensure you have a seamless parking experience every time you park!",
        speak: "我需要和出租资源的个人联系吗?",
        speakDescription: "Many of our hosts choose to not meet with the parker, this would take a lot of time out of their day.",
        guaranteed: "能保证我的停车位一定可以按时按需使用吗?",
        guaranteedDescription: "Your parking will be guaranteed, but we cannot guarantee you a specific spot in the lot.",
        download: "我一定需要下载Parqyt App吗?",
        downloadDescription: "You do not have to, though we encourage you to do so to keep all of your reservations in one place. We also send out frequent deals to our app that you may otherwise miss via email.",
        payment: "我的付款信息安全吗?",
        paymentDescription: "Yes! All of our payments and payouts are done with Stripe, a multi-million dollar company that operates in 20 countries, and we don’t store full credit card information anywhere on our servers. Checkout https://stripe.com/ for more information.",
        multiple: "如果我想预定多个订单怎么办?",
        multipleDescription: "Yes, you can make multiple reservations with Parqyt, we actually encourage you to do so! You deserve to get to your destination right away!",
        cancel: "如果资源提供者突然取消了我的订单怎么办?",
        cancelDescription: "We will find you a spot nearby, and fulfill your need.",
        ////////////
        ////////////
        ////////////
        host: "资源提供者",
        who: "我可以知道谁来到我的车位了吗?",
        whoDescription: "Yes, you will know exactly who is parking in your spot. You will have the parkers name, car make and model, and the times they will are coming and going.",
        need: "万一有人来停车了但是我突然急需自己的车位怎么办?",
        needDescription: "Please give us a call at 415-688-2011 and we will find an alternative parking space for the person.",
        paid: "我怎么才能获取共享资源收入?",
        paidDescription: "We can pay you right away via direct deposit or sending a check in the mail.",
        towing: "Parqyt可以提供拖车服务吗?",
        towingDescription: "Yes, we partner with local towing companies should the need arise to use one.",
    },


    parkingBenefits: {
        header: "使用Parqyt的福利",
        guaranteedTitle: "确保您的预定",
        guaranteedDescription: "放心，您的车位，车辆，租房会在您的预定之前准备好",
        safeTitle: "安全 & 放心",
        safeDescription: "我们与Stripe付款平台合作，保证您支付和获取收入的安全",
        communityTitle: "社区",
        communityDescription: "成为我们互惠共赢社区的一员",
        supportTitle: "客户服务",
        supportDescription: "24小时随时提供的客户服务，保证您能获取绝佳的预定和帮助"
    },

    exploreNearby: {
        title: "探索Parqyt",
    },
    parkingNearby: {
        title: "附近的车位",
        description: "按照小时，天，月为单位收费的车位",
        noData: "Could not find any nearby parking",
    },
    spacesNearby: {
        title: "附近的房间",
        description: "房间，整套公寓或者办公地点，应有尽有",
        noData: "Could not find any nearby spaces",
    },
    carsNearby: {
        title: "附近的车辆",
        description: "按照小时，天，月为单位出租的车辆",
        noData: "Could not find any nearby cars",
    },

    download: {
        title: "下载Parqyt",
        description: "为了更好的使用体验,",
        description1: "请下载我们的APP!",
    },

    parkingShare: {
        title: "拥有闲置车位?",
        title1: "现在注册和分享，即可获得收入",
        description: "共享您的车位并获取收入。无论出游还是去上班，您可以随时按需获取收入",
    },

    footer: {
        rights: "All rights reserved",
        /////Book Parking
        bookParking: "预定车位",
        sfParking: "旧金山的车位",
        bostonParking: "波士顿的车位",
        chicagoParking: "芝加哥的车位",
        BottlerockParking: "Bottlerock的车位",
        //////Solutions
        solutions: "解决方案",
        carShare: "租车服务",
        enforcement: "Enforcement",
        fleet: "Fleet Management",
        ///// Company
        company: "关于公司",
        contact: "联系我们",
        faq: "常见问题FAQs",
        terms: "法律条款",
        privacy: "隐私政策",
    },
    /////////// Become A Host Page ///////////////

    becomeAHost: {
        leftTitle: "Earn money on your parking, car or home.",
        leftDescription: "We make it simple to reach thousands of people looking for access to parking, cars and places to stay and work",
        rightTitle: "Let's get started!",
        rightWhat: "What would you like to list?",
        rightDropDown: ["parking", "car", "home/room/condo", "cafe/car", "office/desk", ],
    },

    howItWorks: {
        title: "如何使用",
        listTitle: "免费出租资源",
        listDescription: "分享您的资源完全免费，我们会帮您推广您的资源并且尽量确保您的资源不会闲置",
        priceTitle: "随时更改价格和共享时间",
        priceDescription: "您可以随意掌控资源共享时间和价格，以及您与租用您资源客人的交流方式",
        bookingTitle: "预约 & 支付",
        bookingDescription: "您可以选择马上确认来自顾客的预约请求，付款流程会自动触发",
        paidTitle: "获取收入",
        paidDescription: "我们确保您的收入安全到账，共享收入按月结算",
    },

    /////Cars Landing Page////
    cars: {
        rightTitle: "Let's find you a car!",
        rightLength: "How long do you need the car?",
        rightTime: ["hourly", "daily", "monthly"],
        rightWhere: "Where do you need this?",
        rightWhen: "When do you need it?",

        leftTitle: "Instantly rent cars nearby",
        leftDescription: "Rent a car when you need it, make money when you don't, share your car today!",
        socialFacebook: "Continue with Facebook",
        socialGoogle: "Continue with Google",
    },

    carBenefits: {
        guaranteedTitle: "Guaranteed Cars",
        guaranteedDescription: "Rent confidently knowing your car will be ready when you are.",
        keyTitle: "Keyless",
        keyDescription: "Handing off keys can be bothersome, which is why we offer Keyless entry for all our cars.",
        safeTitle: "Safe & Secure",
        safeDescription: "You're in full control of availability, pricing, and how you interact with users.",
        verifiedTitle: "Verified",
        verifiedDescription: "We verify all users and cars before giving them access."
    },

    carShare: {
        title: "Share your car and earn.",
        description: "Connect your vehicle to earn and share with others or to receive services like gas, car washes, deliveries, and more!",
        monthly: "Monthly",
        hourly: "Hourly",
        daily: "Daily",
    },


    /////Spaces Landing Page////
    spaces: {
        rightTitle: "Book unique places to stay or work",
        rightWhat: "What kind of space do you need?",
        rightOptions: ["stay", "work"],
        rightLength: "How long do you need the space?",
        rightTime: ['hourly', 'daily', 'monthly'],
        rightWhere: "Where?",
        rightWhen: "How long?",

        leftTitle: "Reserve homes, offices, and desks nearby",
        leftDescription: "Rent a house, a room, an office or a desk! Reserve spaces when you need it, make money when you don't, share your space today!",
        socialFacebook: "Continue with Facebook",
        socialGoogle: "Continue with Google",
    },

    spaceBenefits: {
        guaranteedTitle: "Guaranteed Reservation",
        guaranteedDescription: "Reserve confidently knowing your seat, desk, or home will be available upon your arrival.",
        verifiedTitle: "Verified",
        verifiedDescription: "All of our spaces have been verified and approved. Arrive knowing everything till be taken care of.",
        communityTitle: "Community",
        communityDescription: "A community that works together to give you access to cars, parking and spaces.",
        supportTitle: "Support",
        supportDescription: "We understand that things happen sometimes you need help, which is hy we offer 24/7 support."
    },

    spaceShare: {
        title: "Have an extra space? We have someone for it.",
        description: "List your home or room to welcome travelers. List your cafe or restaurant as a coworking space to extend your day time revenue!",
    },

    ///////DASHBOARD////

    dashboard:{
        earnings: "Check Earnings",
        overview: "Overview",
        locate: "Locate Me",
        address: "Address",
        city: "City",
        state: "State",
        zip: "Zip Code",
        event: "Events",
        availability: "Availability",
        price: "Price",
        yesNo: ["Yes", "No"],
        day:"Day",
        from:"From",
        to: "To",
        add: "Add",
        remove:"Remove",
        back: "Back",
        new: "Add new Listing",

        available:"Is this available?",
        lot: "Lot Name",
        type: "Lot Type",
        typeCar: "What kind of space is your car parked in?",
        homeListing: "Home Listing Title",
        typeQ: "Is this a Home or a Business?",
        homeBusiness: ["Home", "Business"],
        guestQ: "What guests Have?",
        entirePrivate: ["Entire Place", "Private Room","Desk/Office"],

        ////Amenities////
        amenities: "What amenities do you offer?",

        spaceTitle: "Space Amenities",
        space: "Space",
        ac: "A/C",
        heat: "Heat",
        wifi: "WiFi",
        desk: "Desk/workspace",
        washer: "Washer & Dryer",

        kitchen: "Kitchen",
        fridge: "Fridge",
        gas: "Gas Stove",
        electric: "Electric Stove",
        pots: "Pots & Pans",
        dish: "Dishwasher",

        bathroom: "Bathroom",
        shampoo: "Shampoo",
        towels: "Towels",
        hair: "Hair Dryer",
        iron: "Iron",

        safety: "Safety Items",
        fire: "Fire Extinguisher",
        co: "CO & Smoke Detector",
        first: "First Aid Kit",

        covered: "Covered Space",
        ev: "EV Charging",
        gated: "Gated",
        guarded: "Guarded",
        lit: "Lit",

        parkingWhat: "What can you fit ?",
        compact: "Compact",
        mid: "Mid-sized Sedan",
        large: "Large",
        over: "Oversized (Truck or RV)",

        collect: "We will collect these for you.",
        registration: "Registration",
        insurance: "Insurance",
        license: "License / ID",

        towing: "Would you like an assigned towing company ?",
        yes: "Yes",
        no: "No",
        own: "I have my own",

        packages: "Want to get paid to receive packages here ?",
        packagePrice: "We will pay you to secure packages for pick-up",
        receiveTitle: "Receive Packages",
        receivePrice: "Receive Package ($)",

        continue: "Continue",

        how: "How do guests get to your space ?",

        typeCharge: "Type",
        onOff: "Off/On",


        daily: "Daily",
        hourly: "Hourly",
        monthly: "Monthly",

        change: "Change image",
    },
    ////////////LISTING FLOW/////

    listing:{
        title: "List your property",
        what: "What would you like to list?",
        general: "General Parking",
        event: "Event Parking",
        car: "Car (car-share)",
        stay: "Stay (Home/Room/Condo)",
        coWorking: "Coworking (Office/Desk)",
        photo: "Add Photos",
        photoInfo: "Please upload pictures of your parking space.",
    },
    /////////////////

    general:{
        find: "Let's find your lot",
        locate: "Locate Me",
        save: "Save and Proceed",
        verify: "Verify...",
        lot: "Locate lot",
        details: "Listing details",
        events: "Pick Events",
        photos: "Add Photos",
        schedule: "Schedule & Price",
        pay: "Payout",
        payInfo: "Payout Information",
        type: "What type of building is this?",
        type1: ["Residential", "Business"],
        type2: "residential",
        type3: "Residential",
        type4: "Driveway",
        multi: "Is this a muli-unit building?",
        multi1: ["Yes", "No"],
        information: "Space Information",
        kind: "What kind of parking space do you have ?",
        kind1: ["Driveway", "Garage","Blocked Driveway","Parking Garage","Lot"],
        many: "How many parking spaces do you have to rent out ?",
        space: "Space Details & Requirements",

        parkingCosts: "Parking Costs",
        availability: "Parking Availability",
        toggle: "Toggle Monthly for 24/7 availability",

        paymentSignup: "Please sign up below to receive payouts.",
        existing: "Use Existing Account",
        update: "Update Current Account",
        stripe: "Continue with Stripe",
        questions: "Have Questions?",
        later: "Add payout information later",
        check: "Check your earnings",
        loading: "Loading...",
        status: "Checking your status...",
        uploading:"Uploading your parking lot...",
        success: "Your parking lot has successfully been uploaded",

        typeSchedule: "Type",
        price: "$ Price",
        oversize: "$ Oversize",
        onOff: " Off/On",

    },

    ///////Car Listing/////

    carListing: {
        continue: "Continue",

        carLocation: "Car Location",
        carDetails: "Car Details",
        schedule: "Schedule & Price",
        verifyCar: "Verify Car",
        pay: "Payout",

        where: "Where is your car parked?",
        locate: "Locate Pickup Spot",
        verify1: "正在识别...",
        address: "Address",
        save: "Save and Proceed",
        tell: "Tell us about your car",
        title: "Car Listing Title",
        transTitle: "Type of Transmission",
        transType: ["Automatic","Manual"],
        instant: "Instant or Request Rental?",
        option: ["Instant","Request"],

        spaceInfo: "Space Information",
        kind: "What kind of space is your car in?",
        many: "How many cars do you have?",

        photo: "Add Photos",
        photoDetails: "Please upload photos, we cannot list your vehicle without photos.",

        details: "Car Details",

        features:"Features",
        gps:"GPS",
        convertible: "Convertible",
        usb: "USB Input",
        bt: "Bluetooth",
        toll: "Toll Pass",

        carType: "Type of Car",
        compact: "Compact",
        mid: "Mid-sized Sedan",
        suv: "SUV",
        truck: "Truck",
        luxury: "Luxury",

        powered: "Car powered by",
        gas: "Gas Powered",
        ev: "Electric Vehicle",
        hybrid: "Hybrid",

        packages: "Want to get paid to receive packages in your trunk?",
        yes: "Yes",
        no: "No",

        how: "How do guest get to your car?",

        rentalCost: "Rental Costs & Mileage limits",
        rent: "Rental Cost",
        and: "&",
        miles: "Mileage Limits",

        type: "Type",
        price: "Price",
        mileage: "Mileage",
        onOff: "Off/On",

        package: "Receive Packages",
        packageDetails: "We will pay you to secure packages in your trunk",
        packageButton: "Receive a package $",

        availability: "Car Availability",
        day: "Day",
        from: "From",
        to: "To",
        add: "Add",

        verify: "Verify Your car",
        please: "Please connect your vehicle using Smartcar",
        connect: "Connect Vehicle",

        priceMile: "Price/Miles",
        access: "Car Access",
    

    },

    spaceListing: {
        continue: "Continue",

        location: "Location",
        details: "Details",
        schedule: "Schedule & Cost",
        pay: "Payout",
        maps: "Show Map",

        where: "Where is your space?",
        locate: "Locate Space",
        address: "Address",
        save: "Save and Proceed",

        title: "What kind of space do you have?",
        listing: "Listing Title",
        homeBusiness: "Is this a home or business",
        header: ["Home","Business"],
        guest: "Guest access?",
        guestOptions: ["Entire Place","Private Room","Office/Desk"],

        info: "Space Information",
        tell: "Tell us about your space",
        rooms: "How many rooms / desks?",
        people: "How many people can you host?",
        bathroom: "How many bathrooms?",
        what: "What does the space have?",

        secureTitle: "Secure Deliveries",
        secure: "Can this location accept package deliveries for a fee?",

        how: "How do guests enter?",
        photoDetails: "We cannot list your space without photos",
        rates: "Rates",

        availability: "Availability",
        // secure: "Secure Deliveries",
        packages: "How much would you charge for each secure Amazon package deliveries?",
        pricePackage: "Price / Package",

        uploading: "Uploading your space...",
        success: "Your space has successfully been listed",
    },

    reservation: {
        your: "Your Reservations",
        hosting: "You are Hosting",
        header: ["Host Name","Contact","Price","Location","Date"],
        profile: "Profile",
        reservation: "Reservations",
        calendar: "Calendar",
    },

    profile: {
        account: "Account info",
        host: "Host Settings",
        vehicle: "Vehicle Info",
        documents: "Documents",
        payment: "Payment Methods",
    },

}
import {
    defaultLang
} from "./lang-en"
langChs.default = defaultLang
export default langChs