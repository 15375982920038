<script>
export default {
  name: "state-head",
  components: {},
  props: {
    pic: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    online: {
      type: Boolean,
      default: false,
    },
    plan: {
      type: String,
      default: "",
    }
  },
  setup(props, ctx) {
    return {};
  },
};
</script>
<template>
  <div class="flex flex-col shadow-sm rounded border">
    <div class="flex justify-center items-center py-2 px-2">
      <router-link to="/dashboard/account-settings">
        <div class="h-14 w-14 rounded-full my-auto border-indigo-400 hover:border-parqay-primary border-2 p-0.5">
          <img v-if="pic" class="rounded-full my-auto" :src="pic" alt="picture" />
          <div v-else class="w-full h-full rounded-full my-auto bg-blue-200" />
        </div>
      </router-link>
      <div class="ml-4">
        <div class="flex items-center justify-between">
          <p class="text-black my-auto text-sm font-medium">
            {{ name }}
          </p>
          <a v-if="!$isAdmin()" class="text-xs text-gray-500 flex items-center space-x-1" @click="$router.push('/tenant/home')">
            <svg-icon icon-class="switch" class="h-4 w-4 text-gray-500" />
            <span>Switch Role</span>
          </a>
        </div>
        <div class="flex h-8 mt-2 items-center space-x-6">
          <div class="flex items-center">
            <i class="rounded-full h-2 w-2" :class="[online ? 'bg-green-400' : 'bg-red-400']" />
            <span class="text-blue-400 ml-1 text-sm">{{
              online? "Online": "Offline"
            }}</span>
          </div>
          <div @click="$openPlanSelectSlideCard">
            <!-- <a-tag
              color="gold"
              class="flex items-center px-1 py-1 cursor-pointer capitalize"
            >
              <svg-icon icon-class="leaf-plan" class="mr-1 h-5 w-5" />
              {{ plan }}
            </a-tag> -->
            <a-tag v-if="$isAdmin()" color="pink"
              class="flex items-center ml-2 px-1 py-1 cursor-pointer font-medium capitalize">
              Admin
            </a-tag>
            <a-tag v-else color="gold" class="flex items-center px-1 py-1 cursor-pointer font-medium capitalize">
              <svg-icon icon-class="premium-diamond" class="mr-1 h-5 w-5" />
              Beta Trial
            </a-tag>
          </div>
        </div>
      </div>
    </div>
    <div class="border-t">
      <p align="center" class="my-2">
        <a tag="a" class="text-pink-500" @click="$logout()">
          Log Out
        </a>
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-tag {
    @apply flex px-1.5 py-1;
  }
}
</style>