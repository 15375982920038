<script>
import { nextTick, onMounted, ref, watch } from "@vue/composition-api";
import useGoogleMap from 'src/util/useGoogleMap';

export default {
  props: {
    center: {
      type: Object,
      default: () => {
        return { lat: 36.964, lon: -122.015, text: null };
      }
    },
    markerIconUrl: {
      type: String,
      default: "/static/img/dashboard/PARQAY-marker.svg"
    },
    markerIconSizes: {
      type: Object,
      default: () => {
        return {
          normal: {
            width: 60,
            height: 60
          },
          large: {
            width: 80,
            height: 80
          }
        };
      }
    },
    markerLocations: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // Put marker at center
    simpleFocus: {
      type: Boolean,
      default: false
    },
    disableControl: {
      type: Boolean,
      default: false
    },
    disableScrollZoom: {
      type: Boolean,
      default: false
    },
    disableZoom: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const { getMap } = useGoogleMap();

    var map = ref(null);
    var svgMarker, svgMarkerEnlarged;
    const mapLayer = ref(null);
    const markerLocs = ref(props.markerLocations);
    const markers = ref([]);
    if (props.simpleFocus) {
      markerLocs.value = [props.center];
    }
    watch(
      () => props.center,
      async val => {
        console.log("props.center", props.center);
        if (props.simpleFocus) {
          markerLocs.value = [val];
          const maps = await getMap();
          map.value.setCenter(new maps.LatLng(val.lat, val.lon));
          initMarkers(map.value);
        }
      },
      { deep: true }
    );
    watch(
      () => props.markerLocations,
      val => {
        console.log("props.markerLocations", val);
        markerLocs.value = val;
        initMarkers(map.value, true);
      },
      { deep: true }
    );
    const initMap = async () => {
      const maps = await getMap();
      var sydney = new maps.LatLng(-33.867, 151.195);

      map.value = new maps.Map(mapLayer.value, {
        center: { lat: props.center.lat, lng: props.center.lon },
        zoom: 15,
        disableDefaultUI: props.disableControl,
        gestureHandling: props.disableScrollZoom ? "cooperative" : "auto",
        zoomControl: !props.disableZoom
      });
    };
    const initMarkers = async (mapInstance, updateCenter = false) => {
      const maps = await getMap();
      markers.value.forEach((m, i) => {
        m.setMap(null);
      });
      markers.value = [];
      svgMarker = {
        url: props.markerIconUrl,
        // anchor: new google.maps.Point(25,50),
        scaledSize: new maps.Size(
          props.markerIconSizes.normal.width,
          props.markerIconSizes.normal.height
        ),
        labelOrigin: new maps.Point(
          props.markerIconSizes.normal.width / 2,
          props.markerIconSizes.normal.height + 10
        )
      };
      svgMarkerEnlarged = {
        url: props.markerIconUrl,
        scaledSize: new maps.Size(
          props.markerIconSizes.large.width,
          props.markerIconSizes.large.height
        ),
        labelOrigin: new maps.Point(
          props.markerIconSizes.large.width / 2,
          props.markerIconSizes.large.height + 10
        )
      };
      markerLocs.value.map((v, i) => {
        var label;
        if (v.text) {
          label = {
            text: v.text,
            color: "#524de2",
            fontWeight: "500"
          };
        }
        const marker = new maps.Marker({
          position: { lat: v.lat, lng: v.lon },
          icon: svgMarker,
          label: label,
          map: mapInstance
        });
        marker.addListener("click", () => {
          ctx.emit("marker-clicked", v.id);
          toggleMarker(i, true);
        });
        markers.value.push(marker);
        if (v.active) {
          toggleMarker(i, v.active);
          if (updateCenter) {
            nextTick(() => {
              map.value.panTo(new maps.LatLng(v.lat, v.lon));
            });
          }
        }
      });
      maps.event.addListener(mapInstance, "click", event => {
        console.log(
          "{lat: " + event.latLng.lat(),
          ", lng: " + event.latLng.lng() + "}"
        );
      });
    };

    const toggleMarker = async (i, forceValue) => {
      const maps = await getMap();
      if (forceValue != null) {
        const marker = markers.value[i];
        if (forceValue == true) {
          marker.setIcon(svgMarkerEnlarged);
          marker.setAnimation(maps.Animation.BOUNCE);
          markers.value.map((v, i) => {
            if (v != marker) {
              v.setIcon(svgMarker);
              v.setAnimation(null);
            }
          });
        } else {
          marker.setIcon(svgMarker);
          marker.setAnimation(null);
        }
        return;
      }
    };

    onMounted(async () => {
      await initMap();
      initMarkers(map.value);
    });
    return {
      map,
      mapLayer
    };
  }
};
</script>
<template>
  <div ref="mapLayer" class="absolute inset-0 overflow-hidden" />
</template>
