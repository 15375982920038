<script>
export default {
  name: 'label-item',
  props: {
    label: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: null
    },
    errorMessage: {
      type: String,
      default: null
    }
  }
}
</script>
<template>
  <div class="label-item flex flex-col space-y-2">
    <div class="flex items-center space-x-6 w-full">
      <div class="flex flex-col space-y-1 w-full">
        <div class="flex items-center justify-between">
          <span v-if="label" class="label flex-shrink-0 text-gray-700 text-sm text-left">{{ label }}</span>
          <slot v-else name="label" />
          <slot name="actions" />
        </div>
        <span v-if="desc" class="desc text-gray-500 text-xs text-left">{{ desc }}</span>
      </div>
      <slot name="desc" />
    </div>
    <slot />
    <span v-if="errorMessage" class="error text-pink-500 text-sm font-medium">{{ errorMessage }}</span>
  </div>
</template>