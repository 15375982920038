<script>
import { reactive } from "@vue/composition-api";
import startCase from 'lodash/startCase';
export default {
  props: {
    status: String
  },
  setup(props, ctx) {
    const controls = reactive({
      complete: {
        color: "green"
      },
      request: {
        color: "cyan",
        tooltip:
          "This tenant is requesting the reservation, you have to approve/deny this request before the payment can be captured/refunded. You can go to listing > auto-approve toggle to setup auto-approval"
      },
      pending: {
        color: "orange",
        tooltip:
          "Your invitation has been sent to your tenant, this status means waiting for tenant's further input"
      },
      canceled: {
        color: "pink"
      },
      denied: {
        color: "red"
      }
    });
    return {
      controls,
      startCase
    };
  }
};
</script>
<template>
  <a-tooltip v-if="controls[status].tooltip">
    <template #title>
      {{ controls[status].tooltip }}
    </template>
    <a-tag :color="controls[status].color" status-tag>
      <span>{{ startCase(status) }}</span>
      <a-icon v-if="controls[status].tooltip" type="info-circle" />
      <slot name="action" />
    </a-tag>
  </a-tooltip>
  <a-tag v-else :color="controls[status].color" status-tag>
    <span>{{ startCase(status) }}</span>
    <slot name="action" />
  </a-tag>
</template>
<style lang="scss" scoped>
.ant-tag[status-tag] {
  @apply flex items-center space-x-1.5;
}
</style>