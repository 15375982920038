import request from "src/services/api/request";

export const API = {
  list(filter = {}) {
    return request.get("tenants", {
      filter
    });
  },
  getTenant(tenant_id, on_behalf_of) {
    const payload = {};
    if (on_behalf_of) {
      payload.on_behalf_of = on_behalf_of;
    }
    return request.get(`tenants/${tenant_id}`, payload);
  },
  searchTenantsForReservations(keyword) {
    return request.get(`tenants/reservations/search`, {
      filter: {
        keyword
      }
    });
  },
  updateTenant(tenant_id, payload) {
    return request.put(`tenants/${tenant_id}`, payload);
  },
  getTenantReservations(tenant_id, countMode, on_behalf_of = null) {
    if (countMode) {
      if (on_behalf_of) {
        return request.get(`tenants/${tenant_id}/reservations/count`, {
          on_behalf_of
        });
      }
      return request.get(`tenants/${tenant_id}/reservations/count`);
    } else {
      if (on_behalf_of) {
        return request.get(`tenants/${tenant_id}/reservations`, {
          on_behalf_of
        });
      }
      return request.get(`tenants/${tenant_id}/reservations`);
    }
  },
  inviteSearch(payload) {
    return request.post("tenants/invite/search", { ...payload })
  },
  inviteConfirm(payload) {
    return request.post("tenants/invite/confirm", { ...payload })
  }
};
