import LandingRoutes from "./routes/landing";
import HostRoutes from "./routes/host";
import PrerenderRoutes from "./routes/prerender";

export default [
  {
    path: "/next/:next",
    name: "app-splash",
    component: () => import("src/views/components/AppSplash.vue")
  },
  {
    path: "/fullfill-profile",
    name: "fullfill-profile",
    meta: {
      requiresAuth: true
    },
    component: () => import("src/views/components/FullfillProfile.vue")
  },
  // dashboard routes here
  ...HostRoutes,
  ...PrerenderRoutes,
  {
    path: "/demo",
    name: "landing-demo",
    component: () => import(/* webpackChunkName: "landing" */ "src/views/landing-pages/Demo.vue")
  },
  {
    path: "/org/login",
    name: "org-login",
    component: () => import("src/views/auth/OrgLogin.vue")
  },
  {
    path: "/towing",
    name: "towing-home",
    component: () => import("src/views/towing/Login.vue")
  },
  {
    path: "/towing/:id",
    name: "towing-viewer",
    component: () => import("src/views/towing/Viewer.vue")
  },
  {
    path: "/desktop-only",
    name: "mobile-view-warning",
    component: () => import("src/views/components/MobileViewWarning.vue")
  },
  {
    path: "/incognito/:browserName",
    name: "incognito",
    component: () => import("src/views/Incognito.vue")
  },
  {
    path: "/password-reset/:token",
    name: "password-reset",
    component: () => import("src/views/auth/NewPasswordView.vue")
  },
  // landing routes here (NOTE: MUST add last because 404 routes are handled in landing frame)
  ...LandingRoutes
];
