export default [
  {
    path: "",
    component: () => import(/* webpackChunkName: "landing" */ "src/views/landing-pages/Index.vue"),
    children: [
      {
        path: "",
        name: "landing-home",
        component: () => import(/* webpackChunkName: "landing" */ "src/views/landing-pages/View.vue")
      },
      {
        path: "pricing",
        name: "landing-pricing",
        component: () => import(/* webpackChunkName: "landing" */ "src/views/landing-pages/Pricing.vue")
      },
      {
        path: "white-label",
        name: "landing-white-label",
        component: () => import(/* webpackChunkName: "landing" */ "src/views/landing-pages/WhiteLabel.vue")
      },
      {
        path: '*',
        component: () => (/* webpackChunkName: "page-not-found" */ import("src/views/PageNotFound.vue")),
        name: "page-not-found"
      }
    ]
  }
];