import request from "src/services/api/request";

export const API = {
    search(role, keyword) {
        return request.post(`reservations/search/${role}`, {
            filter: {
                keyword
            },
            per_page: 5
        });
    }
}
