<script>
import { ref, watch, onMounted, onBeforeUnmount } from "@vue/composition-api";

import SlideBar from "src/views/components/components/SlideBar";
import NewPaymentMethodCard from "src/views/components/NewPaymentMethodCard";
import isEqual from "lodash/isEqual";

export default {
  name: "PlanSelectSlideCard",
  components: {
    SlideBar,
    // NewPaymentMethodCard,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, ctx) {
    const { $nextTick } = ctx.root;

    const form = ref([
      {
        id: "beta-trial",
        name: "Beta Trial",
        icon: "premium-diamond",
        details: ["All features are available in our beta trial period"],
        price: {
          amount: "Free",
          freq: "",
        },
      },
      {
        id: "hobby",
        name: "Hobby",
        icon: "leaf-plan",
        details: [
          "Marketplace Listings (up to 3)",
          "Payments: Credit Card & ACH",
          "Maintanence Tracking",
          "Tenant Communication Portal",
        ],
        price: {
          amount: "Free",
          freq: "",
        },
      },
      {
        id: "growth",
        name: "Growth",
        icon: "coin",
        details: [
          "Unlimited Listings",
          "Waived ACH Fees",
          "Custom Applications and Leases",
        ],
        price: {
          amount: "$150",
          freq: "/mo",
        },
      },
      {
        id: "scale",
        name: "Scale",
        icon: "crown",
        details: ["24/7 Virtual Leasing / Sales Office"],
        price: {
          amount: "$250",
          freq: "/mo",
        },
      },
    ]);

    const selectedPlan = ref("beta-trial");

    const selectPlan = (plan) => {
      // selectedPlan.value = plan.id;
    };

    const showAddNewCard = ref(false);

    const toggleAddNewCardSection = () => {
      showAddNewCard.value = !showAddNewCard.value;
    };

    const show = ref(false);

    watch(
      () => props.visible,
      (val) => {
        show.value = val;
      }
    );

    const close = () => {
      ctx.emit("close-plan-select-slide-card");
    };

    onMounted(() => {});

    onBeforeUnmount(() => {});

    return {
      form,
      selectedPlan,
      selectPlan,
      showAddNewCard,
      toggleAddNewCardSection,
      show,
      close,
      isEqual,
    };
  },
};
</script>
<template>
  <SlideBar
    v-slot:default="slotProps"
    v-model="show"
    :width="400"
    backdrop
    close-by-mask
    @close-slide-bar="close"
  >
    <div class="h-full flex-col justify-between">
      <div class="flex justify-between items-center border-b px-6 py-6">
        <p class="font-bold text-lg text-gray-900 my-auto">
          Choose A Plan
        </p>
        <span @click="slotProps.closeSlideBar">
          <svg-icon
            icon-class="close"
            class="cursor-pointer w-6 h-6 text-blue-900"
          />
        </span>
      </div>
      <div class="px-6 py-4">
        <div class="flex flex-col px-6 pt-2">
          <span class="text-center pt-4"
            >Choose a plan that best fits your business requirements.</span
          >
          <router-link
            tag="a"
            target="_blank"
            to="/pricing"
            class="text-center pt-1 pb-4 text-parqay-primary font-semibold"
          >
            Click here for more pricing details
          </router-link>
          <div class="mt-1 border-b" />
          <div class="flex flex-col pt-4">
            <fieldset>
              <legend id="radiogroup-label" class="sr-only">
                Please select a plan you prefer
              </legend>
              <ul
                class="space-y-4"
                role="radiogroup"
                aria-labelledby="radiogroup-label"
              >
                <li
                  v-for="(plan, i) in form"
                  :key="`plan-${i}`"
                  tabindex="0"
                  role="radio"
                  aria-checked="true"
                  class="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-4 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="selectPlan(plan)"
                >
                  <div
                    class="rounded-lg border border-gray-300 bg-white px-4 py-4 hover:border-gray-400 grid grid-cols-5 gap-x-2"
                  >
                    <div class="">
                      <svg-icon :icon-class="plan.icon" class="w-8 h-8" />
                    </div>
                    <div class="flex items-center col-span-3">
                      <div class="text-sm space-y-2">
                        <p class="font-medium text-gray-900">
                          {{ plan.name }}
                        </p>
                        <div class="text-xs text-gray-500">
                          <template v-for="(feature, i) in plan.details">
                            <p :key="`feature-${i}-0`" class="sm:inline">
                              {{ feature }}
                            </p>
                            <span
                              v-if="i < plan.details.length - 1"
                              :key="`feature-${i}-1`"
                              class="hidden sm:inline sm:mx-1"
                              aria-hidden="true"
                              >&middot;</span
                            >
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class="mt-2 flex text-sm sm:mt-0 sm:block sm:text-right">
                      <div class="font-medium text-gray-900">
                        {{ plan.price.amount }}
                      </div>
                      <div class="ml-1 text-gray-500 sm:ml-0">
                        {{ plan.price.freq }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="absolute inset-0 rounded-lg border-2 pointer-events-none"
                    :class="{
                      'border-indigo-500': selectedPlan === plan.id,
                      'border-transparent': selectedPlan !== plan.id,
                    }"
                    aria-hidden="true"
                  />
                </li>
              </ul>
            </fieldset>
          </div>
          <span class="text-center py-4">Choose A Payment Method</span>
          <div class="mt-1 border-b" />
          <div class="flex flex-col pt-4 space-y-2 pb-4">
            <!-- <div class="rounded bg-blue-500 py-4 px-4 flex justify-between">
              <span class="text-white font-bold">Visa 4526</span>
              <span class="text-white text-xs">Billed Monthly on 3rd</span>
            </div>
            <div
              v-if="!showAddNewCard"
              class="rounded border border-indigo-500 py-4 px-4 flex justify-center cursor-pointer"
              @click="toggleAddNewCardSection"
            >
              <span class="text-parqay-primary font-bold hover:text-blue-800"
                >+ Add New Card</span
              >
            </div>
            <new-payment-method-card v-if="showAddNewCard" theme="simple">
              <template slot="extraAction">
                <button
                  class="bg-white border border-parqay-primary hover:text-blue-800 text-parqay-primary font-bold py-2 px-4 rounded w-full"
                  @click="showAddNewCard = false"
                >
                  Cancel
                </button>
              </template>
            </new-payment-method-card>
            <div v-else /> -->
            <span class="text-center text-gray-600 font-medium"
              >You will be noticed by email once our beta period is end. After that
              you'll be able to choose and pay for the plan.</span
            >
          </div>
          <!-- <button
            class="bg-parqay-primary text-white px-4 py-2 text-base rounded-lg hover:bg-blue-500 disabled:opacity-50"
            :disabled="!selectedPlan"
          >
            Upgrade Plan
          </button> -->
          <button
            class="bg-parqay-primary text-white px-4 py-2 text-base rounded-lg hover:bg-blue-500 disabled:opacity-50"
            :disabled="true"
          >
            Upgrade Plan
          </button>
        </div>
      </div>
    </div>
  </SlideBar>
</template>
