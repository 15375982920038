<script>
import EventBus from "src/services/util/event-bus";
import { computed, onBeforeUnmount, onMounted, reactive } from "@vue/composition-api";

export default {
  setup(props, ctx) {
    const controls = reactive({
      visible: false,
      success: true,
      error: null,
    });
    const title = computed(() => {
      if (controls.success) {
        return "Your document was submitted";
      } else {
        return "Identity Verification";
      }
    });
    const handleVerificationResult = (success, error = null) => {
      controls.visible = true;
      controls.success = success;
      controls.error = error;
    };
    const close = () => {
      controls.visible = false;
    }
    onMounted(() => {
      EventBus.$on("show-identity-verification-result", handleVerificationResult);
    });
    onBeforeUnmount(() => {
      EventBus.$off("show-identity-verification-result", handleVerificationResult);
    });
    return {
      controls,
      title,
      close
    };
  },
};
</script>
<template>
  <a-modal :visible="controls.visible" :title="title" :footer="null" :closable="$isDesktopView()"
    @cancel="$isDesktopView() && close()">
    <a-result v-if="controls.success" status="success" title="Thanks for submitting your identity document."
      sub-title="We are processing your verification.">
      <template #extra>
        <a-button v-if="$isDesktopView()" key="continue" type="primary" @click="close()">
          Continue
        </a-button>
      </template>
    </a-result>
    <a-result v-else status="warning" title="There are some issue with your identity document:"
      :sub-title="controls.error">
      <template #extra>
        <a-button key="continue" type="primary" @click="close()">
          Continue
        </a-button>
      </template>
    </a-result>
  </a-modal>
</template>