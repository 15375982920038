import axios from "axios";
import request from "src/services/api/request";

export const API = {
  searchEvents(keyword) {
    return request.get("events/search", { keyword, per_page: 20 });
  },
  listAllPaymentMethods() {
    return request.get("payments/payment-methods", {});
  },
  updateDefaultPaymentMethod(payment_method_id) {
    return request.put(
      `payments/payment-methods/update-default/${payment_method_id}`,
      {}
    );
  },
  deletePaymentMethod(payment_method_id) {
    return request.delete(`payments/payment-methods/${payment_method_id}`, {});
  },
  getSmartCarInfo() {
    return request.get("vehicle", { mobile: false });
  },
  getCarImage(make, model, color) {
    var payload = {
      make,
      model
    };
    if (color) {
      payload.color = color;
    }
    return request.get("vehicle/image-search", payload);
  },
  // Note: image should be URL format
  recognizeCarInImage(image_url) {
    return request.post("vehicle/car-recognition", { image_url });
  },
  recognizePlateNumberInImage(image_url) {
    return request.post("vehicle/plate-number-recognition", { image_url });
  },
  listCarMakes() {
    return axios({
      method: "get",
      url: "https://vpic.nhtsa.dot.gov/api/vehicles/getallmakes?format=json"
    });
  },
  listCarModels(makeID) {
    return axios({
      method: "get",
      url: `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeId/${makeID}?format=json`
    });
  },
  // Help Host / Guest to save vehicle under their OWN account, not on behave of others!
  // Alt option: host invite guest onboard to add their car
  saveSmartCarVehicle(smartcar_auth_code, on_behalf_of = null) {
    return request.post("vehicle", {
      smartcar_auth_code,
      mobile: false,
      ...(on_behalf_of && { on_behalf_of })
    });
  },
  smartCarOperations(
    smartcar_auth_code,
    vehicle_id,
    operation,
    on_behalf_of = null
  ) {
    return request.post("vehicle/operations", {
      smartcar_auth_code,
      vehicle_id,
      operation,
      mobile: false,
      ...(on_behalf_of && { on_behalf_of })
    });
  },
  getVehicle(vehicle_id, on_behalf_of = null) {
    let queryString = `vehicle/${vehicle_id}`;
    if (on_behalf_of) {
      queryString = queryString + `?on_behalf_of=${on_behalf_of}`;
    }
    return request.get(queryString);
  },
  saveVehicle(v, on_behalf_of = null) {
    return request.post("vehicle", {
      make: v.make,
      make_id: v.make_id,
      model: v.model,
      model_id: v.model_id,
      plate_number: v.plate_number,
      color: v.color,
      year: v.year,
      car_image_url: v.image,
      mobile: false,
      ...(on_behalf_of && { on_behalf_of })
    });
  },
  updateVehicle(v, on_behalf_of = null) {
    return request.put("vehicle", {
      vehicle_id: v.id,
      make: v.make,
      make_id: v.make_id,
      model: v.model,
      model_id: v.model_id,
      plate_number: v.plate_number,
      color: v.color,
      year: v.year,
      car_image_url: v.image,
      mobile: false,
      ...(on_behalf_of && { on_behalf_of })
    });
  },
  deleteVehicle(vehicle_id, on_behalf_of = null) {
    return request.delete("vehicle", {
      vehicle_id,
      mobile: false,
      ...(on_behalf_of && { on_behalf_of })
    });
  },
  savePaymentMethod(payment_method, on_behalf_of = null) {
    return request.post("payments/payment-methods", {
      payment_method,
      ...(on_behalf_of && { on_behalf_of })
    });
  },
  // Email
  generateEmailAltTemplate(template) {
    return request.post("messaging/GPT/email/alt-template", { template });
  },
  // Notifications
  readAllNotifications(role) {
    return request.put("notifications/status/read-all/" + role);
  },
  // Messages
  forwardSMMS(room_id, conv_type, payload) {
    return request.post(`messaging/smms/${conv_type}/${room_id}`, payload);
  },
  readAllMessages(room_id) {
    return request.put("messaging/status/read-all/" + room_id);
  },
  // Images,
  getListingImages(listingId) {
    return request.get(`listings/${listingId}/images`);
  },
  // Redirects
  checkRedirect(redirect_from) {
    return request.post("seo/redirects", { redirect_from, format: "json" });
  }
};
