<script>
import {
  reactive,
  toRefs,
  onMounted
} from "@vue/composition-api";
import PQDropzone from "src/views/components/PQDropzone.vue";
import { shortUID } from "src/plugins/prototype";
import { uploadFileToS3 } from "src/views/components/util";
import sumBy from "lodash/sumBy";
import SparkMD5 from "spark-md5";

export default {
  name: "DocumentUploader",
  components: {
    "pq-dropzone": PQDropzone
  },
  props: {
    files: {
      type: Array,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const controls = reactive({
      loading: false,
      errors: []
    });
    const state = reactive({
      allowedFileTypes: [
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/pdf"
      ]
    })
    const getFileNameExt = name => {
      const re = /(?:\.([^.]+))?$/;
      return re.exec(name)[1];
    }
    const bytesToKB = (bytes) => {
      return (bytes / 1024).toFixed(1);
    }
    const bytesToMB = (bytes) => {
      return (bytes / (1024 * 1024)).toFixed(1);
    }

    const generateFileMD5Hash = (file, callback) => {
      var blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice,
        chunkSize = 2097152, // Read in chunks of 2MB
        chunks = Math.ceil(file.size / chunkSize),
        currentChunk = 0,
        spark = new SparkMD5.ArrayBuffer(),
        fileReader = new FileReader();

      fileReader.onload = function (e) {
        console.log("read chunk nr", currentChunk + 1, "of", chunks);
        spark.append(e.target.result); // Append array buffer
        currentChunk++;

        if (currentChunk < chunks) {
          loadNext();
        } else {
          const fileHash = spark.end(); // Compute hash
          console.log("finished loading");
          console.info("computed hash", fileHash);
          callback(fileHash);
        }
      };

      fileReader.onerror = function () {
        console.warn("oops, something went wrong.");
      };

      function loadNext() {
        var start = currentChunk * chunkSize,
          end = start + chunkSize >= file.size ? file.size : start + chunkSize;

        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }

      loadNext();
    };

    const handleFileAdd = async file => {
      console.log("handleFileAdd", file);
      // Media Size Limits, ref -> https://support.twilio.com/hc/en-us/articles/360018832773-Twilio-Programmable-SMS-Supported-File-Types-and-Size-Limits-for-MMS-Media-Messages#:~:text=An%20MMS%20message%20body%20can,as%20a%201%2Dsegment%20message.
      const maxSingleSize = 5 * 1024 * 1024; // 5MB in bytes
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file.size > maxSingleSize) {
        ctx.root.$openNotification(
          `Each media cannot be more than 5MB. Now: ${bytesToKB(file.size)}KB`
        );
        return;
      }
      const totalSize = sumBy(props.files, 'size') + file.size;
      console.log("totalSize -> ", totalSize);
      if (totalSize > maxSize) {
        ctx.root.$openNotification(
          `Total media size cannot be more than 10MB. Now: ${bytesToMB(totalSize)}MB`
        );
        return;
      }
      if (props.files.length == 5) {
        ctx.root.$openNotification(
          "Max 5 media files are allowed."
        );
        return;
      }
      if (!state.allowedFileTypes.includes(file.type)) {
        ctx.root.$openNotification(
          `"${file.type}" format is not supported.`
        );
        return;
      }
      const f = {
        name: file.name,
        blob: file,
        type: file.type,
        url: null,
        size: file.size
      }
      await updateDocument(f);
    };

    const removeFile = f => {
      const i = props.files.findIndex(it => it.id === f.id);
      if (i >= 0) {
        props.files.splice(i, 1);
      }
    };

    const previewMedia = f => {
      const previewFile = Object.assign({}, f);
      previewFile.type = detectFileType(previewFile);
      ctx.root.$bus.$emit("preview-attachment-media", previewFile);
    };

    const getPreviewerUrl = f => {
      if (f.use_short_url) {
        return `https://view.officeapps.live.com/op/embed.aspx?src=${f.url}`
      }
      return f.url;
    }

    const shouldUseShortURL = (f) => {
      return detectIsFileType(f, "word") || detectIsFileType(f, "excel") || detectIsFileType(f, "ppt");
    }

    const updateDocument = async (f) => {
      if (controls.errors.length > 0) {
        return;
      }
      controls.loading = true;
      try {
        const fild_id = shortUID();
        const s3_name = fild_id + "." + getFileNameExt(f.name);
        const s3_path = "templates/documents/" + s3_name;
        let url = await uploadFileToS3({ path: s3_path, fileBlob: f.blob, isPublicAccess: true });
        const useShortURL = shouldUseShortURL(f);
        if (useShortURL) {
          url = await ctx.root.$generateShortLink(url);
        }
        f.url = url;
        console.log("uploadFileToS3 -> url: ", f.url);

        generateFileMD5Hash(f.blob, fileHash => {
          props.files.push({
            id: fild_id,
            name: f.name,
            hash: fileHash,
            blob: f.blob,
            s3_name,
            dbx_path: null,
            s3_path,
            url,
            size: f.size,
            type: f.type,
            use_short_url: useShortURL
          });
          setTimeout(() => {
            controls.loading = false;
          }, 500);
        })
      } catch (error) {
        ctx.root.$openNotification("Media Upload Error", error.message, 10, "error");
        controls.loading = false;
      }
    };

    const detectFileType = (f) => {
      const fileType = f.type;

      if ([
        "image/jpeg",
        "image/png",
        "image/gif"
      ].includes(fileType)) {
        return "image";
      } else if ([
        "audio/mpeg",
        "audio/aac",
        "audio/amr",
        "audio/wav"
      ].includes(fileType)) {
        return "audio";
      } else if ([
        "video/mp4",
        "video/3gpp",
        "video/quicktime"
      ].includes(fileType)) {
        return "video";
      } else if (fileType === "application/pdf") {
        return "pdf";
      } else if ([
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ].includes(fileType)) {
        return "ppt";
      } else if ([
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ].includes(fileType)) {
        return "excel";
      } else if ([
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ].includes(fileType)) {
        return "word";
      }

      return "unknown";
    };

    const detectIsFileType = (f, target_type) => {
      return detectFileType(f) === target_type;
    }

    onMounted(() => { });

    return {
      ...toRefs(controls),
      ...toRefs(state),
      handleFileAdd,
      removeFile,
      getPreviewerUrl,
      previewMedia,
      detectFileType,
      detectIsFileType
    };
  }
};
</script>
<template>
  <div>
    <div v-if="files.length > 0" class="">
      <viewer :images="files.filter(f => detectIsFileType(f, 'image'))">
        <div class="grid grid-cols-3 gap-2">
          <div v-for="(f, i) in files.filter(f =>
            detectIsFileType(f, 'image')
          )" :key="`${f.url}-${i}`" class="relative" @mouseover="() => {
  $set(f, '$visible.remove', true);
  files.forEach((f, j) => {
    if (i == j) { return }
    $set(f, '$visible.remove', false);
  })
}">
            <img :src="f.url" alt=""
              class="w-full object-cover hover:opacity-50 cursor-pointer border rounded overflow-hidden"
              style="max-height: 150px" />

            <div v-if="!readOnly && $deepGet(f, '$visible.remove')" class="absolute" style="right:-8px; top: -8px;"
              @click.stop="removeFile(f)">
              <div class="flex flex-shrink-0 rounded-full bg-red-500 hover:bg-red-600 cursor-pointer p-1">
                <svg-icon icon-class="close" class="w-4 h-4 text-white" />
              </div>
            </div>
          </div>
        </div>
      </viewer>
      <div class="flex flex-col space-y-2 pb-3">
        <div v-for="(f, i) in files.filter(f => detectIsFileType(f, 'video')
        )" :key="`${f.url}-${i}`" class="relative" @mouseover="() => {
  $set(f, '$visible.remove', true);
  files.forEach((f, j) => {
    if (i == j) { return }
    $set(f, '$visible.remove', false);
  })
}">
          <video controls class="w-full object-cover hover:opacity-50 cursor-pointer border rounded overflow-hidden">
            <source :src="f.url" :type="f.type">
            Your browser does not support the video tag.
          </video>

          <div v-if="!readOnly && $deepGet(f, '$visible.remove')" class="absolute" style="right:-8px; top: -8px;"
            @click.stop="removeFile(f)">
            <div class="flex flex-shrink-0 rounded-full bg-red-500 hover:bg-red-600 cursor-pointer p-1">
              <svg-icon icon-class="close" class="w-4 h-4 text-white" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-2 pb-3">

        <div v-for="(f, i) in files.filter(f =>
          detectIsFileType(f, 'audio')
        )" :key="`${f.url}-${i}`" class="flex relative">
          <audio controls>
            <source :src="f.url" :type="f.type">
            Your browser does not support the audio element.
          </audio>
          <div v-if="!readOnly" class="absolute right-2 top-1/2 transform -translate-y-1/2">
            <div class="
                rounded-full
                bg-red-500 
                hover:bg-red-600
                cursor-pointer
                text-white
                flex
                justify-center
                items-center
                p-1
              " @click.stop="removeFile(f)">
              <svg-icon icon-class="close" class="h-4 w-4" />
            </div>
          </div>
        </div>

        <div v-for="(f, i) in files.filter(f =>
          detectIsFileType(f, 'pdf')
        )" :key="`${f.url}-${i}`" class="flex relative">
          <a target="_blank"
            class="flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full"
            @click="previewMedia(f)">
            <div class="flex items-center justify-center border rounded p-2 bg-gray-50">
              <span class="font-bold text-lg text-pink-500">PDF</span>
            </div>
            <span class="font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis">
              {{ f.name }}
            </span>
          </a>
          <div v-if="!readOnly" class="absolute right-2 top-1/2 transform -translate-y-1/2">
            <div class="
                rounded-full
                bg-red-500 
                hover:bg-red-600
                cursor-pointer
                text-white
                flex
                justify-center
                items-center
                p-1
              " @click.stop="removeFile(f)">
              <svg-icon icon-class="close" class="h-4 w-4" />
            </div>
          </div>
        </div>

        <div v-for="(f, i) in files.filter(f =>
          detectIsFileType(f, 'word')
        )" :key="`${f.url}-${i}`" class="flex relative">
          <a target="_blank"
            class="flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full"
            @click="previewMedia(f)">
            <div class="flex items-center justify-center border rounded p-2 bg-gray-50">
              <span class="font-bold text-lg text-parqay-primary">Word</span>
            </div>
            <span class="font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis">
              {{ f.name }}
            </span>
          </a>
          <div v-if="!readOnly" class="absolute right-2 top-1/2 transform -translate-y-1/2">
            <div class="
                rounded-full
                bg-red-500 
                hover:bg-red-600
                cursor-pointer
                text-white
                flex
                justify-center
                items-center
                p-1
              " @click.stop="removeFile(f)">
              <svg-icon icon-class="close" class="h-4 w-4" />
            </div>
          </div>
        </div>

        <div v-for="(f, i) in files.filter(f =>
          detectIsFileType(f, 'excel')
        )" :key="`${f.url}-${i}`" class="flex relative">
          <a target="_blank"
            class="flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full"
            @click="previewMedia(f)">
            <div class="flex items-center justify-center border rounded p-2 bg-gray-50">
              <span class="font-bold text-lg text-green-400">Excel</span>
            </div>
            <span class="font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis">
              {{ f.name }}
            </span>
          </a>
          <div v-if="!readOnly" class="absolute right-2 top-1/2 transform -translate-y-1/2">
            <div class="
                rounded-full
                bg-red-500 
                hover:bg-red-600
                cursor-pointer
                text-white
                flex
                justify-center
                items-center
                p-1
              " @click.stop="removeFile(f)">
              <svg-icon icon-class="close" class="h-4 w-4" />
            </div>
          </div>
        </div>

        <div v-for="(f, i) in files.filter(f =>
          detectIsFileType(f, 'ppt')
        )" :key="`${f.url}-${i}`" class="flex relative">
          <a target="_blank"
            class="flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full"
            @click="previewMedia(f)">
            <div class="flex items-center justify-center border rounded p-2 bg-gray-50">
              <span class="font-bold text-lg text-orange-400">PPT</span>
            </div>
            <span class="font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis">
              {{ f.name }}
            </span>
          </a>
          <div v-if="!readOnly" class="absolute right-2 top-1/2 transform -translate-y-1/2">
            <div class="
                rounded-full
                bg-red-500 
                hover:bg-red-600
                cursor-pointer
                text-white
                flex
                justify-center
                items-center
                p-1
              " @click.stop="removeFile(f)">
              <svg-icon icon-class="close" class="h-4 w-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot />
    <div v-if="!readOnly" class="flex items-center justify-center my-3 bg-gray-100">
      <div v-if="loading" class="flex items-center justify-center" style="height: 222px;">
        <a-icon type="loading" style="font-size: 35px;" />
      </div>
      <pq-dropzone v-else height="280px" intro="Drop PDF file to sign here" @file-added="handleFileAdd" />
    </div>
    <div class="flex flex-col space-y-2">
      <div class="flex items-center space-x-4">
        <a-tag v-for="(error, i) in errors" :key="`error-${i}`" color="pink">
          {{ error }}
        </a-tag>
      </div>
    </div>
  </div>
</template>
