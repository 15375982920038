<script>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive
} from "@vue/composition-api";
import EventBus from "src/services/util/event-bus";
import HostMenu from "src/views/dashboard/components/HostMenu";
import HostHeader from "src/views/dashboard/components/HostHeader";
import SearchBar from "src/views/search/SearchBar";
import NotificationSlideCard from "src/views/components/NotificationSlideCard.vue";
// import ReservationSlideBar from "src/views/components/ReservationSlideBar";
import ContactSlideCard from "src/views/components/ContactSlideCard.vue";
import EmailEditorModal from "src/views/components/EmailEditorModal.vue";
import TenantDrawer from "src/views/tenants/components/TenantDrawer.vue";
import PendingTenantDrawer from "src/views/tenants/components/PendingTenantDrawer.vue";
import AutomationSlideCard from "src/views/components/AutomationSlideCard.vue";
import PlanSelectSlideCard from "src/views/components/PlanSelectSlideCard";
import AdminHostSearchInput from "src/views/components/AdminHostSearchInput.vue";
import AdminPricingCtrl from "src/views/components/AdminPricingCtrl.vue";

import { API } from "src/views/settings/api";
import { API as AuthAPI } from "src/views/auth/api";
import throttle from "lodash/throttle";

export default {
  name: "dashboard-frame",
  components: {
    HostMenu,
    HostHeader,
    SearchBar,
    NotificationSlideCard,
    // ReservationSlideBar,
    ContactSlideCard,
    EmailEditorModal,
    TenantDrawer,
    PendingTenantDrawer,
    AutomationSlideCard,
    PlanSelectSlideCard,
    AdminHostSearchInput,
    AdminPricingCtrl
  },
  setup(props, ctx) {
    // Slide Bar Setup
    const slideBarVisible = ref({
      notification: false,
      reservation: false,
      contact: false,
      automation: false,
      doc: false,
      plan: false,
      editEventSchedule: false,
      editEventPricing: false
    });

    const account = ref(null);

    const controls = reactive({
      splash: {
        visible: false
      },
      userMagician: {
        loading: false,
        visible: false,
        selectedHost: null
      },
      pricingCtrl: {
        visible: false
      },
      key: 0
    });

    const slideBarModel = ref({
      notification: null,
      reservation: {},
      contact: {},
      automation: {},
      doc: {},
      plan: {},
      editEventSchedule: {},
      editEventPricing: {}
    });

    const handleScroll = () => {
      EventBus.$emit("adjust-automation-arrows");
    };

    // Slide Bar Methods

    const closeSlideBar = name => {
      slideBarVisible.value[name] = false;
      toggleIntercomPosition(false);
    };

    const openNotification = ctx.root.$openNotification;
    const route = ctx.root.$route;
    const router = ctx.root.$router;

    const accessTokenRefreshFn = throttle(
      () => {
        console.log("***** access-token-refresh ***** ");
        openNotification(
          "Automatic Login",
          "Parqay is automatically refreshing token to keep your account secure"
        );
      },
      10000,
      // Fire token refresh ONLY ONCE every 10 seconds to avoid 401 response through multiple requests at once
      { trailing: false }
    );

    const authRequiredLoginFn = throttle(
      ({ target = "dashboard", silent = false } = { target: "dashboard", silent: false }) => {
        console.log("***** auth-required-login ***** ");
        router.push(target === "dashboard" ? "/login" : "/login/tenant");
        if (silent) {
          return
        }
        const automaticLogin = ctx.root.$store.state.automaticLogin;
        var notificationTitle, notificationMessage;
        if (automaticLogin) {
          notificationTitle = "Automatic Login";
          notificationMessage =
            "For security reason, Parqay requires user to login again after 2 months";
        } else {
          notificationTitle = "Secure Login";
          notificationMessage =
            "Current session expired,Parqay requires user to login again after 2 hours. You can check automatic login option for extended login session up to 2 months";
        }
        openNotification(notificationTitle, notificationMessage);
      },
      10000,
      // Fire token refresh ONLY ONCE every 10 seconds to avoid 401 response through multiple requests at once
      { trailing: false }
    );

    const toggleIntercomPosition = toggle => {
      // if (toggle) {
      //   window.intercomSettings.horizontal_padding = 420;
      // } else {
      //   window.intercomSettings.horizontal_padding = 20;
      // }
      // window.Intercom("boot", window.intercomSettings);
    };

    const userMagicianLogin = async () => {
      if (!controls.userMagician.selectedHost) { return }
      controls.userMagician.loading = true;
      try {
        const { data } = await AuthAPI.loginTarget(controls.userMagician.selectedHost);
        ctx.root.$saveUserData(data);
        location.reload();
      } catch (error) {
        controls.userMagician.loading = false;
        openNotification(
          "User Magician Login",
          error.message,
          10,
          "error"
        );
      }
    }

    /**
     * @deprecated Deprecated
     */
    const checkIfRequiresLogout = async () => {
      controls.splash.visible = true;
      let resp;
      resp = await API.getAccountDetails();
      account.value = resp.data;
      if (resp.data.roles.includes("host")) {
        return false;
      } else {
        await new Promise(resolve => setTimeout(resolve, 1000));
        return true;
      }
    };

    onMounted(async () => {
      EventBus.$on("access-token-refresh", accessTokenRefreshFn);
      EventBus.$on("auth-required-login", authRequiredLoginFn);
      EventBus.$on("open-slide-bar", (name, model) => {
        slideBarModel.value[name] = model;
        slideBarVisible.value[name] = true;
        toggleIntercomPosition(true);
      });
    });

    onBeforeUnmount(() => {
      EventBus.$off("access-token-refresh", accessTokenRefreshFn);
      EventBus.$off("auth-required-login", authRequiredLoginFn);
    });

    return {
      controls,
      slideBarModel,
      slideBarVisible,
      closeSlideBar,
      handleScroll,
      userMagicianLogin
    };
  }
};
</script>
<template>
  <div id="host-dashbord" class="w-screen">
    <div class="h-screen flex overflow-hidden bg-white">
      <host-menu />
      <main class="flex flex-col w-full">
        <host-header>
          <template slot="left">
            <search-bar />
          </template>
          <template slot="right">
            <button v-if="$isAdmin()"
              class="flex items-center justify-center bg-pink-300 border border-pink-400 rounded-full mr-4 p-1 focus:outline-none hover:bg-blue-100 hover:border-blue-600 hover:shadow-lg"
              @click.stop="() => controls.userMagician.visible = true">
              <img src="/static/img/dashboard/magician-hat.png" class="object-contain w-7 h-7">
            </button>
            <a-modal v-if="$isAdmin()" v-model="controls.userMagician.visible" title="Admin User Magician"
              :footer="null" @cancel="() => {
              controls.userMagician.selectedHost = null;
              controls.userMagician.visible = false;
              controls.key += 1;
            }">
              <div class="user-magician space-y-4" :class="{ 'pb-4': !controls.userMagician.selectedHost }">
                <div class="flex items-center justify-center pb-2">
                  <img src="/static/img/dashboard/magician-hat.png" class="object-contain">
                </div>
                <label-item label="Choose a user you would like to switch to:"
                  desc="Dashboard will reload and login as the target user." />
                <admin-host-search-input :key="controls.key"
                  @select="host => controls.userMagician.selectedHost = host" />
                <a-button v-if="controls.userMagician.selectedHost" :loading="controls.userMagician.loading"
                  type="primary" block size="large" @click.stop="userMagicianLogin()">
                  Switch Account
                </a-button>
              </div>
            </a-modal>
            <button v-if="$isAdmin()"
              class="flex items-center justify-center bg-yellow-100 border border-yellow-300 rounded-full mr-4 p-1 focus:outline-none hover:bg-yellow-200 hover:border-yellow-400 hover:shadow-lg"
              @click.stop="$router.push({ name: 'admin-payout' })">
              <img src="/static/img/dashboard/money-transfer.png" style="width: 1.75rem; height: 1.75rem;">
            </button>
            <button v-if="$isAdmin()"
              class="flex items-center justify-center bg-green-300 border border-green-400 rounded-full mr-4 p-1 focus:outline-none hover:bg-green-100 hover:border-green-600 hover:shadow-lg"
              @click.stop="() => controls.pricingCtrl.visible = true">
              <a-icon type="dollar-circle" theme="filled"
                style="color:rgba(5, 122, 85); font-size: 1.75rem; font-size: 1.75rem;" />
            </button>
            <button v-if="$isAdmin()"
              class="flex items-center justify-center bg-yellow-100 border border-yellow-300 rounded-full mr-4 p-1 focus:outline-none hover:bg-yellow-200 hover:border-yellow-400 hover:shadow-lg"
              @click.stop="$router.push({ name: 'admin-seo-editor' })">
              <img src="/static/img/dashboard/seo.png" style="width: 1.75rem; height: 1.75rem;">
            </button>
            <a-modal v-if="$isAdmin()" v-model="controls.pricingCtrl.visible" title="Admin Pricing Control"
              :footer="null" @cancel="() => {
              controls.pricingCtrl.visible = false;
              controls.key += 1;
            }">
              <div class="user-magician space-y-4">
                <div class="flex items-center justify-center pb-2">
                  <a-icon type="dollar" theme="twoTone" style="font-size: 50px; font-size: 50px;" />
                </div>
                <label-item label="Update Platform-Wise Pricing"
                  desc="BE AWARE: Changes of following will affect entire platform's pricing." />
                <admin-pricing-ctrl :key="controls.key" />
              </div>
            </a-modal>
            <button class="
                bg-blue-600
                border
                hover:bg-blue-500
                focus:outline-none
                text-white
                rounded-full
                p-2
                flex
              " @click="$openNotificationSlideCard">
              <a-badge :count="$store.getters.getUnreadNotificationsCount('host')">
                <svg-icon icon-class="notifications-outline" class="w-5 h-5" />
              </a-badge>
              <!-- <a-badge :count="0">
                <svg-icon icon-class="notifications-outline" class="w-5 h-5" />
              </a-badge> -->
            </button>
            <a-tooltip v-if="$isAdmin()"
              title="Admin listing is WIP, please go to normal host dashboard to create new listings.">
              <a-button disabled class="ml-2" style="height:38px; line-height: 19px;">+ New Listing</a-button>
            </a-tooltip>
            <a-dropdown v-else class="ml-4 mr-2">
              <a-button type="primary" icon="plus" size="large"
                style="display: flex; align-items: center; justify-content: center; font-size: 1.5rem; width: 35px; height: 35px; padding-bottom: 2px;" />
              <a-menu slot="overlay">
                <a-menu-item>
                  <div class="flex items-center px-4 py-2 space-x-2" @click="$router.push('/listing')">
                    <a-icon type="menu" />
                    <span class="text-base font-semibold">
                      + New Listing
                    </span>
                  </div>
                </a-menu-item>
                <a-menu-item>
                  <div class="flex items-center px-4 py-2 space-x-2" @click="$router.push('/listing')">
                    <a-icon type="calendar" />
                    <span class="text-base font-semibold">
                      + New Event
                    </span>
                  </div>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <button class="
                text-white
                bg-gradient-to-r
                from-green-400
                to-blue-500
                hover:from-pink-500 hover:to-yellow-300
                font-semibold
                focus:outline-none
                py-2
                px-4
                rounded
                ml-2
              " @click="$router.push('/dashboard/developers')">
              Developers
            </button>
            <button class="
                border
                hover:text-parqay-primary hover:border-parqay-primary
                font-semibold
                text-gray-600
                bg-white
                focus:outline-none
                py-2
                px-4
                rounded
                ml-2
              " @click="() => $router.push('/dashboard/payments')
              ">
              Check Earnings
            </button>
          </template>
        </host-header>
        <div id="dashboard-content" class="flex-grow overflow-hidden"
          style="height: calc(100vh - 80px); background-color: #f9f9f9">
          <div class="h-full overflow-y-auto relative">
            <smooth-scroll @handle-scroll="handleScroll">
              <router-view />
            </smooth-scroll>
          </div>
        </div>
      </main>
    </div>
    <reservation-drawer />
    <notification-slide-card :visible="slideBarVisible.notification"
      @close-notification-slide-card="closeSlideBar('notification')" />
    <contact-slide-card />
    <email-editor-modal />
    <tenant-drawer />
    <pending-tenant-drawer />
    <automation-slide-card :model="slideBarModel.automation" :visible="slideBarVisible.automation"
      @close-automation-slide-card="closeSlideBar('automation')" />
    <documents-drawer />
    <plan-select-slide-card :model="slideBarModel.plan" :visible="slideBarVisible.plan"
      @close-plan-select-slide-card="closeSlideBar('plan')" />
    <splash v-if="controls.splash.visible" />
  </div>
</template>
<style lang="scss">
.user-magician {
  .ant-btn {
    @apply flex items-center justify-center;
  }
}
</style>