<script>
import { reactive, ref, toRefs, watch, onMounted, onBeforeUnmount } from '@vue/composition-api';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor';
import ClickOutside from "vue-click-outside";
import cloneDeep from "lodash/cloneDeep";
import { API } from "./api";

import { API as AccountAPI } from "src/views/components/api/account";
import { API as EmailAPI } from "src/views/messaging/api";

export default {
    directives: {
        ClickOutside
    },
    components: {
        'quill-editor': quillEditor
    },
    setup(props, ctx) {

        const stateValues = {
            loading: false,
            loadingSendEmail: false,
            visible: false,
            tenant: null,
            emailFrom: null,
            emailFromName: "Parqay Host",
            emailRecipients: [],
            inputRecipient: null,
            showRecipientInput: false,
            emailCcEnabled: false,
            emailCc: null,
            emailBccEnabled: false,
            emailBcc: null,
            emailSubject: null,
            emailContent: `
            <p>Dear [Tenant Name],</p><p><br></p><p>I hope this email finds you well! </p><p><br></p><p>[Main Content] </p><p><br></p><p>We look forward to hosting you!</p><p><br></p><p>Best regards,</p><p>[Your Name]</p><p>Parqay Host</p>
            `,
            selectedTemplate: null,
            emailTemplates: [
                {
                    name: "Tenant Onboarding",
                    subject: "Register for Parqay Marketplace and Book Your First Parking Spot Today!",
                    content: `<p>Dear [Tenant Name],</p><p><br></p><p>I hope this email finds you well! My name is [Your Name], and I am a sales representative from Parqay Marketplace, the leading sharing economy platform for parking and other sharing economy listings.</p><p><br></p><p>We are excited to offer you a convenient and affordable parking solution through our platform. You can easily search for and book parking spots for the duration of your lease.</p><p><br></p><p>To complete your onboarding process and book your first parking spot, we kindly request that you register on our platform and make your first payment. You can do this by following the link below:</p><p><br></p><p><a href="[Insert Registration and Payment Link]" rel="noopener noreferrer" target="_blank">[Insert Registration and Payment Link]</a></p><p><br></p><p>If you have any questions or concerns, please do not hesitate to reach out to our customer service team at [Insert Contact Information]. We are here to assist you every step of the way.</p><p><br></p><p>Thank you for choosing Parqay Marketplace as your parking provider. We look forward to serving you!</p><p><br></p><p>Best regards,</p><p>[Your Name]</p><p>Parqay Marketplace Sales Representative</p>`,
                    loading: false,
                    progress: 0
                },
                {
                    name: "Host Onboarding",
                    subject: "Join the Parqay Host Community and Start Earning Today!",
                    content: `<p>Dear [Host Name],</p><p><br></p><p>I hope this email finds you well! My name is [Your Name], and I am a sales representative from Parqay, the leading sharing economy parking company in the area.</p><p><br></p><p>We are excited to offer you a convenient and affordable parking solution for your [Residential/Commercial] property. Our platform allows you to easily register your vehicle and reserve a parking spot for the duration of your lease.</p><p>To complete your onboarding process and secure your parking spot, we kindly request that you register on our platform and make your first payment. You can do this by following the link below:</p><p><br></p><p><a href="[Insert Registration and Payment Link]" rel="noopener noreferrer" target="_blank">[Insert Registration and Payment Link]</a></p><p><br></p><p>If you have any questions or concerns, please do not hesitate to reach out to our customer service team at [Insert Contact Information]. We are here to assist you every step of the way.</p><p><br></p><p>Thank you for choosing Parqay as your parking provider. We look forward to serving you!</p><p>Best regards,</p><p>[Your Name]</p><p>Parqay Sales Representative</p>`,
                    loading: false,
                    progress: 0
                },
                {
                    name: "Marketplace Intro",
                    subject: "Introducing Parqay Marketplace: Your One-Stop Solution for Parking and More",
                    content: `<p>Dear [Recipient Name],</p><p><br></p><p>I hope this email finds you well. My name is [Your Name], and I am reaching out to introduce you to Parqay Marketplace, a leading sharing economy platform for parking and other sharing economy listings.</p><p><br></p><p>At Parqay Marketplace, we strive to provide our users with a convenient and affordable way to access parking spaces, rooms, and offices. Our platform connects tenants in need of parking with hosts who have unused parking spaces, allowing everyone to benefit from the sharing economy.</p><p><br></p><p>In addition to our marketplace, we also offer Parqay Dashboard, a powerful tool for hosts to manage their parking spaces. With Parqay Dashboard, hosts can easily share and rent out their parking spaces, monitor their reservations, and track their earnings. Our platform also provides hosts with a variety of features to ensure the safety and security of their spaces, including insurance coverage and 24/7 customer support.</p><p><br></p><p>Whether you are a tenant in need of parking or a host with unused spaces, Parqay Marketplace has something to offer you. We are committed to providing our users with a seamless and enjoyable experience, and we work tirelessly to ensure the satisfaction of both tenants and hosts.</p><p><br></p><p>If you have any questions or would like to learn more about Parqay Marketplace and Dashboard, please do not hesitate to reach out to our customer service team at [Insert Contact Information]. We would be happy to assist you.</p><p><br></p><p>Thank you for considering Parqay Marketplace. We look forward to serving you!</p><p><br></p><p>Best regards,</p><p>[Your Name]</p><p>Parqay Sales Representative</p>`,
                    loading: false,
                    progress: 0
                },
                {
                    name: "Dashboard Intro",
                    subject: "Take Your Parking Business to the Next Level with Parqay Dashboard",
                    content: `<h1>Welcome to Parqay Dashboard!</h1><p>Parqay Dashboard is a powerful tool for managing parking services for small parking businesses and real estate companies. With our platform, you can offer sharing economy services around parking, making it easier for your customers to find and reserve parking spaces.</p><p><br></p><h2>Features of Parqay Dashboard</h2><ul><li>Intuitive dashboard that provides a bird's eye view of your parking spaces</li><li>Real-time data on parking space availability and utilization</li><li>Automated billing and payment processing for hassle-free transactions</li><li>Customizable pricing and availability rules to suit your needs</li><li>Easy integration with your existing website or mobile app</li></ul><p><br></p><h2>Why Choose Parqay Dashboard?</h2><p>Parqay Dashboard is designed to help small parking businesses and real estate companies compete with larger players in the sharing economy space. Our platform is easy to use, affordable, and customizable to meet your specific needs. Whether you're looking to streamline your operations, increase revenue, or offer new services to your customers, Parqay Dashboard can help you achieve your goals.</p><p><br></p><h2>Get Started Today!</h2><p>Ready to take your parking services to the next level? Sign up for Parqay Dashboard today and get started with a free trial. Our team is here to help you every step of the way, from onboarding to ongoing support. Contact us to learn more!</p><p><br></p><p>Best regards,</p><p>The Parqay Team</p>`,
                    loading: false,
                    progress: 0
                }
            ],
            quillOptions: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline'],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['clean'],
                    ],
                },
                formats: [
                    'header',
                    'bold', 'italic', 'underline',
                    'list', 'bullet', 'indent',
                    'link', 'image'
                ],
            }
        }

        const state = reactive(cloneDeep(stateValues));

        watch(() => state.emailContent, (val) => {
            console.log(val);
        })

        const updateProgressBar = (template, duration) => {
            template.progress = 0;
            const startTime = Date.now();
            const endTime = startTime + duration;
            const targetProgress = 100;
            let progress = template.progress;
            let delta = 0;

            let animationId = null;

            const update = () => {
                const now = Date.now();
                const deltaTime = now - startTime;
                const remainingTime = Math.max(endTime - now, 0);

                if (progress >= targetProgress && remainingTime <= 0) {
                    template.progress = targetProgress;
                    return;
                }

                const currentProgress = (deltaTime / duration) * targetProgress;
                const progressDelta = currentProgress - progress;

                if (remainingTime > 0 && progress >= 99.5 && progress < targetProgress) {
                    // Add a smaller delta if the progress is close to the target progress and the target duration hasn't been reached yet
                    const targetDelta = (targetProgress - progress) / (duration - deltaTime);
                    delta += (targetDelta - delta) * 0.1;
                } else {
                    delta += progressDelta * (Math.random() * 0.4 + 0.8);
                }

                if (Math.abs(delta) >= 1) {
                    progress += Math.floor(delta);
                    delta %= 1;
                    template.progress = progress;
                }

                console.log("progress -> ", template.progress);

                animationId = requestAnimationFrame(update);
            };

            update();

            const cancel = () => {
                template.progress = 100;
                setTimeout(() => {
                    template.progress = 0;
                }, 1500);
                cancelAnimationFrame(animationId);
            };

            return { cancel };
        };

        const generateEmailAltTemplate = async (template) => {
            try {
                if (template.loading) {
                    return;
                }
                const { cancel } = updateProgressBar(template, 12000);
                template.loading = true;
                await API.generateEmailAltTemplate(template.content);
                const handleGenerateEmailAltTemplateResult = (resp) => {
                    console.log("generate-email-alt-template: ", resp.template);
                    template.loading = false;
                    cancel();
                    state.emailContent = resp.template;
                }
                ctx.root.sockets.subscribe("generate-email-alt-template", handleGenerateEmailAltTemplateResult);
            } catch (error) {
                template.loading = false;
                template.progress = 0;
            }
        }

        const selectEmailTemplate = (template) => {
            if (state.selectedTemplate && state.selectedTemplate.loading) {
                return;
            }
            state.selectedTemplate = template;
            state.emailSubject = template.subject;
            state.emailContent = template.content;
        }

        const showModal = ({ tenant_id, to_emails }) => {
            console.log("showModal -> tenant_id: ", tenant_id);
            console.log("showModal -> to_emails: ", to_emails);
            if (tenant_id) {
                fetchTenant(tenant_id);
            }
            getUniqueAddress();
            const emailContent = state.emailContent;
            Object.assign(state, cloneDeep(stateValues));
            state.visible = true;
            if (to_emails) {
                state.emailRecipients = [...to_emails]; // <- pass by reference
            }
            state.emailContent = emailContent;
        }

        const closeModal = () => {
            ctx.root.sockets.unsubscribe("generate-email-alt-template");
            state.visible = false;
            state.tenant = null;
        }

        const handleOk = () => {
            closeModal();
        }

        const handleCancel = () => {
            closeModal();
        }

        const formatEmail = (email) => {
            const recipientName = ctx.root.$deepGet(state.tenant, "name", "");
            if (state.tenant) {
                // Case: contact data passing from tenant_id
                return `${recipientName} <${email}>`
            }
            // Case: contact data passing from to_emails
            return email
        }

        const formatHostEmail = () => {
            let fromName = "Parqay Host";
            if (state.emailFromName) {
                fromName = `${state.emailFromName} - ${fromName}`;
            }
            return `${fromName} <${state.emailFrom}>`;
        }

        const fetchTenant = async (tenant_id) => {
            state.loading = true;
            try {
                const { data } = await AccountAPI.getUser(tenant_id);
                state.tenant = data;
                state.emailRecipients = [data.email];
                state.loading = false;
            } catch (error) {
                console.warn(error);
                state.loading = false;
            }
        };

        const getUniqueAddress = async () => {
            try {
                const { data } = await EmailAPI.getUniqueEmailAddress();
                state.emailFrom = data.email;
                state.emailFromName = data.name;
            } catch (error) {
                state.emailFrom = "support@parqay.com";
                console.log(error.message);
            }
        }

        const toggleRecipientInput = () => {
            state.showRecipientInput = false;
            if (state.inputRecipient) {
                state.emailRecipients.push(state.inputRecipient);
            }
            state.inputRecipient = null;
        }


        const sendEmail = async () => {
            try {
                state.loadingSendEmail = true;
                const payload = {
                    from_email: formatHostEmail(),
                    recipients: state.emailRecipients,
                    subject: state.emailSubject,
                    content: state.emailContent
                }
                if (state.emailCc) {
                    payload.cc = state.emailCc;
                }
                if (state.emailBcc) {
                    payload.bcc = state.emailBcc;
                }
                await EmailAPI.sendEmail(
                    payload
                );
                state.loadingSendEmail = false;
                ctx.root.$openNotification(
                    "Your email has been sent!"
                )
                closeModal();
            } catch (error) {
                state.loadingSendEmail = false;
                ctx.root.$openNotification(
                    "Outbound Email",
                    error.message
                )
            }
        }


        onMounted(() => {
            ctx.root.$bus.$on("toggle-email-editor", showModal);
        })

        onBeforeUnmount(() => {
            ctx.root.$bus.$off("toggle-email-editor", showModal);
        })

        return {
            recipientInput: ref(null),
            ccInput: ref(null),
            bccInput: ref(null),
            ...toRefs(state),
            generateEmailAltTemplate,
            selectEmailTemplate,
            formatEmail,
            toggleRecipientInput,
            sendEmail,
            handleOk,
            handleCancel,
        };
    },
};
</script>
<template>
    <a-modal title="New Email Message" :visible="visible" width="800px" :wrap-style="{ zIndex: 9999 }"
        @cancel="handleCancel" @ok="handleOk">
        <div class="flex flex-col space-y-3">
            <div class="flex flex-col space-y-4">
                <div class="flex flex-col justify-center space-y-4">
                    <div class="flex flex-wrap items-center gap-4">
                        <div class="text-gray-600">To:</div>
                        <avatar v-if="tenant" :user-id="$deepGet(tenant, 'id')" show-name :popover="false" class="cursor-pointer" />
                        <a-tag v-for="(email, i) in emailRecipients" :key="`recipient-tag-${i}`" email-recipient closable
                            color="geekblue" style="margin-left: 0px; margin-right: 0px;" @close="() => {
                                emailRecipients.splice(i, 1);
                            }">{{ formatEmail(email) }}</a-tag>
                        <a-input ref="recipientInput" v-if="showRecipientInput" v-model="inputRecipient"
                            placeholder="recipient@mail.com" style="max-width: 200px;" @keyup.enter="toggleRecipientInput()"
                            @blur="toggleRecipientInput()">
                            <template #suffix>
                                <a-button type="link" icon="plus" size="small"
                                    style="display: flex; align-items: center; justify-content: center;"
                                    @click.stop="toggleRecipientInput()" />
                            </template>
                        </a-input>
                        <a-button v-else icon="plus" style="display: flex; align-items: center; justify-content: center;"
                            @click.stop="() => {
                                showRecipientInput = true;
                                $nextTick(() => {
                                    recipientInput.focus();
                                })
                            }" />
                        <div v-if="emailRecipients.length > 0" class="flex items-center space-x-4">
                            <div class="text-gray-600">Cc:</div>
                            <a-switch v-model="emailCcEnabled" @change="isChecked => {
                                if (isChecked) {
                                    $nextTick(() => {
                                        ccInput.focus();
                                    })
                                }
                            }" />
                            <a-input ref="ccInput" v-if="emailCcEnabled" v-model="emailCc" placeholder="Enter Cc recipient"
                                allow-clear style="max-width: 200px;" @blur="() => {
                                    if (!emailCc) {
                                        emailCcEnabled = false;
                                    }
                                }" />
                        </div>
                        <div v-if="emailRecipients.length > 0" class="flex items-center space-x-4">
                            <div class="text-gray-600">Bcc:</div>
                            <a-switch v-model="emailBccEnabled" @change="isChecked => {
                                if (isChecked) {
                                    $nextTick(() => {
                                        bccInput.focus();
                                    })
                                }
                            }" />
                            <a-input ref="bccInput" v-if="emailBccEnabled" v-model="emailBcc"
                                placeholder="Enter Bcc recipient" allow-clear style="max-width: 200px;" @blur="() => {
                                    if (!emailBcc) {
                                        emailBccEnabled = false;
                                    }
                                }" />
                        </div>
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="mr-4 text-gray-600">Subject:</div>
                    <a-input v-model="emailSubject" placeholder="Enter the email subject" allow-clear />
                </div>
            </div>
            <div class="flex flex-col space-y-3">
                <a-divider email-template>Email Template</a-divider>
                <div class="grid grid-cols-4 gap-2 w-full">
                    <div v-for="(temp, i) in emailTemplates" :key="`email-template-${i}`"
                        class="relative overflow-hidden flex items-center justify-center rounded border border-black bg-white hover:bg-gray-200 text-black text-sm font-semibold px-2 py-2 cursor-pointer"
                        @click.stop="selectEmailTemplate(temp)">
                        <div v-if="temp.progress > 0" :style="{ width: temp.progress + '%' }"
                            class="absolute left-0 bottom-0 h-full bg-blue-100">
                        </div>
                        <span class="z-0">{{ temp.name }}</span>
                        <a-icon v-if="temp.loading" type="loading" class="ml-2 z-0" />
                        <a-icon v-if="!temp.loading && $deepGet(selectedTemplate, 'name') === temp.name" type="check-circle"
                            theme="filled" class="ml-2 z-0" />
                    </div>
                </div>
            </div>
            <div class="relative">
                <quill-editor v-model="emailContent" :options="quillOptions" />
                <div class="absolute right-0 top-0 py-2 px-2">
                    <a-button type="primary" icon="reload" :loading="$deepGet(selectedTemplate, 'loading')" size="small"
                        generate-template :disabled="!selectedTemplate"
                        @click.stop="generateEmailAltTemplate(selectedTemplate)">{{ $deepGet(selectedTemplate, 'loading') ?
                            "Generating..." : "Generate Alternative Template" }}</a-button>
                </div>
            </div>
        </div>
        <div slot="footer" class="flex px-2 items-center justify-between">
            <div class="flex items-center">
                <div class="mr-4 text-gray-600">From:</div>
                <a-tag v-if="emailFrom" email-recipient color="cyan"><span class="font-semibold mr-1">{{ `${emailFromName} -
                        Parqay Host` || "Parqay Host" }}</span>&lt;{{ emailFrom }}&gt;</a-tag>
                <a-icon v-else type="loading" />
            </div>
            <a-button type="primary" :loading="loadingSendEmail"
                :disabled="emailRecipients.length == 0 || !emailFrom || !emailSubject || !emailContent"
                @click="sendEmail()">Send</a-button>
        </div>
    </a-modal>
</template>
<style lang="scss" scoped>
.ant-btn[generate-template] {
    @apply flex items-center justify-center text-xs font-semibold;
}

.ant-tag[email-recipient] {
    @apply flex items-center px-2 py-1;
}

::v-deep {
    .ant-input-suffix {
        right: 8px;
    }
}

.ant-divider[email-template] {
    @apply text-sm font-medium m-0;
}
</style>