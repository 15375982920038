<script>
export default {
  name: "HostHeader"
};
</script>
<template>
  <div
    class="host-header shadow-sm border-b border-gray-300 inline-flex justify-between flex-grow-0 items-center px-10"
  >
    <div>
      <slot name="left" />
    </div>
    <div class="flex items-center justify-end">
      <slot name="right" />
    </div>
  </div>
</template>
<style lang="scss">
.host-header {
  z-index: 10;
  height: 80px;
}
</style>
