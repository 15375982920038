<script>
import { reactive, ref, watch, onMounted } from "@vue/composition-api";
import ClickOutside from "vue-click-outside";
import debounce from "lodash/debounce";

import { API } from "src/views/tenants/api";

export default {
  directives: {
    ClickOutside
  },
  props: {
    defaultTenant: {
      type: Object,
      default: null
    }
  },
  setup(props, ctx) {
    const loading = ref(false);
    const visible = reactive({
      ok: false
    });
    const keyword = ref('');
    const tenants = ref([]);
    const selectedTenant = ref(null);
    const showDropdown = ref(false);

    watch(selectedTenant, (newValue) => {
      if (newValue) {
        ctx.emit("select", newValue);
        ctx.emit("error", null);
        keyword.value = "";
        visible.ok = true;
      } else {
        ctx.emit("select", null);
        ctx.emit("error", "A tenant MUST be selected.");
        visible.ok = false;
      }
    })

    const handleInput = debounce(async (event) => {
      try {
        loading.value = true;
        if (event) {
          keyword.value = event.target.value;
        }
        const { data } = await API.searchTenantsForReservations(keyword.value);
        tenants.value = data;
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    }, 500, { trailing: true });

    const handleFocus = () => {
      showDropdown.value = true;
      ctx.emit("error", "A host MUST be selected.");
    };

    const handleBlur = () => {
      if (showDropdown.value) {
        showDropdown.value = false;
      }
    }

    onMounted(() => {
      if (props.defaultTenant) {
        selectedTenant.value = props.defaultTenant;
      }
      handleInput();
    })

    return {
      loading,
      visible,
      keyword,
      tenants,
      selectedTenant,
      showDropdown,
      handleInput,
      handleFocus,
      handleBlur
    };
  }
};
</script>
<template>
  <div class="flex flex-col space-y-2 relative" v-click-outside="handleBlur">
    <div v-if="selectedTenant" class="flex items-center space-x-4">
      <div class="flex items-center space-x-2">
        <a-tag v-if="selectedTenant.status === 'invited'" color="orange">
          Invited
        </a-tag>
        <a-tag v-if="selectedTenant.status === 'joined'" color="cyan">
          Accepted
        </a-tag>
        <a-tag tenant>
          <span class="text-black font-medium">
            {{
              selectedTenant.name || ""
            }}
          </span>
          <div @click.stop="() => selectedTenant = null">
            <svg-icon icon-class="close" class="w-4 h-4 text-gray-400 hover:text-black" />
          </div>
        </a-tag>
      </div>
      <button v-if="visible.ok" class="
        bg-parqay-primary
        px-4
        py-1
        text-xs text-white
        border border-parqay-primary
        rounded
      " @click="() => $emit('select-confirmed')">
        Save
      </button>
    </div>
    <template v-else>
      <a-input-search v-model="keyword" :loading="loading" @input="handleInput" @focus="handleFocus"
        placeholder="Search tenant" size="large" allow-clear />
      <ul v-if="showDropdown"
        class="absolute z-30 top-full left-0 w-full bg-white rounded shadow-md border-l border-b border-r">
        <li v-if="tenants.length == 0"
          class="flex items-center justify-center px-4 py-3 border-t hover:bg-gray-100 cursor-pointer">
          No Result.
        </li>
        <li v-for="(tenant, i) in tenants" :key="`reservation-tenant-result-${i}`"
          class="flex items-center justify-between px-4 py-3 border-t hover:bg-gray-100 cursor-pointer"
          @click.stop="() => selectedTenant = tenant">
          <div class="flex items-center space-x-4">
            <span class="text-black font-medium">
              {{
                tenant.name || ""
              }}
            </span>
            <a-tag v-if="tenant.status === 'joined'" color="cyan">Accepted</a-tag>
            <a-tag v-if="tenant.status === 'invited'" color="orange">Invited</a-tag>
          </div>
          <div class="flex flex-col justify-center items-end space-y-2">
            <span v-if="tenant.email" class="text-xs font-semibold text-gray-400">{{ tenant.email }}</span>
            <span v-if="tenant.phone_number" class="text-xs font-semibold text-parqay-primary">{{
              tenant.phone_number }}</span>
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.ant-tag[tenant] {
  span {
    @apply pr-4;
  }

  @apply inline-flex items-center justify-center text-sm font-medium px-4 py-1.5 cursor-pointer border-black bg-white;
}
</style>