import langEn from "./lang-en"
import langChs from "./lang-chs"
import langEs from "./lang-es"
// Ready translated locale messages
const intLangs = {
    en: langEn,
    chs: langChs,
    es: langEs
}

export default intLangs