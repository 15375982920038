import axios from "axios";
import store from "src/state/dashboard";
// import { Urls } from '../constants';
import EventBus from "src/services/util/event-bus";
import TokenManager from "../authentication/token-manager";
import has from "lodash/has";

import { Urls } from "src/services/constants";
import { logout } from "src/plugins/prototype";

const http = axios;

class Request {
  /**
   * Get the API request url with base url
   * @param  {String} url Url for request
   * @return {String}     Final url
   */
  baseUrl(url) {
    return Urls.API_BASE_URL() + url;
  }

  /**
   * Initiate a HTTP request
   * @param  {String} method Http verb
   * @param  {String} url    Partial url of api url
   * @param  {Object} params Query strings
   * @param  {Object} data   Post body
   * @return {Promise}       Response
   */
  request(method, url, params = {}, data = {}, norefresh = false) {
    let options = {
      cancelToken: store.state.cancelToken.token,
      validateStatus: function (status) {
        return status != 401 && status < 500; // Reject only if the status code is greater than or equal to 500
      }
    };

    if (method.toLowerCase() == "get") {
      options["params"] = params;
      // Custom cancel token override if certain request cancellation needs to handle independently
      if (has(params, "cancelToken")) {
        options["cancelToken"] = params["cancelToken"];
        delete params["cancelToken"];
      }
      // If protectFromCancel, this request won't be affected from global cancellation in router guard
      if (has(params, "protectFromCancel")) {
        delete options["cancelToken"];
        delete params["protectFromCancel"];
      }
    }

    if (method.toLowerCase() == "post") {
      options = data;
    }

    if (method.toLowerCase() == "put") {
      options = data;
    }

    if (method.toLowerCase() == "delete") {
      options["params"] = params;
    }

    return this.makeRequest(method, url, options, norefresh);
  }

  makeRequest(method, url, options, norefresh, current_retry = 1, max_retry = 3) {
    if (TokenManager().accessToken) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + TokenManager().accessToken;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
    if (norefresh) {
      delete axios.defaults.headers.common["Authorization"];
    }
    return http[method](this.baseUrl(url), options).then(
      (success) => {
        let response = success.data;

        if (response["status"] === "error") {
          return Promise.reject(response);
        }

        if (response["status"] === "success") {
          return Promise.resolve(response);
        }

        return Promise.reject({
          status: "error",
          message: "No status value set!",
        });
      },

      (error) => {
        console.log("error:", error);
        if (error.response.status === 401) {
          if (current_retry <= max_retry) {
            console.log("***** re-auth error ***** " + error.response.status);
            var next_retry = current_retry + 1;
            return this.refreshAccessToken().then(
              () => {
                EventBus.$emit("access-token-refresh");
                return this.makeRequest(method, url, options, norefresh, next_retry);
              },
              (error) => {
                // notify to resign-in
                console.warn("Failed to refresh the auth token...", error);
                EventBus.$emit("auth-required-login", { target: window.location.pathname.startsWith("/dashboard") ? "dashboard" : "tenant" });
                logout({skipEvent: true});
                return Promise.reject(error);
              }
            );
          } else {
            // notify to resign-in
            console.warn("Auth token refresh reached retry threshold -> current_retry: ", current_retry);
            EventBus.$emit("auth-required-login", { target: window.location.pathname.startsWith("/dashboard") ? "dashboard" : "tenant" });
            logout({skipEvent: true});
          }
        }
        return Promise.reject({
          status: "error",
          message: error.response.data.message || error.message || "Internal server error",
          data: {
            error: error.response
          }
        });
      }
    );
  }

  refreshAccessToken() {
    //Check whether there's refresh token set or not.
    if (!TokenManager().refreshToken) {
      return Promise.reject("no refresh token");
    }

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + TokenManager().refreshToken;

    return http["post"](this.baseUrl("auth/refresh_token"), {}).then(
      (success) => {
        // update access token
        TokenManager().accessToken = success.data.data.access_token;
        console.log(
          "success.data.access_token: " + success.data.data.access_token
        );
        return Promise.resolve();
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  get(url, params = {}) {
    return this.request("get", url, params);
  }

  post(url, data, params = {}, norefresh = false) {
    return this.request("post", url, params, data, norefresh);
  }

  put(url, data, params = {}) {
    return this.request("put", url, params, data);
  }

  delete(url, params = {}) {
    return this.request("delete", url, params);
  }
}

export default new Request();
