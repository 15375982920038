<script>

import {
  reactive,
  onMounted,
  onBeforeUnmount,
} from "@vue/composition-api";

export default {
  name: "pending-tenant-drawer",
  setup(props, ctx) {

    const controls = reactive({
      key: 0,
      visible: false,
      tenant: {
        name: null,
        email: null,
        phone_number: null,
        invited_at: null,
        reservations: []
      }
    });

    const close = () => {
      controls.visible = false;
    };

    const handleOpenEvent = (tenant) => {
      controls.visible = true;
      controls.key += 1;
      controls.tenant = tenant;
    };
    const handleCloseEvent = () => {
      close();
    };

    onMounted(() => {
      ctx.root.$bus.$on("open-pending-tenant-drawer", handleOpenEvent);
      ctx.root.$bus.$on("close-pending-tenant-drawer", handleCloseEvent);
    });

    onBeforeUnmount(() => {
      ctx.root.$bus.$off("open-pending-tenant-drawer", handleOpenEvent);
      ctx.root.$bus.$off("close-pending-tenant-drawer", handleCloseEvent);
    });

    return {
      controls,
      close,
    };
  },
};
</script>
<template>
  <a-drawer width="400px" :visible="controls.visible" placement="right" destroy-on-close :footer="null" @close="close()">
    <template #title>
      <div class="flex justify-between items-center">
        <p class="font-semibold text-lg text-gray-900 my-auto">
          Lead
        </p>
      </div>
    </template>
    <div class="h-full flex-col justify-between">
      <div :key="controls.key" class="px-2">
        <div class="flex flex-col space-y-3">
          <span class="text-base font-medium">Tenant Name</span>
          <a-input v-model="controls.tenant.name" />
          <span class="text-base font-medium">Email</span>
          <a-input v-model="controls.tenant.email" />
          <span class="text-base font-medium">Contact</span>
          <div class="
                      text-center text-xs
                      font-medium
                      border
                      rounded
                      bg-gray-100
                      px-4
                      py-2
                      whitespace-nowrap
                    ">
            Phone: {{ controls.tenant.phone_number || "N/A" }}
          </div>
          <span class="text-base font-medium">Invited Since</span>
          <div class="
                      text-center text-xs
                      font-medium
                      border
                      rounded
                      bg-gray-100
                      px-4
                      py-2
                      whitespace-nowrap
                    ">
            {{ $formatDate(controls.tenant.created_at) }}
          </div>
          <span class="text-base font-medium">Reservations</span>
          <a-button v-if="!$isAdmin()" size="large" block @click="() => {
            $router.push('/dashboard/reservations#calendar');
            close();
          }">
            + Create New Reservation
          </a-button>
          <a-button v-if="controls.tenant.reservations" type="primary" size="large" block
            :disabled="controls.tenant.reservations.length == 0" @click="() => {
              $bus.$emit('tenant-drawer-expand-tenant', controls.tenant);
              close();
            }">
            View Reservations ({{ controls.tenant.reservations.length }})
          </a-button>
          <a-button size="large" block disabled>
            Resend Invitation
          </a-button>
        </div>
      </div>
    </div>
  </a-drawer>
</template>