<script>
import { reactive, ref, watch, onMounted, nextTick, onBeforeUnmount } from "@vue/composition-api";
import SlideBar from "src/views/components/components/SlideBar.vue";
import DocumentViewer from "src/views/components/document-drawers/components/DocumentViewer.vue";
import DocumentUploader from "src/views/components/document-drawers/components/DocumentUploader.vue";

import { API } from "src/views/documents/api";

export default {
  name: "documents-drawer",
  components: {
    SlideBar,
    DocumentViewer,
    DocumentUploader,
  },
  props: {},
  setup(props, ctx) {

    const controls = reactive({
      loading: {
        reservation: false,
        requestUpdate: false,
        download: false,
      },
      visible: false,
      role: null,
      docs: [],
      type: null,
      key: 0
    });

    const handleDocumentUploaded = (file) => {
      console.log("handleDocumentUploaded", file);
    };

    const getDocumentsByReservation = async (reservation_id) => {
      try {
        controls.loading.reservation = true;
        const { data } = await API.getDocumentsByReservation({
          role: controls.role,
          reservation_id
        })
        data.forEach(doc => {
          ctx.root.$set(doc, '$expand', {
            uploader: false
          })
        })
        controls.docs = data;
        controls.loading.reservation = false;
      } catch (error) {
        console.log("getDocumentsByReservation -> error: ", error);
        controls.loading.reservation = false;
      }
    }

    const getIdentityVerificationResult = async (tenant_id) => {
      try {
        controls.loading.reservation = true;
        const { data } = await API.getIdentityVerificationResult({
          on_behalf_of: tenant_id
        });
        ctx.root.$set(data, '$expand', {
          uploader: false
        })
        controls.docs = [data];
        controls.loading.reservation = false;
      } catch (error) {
        console.log("getIdentityVerificationResult -> error: ", error);
        controls.loading.reservation = false;
      }
    };

    const handleOpenEvent = (
      role,
      { doc, reservation_id,  tenant_id }
    ) => {
      controls.role = role;
      controls.docs = [];
      controls.visible = true;
      controls.key += 1;
      if (doc) {
        ctx.root.$set(doc, '$expand', {
          uploader: false
        });
        controls.docs = [doc];
      }
      if (reservation_id) {
        getDocumentsByReservation(reservation_id);
      }
      if (tenant_id) {
        getIdentityVerificationResult(tenant_id);
      }
    };
    const handleCloseEvent = (
    ) => {
      close();
    };
    const requestUpdate = async (documentId) => {
      controls.loading.requestUpdate = true;
      let resp;
      try {
        resp = await API.hostRequestUpdate(documentId);
        console.log("requestUpdate: ", resp.message);
      } catch (error) {
        console.log("requestUpdate: error:", error);
      }
      setTimeout(() => {
        controls.loading.requestUpdate = false;
        ctx.root.$bus.$emit("document-sign-refresh");
      }, 2000);
    };
    const reinviteTenant = async (doc) => {
      ctx.root.$set(doc, "$loading", true);
      let resp;
      try {
        resp = await API.hostInviteDocumentTenant(doc.id);
        console.log("reinviteTenant: ", resp.message);
      } catch (error) {
        console.log("reinviteTenant: error:", error);
      }
      ctx.root.$set(doc, "$loading", false);
    };
    const close = () => {
      controls.visible = false;
    };
    onMounted(() => {
      ctx.root.$bus.$on("open-documents-drawer", handleOpenEvent);
      ctx.root.$bus.$on("open-identity-drawer", handleOpenEvent);
      ctx.root.$bus.$on("close-documents-drawer", handleCloseEvent);
      ctx.root.$bus.$on(
        "document-download-stop",
        () => (controls.loading.download = false)
      );
    });
    onBeforeUnmount(() => {
      ctx.root.$bus.$off("open-documents-drawer", handleOpenEvent);
      ctx.root.$bus.$off("open-identity-drawer", handleOpenEvent);
      ctx.root.$bus.$off("close-documents-drawer", handleCloseEvent);
      ctx.root.$bus.$off(
        "document-download-stop",
        () => (controls.loading.download = false)
      );
    });
    return {
      controls,
      handleDocumentUploaded,
      handleCloseEvent,
      requestUpdate,
      reinviteTenant,
    };
  },
};
</script>
<template>
  <slide-bar v-slot:default="slotProps" v-model="controls.visible" :width="400" backdrop close-by-mask
    @close-slide-bar="handleCloseEvent()">
    <div class="flex justify-between items-center border-b px-6 py-6">
      <span class="font-bold text-lg text-gray-900 my-auto">
        {{ controls.role === "host" ? "Tenant Documents" : "View Documents" }}
      </span>
      <span @click="slotProps.closeSlideBar">
        <svg-icon icon-class="close" class="cursor-pointer w-6 h-6 text-blue-900" />
      </span>
    </div>
    <div v-if="!controls.loading.reservation" class="h-full flex flex-col justify-between space-y-4 px-6 py-4">
      <div v-for="(form, i) in controls.docs" :key="`doc-${i}`" class="flex flex-col justify-between space-y-4">
        <a-divider v-if="controls.docs.length > 1">Document #{{ i + 1 }}</a-divider>
        <document-viewer :key="`document-viewer-${controls.key}`" :role="controls.role" :form="form">
        </document-viewer>
        <a-button v-if="form.type === 'upload'" type="primary" size="large"
          :disabled="!(form.files && form.files.length > 0)" @click="() => {
            $bus.$emit('expand-document-viewer');
          }
            ">
          View Attachments
        </a-button>
        <a-button v-if="controls.type === 'sign'" type="primary" size="large" :disabled="!(
          form.signatures &&
          form.signatures.length > 0 &&
          form.status === 'complete'
        )
          " @click="() => {
    $bus.$emit('expand-document-viewer');
  }
    ">
          View Signatures
        </a-button>
        <template v-if="form.type === 'upload'">
          <document-uploader v-if="form.$expand.uploader" :key="`document-uploader-${controls.key}`" :doc-id="form.id"
            :template="form.template" :reservation-id="form.reservation_id" :host-id="form.host" @document-uploaded="() => {
              $bus.$emit('document-upload-refresh');
              handleCloseEvent();
            }
              " />
          <a-button v-else size="large" @click="() => (form.$expand.uploader = true)">
            Upload New Document
          </a-button>
        </template>
        <div class="flex flex-col space-y-4 justify-between mb-6">
          <a-button v-if="controls.role === 'host' && form.type === 'upload'" size="large"
            :disabled="!(form.files && form.files.length > 0)" :loading="controls.loading.download" @click="() => {
              controls.loading.download = true;
              $bus.$emit('document-download-start', form);
            }
              ">
            Download Attachments
          </a-button>
          <template v-if="form.type === 'sign'">
            <a-popconfirm v-if="form.status === 'complete'"
              title="This document is complete. Please confirm if you want to start a new sign request" ok-text="Sign"
              @confirm="() => {
                $bus.$emit('start-new-document-signature-request', form);
                handleCloseEvent();
              }
                ">
              <a-button type="primary" size="large">
                Restart Signing
              </a-button>
            </a-popconfirm>
            <a-button v-else type="primary" size="large" @click="() => {
              $bus.$emit('start-new-document-signature-request', form);
              handleCloseEvent();
            }
              ">
              Start Signing
            </a-button>
          </template>
          <a-popconfirm v-if="form.type !== 'identity' &&
            controls.role === 'host' &&
            form.status !== 'incomplete'
            "
            title="Requesting document update will invalidate current uploads and reset document status to incomplete. Are you sure to make this action?"
            ok-text="Yes" cancel-text="No" @confirm="requestUpdate(form.id)" @cancel="() => { }">
            <a-button size="large" :loading="controls.loading.requestUpdate">
              Request for Update
            </a-button>
          </a-popconfirm>
          <a-button v-if="form.type !== 'identity' &&
            controls.role === 'host' &&
            form.status !== 'complete'
            " :loading="form.$loading" size="large" @click="reinviteTenant(form)">
            Invite Tenant
          </a-button>
          <a-button v-if="controls.type === 'identity' && form.status !== 'verified'" type="primary" size="large" @click="() => {
            $bus.$emit(
              'start-new-document-identity-verification',
              controls.template
            );
            handleCloseEvent();
          }
            ">
            Start Identity Verification
          </a-button>
        </div>
      </div>
    </div>
    <div v-else class="h-16 flex flex-col items-center justify-center">
      <a-button type="link" size="large" loading>Fetching Details...</a-button>
    </div>
  </slide-bar>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-btn {
    @apply flex items-center justify-center;
  }
}
</style>
