<script>
import { reactive, ref, onMounted } from "@vue/composition-api";
import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';
import cloneDeep from "lodash/cloneDeep";
import AdminHostSearchInput from "src/views/components/AdminHostSearchInput.vue";
import { notification } from 'ant-design-vue';
import { API } from "src/views/components/api/pricing";

export default {
    components: {
        AdminHostSearchInput
    },
    setup(props, ctx) {

        const loading = reactive({
            get: true,
            post: false
        });

        const selectedHost = ref({});

        const defaultPricing = {
            business_type: 0.1,
            residential_type: 0.15,
            guest: 0.03,
            guest_ach: 0.01,
            monthly_surge: 0.05
        };

        const globalPricing = ref(cloneDeep(defaultPricing));

        const overrides = ref([]);

        const snaps = reactive({
            globalPricing: {},
            overrides: {}
        })

        const getPricingCtrl = async () => {
            loading.get = true;
            const { data } = await API.control("get");
            data.forEach(config => {
                config.$snap = cloneDeep(config);
                config.$loading = {
                    put: false,
                    delete: false
                }
            })
            const global = data.find(config => !config.user)
            if (global) {
                globalPricing.value = global;
            }
            overrides.value = data.filter(config => config.user);
            loading.get = false;
        }

        const addPricingCtrl = async () => {
            try {
                loading.post = true;
                let updateDict = {};
                if (selectedHost.value.id) {
                    updateDict = {
                        ...cloneDeep(defaultPricing)
                    }
                    updateDict.override = selectedHost.value.id;
                    await API.control("put", updateDict);
                    selectedHost.value = {};
                    loading.post = false;
                    getPricingCtrl();
                }
            } catch (error) {
                loading.post = false;
                notification.error({
                    message: 'Pricing Error',
                    description: error.message,
                    duration: null
                });
            }
        }

        /**
         * Constructs an object with updates to pricing by comparing the current pricing
         * to a snapshot and excluding keys that start with '$'.
         *
         * @param {Object} ctrl - The object containing the pricing data and a snapshot for comparison.
         * @returns {Object} An object containing the updated pricing data with keys that do not start with '$'.
         */
        const getPricingUpdateDict = (ctrl) => {
            const currentPricing = cloneDeep(ctrl);
            delete currentPricing.$snap; // Ensure the $snap key is not in the currentPricing object.
            const snap = cloneDeep(ctrl).$snap; // Get the snapshot for comparison.
            return transform(currentPricing, (result, value, key) => {
                if (!key.startsWith('$') && !isEqual(value, snap[key])) {
                    result[key] = value;
                }
            }, {});
        };

        const updatePricingCtrl = async (ctrl) => {
            try {
                ctrl.$loading.put = true;
                let updateDict = getPricingUpdateDict(ctrl);
                if (ctrl.user) {
                    updateDict.override = ctrl.user.id;
                }
                await API.control("put", updateDict);
                ctrl.$loading.put = false;
                getPricingCtrl();
            } catch (error) {
                ctrl.$loading.put = false;
                notification.error({
                    message: 'Pricing Error',
                    description: error.message,
                    duration: null
                });
            }
        }

        const deletePricingCtrl = async (ctrl) => {
            try {
                ctrl.$loading.delete = true;
                await API.control("delete", { id: ctrl.user.id });
                ctrl.$loading.delete = false;
                getPricingCtrl();
            } catch (error) {
                ctrl.$loading.delete = false;
                notification.error({
                    message: 'Pricing Error',
                    description: error.message,
                    duration: null
                });
            }
        }

        onMounted(() => {
            getPricingCtrl();
        })

        return {
            loading,
            selectedHost,
            globalPricing,
            overrides,
            snaps,
            addPricingCtrl,
            getPricingUpdateDict,
            updatePricingCtrl,
            deletePricingCtrl
        };
    }
};
</script>
<template>
    <div v-if="!loading.get" class="flex flex-col space-y-4">
        <span class="text-center text-lg font-medium text-black">Global Pricing</span>
        <div class="flex flex-col space-y-2 pb-2">
            <label-item label="Host Fees">
                <div class="grid grid-cols-2 gap-4">
                    <label-item desc="Business type fee percentage">
                        <a-input-number :default-value="globalPricing.business_type * 100"
                            @change="value => { globalPricing.business_type = value / 100; }"
                            :formatter="value => `${Math.round(value)}%`" :parser="value => value.replace('%', '')"
                            style="width: 100%;" />
                    </label-item>
                    <label-item desc="Residential type fee percentage">
                        <a-input-number :default-value="globalPricing.residential_type * 100"
                            @change="value => { globalPricing.residential_type = value / 100; }"
                            :formatter="value => `${Math.round(value)}%`" :parser="value => value.replace('%', '')"
                            style="width: 100%;" />
                    </label-item>
                </div>
            </label-item>
            <label-item label="Tenant Fees">
                <div class="grid grid-cols-2 gap-4">
                    <label-item desc="Credit / Debit card fee percentage">
                        <a-input-number :default-value="globalPricing.guest * 100"
                            @change="value => { globalPricing.guest = value / 100; }" :formatter="value => `${Math.round(value)}%`"
                            :parser="value => value.replace('%', '')" style="width: 100%;" />
                    </label-item>
                    <label-item desc="ACH fee percentage">
                        <a-input-number :default-value="globalPricing.guest_ach * 100"
                            @change="value => { globalPricing.guest_ach = value / 100; }" :formatter="value => `${Math.round(value)}%`"
                            :parser="value => value.replace('%', '')" style="width: 100%;" />
                    </label-item>
                </div>
            </label-item>
            <label-item label="Upsales">
                <div class="grid grid-cols-1 gap-4">
                    <label-item desc="Subscription (more than 3 months) surge charge percentage">
                        <a-input-number :default-value="globalPricing.monthly_surge * 100"
                            @change="value => { globalPricing.monthly_surge = value / 100; }"
                            :formatter="value => `${Math.round(value)}%`" :parser="value => value.replace('%', '')"
                            style="width: 100%;" />
                    </label-item>
                </div>
            </label-item>
            <a-button v-if="Object.keys(getPricingUpdateDict(globalPricing)).length > 0" :loading="globalPricing.$loading.put" type="primary" block size="large"
                @click="updatePricingCtrl(globalPricing)">
                Update Global Pricing
            </a-button>
        </div>
        <div class="flex flex-col space-y-2">
            <span class="text-center text-base font-medium">Pricing Overrides by Host</span>
            <span class="text-center text-xs font-light">Override global pricing for specific host.</span>
            <span class="text-center text-xs underline">Other hosts will still use the global pricing.</span>
        </div>
        <template v-for="(override, i) in overrides">
            <a-divider :key="`override-pricing-${i}-d`">
                <div class="flex items-center space-x-2">
                    <avatar :user-id="$deepGet(override, 'user.id')" class="w-8 h-8" />
                    <span>{{ $deepGet(override, "user.first_name") }}'s Pricing</span>
                </div>
            </a-divider>
            <div :key="`override-pricing-${i}`" class="flex flex-col space-y-2 pb-2">
                <label-item label="Host Fees">
                    <div class="grid grid-cols-2 gap-4">
                        <label-item desc="Business type fee percentage">
                            <a-input-number :default-value="override.business_type * 100"
                                @change="value => { override.business_type = value / 100; }"
                                :formatter="value => `${Math.round(value)}%`" :parser="value => value.replace('%', '')"
                                style="width: 100%;" />
                        </label-item>
                        <label-item desc="Residential type fee percentage">
                            <a-input-number :default-value="override.residential_type * 100"
                                @change="value => { override.residential_type = value / 100; }"
                                :formatter="value => `${Math.round(value)}%`" :parser="value => value.replace('%', '')"
                                style="width: 100%;" />
                        </label-item>
                    </div>
                </label-item>
                <label-item label="Tenant Fees">
                    <div class="grid grid-cols-2 gap-4">
                        <label-item desc="Credit / Debit card fee percentage">
                            <a-input-number :default-value="override.guest * 100"
                                @change="value => { override.guest = value / 100; }" :formatter="value => `${Math.round(value)}%`"
                                :parser="value => value.replace('%', '')" style="width: 100%;" />
                        </label-item>
                        <label-item desc="ACH fee percentage">
                            <a-input-number :default-value="override.guest_ach * 100"
                                @change="value => { override.guest_ach = value / 100; }" :formatter="value => `${Math.round(value)}%`"
                                :parser="value => value.replace('%', '')" style="width: 100%;" />
                        </label-item>
                    </div>
                </label-item>
                <label-item label="Upsales">
                    <div class="grid grid-cols-1 gap-4">
                        <label-item desc="Subscription (more than 3 months) surge charge percentage">
                            <a-input-number :default-value="override.monthly_surge * 100"
                                @change="value => { override.monthly_surge = value / 100; }"
                                :formatter="value => `${Math.round(value)}%`" :parser="value => value.replace('%', '')"
                                style="width: 100%;" />
                        </label-item>
                    </div>
                </label-item>
            </div>
            <div :key="`override-actions-${i}`" class="flex flex-col py-2 space-y-4">
                <a-button v-if="Object.keys(getPricingUpdateDict(override)).length > 0" :loading="override.$loading.put" type="primary" block size="large"
                    @click="updatePricingCtrl(override)">
                    Update Pricing for {{ override.user.first_name }}
                </a-button>
                <a-button :loading="override.$loading.delete" type="danger" block size="large"
                    @click="deletePricingCtrl(override)">Delete Pricing
                    for {{ override.user.first_name }}</a-button>
            </div>
        </template>
        <a-divider>+ New Override</a-divider>
        <admin-host-search-input @host-update="host => selectedHost = host" />
        <a-button v-if="selectedHost.id" :loading="loading.post" icon="plus" size="large" @click="addPricingCtrl()">Add
            Pricing Override for {{
                selectedHost.first_name
            }}</a-button>
    </div>
    <div v-else class="flex flex-col items-center justify-center space-y-4">
        <a-icon type="loading" style="font-size: 25px;;" />
        <span class="text-base">Loading Pricing...</span>
    </div>
</template>
<style lang="scss" scoped>
.ant-tag[host] {
    @apply inline-flex items-center text-sm font-medium px-3 py-1.5 space-x-2 cursor-pointer;
}
</style>