import Vue from "vue";
import axios from 'axios';
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import EventBus from "src/services/util/event-bus";
import dayjs from "dayjs";
import { parse, stringify } from 'flatted';
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { notification } from 'ant-design-vue';
import replace from "lodash/replace";
import forOwn from "lodash/forOwn";
import uniqBy from "lodash/uniqBy";
import unionBy from "lodash/unionBy";
import sumBy from "lodash/sumBy";

const deepGet = (obj, keyPath, defaultVal = null) => {
  return get(obj, keyPath) ? get(obj, keyPath) : defaultVal;
};

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export default new Vuex.Store({
  state: {
    signUpForm: {},
    signUpStepIndex: 0,
    listingForm: {},
    recentSearches: [],
    starredSearches: [],
    scheduleToAddList: [],
    messages: {},
    automaticLogin: false,
    notifications: [],
    notificationsUnreadCount: {
      host: 0,
      tenant: 0
    },
    notificationsPagination: {
      currentPage: 1,
      perPage: 10,
      total: 1
    },
    isAdmin: false,
    requestsCount: 0,
    messagesCount: null,
    cancelToken: axios.CancelToken.source()
  },
  getters: {
    // Search
    checkIsStarred: state => objectID => {
      var removeIndex = state.starredSearches
        .map(it => it.objectID)
        .indexOf(objectID);
      return removeIndex != -1;
    },
    // Recover TZDate instance when retrieve
    getScheduleToAddList: state => () => {
      var scheduleToAdd = [];
      state.scheduleToAddList.forEach((v, i) => {
        const startDt = v.start ? dayjs(v.start).toDate() : null;
        const endDt = v.end ? dayjs(v.end).toDate() : null;
        const _v = cloneDeep(v);
        _v.start = startDt;
        _v.end = endDt;
        scheduleToAdd.push(_v);
      });
      console.log("getScheduleToAddList", scheduleToAdd);
      return scheduleToAdd;
    },
    getUnreadNotificationsCount: state => (role) => {
      return state.notificationsUnreadCount[role];
    },
    getUnreadMessagesCount: state => () => {
      if (!state.messagesCount) {
        return 0;
      }
      return sumBy(state.messagesCount, "count");
    },
    getUnreadMessagesCountForReservation: state => (reservation) => {
      return deepGet(deepGet(state, "messagesCount", []).find(v => v['_id'] === deepGet(reservation, 'id')), 'count', 0)
    },
    isAdmin(state) {
      return state.isAdmin;
    }
  },
  mutations: {
    // Auth
    updateSignUpForm(state, payload) {
      const [pk, pd] = payload;
      if (pk == "subscribe") {
        console.log("updateSignUpForm >> subscribe: " + pd);
        const [sub, val] = pd;
        if (val) {
          if (state.signUpForm.subscribe) {
            if (!state.signUpForm.subscribe.includes(sub)) {
              state.signUpForm.subscribe += `, ${sub}`;
            }
          } else {
            state.signUpForm.subscribe = sub;
          }
        } else {
          state.signUpForm.subscribe = replace(
            state.signUpForm.subscribe,
            `, ${sub}`,
            ""
          );
          state.signUpForm.subscribe = replace(
            state.signUpForm.subscribe,
            sub,
            ""
          );
        }
        return;
      }
      state.signUpForm[pk] = pd;
      console.log("state.signUpForm: " + JSON.stringify(state.signUpForm));
    },
    updateSignUpStep(state, stepIndex) {
      console.log("state.updateSignUpStep:", stepIndex);
      state.signUpStepIndex = stepIndex;
    },
    // Reset signup form when sign up page unload
    resetSignUpForm(state) {
      state.signUpStepIndex = 0;
      state.signUpForm = {};
      console.log("state.signUpForm: cleared");
    },
    // Reservations
    addScheduleToAddList(state, payload) {
      state.scheduleToAddList.push(payload);
      console.log("state.scheduleToAddList:", state.scheduleToAddList);
    },
    updateScheduleToAddList(state, payload) {
      const targetIndex = state.scheduleToAddList.findIndex(
        m => m.id === payload.id
      );
      // If the function returns false for all elements of the array, the result is -1
      if (targetIndex >= 0) {
        forOwn(payload, (v, k) => {
          state.scheduleToAddList[targetIndex][k] = v;
        });
      }
      console.log(
        `updateScheduleToAddList: targetIndex:${targetIndex}`,
        payload,
        "updated target:",
        state.scheduleToAddList[targetIndex]
      );
    },
    bulkUpdateScheduleToAddList(state, payload) {
      const [pk, pv] = payload;
      state.scheduleToAddList.forEach((v, i) => {
        v[pk] = pv;
      });
      console.log(
        `bulkUpdateScheduleToAddList: key:${pk}, value:${pv}`,
        state.scheduleToAddList
      );
    },
    deleteScheduleToAddFromList(state, payload) {
      const { id } = payload;
      const targetIndex = state.scheduleToAddList.findIndex(m => m.id === id);
      // If the function returns false for all elements of the array, the result is -1
      if (targetIndex >= 0) {
        state.scheduleToAddList.splice(targetIndex, 1);
      }
      console.log(`deleteScheduleToAddFromList: targetIndex:${targetIndex}`);
    },
    clearScheduleToAddList(state) {
      state.scheduleToAddList = [];
      console.log("state.clearScheduleToAddList:", state.scheduleToAddList);
    },
    // Listing
    updateListingForm(state, payload) {
      const [pk, pd] = payload;
      state.listingForm[pk] = pd;
      console.log("state.listingForm: " + JSON.stringify(state.listingForm));
    },
    // Reset listing form when listing page unload
    resetLisitngForm(state) {
      state.listingForm = {};
      console.log("state.listingForm: cleared");
    },
    toggleAutomaticLogin(state, val) {
      state.automaticLogin = val;
    },
    // Search
    addRecentSearch(state, data) {
      state.recentSearches.push(data);
      state.recentSearches = uniqBy(state.recentSearches, e => {
        return e.objectID;
      });
    },
    toggleStarSearch(state, item) {
      var removeIndex = state.starredSearches
        .map(it => it.objectID)
        .indexOf(item.objectID);
      console.log("removeIndex: " + removeIndex);
      if (removeIndex != -1) {
        console.log("remove");
        ~removeIndex && state.starredSearches.splice(removeIndex, 1);
      } else {
        console.log("add");
        state.starredSearches.push(item);
        state.starredSearches = uniqBy(state.starredSearches, it => {
          return it.objectID;
        });
      }
    },
    removeRecentSearch(state, index) {
      state.recentSearches.splice(index, 1);
    },
    clearAllRecentSearches(state) {
      state.recentSearches = [];
    },
    SET_SOCKET(state, socket) {
      state.socket = stringify(socket);
    },
    // Socket IO
    "SOCKET_get-latest-messages"(state, { room_id, messages }) {
      state.messages[room_id] = messages;
    },
    "SOCKET_conversation-exchange"(state, d) {
      // Note: Reactivity has to be maintained by Vue.set
      !(d.room_id in state.messages) && Vue.set(state.messages, d.room_id, []);
      state.messages[d.room_id].push(d);
    },
    "SOCKET_client-connected"(state, d) {
      console.log("Backend socket connected: √", this._vm);
      if (this._vm.$getUserUID()) {
        this._vm.$socket.emit(
          "notification-join-room",
          {
            user_uid: this._vm.$getUserUID()
          },
          () => {
            console.log("notification-join-room");
            this._vm.$socket.emit("get-all-notifications", {
              user_uid: this._vm.$getUserUID()
            });
          }
        );
      }
    },
    "SOCKET_push-all-notifications"(state, payload) {
      // console.log("push-all-notifications -> ", payload);
      const { notifications, pagination } = JSON.parse(payload);
      state.notifications = notifications;
      state.notificationsPagination = pagination;
    },
    "SOCKET_new-notification"(state, payload) {
      // console.log("new-notification: ", payload);
      const { title, message, type, sender_name, room_id } = payload;
      if (type === "message") {
        const key = `open${Date.now()}`;
        const viewChatAction = () => {
          EventBus.$emit("close-contact-drawer");
          EventBus.$emit("open-contact-drawer", { room_id });
          notification.close(key);
        }
        notification.open({
          message: h => {
            return (
              <div class="flex items-center justify-center space-x-2">
                <div class="w-2 h-2 bg-green-400 rounded-full" />
                <span class="text-black text-sm font-semibold">{title}</span>
              </div>
            )
          },
          // In JSX, you need to use curly braces { } to insert dynamic values or expressions
          description: h => {
            return (
              <div class="mt-1 bg-white rounded-lg border overflow-hidden w-full max-w-md mx-auto">
                <div class="bg-gray-100 px-4 py-3 flex items-center justify-between space-x-2">
                  <p class="font-bold">{sender_name} &#58;</p>
                  <a-button type="primary" onClick={viewChatAction}>View Chat</a-button>
                </div>
                <div class="p-4 relative">
                  <div class="bg-gray-100 rounded-lg py-2 px-3 mb-1 inline-block">
                    <span class="text-gray-900 leading-tight">{message}</span>
                  </div>
                  <div
                    class="absolute right-0 bottom-0 border-t-8 border-gray-100 border-solid transform rotate-45 -mr-4">
                  </div>
                </div>
              </div>
            )
          },
          key,
          onClose: () => { },
          placement: "bottomRight",
          duration: 10
        })
        return;
      }
      notification.success({
        message: title,
        description: message,
        placement: "bottomRight",
        duration: 0
      });
    },
    "SOCKET_notifications-unread-count-update"(state, payload) {
      // console.log("notifications-unread-count-update: ", payload);
      state.notificationsUnreadCount = Object.assign({}, state.notificationsUnreadCount, payload);
    },
    "SOCKET_messages-unread-count-update"(state, unreads) {
      // console.log(`messages-unread-count-update -> ${unreads}`);
      state.messagesCount = JSON.parse(unreads);
    },
    "SOCKET_requests-count-update"(state, count) {
      // console.log("requests-count-update: ", count);
      state.requestsCount = count;
    },
    "SOCKET_client-disconnected"(state, d) {
      console.log("Backend socket disconnected: x");
    },
    clearNotifications(state, d) {
      console.log("clearNotifications >> ", d);
      state.notifications = [];
    },
    clearMessages(state, d) {
      console.log("clearMessages >> ", d);
      state.messagesCount = null;
    },
    cacheSentMessage(state, d) {
      console.log("cacheSentMessage >> " + JSON.stringify(d));
      // Note: Reactivity has to be maintained by Vue.set
      !(d.room_id in state.messages) && Vue.set(state.messages, d.room_id, []);
      state.messages[d.room_id].push(d);
    },
    removeMessage(state, d) {
      console.log("removeMessage >> ", d);
      state.messages[d.room_id] = state.messages[d.room_id].filter(
        m => m.id !== d.id
      );
    },
    setAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setCancelToken(state) {
      state.cancelToken.cancel();
      const source = axios.CancelToken.source();
      state.cancelToken = source;
    }
  },
  actions: {
    resetCancelToken({ commit }) {
      commit('setCancelToken');
    }
  },
  plugins: [vuexLocal.plugin]
});
