<script>
import { reactive, ref, computed, onMounted, onBeforeUnmount } from "@vue/composition-api";
import TemplateUploader from "src/views/documents/components/Uploader.vue";
import ReservationSearchInput from "src/views/components/ReservationSearchInput.vue";
import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";

import { API } from "src/views/documents/api";

export default {
    components: {
        TemplateUploader,
        ReservationSearchInput
    },
    setup(props, ctx) {
        const controls = reactive({
            loading: {
                update: false,
                invite: false,
                delete: false
            },
            visible: false,
            expand: {
                message: false,
                intervalMode: false,
                listingType: false
            },
            showComplete: false,
            selected: {
                reservation: null
            },
            currentStep: 0,
            updatePayload: {
                name: null,
                signer_message: null,
                interval_modes: null,
                subtypes: null
            },
            model: {
                name: null,
                signer_message: null,
                doc_sign_required: false,
                type: "upload",
                subtypes: [
                    "parking", "stay", "coworking", "car-rental"
                ],
                interval_modes: [
                    "monthly", "daily", "hourly"
                ],
                editable: true,
                docs_to_sign: []
            },
            templateReviewConsent: false
        })
        const templates = ref([
            controls.model
        ]);
        const shouldDisableUpdate = computed(() => {
            return !(controls.updatePayload.name || controls.updatePayload.signer_message || controls.updatePayload.interval_modes || controls.updatePayload.subtypes)
        })
        const getDisplayStepPrev = () => {
            if (controls.currentStep > 0) {
                if (controls.model.type === "upload") {
                    if (controls.currentStep <= 2) {
                        return true;
                    }
                }
                if (controls.model.type === "sign") {
                    return true;
                }
            }
            return false;
        }
        const getStepNameNext = () => {
            if (controls.currentStep == 2) {
                if (controls.model.type === "upload") {
                    return "Create Document";
                }
            }
            return "Next";
        }
        const getDisableStepNext = () => {
            // return false;
            if (controls.currentStep == 0) {
                if (!controls.model.name) {
                    return true;
                }
            }
            if (controls.currentStep == 1) {
                if (!controls.model.signer_message) {
                    return true;
                }
            }
            if (controls.currentStep == 2) {
                if (controls.model.type === "sign") {
                    if (controls.model.docs_to_sign.length == 0) {
                        return true;
                    }
                }
            }
            return false;
        }
        const getStepNextAction = async () => {
            if (controls.currentStep == 2) {
                if (controls.model.type === "upload") {
                    return;
                }
            }
            controls.currentStep += 1;
        }
        const openModal = (template) => {
            controls.updatePayload.name = null;
            controls.updatePayload.signer_message = null;
            controls.updatePayload.interval_modes = null;
            controls.updatePayload.subtypes = null;
            const tempRaw = cloneDeep(template);
            controls.model = tempRaw;
            templates.value = [tempRaw];
            controls.visible = true;
        }
        const closeModal = () => {
            controls.visible = false;
            controls.currentStep = 0;
        }
        const openPreview = async () => {
            if (templates.value[0].is_reviewed) {
                closeModal();
                ctx.root.$bus.$emit("toggle-document-sign-embed-preview", true, templates.value[0].id);
            }
        };
        const sendDocumentTemplateInvite = async () => {
            try {
                controls.loading.invite = true;
                await API.sendDocumentTemplateInvite(templates.value.map(v => v.id), controls.selected.reservation);
                controls.loading.invite = false;
                controls.currentStep += 1;
            } catch (error) {
                controls.loading.invite = false;
                ctx.root.$openNotification("Send Invite", error.message, 10, "error");
            }
        }
        const updateDocumentTemplate = async () => {
            try {
                controls.loading.update = true;
                await API.updateDocumentTemplate(templates.value[0].id, controls.updatePayload);
                controls.loading.update = false;
                ctx.root.$bus.$emit("refresh-document-templates-list");
                closeModal();
            } catch (error) {
                controls.loading.update = false;
                ctx.root.$openNotification("Document Template Update", error.message, 10, "error");
            }
        }
        const deleteDocumentTemplates = async () => {
            try {
                controls.loading.delete = true;
                await API.deleteDocumentTemplate(templates.value[0].id);
                controls.loading.delete = false;
                ctx.root.$bus.$emit("refresh-document-templates-list");
                closeModal();
            } catch (error) {
                controls.loading.delete = false;
                console.log("document template delete error -> ", error);
            }
        }
        onMounted(() => {
            ctx.root.$bus.$on("open-document-template-modal", openModal);
        })
        onBeforeUnmount(() => {
            ctx.root.$bus.$off("open-document-template-modal", openModal);
        })
        return {
            controls,
            templates,
            shouldDisableUpdate,
            getDisplayStepPrev,
            getStepNameNext,
            getDisableStepNext,
            getStepNextAction,
            closeModal,
            openPreview,
            sendDocumentTemplateInvite,
            updateDocumentTemplate,
            deleteDocumentTemplates,
            startCase,
            console
        }
    }
}
</script>

<template>
    <a-drawer :visible="controls.visible"
        :width="(controls.model.type === 'sign' && controls.currentStep === 3) ? '65vw' : '600px'" @close="closeModal()">
        <template>
            <div class="flex items-center space-x-4 pb-4">
                <span class="text-base text-black font-semibold">
                    {{ controls.model.name }}
                </span>
                <a-tag v-if="templates[0].is_reviewed || templates[0].type === 'upload'" color="green"
                    class="template-status-tag">
                    <span>Ready to use</span>
                    <a-icon type="check-circle" />
                </a-tag>
                <a-tag v-if="templates[0].type === 'sign' && !templates[0].is_reviewed" color="orange"
                    class="template-status-tag">
                    <span>Under review</span>
                    <a-tooltip>
                        <template #title>
                            Your new template is under review by our team, we'll get back to you or approve it
                            directly after the review.
                        </template>
                        <a-icon type="info-circle" />
                    </a-tooltip>
                </a-tag>
            </div>
        </template>
        <div class="doc-template flex flex-col space-y-2">
            <div class="flex flex-col items-center justify-between space-y-8" style="min-height: 300px;">
                <keep-alive>
                    <div v-for="(temp, i) in templates.filter(t => ['upload', 'sign'].includes(t.type))"
                        :key="`doc-template-${i}`"
                        class="flex flex-col items-center justify-start py-4 w-full rounded-xl border"
                        style="min-height: 65vh;">
                        <template v-if="controls.currentStep == 0">
                            <div v-if="controls.currentStep == 0"
                                class="flex flex-col items-center justify-center space-y-4 border-gray-400 p-4">
                                <img v-if="temp.type === 'upload'" class="w-16 h-16 object-contain"
                                    src="/static/img/doc-upload.png">
                                <img v-if="temp.type === 'sign'" class="w-16 h-16 object-contain"
                                    src="/static/img/doc-sign.png">
                                <label-item label="Document type" class="w-full">
                                    <div v-if="temp.type === 'upload'"
                                        class="flex items-center justify-between space-x-4 rounded-lg border-2 border-blue-500 text-parqay-primary bg-blue-50 hover:bg-blue-100 hover:text-blue-400 px-4 py-4 cursor-pointer">
                                        <span class="text-lg font-bold">Upload</span>
                                        <span class="text-blue-500 text-sm">The
                                            document type that allows your tenant to
                                            upload files.</span>
                                    </div>
                                    <div v-if="temp.type === 'sign'"
                                        class="flex items-center justify-between space-x-4 rounded-lg border-2 border-blue-500 text-parqay-primary bg-blue-50 hover:bg-blue-100 hover:text-blue-400 px-4 py-4 cursor-pointer">
                                        <span class="text-lg font-bold px-4">Sign</span>
                                        <span class="text-blue-500 text-sm">The
                                            document type that requires tenant or you
                                            to sign.</span>
                                    </div>
                                </label-item>
                                <label-item label="Enter Document Name"
                                    desc="The document name will be shown in tenant document email invitation and notification as the name of the required document."
                                    class="w-full">
                                    <a-input v-model="temp.name" placeholder="Enter document name." allow-clear size="large"
                                        :disabled="!temp.editable"
                                        @change="() => controls.updatePayload.name = temp.name" />
                                </label-item>
                            </div>
                            <div v-if="controls.currentStep == 0"
                                class="flex flex-col pt-4 space-y-4 h-full w-full items-center justify-center border-gray-400 overflow-y-auto">
                                <div class="flex flex-col w-full px-4 space-y-3">
                                    <label-item label="Message for Tenants"
                                        desc="Enter any message, instruction or guidance for the document." class="w-full">
                                        <a-textarea v-model="temp.signer_message" allow-clear
                                            placeholder="Message for tenants." :auto-size="{ minRows: 3, maxRows: 6 }"
                                            :disabled="!temp.editable" size="large"
                                            @change="() => controls.updatePayload.signer_message = temp.signer_message" />
                                    </label-item>
                                    <a-divider />
                                    <div v-if="!controls.expand.intervalMode" class="flex justify-between items-center">
                                        <span class="font-medium text-sm">Allowed durations</span>
                                        <div class="flex items-center">
                                            <a-tag v-if="temp.interval_modes.length == 3" color="green">All</a-tag>
                                            <a-tag v-else-if="temp.interval_modes.length != 1" color="geekblue">{{
                                                temp.interval_modes.length }}
                                                selected</a-tag>
                                            <a-tag v-else color="geekblue">{{ startCase(temp.interval_modes[0]) }}</a-tag>
                                            <a-button v-if="temp.editable"
                                                @click="() => controls.expand.intervalMode = true">Edit</a-button>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div class="flex items-center justify-between">
                                            <span class="font-medium text-sm">This template applies to:</span>
                                            <a-button type="primary" @click="() => {
                                                controls.expand.intervalMode = false;
                                                controls.updatePayload.interval_modes = temp.interval_modes;
                                            }">Confirm</a-button>
                                        </div>
                                        <a-checkbox-group class="listing-type-checkbox-group" v-model="temp.interval_modes">
                                            <div class="flex flex-wrap">
                                                <a-checkbox value="monthly" class="mx-2 my-1">
                                                    Monthly
                                                </a-checkbox>
                                                <a-checkbox value="daily" class="mx-2 my-1">
                                                    Daily
                                                </a-checkbox>
                                                <a-checkbox value="hourly" class="mx-2 my-1">
                                                    Hourly
                                                </a-checkbox>
                                            </div>
                                        </a-checkbox-group>
                                    </template>
                                    <a-divider />
                                    <div v-if="!controls.expand.listingType" class="flex justify-between items-center">
                                        <span class="font-medium text-sm">Allowed listing types</span>
                                        <div class="flex items-center">
                                            <a-tag v-if="temp.subtypes.length == 4" color="green">All</a-tag>
                                            <a-tag v-else-if="temp.subtypes.length != 1" color="geekblue">{{
                                                temp.subtypes.length }}
                                                selected</a-tag>
                                            <a-tag v-else color="geekblue">{{ startCase(temp.subtypes[0]) }}</a-tag>
                                            <a-button v-if="temp.editable"
                                                @click="() => controls.expand.listingType = true">Edit</a-button>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div class="flex items-center justify-between">
                                            <span class="font-medium text-sm">This template applies to:</span>
                                            <a-button type="primary" @click="() => {
                                                controls.expand.listingType = false;
                                                controls.updatePayload.subtypes = temp.subtypes;
                                            }">Confirm</a-button>
                                        </div>
                                        <a-checkbox-group class="listing-type-checkbox-group" v-model="temp.subtypes">
                                            <div class="flex flex-wrap">
                                                <a-checkbox value="parking" class="mx-2 my-1">
                                                    Parking
                                                </a-checkbox>
                                                <a-checkbox value="stay" class="mx-2 my-1">
                                                    Stay
                                                </a-checkbox>
                                                <a-checkbox value="coworking" class="mx-2 my-1">
                                                    Coworking
                                                </a-checkbox>
                                                <a-checkbox value="car-rental" class="mx-2 my-1">
                                                    Car Rental
                                                </a-checkbox>
                                            </div>
                                        </a-checkbox-group>
                                    </template>
                                </div>
                            </div>
                            <template v-if="controls.currentStep == 0">
                                <div v-if="temp.type === 'sign'"
                                    class="flex flex-col items-center justify-center h-full w-full pt-4 space-y-4 px-4 pb-2 overflow-y-auto">
                                    <template-uploader read-only :files="controls.model.docs_to_sign" class="w-full"
                                        @uploaded="(files) => {
                                            controls.model.docs_to_sign = files;
                                        }" />
                                </div>
                            </template>
                        </template>
                        <div v-if="controls.currentStep == 1"
                            class="flex-grow flex flex-col items-center justify-start p-4 space-y-4">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-16 h-16 text-parqay-primary transform -rotate-45">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                            </svg>
                            <span class="text-lg font-semibold pt-2">Select A Recipient</span>
                            <span class="text-center text-sm font-light pb-4">Choose a tenant to send invitation through
                                email
                                and notifications.</span>
                            <a-divider></a-divider>
                            <label-item label="Select A Reservation"
                                desc="Send invite to the tenant of an existing reservation." class="w-full pt-4">
                                <reservation-search-input
                                    @select="reservation => controls.selected.reservation = reservation"
                                    @select-tenant="tenant => controls.selected.tenant = tenant" />
                            </label-item>
                            <div class="flex-grow w-full flex items-end pt-4 space-x-4">
                                <a-button icon="left" size="large" @click.stop="() => {
                                    controls.currentStep -= 1;
                                    controls.selected.reservation = null;
                                    controls.selected.tenant = null;
                                }"></a-button>
                                <a-button type="primary" :loading="controls.loading.invite" size="large" block
                                    :disabled="!controls.selected.reservation"
                                    @click.stop="sendDocumentTemplateInvite()">Send
                                    Invite</a-button>
                            </div>
                        </div>
                        <div v-if="controls.currentStep == 2"
                            class="flex-grow flex flex-col items-center justify-start px-8 py-8 space-y-4">
                            <a-icon type="check-circle" class="text-green-400" style="font-size: 4rem;" />
                            <span class="text-lg font-semibold pt-2">Invitations Sent</span>
                            <span class="text-center text-sm font-light pb-4">Document invitations have been sent through
                                email and
                                notifications to your tenant</span>
                            <div v-if="controls.selected.tenant" class="flex items-center space-x-4">
                                <b>{{ controls.selected.tenant.name }}</b>
                                <a-tag>{{ controls.selected.tenant.email }}</a-tag>
                            </div>
                            <div class="flex-grow w-full flex items-end pt-4 space-x-4">
                                <a-button type="primary" size="large" block
                                    @click.stop="() => controls.currentStep = 0">Done</a-button>
                            </div>
                        </div>
                    </div>
                </keep-alive>
            </div>
        </div>
        <div class="doc-template w-full flex items-center justify-center px-8 py-4">
            <div v-if="controls.currentStep == 0" class="w-full flex items-center justify-between space-x-4">
                <a-button block size="large"
                    :disabled="!((templates[0].type === 'upload') || templates[0].type === 'sign' && templates[0].is_reviewed)"
                    @click.stop="() => controls.currentStep += 1">Invite Tenant</a-button>
                <a-popconfirm v-if="templates[0].editable" title="Deleting a template, please confirm." ok-text="Delete"
                    @confirm="deleteDocumentTemplates()">
                    <a-button type="danger" :loading="controls.loading.delete" block size="large">Delete</a-button>
                </a-popconfirm>
                <a-button v-if="templates[0].type === 'sign' && templates[0].is_reviewed" type="primary" block size="large"
                    :disabled="templates[0].docs_to_sign.length == 0" @click.stop="openPreview()">
                    Preview
                </a-button>
                <a-button v-if="templates[0].editable" type="primary" :loading="controls.loading.update" block size="large"
                    :disabled="shouldDisableUpdate" @click.stop="updateDocumentTemplate()">Update</a-button>
            </div>
        </div>
    </a-drawer>
</template>

<style lang="scss">
.doc-template {

    .ant-steps-icon {
        @apply flex items-center justify-end;
    }

    .ant-btn {
        @apply flex items-center justify-center;
    }

}

.template-status-tag {
    display: flex !important;
    @apply items-center justify-center space-x-2;
}
</style>