<script>

export default {
    props: {
        provider: {
            type: String,
            default: null,
        },
    },
};
</script>

<template>
    <div class="flex items-center justify-center">
        <a-icon v-if="provider === 'google.com'" type="google-circle" theme="filled" />
        <a-icon v-if="provider === 'apple.com'" type="apple" theme="filled" />
        <a-icon v-if="provider === 'facebook.com'" type="facebook" theme="filled" />
    </div>
</template>