<script>
import { ref, watch, onMounted, computed, onBeforeUnmount, nextTick } from "@vue/composition-api";
import NewPaymentMethodCard from "src/views/components/NewPaymentMethodCard";
import EventBus from "src/services/util/event-bus";
import { API } from "src/views/components/api";

export default {
  name: "PaymentMethodDrawer",
  components: {
    NewPaymentMethodCard,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, ctx) {
    const selectedPaymentMethod = ref(null);

    const showAddNewCard = ref(false);

    const toggleAddNewCardSection = () => {
      selectedPaymentMethod.value = null;
      showAddNewCard.value = !showAddNewCard.value;
    };

    const show = ref(false);

    const paymentMethods = ref([]);

    const listAllPaymentMethods = async () => {
      let resp;
      try {
        resp = await API.listAllPaymentMethods();
      } catch (error) {
        console.log(JSON.stringify(error.message));
        return;
      }
      console.log(JSON.stringify(resp.data));
      paymentMethods.value = resp.data;
    };

    const updateDefaultPaymentMethod = async (paymentMethod) => {
      paymentMethods.value.forEach((p) => {
        if (p.id === paymentMethod.id) {
          p.is_default = true;
        } else {
          p.is_default = false;
        }
      });
      let resp;
      try {
        resp = await API.updateDefaultPaymentMethod(paymentMethod.id);
      } catch (error) {
        console.log(JSON.stringify(error.message));
        return;
      }
      console.log(JSON.stringify(resp.data));
    };

    const deletePaymentMethod = async (paymentMethod) => {
      selectedPaymentMethod.value = paymentMethods.value.find((v) => v.is_default);
      paymentMethods.value.forEach((v, i) => {
        if (v.id === paymentMethod.id) {
          paymentMethods.value.splice(i, 1);
        }
      });
      let resp;
      try {
        resp = await API.deletePaymentMethod(paymentMethod.id);
      } catch (error) {
        console.log(JSON.stringify(error.message));
        return;
      }
      console.log(JSON.stringify(resp.data));
    };

    const handleOpenEvent = (role) => {
      if (role === "tenant") {
        show.value = true;
      }
    };

    const close = () => {
      show.value = false;
      ctx.root.$toggleIntercomPosition(false);
    };

    onMounted(() => {
      EventBus.$on("open-payment-method-drawer", handleOpenEvent);
      selectedPaymentMethod.value = ctx.root.$deepGet(
        paymentMethods.value.filter((v) => v.is_default),
        "[0]"
      );
      listAllPaymentMethods();
    });

    onBeforeUnmount(() => {
      EventBus.$off("open-payment-method-drawer", handleOpenEvent);
    });

    return {
      paymentMethods,
      selectedPaymentMethod,
      showAddNewCard,
      toggleAddNewCardSection,
      listAllPaymentMethods,
      updateDefaultPaymentMethod,
      deletePaymentMethod,
      show,
      close,
      console,
    };
  },
};
</script>
<template>
  <a-drawer
    class="payment-drawer"
    width="400px"
    :visible="show"
    placement="right"
    :footer="null"
    @close="close()"
  >
    <template #title>
      <div class="flex justify-between items-center">
        <p class="font-semibold text-lg text-gray-900 my-auto">
          {{ $deepGet(form, "transaction.payment_method") ? "Edit Payment Method" : "Complete Payment" }}
        </p>
      </div>
    </template>
    <div class="h-full flex flex-col justify-between">
      <div class="flex flex-col space-y-4">
        <span class="text-gray-600 font-medium text-base text-center pt-4">Choose payment method to complete reservation</span>
        <div
          v-if="!$deepGet(form, 'transaction.payment_method')"
          class="sm:col-span-3 rounded-lg bg-gray-50 border flex flex-col divide-y"
        >
          <div
            v-for="(v, i) in $deepGet(form, 'transaction.invoice_items', [])"
            :key="`transaction-invoice-item-${i}`"
            class="flex w-full items-center justify-between space-x-4 px-4 py-2"
          >
            <div class="text-base font-medium">
              {{ v.description }}
            </div>
            <div class="text-base font-medium">
              {{ $formatCurrency(v.amount) }}
            </div>
          </div>
          <div
            v-if="$deepGet(form, 'transaction.invoice_items', []).length === 0"
            class="h-12 flex w-full items-center justify-center space-x-4 text-gray-500 cursor-pointer"
          >
            <svg-icon
              icon-class="document"
              class="h-6 w-6"
            />
            <span class="leading-6 text-base font-semibold">No Invoice Item</span>
          </div>
        </div>
        <div class="rounded-lg border border-blue-500 bg-blue-100 flex justify-between px-6 py-2">
          <span class="text-gray-600 font-semibold text-lg">Total</span>
          <span class="text-blue-500 font-semibold text-lg">{{ $formatCurrency($deepGet(form, "transaction.final_charge")) }}</span>
        </div>
        <span class="text-center pb-4 border-b">Choose A Payment Method</span>
        <div class="flex flex-col space-y-2">
          <template>
            <div
              v-for="(v, i) in paymentMethods"
              :key="`payment-method-${i}`"
              class="flex flex-col"
            >
              <div
                class="rounded bg-blue-500 hover:bg-blue-500 cursor-pointer py-4 px-4 flex justify-between"
                @click="() => (selectedPaymentMethod = v)"
              >
                <span class="text-white font-bold">{{ v.brand }} {{ v.last4 }}</span>
                <div class="flex items-center space-x-2">
                  <a-tag
                    v-if="v.is_default"
                    color="geekblue"
                  >
                    Default
                  </a-tag>
                  <a-checkbox
                    :ref="`checkbox-${i}`"
                    :checked="$deepGet(selectedPaymentMethod, 'id') == v.id"
                    @change="
                      (e) => {
                        if (e.target.checked) {
                          selectedPaymentMethod = v;
                        } else {
                          selectedPaymentMethod = null;
                        }
                      }
                    "
                  />
                </div>
              </div>
              <div
                v-if="!v.is_default && $deepGet(selectedPaymentMethod, 'id') == v.id"
                class="flex justify-between py-2"
              >
                <div>
                  <a-button
                    type="danger"
                    @click="deletePaymentMethod(v)"
                  >
                    Delete
                  </a-button>
                </div>
                <div class="flex space-x-4 items-center text-parqay-primary">
                  <span class="">Make Default</span>
                  <a-switch
                    class="text-white border border-white"
                    size="large"
                    @change="
                      (checked) => {
                        updateDefaultPaymentMethod(v);
                      }
                    "
                  />
                </div>
              </div>
            </div>
            <div v-if="!showAddNewCard">
              <div
                class="rounded border border-indigo-500 py-3 px-4 flex justify-center cursor-pointer"
                @click="toggleAddNewCardSection"
              >
                <span class="text-parqay-primary font-bold hover:text-blue-800">+ Add New Card</span>
              </div>
            </div>
            <new-payment-method-card
              v-if="showAddNewCard"
              theme="simple"
              @refresh-list="
                () => {
                  showAddNewCard = false;
                  listAllPaymentMethods();
                }
              "
              @error="(message) => $openNotification('Error', message, null, 'error')"
            >
              <template slot="extraAction">
                <button
                  class="bg-white border border-parqay-primary hover:text-blue-800 text-parqay-primary font-bold py-2 px-4 rounded w-full"
                  @click="showAddNewCard = false"
                >
                  Cancel
                </button>
              </template>
            </new-payment-method-card>
          </template>
        </div>
        <template>
          <button
            v-if="$deepGet(form, 'transaction.payment_method')"
            class="bg-parqay-primary text-white px-4 py-2.5 text-base rounded-lg hover:bg-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            :disabled="!selectedPaymentMethod"
            @click="
              () => {
                form.transaction.payment_method = selectedPaymentMethod;
                close();
              }
            "
          >
            Update Payment Method
          </button>
          <button
            v-else
            class="bg-parqay-primary text-white px-4 py-2.5 text-base rounded-lg hover:bg-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            :disabled="!selectedPaymentMethod"
            @click="
              () => {
                form.transaction.payment_method = selectedPaymentMethod;
                close();
              }
            "
          >
            Use Payment Method
          </button>
        </template>
        <div class="h-20" />
      </div>
    </div>
  </a-drawer>
</template>

<style lang="scss" scoped>
::v-deep {
}
</style>