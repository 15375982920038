<script>
import { reactive } from "@vue/composition-api";
import VDropzone from "vue2-dropzone";
export default {
  name: "PQDropzone",
  components: {
    VDropzone
  },
  props: {
    height: {
      type: String,
      default: "300px"
    },
    intro: {
      type: String,
      default: "Drop Document File here"
    }
  },
  setup(props, ctx) {
    const dropzoneOptions = reactive({
      url: "post-images",
      autoProcessQueue: false,
      createImageThumbnails: false,
      previewTemplate: "<div/>"
    });
    const controls = reactive({
      dropzoneActive: false,
      showDropzoneHoverEffect: false
    });
    const toggleDropzoneActive = v => {
      controls.dropzoneActive = v;
    };

    const hoverDropzone = () => {
      controls.showDropzoneHoverEffect = true;
      toggleDropzoneActive(true);
    };
    const leaveDropzone = () => {
      controls.showDropzoneHoverEffect = false;
      toggleDropzoneActive(false);
    };
    return {
      dropzoneOptions,
      controls,
      toggleDropzoneActive,
      hoverDropzone,
      leaveDropzone
    };
  }
};
</script>
<template>
  <div
    class="
      flex
      w-full
      justify-center
      overflow-hidden
      rounded-lg
      border-dashed border-4 border-blue-300
      hover:border-blue-500
      bg-white
    "
    :class="controls.showDropzoneHoverEffect ? 'dropzone-animate-pulse' : ''"
    :style="{ height }"
    @mouseover="hoverDropzone"
    @mouseleave="leaveDropzone"
  >
    <v-dropzone
      :id="`pq-dropzone-${$shortUID()}`"
      :options="dropzoneOptions"
      use-custom-slot
      class="flex"
      @vdropzone-file-added="e => $emit('file-added', e)"
      @vdropzone-drag-over="toggleDropzoneActive(true)"
      @vdropzone-drag-leave="toggleDropzoneActive(false)"
    >
      <div class="bg-white w-full h-full">
        <div
          class="flex flex-col space-y-2 w-full h-full items-center justify-center"
          :class="{
            'text-blue-500': controls.showDropzoneHoverEffect,
            'text-gray-500': !controls.showDropzoneHoverEffect
          }"
        >
          <svg-icon
            icon-class="cloud-upload"
            class="transition-width ease-in-out duration-800"
            :class="controls.dropzoneActive ? 'w-16 h-16' : 'w-12 h-12'"
          />
          <span
            class="text-center dz-message"
            :class="controls.dropzoneActive ? 'animate-pulse' : ''"
            >{{ intro }}</span
          >
        </div>
      </div>
    </v-dropzone>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  div[id^="pq-dropzone-"] {
    @apply w-full flex justify-center items-center cursor-pointer;
  }
}
</style>
