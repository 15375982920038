import throttle from "lodash/throttle";
import { API } from "src/views/auth/api";

const APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;

export const launchIntercom = async () => {
  // try {
  //   const res = await API.getIntercomCredential()
  //   console.log("getIntercomCredential", res);
  //   boot(res.data.user_uid, res.data.credential_hash);
  // } catch (error) {
  //   console.warn("getIntercomCredential", error);
  //   boot();
  // }
}
const boot = throttle(
  (user_id = null, user_hash = null) => {
    if (!window.Intercom) {
      console.warn("Intercom not loaded");
      return;
    }
    if (user_id && user_hash) {
      console.log("Start Intercom for existing user...")
      window.Intercom("shutdown");
      window.intercomSettings = {
        app_id: APP_ID,
        user_id, // User ID
        user_hash, // HMAC using SHA-256
        horizontal_padding: 20
      }
      window.Intercom("boot", window.intercomSettings);
    } else {
      console.log("Start Intercom for new user...")
      window.Intercom("shutdown");
      window.intercomSettings = {
        app_id: APP_ID,
        horizontal_padding: 20
      }
      window.Intercom("boot", window.intercomSettings);
    }
  },
  10000,
  // Fire token refresh ONLY ONCE every 10 seconds to avoid 401 response through multiple requests at once
  { trailing: false }
);

export const initIntercom = () => {
  // (function () {
  //   var w = window;
  //   var ic = w.Intercom;
  //   if (typeof ic === "function") {
  //     ic("reattach_activator");
  //     ic("update", w.intercomSettings);
  //   } else {
  //     var d = document;
  //     var i = function () {
  //       i.c(arguments);
  //     };
  //     i.q = [];
  //     i.c = function (args) {
  //       i.q.push(args);
  //     };
  //     w.Intercom = i;
  //     var l = function () {
  //       var s = d.createElement("script");
  //       s.type = "text/javascript";
  //       s.async = true;
  //       s.src = "https://widget.intercom.io/widget/" + APP_ID;
  //       var x = d.getElementsByTagName("script")[0];
  //       x.parentNode.insertBefore(s, x);
  //     };
  //     if (document.readyState === "complete") {
  //       l();
  //     } else if (w.attachEvent) {
  //       w.attachEvent("onload", l);
  //     } else {
  //       w.addEventListener("load", l, false);
  //     }
  //   }
  // })();
};
