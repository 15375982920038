import Vue from "vue";
import vuescroll from "vuescroll";
// You can set global config here.
Vue.use(vuescroll, {
  ops: {
    // The global config
    vuescroll: {},
    scrollPanel: {},
    rail: {},
    bar: {
      size: "4px",
      background: "#847cf8",
    },
  },
  name: "smooth-scroll", // customize component name, default -> vueScroll
});
