import request from "src/services/api/request";

export const API = {
    control(action, payload={}) {
        if (action === "delete") {
            return request[action]("pricing/ctrl/" + payload["id"]);
        }
        return request[action]("pricing/ctrl", payload);
    }
}
