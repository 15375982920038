<script>
import EventBus from "src/services/util/event-bus";
import { onMounted } from "@vue/composition-api";
export default {
  props: {},
  setup(props, ctx) {
    onMounted(() => {
      EventBus.$on("toggle-loading-indicator", (visible, title) => {
        ctx.root.$controls.modal.loading.visible = visible;
        ctx.root.$controls.modal.loading.title = title;
      });
    });
    return {};
  }
};
</script>
<template>
  <a-modal
    :visible="$controls.modal.loading.visible"
    :closable="false"
    width="200px"
    :footer="null"
    dialog-class="rounded-lg overflow-hidden p-0 top-1/2 transform -translate-y-1/2"
    :mask-style="{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }"
    class="pq-loading"
    @ok="() => {}"
  >
    <div
      class="bg-white h-full flex flex-col space-y-4 justify-center items-center"
    >
      <svg-icon
        icon-class="PARQAY-watermark"
        class="w-3/5 h-3/5 animate-pulse"
      />
      <span
        class="loading-title text-sm font-medium text-gray-500 whitespace-nowrap"
        >{{ $controls.modal.loading.title }}</span
      >
    </div>
  </a-modal>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-modal {
    top: 50%;
  }
  .ant-modal-body {
    height: 200px;
    @apply rounded-lg overflow-hidden;
  }
  .loading-title:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 20px;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 20px;
    }
  }
}
</style>
