<script>
import {
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch
} from "@vue/composition-api";
import PQDropzone from "src/views/components/PQDropzone";
import { API } from "src/views/documents/api";
import { shortUID, convertFileToDataURL } from "src/plugins/prototype";
import { uploadFileToS3 } from "src/views/components/util";

export default {
  name: "document-uploader",
  components: {
    "pq-dropzone": PQDropzone
  },
  props: {
    docId: String,
    reservationId: String,
    hostId: String,
    template: {
      type: Object,
      default: () => ({})
    },
    allowedFileTypes: {
      type: Array,
      default: () => ["image/png", "image/jpeg", "application/pdf"]
    }
  },
  setup(props, ctx) {
    const form = reactive({
      name: null,
      tenant_message: null,
      filesToUpload: [],
      files: []
    });
    const controls = reactive({
      loading: false,
      errors: []
    });
    watch(
      () => form,
      val => {
        validateForm();
      },
      { deep: true }
    );
    const getFileNameExt = name => {
      const re = /(?:\.([^.]+))?$/;
      return re.exec(name)[1];
    }
    const handleFileAdd = async file => {
      console.log("handleFileAdd", file);
      if (!props.allowedFileTypes.includes(file.type)) {
        ctx.root.$openNotification(
          `Only ${props.allowedFileTypes.join()} formats are allowed`
        );
        return;
      }
      form.filesToUpload.push({
        name: file.name,
        blob: file,
        type: file.type,
        url: await convertFileToDataURL(file)
      });
    };

    const removeFile = i => {
      form.filesToUpload.splice(i, 1);
    };

    const previewPDF = f => {
      window.open(f.url);
    };

    const validateForm = () => {
      controls.errors = [];
      if (!form.name) {
        controls.errors.push("Document name is required");
      }
      if (form.filesToUpload.length === 0) {
        controls.errors.push("At least one file is required to upload");
      }
    };

    const updateDocument = async () => {
      if (controls.errors.length > 0) {
        return;
      }
      ctx.root.$bus.$emit("toggle-loading-indicator", true, "Uploading Documents");
      controls.loading = true;
      let resp;
      for (const f of form.filesToUpload) {
        const s3_path = "documents/" + shortUID() + "/" + form.name.replace(" ", "-") + "." + getFileNameExt(f.name);
        resp = await uploadFileToS3({ path: s3_path, fileBlob: f.blob });
        console.log("uploadFileToS3 >> resp: ", resp);
        form.files.push({
          name: form.name,
          dbx_path: null,
          s3_path: s3_path,
          url: null,
          type: f.type
        });
      }
      try {
        resp = await API.updateDocument({
          id: props.docId,
          payloads: {
            files: form.files,
            tenant_message: form.tenant_message,
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        ctx.root.$bus.$emit("toggle-loading-indicator", false);
        controls.loading = false;
      }
      ctx.emit("document-uploaded", resp.data);
      ctx.root.$openNotification("Document Upload", "Your document was uploaded, thank you.", 10, "info");
    };

    onMounted(() => { });

    return {
      form,
      controls,
      handleFileAdd,
      removeFile,
      previewPDF,
      updateDocument
    };
  }
};
</script>
<template>
  <div>
    <div v-if="form.filesToUpload.length > 0" class="pb-4 border-b">
      <viewer :images="form.filesToUpload.filter(f => f.type.includes('image/'))">
        <div class="grid grid-cols-3 gap-2 my-3">
          <img v-for="(f, i) in form.filesToUpload.filter(f =>
            f.type.includes('image/')
          )" :key="`${f.url}-${i}`" style="max-height: 100px"
            class="w-full object-cover border rounded overflow-hidden" :src="f.url" alt="" />
          <div
            class="flex flex-col justify-center items-center space-y-1 px-2 rounded cursor-pointer bg-parqay-primary hover:bg-blue-500"
            style="height: 100px;">
            <!-- <svg-icon icon-class="download" class="h-5 w-5 text-white" /> -->
            <span class="font-semibold text-sm text-white">
              {{
              form.filesToUpload.filter(f => f.type.includes("image/")).length
              }}
              images
            </span>
            <span class="font-semibold text-sm text-white">
              {{
              form.filesToUpload.filter(f => f.type.includes("/pdf")).length
              }}
              PDFs
            </span>
          </div>
        </div>
      </viewer>

      <div class="flex flex-col space-y-2">
        <div v-for="(f, i) in form.filesToUpload.filter(f =>
          f.type.includes('/pdf')
        )" :key="`${f.url}-${i}`" class="flex relative">
          <a target="_blank"
            class="flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full"
            @click="previewPDF(f)">
            <div class="flex items-center justify-center border rounded p-2 bg-gray-50">
              <span class="font-bold text-lg text-pink-500">PDF</span>
            </div>
            <span class="font-semibold text-base text-gray-600 pb-1 mr-10 overflow-ellipsis">
              {{ f.name }}
            </span>
          </a>
          <div class="absolute right-2 top-1/2 transform -translate-y-1/2">
            <div class="
                rounded-full
                bg-blue-500
                hover:bg-parqay-primary
                cursor-pointer
                text-white
                flex
                justify-center
                items-center
                p-1
              " @click="() => {}">
              <svg-icon icon-class="close" class="h-4 w-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex my-3 bg-gray-100">
      <pq-dropzone @file-added="handleFileAdd" />
    </div>

    <div class="flex flex-col justify-between items-center space-y-2 py-4">
      <span class="text-base font-medium text-gray-600">Document Name</span>
      <a-input placeholder="enter a name for easy reference." v-model="form.name" />
    </div>

    <div class="flex justify-between items-center py-4">
      <span class="text-base font-medium text-gray-600">Type</span>
      <a-tag color="geekblue" class="flex flex-col space-y-1 justify-center items-center cursor-pointer py-1">
        <span>{{ $deepGet(template, "name", "N/A") }}</span>
      </a-tag>
    </div>

    <div class="flex flex-col py-4">
      <div class="text-left w-full my-auto">
        <span class="text-base font-medium text-gray-600">Host Message: </span>
      </div>
      <div class="mt-2">
        <div class="bg-gray-50 rounded border px-4 py-4">
          {{ $deepGet(template, "signer_message", "N/A") }}
        </div>
      </div>
    </div>

    <div class="flex flex-col py-2">
      <div class="text-left w-full my-auto">
        <span class="text-base font-medium text-gray-600">Notes for Host</span>
      </div>
      <div class="mt-2">
        <a-textarea v-model="form.tenant_message"
          placeholder="enter any instructions or concerns to help your host identify your document"
          :auto-size="{ minRows: 3, maxRows: 3 }" />
      </div>
    </div>

    <div class="flex flex-col space-y-2 my-6">
      <div class="flex items-center space-x-4">
        <a-tag v-for="(error, i) in controls.errors" :key="`error-${i}`" color="pink">
          {{ error }}
        </a-tag>
      </div>
      <a-button type="primary" size="large" :loading="controls.loading" :disabled="controls.errors.length > 0" class="
          w-full
        " @click="updateDocument()">
        Upload Document
      </a-button>
    </div>
  </div>
</template>
